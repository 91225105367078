import { Game, User } from '@/api/model';
import { createModel } from '@rematch/core';
import { RootModel } from './index';

type ChallengeStore = {
  game: Game | undefined;
  opponent: Pick<User, 'id' | 'image' | 'username'> | undefined;
};

export const challenge = createModel<RootModel>()({
  state: {
    game: undefined,
    opponent: undefined,
  } as ChallengeStore,
  reducers: {
    setGame: (state, game: Game) => ({
      ...state,
      game,
    }),
    setOpponent: (state, opponent: Pick<User, 'id' | 'image' | 'username'>) => ({
      ...state,
      opponent,
    }),
    reset: () => ({
      game: undefined,
      opponent: undefined,
    }),
    resetGame: () => ({
      game: undefined,
      opponent: undefined,
    }),
    resetOpponent: () => ({
      game: undefined,
      opponent: undefined,
    }),
  },
  effects: () => ({}),
});
