import { Game } from '@/api/model';
import formatDate from '@/lib/general/date-format';
import { generateImageUrl } from '@/store/models/auth';
import { motion } from 'framer-motion';
import { FC } from 'react';

const variants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

type Props = {
  game: Game;
  onSelectGame: (game: Game) => void;
};

export const SubscriptionSelectGameItem: FC<Props> = ({ game, onSelectGame }) => {
  return (
    <motion.li
      variants={variants}
      className="mb-6 flex w-full max-w-screen-sm flex-col items-center justify-between rounded-xl bg-neutral-800"
    >
      <img
        src={generateImageUrl(game.desktopMobileGameSelectImageId || '', 'public')}
        className="h-60 w-full rounded-t-xl object-cover"
      />
      <div className="flex flex-col items-center px-2 pt-4">
        <p className="mb-1 text-xl font-bold">
          {game.host.firstName} {game.host.lastName}
        </p>
        <p className="text-sm font-light"> {game.name} </p>
        <p className="text-sm font-bold">{formatDate(game.startsAt, true)}</p>
      </div>

      <button type="button" className="btn bg-secondary m-4 rounded-full text-white" onClick={() => onSelectGame(game)}>
        Continue
      </button>
    </motion.li>
  );
};
