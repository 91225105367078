import { updateProfile } from '@/api/endpoints/fanalyst';
import { ScrollContainer } from '@/components/ScrollContainer';
import { Checkbox } from '@/components/ui/Checkbox';
import { Modal } from '@/components/ui/Modal';
import { OnboardingField } from '@/store/models/config';
import { Dispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { ActivityIndicatorFullScreen } from '../ui/ActivityIndicatorFullScreen';
import ContentContainer from '../ui/ContentContainer';
import Input from '../ui/Input';
import InputErrorMessage from '../ui/InputErrorMessage';
import InputGroup from '../ui/InputGroup';
import InputPhone from '../ui/InputPhone';
import AvatarUpload from './AvatarUpload';

export type ProfileFormData = {
  username: string;
  phoneNumber: string;
  phoneNumberNotifications: boolean;
  email: string;
  emailNotifications: boolean;
  zipCode: string;
  age: number;
};

const ProfileFormDataSchema = z.object({
  username: z.string().min(3),
  phoneNumber: z.string().refine(isPossiblePhoneNumber),
  email: z.string().email(),
  zipCode: z.string().min(1),
  age: z.coerce.number().min(0),
  phoneNumberNotifications: z.boolean(),
  emailNotifications: z.boolean(),
});

type ProfileFormProps = {
  children?: ReactNode;
  className?: string;
  buttonText?: string;
  onSaveSuccessful?: () => void;
  showModalOnSave?: boolean;
};

export const ProfileForm: FC<ProfileFormProps> = ({
  children,
  className,
  buttonText,
  onSaveSuccessful,
  showModalOnSave = true,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const config = useSelector((state: RootState) => state.config);
  const { user } = useSelector((state: RootState) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [apiError, setApiError] = useState('');

  const omittedSchema = Object.values(OnboardingField)
    .filter((xField) => !config.settings.onboardingFields.includes(xField as OnboardingField))
    .reduce((schema: any, yField: OnboardingField) => schema.omit({ [yField]: true }), ProfileFormDataSchema);

  const { handleSubmit, register, control, formState } = useForm<ProfileFormData>({
    defaultValues: {
      username: user?.username ?? '',
      email: user?.email ?? '',
      emailNotifications: user?.emailNotifications ?? false,
      phoneNumber: user?.phoneNumber ?? '',
      phoneNumberNotifications: user?.phoneNumberNotifications ?? true,
      age: 0,
      zipCode: '',
    },
    resolver: zodResolver(omittedSchema),
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch.auth.fetchUser().finally(() => setIsLoading(false));
  }, [dispatch.auth]);

  const onSubmit = async (data: ProfileFormData) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await updateProfile(data);
      if (showModalOnSave) setShowModal(true);
      onSaveSuccessful?.();
    } catch (error: any) {
      setApiError(error.response.data.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <div className="text-center">
          <div className="text-2xl font-bold">Profile Updated</div>
          <div className="mt-4 text-lg">Your profile has been updated successfully.</div>
          <div className="mt-8">
            <button onClick={() => setShowModal(false)} className="btn btn-primary">
              OK
            </button>
          </div>
        </div>
      </Modal>
      <ScrollContainer>
        <ContentContainer className={className}>
          {isLoading && <ActivityIndicatorFullScreen />}
          <AvatarUpload />
          {apiError !== '' && <InputErrorMessage message={apiError} />}
          <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <div className="grid grid-cols-1 gap-y-1 sm:mt-8 md:grid-cols-2 md:gap-x-4">
              {config?.settings.onboardingFields.includes(OnboardingField.Email) && (
                <InputGroup
                  title="EMAIL"
                  inputComponent={
                    <Input type="email" errors={formState.errors as FieldErrors} {...register('email')} />
                  }
                />
              )}
              <InputGroup
                title="USERNAME"
                inputComponent={
                  <Input errors={formState.errors as FieldErrors} type="text" required {...register('username')} />
                }
              />
              {config?.settings.onboardingFields.includes(OnboardingField.Phone) && (
                <InputGroup
                  title="PHONE #"
                  inputComponent={
                    <>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field: { onChange, value } }) => <InputPhone value={value} onChange={onChange} />}
                      />
                      {formState.errors.phoneNumber && <InputErrorMessage message="Invalid phone" />}
                    </>
                  }
                />
              )}
              {config?.settings.onboardingFields.includes(OnboardingField.ZipCode) && (
                <InputGroup
                  title="ZIPCODE"
                  inputComponent={
                    <Input errors={formState.errors as FieldErrors} type="text" required {...register('zipCode')} />
                  }
                />
              )}
              {config?.settings.onboardingFields.includes(OnboardingField.Age) && (
                <InputGroup
                  title="AGE"
                  inputComponent={
                    <Input errors={formState.errors as FieldErrors} type="number" required {...register('age')} />
                  }
                />
              )}
              <InputGroup
                title="NOTIFICATIONS"
                className="items-centers flex flex-col"
                inputComponent={
                  <>
                    <div className="mt-auto grid flex-1 grid-cols-2">
                      <Checkbox label="SMS" {...register('phoneNumberNotifications')} />
                      <Checkbox label="EMAIL" {...register('emailNotifications')} />
                    </div>
                  </>
                }
              />
            </div>
            <div className="flex flex-col gap-2 sm:mt-12">
              <button
                type="submit"
                className="btn btn-primary mb-2 text-center disabled:opacity-60 disabled:grayscale"
                onClick={() => console.log(formState.errors)}
              >
                {buttonText ?? 'SAVE'}
              </button>
            </div>
          </form>

          {children}
        </ContentContainer>
      </ScrollContainer>
    </>
  );
};
