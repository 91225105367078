import { getUpcomingGames } from '@/api/endpoints/fanalyst';
import { ImageVideoBackgroundProps } from '@/components/ui/ImageVideoBackground';
import { createModel } from '@rematch/core';
import { RootModel } from '.';

export interface BackgroundState {
  imageVideoBackgroundProps: ImageVideoBackgroundProps | null;
  visible: boolean;
}

export const background = createModel<RootModel>()({
  state: {
    visible: false,
    imageVideoBackgroundProps: null,
  } as BackgroundState,
  reducers: {
    SET_VISIBLE_STATE: (state: BackgroundState, visible = true): BackgroundState => ({
      ...state,
      visible,
    }),
    SET_BACKGROUND: (
      state: BackgroundState,
      imageVideoBackgroundProps: ImageVideoBackgroundProps,
    ): BackgroundState => ({
      ...state,
      imageVideoBackgroundProps,
    }),
    RESET_BACKGROUND: (state: BackgroundState): BackgroundState => ({
      ...state,
      visible: false,
      imageVideoBackgroundProps: null,
    }),
  },
  effects: (dispatch) => ({
    async loadBackground() {
      try {
        const games = await getUpcomingGames({
          onlyWithProductId: false,
        });

        if (!games || !games[0]) {
          return;
        }

        const game = games[0];

        dispatch.background.SET_BACKGROUND({
          desktopVideoUrl: game.desktopVideoUrl,
          mobileVideoUrl: game.mobileVideoUrl,
          desktopImageId: game.desktopGameImageId,
          mobileImageId: game.mobileGameImageId,
          fillScreen: true,
        });
      } catch (error) {
        console.error(error);
        dispatch.background.RESET_BACKGROUND();
      }
    },
  }),
});
