import SmallLogo from '@/components/ui/logo/SmallLogo';
import { RootState } from '@/store/store';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  loadingState: {
    error: string | null;
    loading: boolean;
  };
  verifyAuth?: () => void;
};

export const SplashScreen: FC<Props> = ({ loadingState, verifyAuth }) => {
  const { logo } = useSelector((state: RootState) => state.config);
  return (
    <div className="m-auto flex flex-col">
      {logo.icon && <SmallLogo className="h-16 w-16 animate-pulse" />}
      <AnimatePresence>
        {loadingState.error && (
          <motion.div
            className="mt-6 flex flex-col px-4"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
          >
            <h3 className="text-center text-lg font-bold">{loadingState.error}</h3>
            {verifyAuth && (
              <button className="btn btn-secondary mt-3" onClick={() => verifyAuth()}>
                Retry
              </button>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
