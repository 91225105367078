import { IChatMessage } from '@/store/models/chat';

export interface ChatMessageProps {
  message: IChatMessage;
  isSender: boolean;
}

export function ChatProfileImage({ profilePictureUrl, admin }: { profilePictureUrl: string; admin: boolean }) {
  if (admin) {
    return (
      <div className="mx-auto h-7 w-7 rounded-full border-2 border-blue-500 bg-blue-500 p-1 ring-2 ring-blue-700 ring-offset-2 ring-offset-white">
        <img className="object-contain" src="/white-logo.svg" alt="Admin Icon" />
      </div>
    );
  }

  return (
    <div
      className={`mx-auto flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border-2 border-white bg-white object-contain ${
        profilePictureUrl ? '' : 'p-1'
      }`}
    >
      <img className={`object-contain`} src={profilePictureUrl ?? '/img/defaultProfile.png'} alt="User profile icon" />
    </div>
  );
}

export function ChatMessage({ message }: ChatMessageProps) {
  if (message.hidden) {
    return <div className="h-12"></div>;
  }

  return (
    <div className="grid grid-cols-6 items-start gap-2 px-2 font-poppins md:grid-cols-12">
      <div className="col-span-1 mt-2 flex flex-col items-center">
        <ChatProfileImage profilePictureUrl={message.profilePictureUrl} admin={message.admin} />
      </div>
      <div className="col-span-5 flex flex-col items-start md:col-span-11">
        <p className="w-[90%] truncate whitespace-pre-line font-bold">
          {message.admin && (
            <>
              <span className="rounded bg-blue-500 px-1 text-xs text-white">Admin</span>
              &nbsp;&nbsp;
            </>
          )}
          {message.username}
        </p>
        <p className="max-w-full whitespace-pre-line break-words">{message.message}</p>
      </div>
    </div>
  );
}
