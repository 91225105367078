import { ForwardRefExoticComponent, ForwardedRef, HTMLProps, PropsWithoutRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface FormInputProps extends HTMLProps<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
}

export const FormInput: ForwardRefExoticComponent<PropsWithoutRef<FormInputProps>> = forwardRef(
  ({ errorMessage, ...props }: FormInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <div className={twMerge('flex flex-col gap-1 ', props.className)}>
        <div>{props.label && <label className="font-bold">{props.label}</label>}</div>
        <input
          {...props}
          className={`w-full rounded border ${errorMessage ? 'border-red-500' : ''} bg-transparent p-2`}
          ref={ref}
        />
        {errorMessage && <label className=" text-red-500">{errorMessage}</label>}
      </div>
    );
  },
);
