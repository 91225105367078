import { RootModel } from '@/store/models/index';
import { createModel } from '@rematch/core';

type MenuStore = {
  enabled: boolean;
  content: React.ReactElement | undefined;
  position: {
    x: number;
    y: number;
  };
};

export const menu = createModel<RootModel>()({
  state: {
    enabled: false,
    content: undefined,
    position: {
      x: 0,
      y: 0,
    },
  } as MenuStore,
  reducers: {
    enable: (state, positionAndContent: Omit<MenuStore, 'enabled'>) => ({
      enabled: true,
      ...positionAndContent,
    }),
    reset: () => ({
      enabled: false,
      content: undefined,
      position: {
        x: 0,
        y: 0,
      },
    }),
  },
  effects: () => ({}),
});
