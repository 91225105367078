import { Dialog } from '@headlessui/react';
import { FC } from 'react';
import { Modal } from '../ui/Modal';

type Props = {
  show: boolean;
  confirm: () => void;
  close: () => void;
};

export const StartVerificationModal: FC<Props> = ({ show, confirm, close }) => {
  return (
    <Modal show={show} onClose={close}>
      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-center">
        <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-white">
          VERIFICATION REQUIRED
        </Dialog.Title>
        <div className="my-8">
          <p>Before completing this action we'll have to perform a quick verification step</p>
          <p className="mt-2">It will only take 1 minute</p>
        </div>
      </div>
      <div className="flex-col justify-center sm:flex sm:flex-col">
        <button
          type="button"
          onClick={() => confirm()}
          className="inline-flex w-full items-center justify-center rounded-full bg-green-600 px-4 py-4 text-base font-bold text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm"
        >
          CONTINUE
        </button>
        <button
          type="button"
          onClick={() => close()}
          className="focus:ring-white-500 mt-2 w-full rounded-full  bg-transparent px-4 py-4 font-bold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          CANCEL
        </button>
      </div>
    </Modal>
  );
};
