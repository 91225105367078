export default function BackIcon() {
  /*
   */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0007 16.1571L12.5007 16.6451C12.289 16.8517 11.9466 16.8517 11.7371 16.6451L7.3585 12.3738C7.14678 12.1671 7.14678 11.833 7.3585 11.6286L11.7371 7.35505C11.9489 7.14841 12.2912 7.14841 12.5007 7.35505L13.0007 7.84308C13.2147 8.05192 13.2102 8.39265 12.9917 8.5971L10.2776 11.1208H16.2591C16.5587 11.1208 16.7997 11.356 16.7997 11.6483V12.3518C16.7997 12.6442 16.5587 12.8794 16.2591 12.8794H10.2776L12.9917 15.4031C13.2124 15.6075 13.217 15.9482 13.0007 16.1571Z"
        fill="white"
      />
      <circle cx="12" cy="12" r="11" stroke="white" strokeWidth="2" />
    </svg>
  );
}
