import { useGetChallenges } from "@/api/endpoints/fanalyst";
import VersusIcon from "@/assets/icons/challenges/versus.svg?react";
import FanalystTransparent from "@/assets/icons/ui/fanalyst-transparent.svg?react";
import { ScrollContainer } from "@/components/ScrollContainer";
import { Avatar } from "@/components/profile/Avatar";
import { generateImageUrl } from "@/store/models/auth";
import { Dispatch, RootState } from "@/store/store";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const listVariants: Variants = {
  visible: {
    transition: { staggerChildren: 0.1 },
  },
  hidden: {
    transition: { staggerChildren: 0.2, staggerDirection: -1 },
  },
};

const itemVariants: Variants = {
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
};

export const BeforeGameChallenges: FC = () => {
  const { gameId } = useParams();
  const { data: challenges } = useGetChallenges({ accepted: true, gameId });
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.game.SET_DIRECT_START(true);

    setTimeout(() => {
      navigate(`/game/${gameId}`);
    }, 4000);
  }, []);

  return (
    <ScrollContainer
      style={{ background: "radial-gradient(169.93% 78.52% at 51.69% 21.48%, #51A949 0%, #15301D 100%)" }}
      className="flex h-full w-full !max-w-full flex-col items-center py-8"
    >
      <motion.div className="flex flex-col items-center justify-center">
        <FanalystTransparent className="mb-8 h-14 w-14 drop-shadow-xl" />
        <div className="flex flex-row items-center gap-4">
          <h1 className="drop-shadow-xl">CHALLENGERS</h1>
          <div className="flex h-fit items-center justify-center rounded-full border-[1px] !border-solid border-white px-4 text-xl">
            {challenges?.length}
          </div>
        </div>
        <VersusIcon fill="white" className="mt-8 h-24 w-24 drop-shadow-xl" />
      </motion.div>

      <motion.ul variants={listVariants} initial="hidden" animate="visible" className="mt-12 grid grid-cols-2 gap-12">
        {challenges && (
          <AnimatePresence>
            {challenges.length > 0 &&
              challenges?.map(challenge => {
                const { challenger, challengee, wins, losses } = challenge;
                const opponent = challenger.id === userId ? challengee : challenger;

                return (
                  <motion.li
                    variants={itemVariants}
                    className="flex flex-col items-center justify-center gap-2"
                    key={challenge.id}
                    layout
                  >
                    <Avatar
                      size="h-32 w-32"
                      imageUrl={generateImageUrl(opponent.image?.id, "thumb")}
                      className="rounded-full border-[8px] !border-solid drop-shadow-md"
                    />
                    <p className="font-poppins text-xl font-bold drop-shadow-md">{opponent.username}</p>
                    <div className="-mt-2 flex items-center">
                      <p className="font-poppins">
                        W <span className="font-bold">{wins}</span>
                      </p>
                      <div className="mx-3 mb-0.5 h-1.5 w-1.5 rounded-full bg-white" />
                      <p className="font-poppins">
                        L <span className="font-bold">{losses}</span>
                      </p>
                    </div>
                  </motion.li>
                );
              })}
          </AnimatePresence>
        )}
      </motion.ul>
    </ScrollContainer>
  );
};
