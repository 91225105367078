import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function BaseHeader({
  children,
  className,
  safeTop = true,
}: {
  children?: React.ReactNode;
  className?: string;
  safeTop?: boolean;
}) {
  return (
    <div
      className={twMerge(
        `z-20 flex ${safeTop && 'mt-safe-top'} h-16 w-full flex-fixed items-center justify-between text-white md:my-6`,
        className,
      )}
    >
      {children}
    </div>
  );
}
