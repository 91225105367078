import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
  to: string;
  children: ReactNode;
  time?: number;
  className?: string;
};

export const RedirectButton: FC<Props> = ({ to, children, className, time }) => {
  const [timer, setTimer] = useState<number>(time ?? 3);
  const navigate = useNavigate();

  const decrease = useCallback(() => {
    const newTimer = timer - 1;
    if (newTimer === 0) {
      navigate(to);
      return;
    }

    setTimer(newTimer);
  }, [timer, navigate]);

  useEffect(() => {
    const interval = setTimeout(() => decrease(), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [decrease, navigate, timer]);

  return (
    <div className="mt-3.5 max-w-max">
      <Link to={to} className={className ? className : 'btn btn-primary'}>
        {children}
        <span>&nbsp;({timer})</span>
      </Link>
    </div>
  );
};
