import { Notification } from '@/api/model';
import NotificationChallengeRequest from '@/components/notification/type/NotificationChallengeRequest';
import NotificationFriendRequest from '@/components/notification/type/NotificationFriendRequest';
import NotificationFriendRequestAccepted from '@/components/notification/type/NotificationFriendRequestAccepted';
import NotificationGroupInvite from '@/components/notification/type/NotificationGroupInvite';

export function ConditionalNotification({
  notification,
  selected,
  refetch,
}: {
  notification: Notification;
  selected: boolean;
  refetch: () => void;
}) {
  switch (notification.type) {
    case 'FRIEND_REQUEST':
      return <NotificationFriendRequest selected={selected} notification={notification} />;
    case 'FRIEND_REQUEST_ACCEPTED':
      return <NotificationFriendRequestAccepted selected={selected} notification={notification} />;
    case 'CHALLENGE_REQUEST_OUTGOING':
    case 'CHALLENGE_REQUEST_INCOMING':
    case 'CHALLENGE_REQUEST_ACCEPTED':
    case 'CHALLENGE_REQUEST_DECLINED':
      return <NotificationChallengeRequest selected={selected} notification={notification} type={notification.type} />;
    case 'GROUP_INVITE_ACCEPTED':
    case 'GROUP_INVITE_DECLINED':
    case 'GROUP_INVITE_INCOMING':
      return (
        <NotificationGroupInvite
          refetch={refetch}
          selected={selected}
          notification={notification}
          type={notification.type}
        />
      );
    default:
      return <></>;
  }
}
