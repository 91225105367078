const getType = (val: string) => {
  if (val === 'true') return true;
  if (val === 'false') return false;
  return val;
};


export const parseNestedQuery = <T>(queryString: string): T => {
  const params = new URLSearchParams(queryString);
  const parsedParams: any = {};

  for (const [key, value] of params.entries()) {
    const match = key.match(/(\w+)\[(\w+)\]/);

    if (match) {
      const [, parentKey, childKey] = match;

      if (!parsedParams[parentKey]) {
        parsedParams[parentKey] = {};
      }

      parsedParams[parentKey][childKey] = getType(value);
    } else {
      parsedParams[key] = getType(value);
    }
  }

  return parsedParams;
};