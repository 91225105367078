import { Notification } from '@/api/model';
import { ReactNode, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export function BaseNotification({
  notification,
  children,
  selected,
  actions = null,
}: {
  notification: Notification;
  children: ReactNode;
  selected: boolean;
  actions?: ReactNode;
}) {
  const day = useMemo(() => new Date(notification.createdAt).getDate(), [notification.createdAt]);
  const shortMonth = useMemo(
    () => new Date(notification.createdAt).toLocaleString('default', { month: 'short' }).toUpperCase(),
    [notification.createdAt],
  );

  return (
    <div className={twMerge('flex justify-between p-4', selected ? 'bg-white/10' : '')}>
      <div className="flex items-center">
        <div className="mr-4 flex w-4 flex-col items-center justify-center text-center md:w-8">
          <p className="font-lemonmilk text-xs md:text-sm lg:text-base">{shortMonth}</p>
          <p className="md:text-l font-lemonmilk font-bold md:text-xl">{day}</p>
        </div>
        <div className={twMerge('flex flex-col')}>{children}</div>
      </div>
      {actions ? <div className="flex">{actions}</div> : null}
    </div>
  );
}
