import VersusIcon from "@/assets/icons/challenges/versus.svg?react";
import { classNames } from "@/lib/util/classNames";
import { useMemo } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { TabBarPropsInterface } from "./TabBar";
import { TabBarItem } from "./TabBarItem";

export function TabBarHome({ routes, className = "" }: TabBarPropsInterface) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeRouteIndex = useMemo(() => {
    return routes.findIndex(element => matchPath(element.routerLink ?? element.link, pathname));
  }, [pathname, routes]);

  const marginLeft = useMemo(() => {
    const width = (document.body.clientWidth - 60) / 4;
    let tabBarItemMarginLeft = width * activeRouteIndex;

    if (activeRouteIndex > 1) {
      // Add icon spacing
      tabBarItemMarginLeft += 60;
    }

    if (activeRouteIndex === -1) {
      return width * 2;
    }
    return tabBarItemMarginLeft;
  }, [activeRouteIndex, routes]);

  return (
    <div
      className={classNames("relative z-10 grid bg-gradientFade safe-padding-bottom md:hidden", className)}
      style={{
        gridTemplateColumns: `1fr 1fr 60px 1fr 1fr`,
      }}
    >
      {routes.slice(0, 2).map((route, index) => (
        <TabBarItem key={index} title={route.title} link={route.link} />
      ))}
      <div
        onClick={() => {
          navigate("/challenge");
        }}
        className="z-10 mx-auto mb-1 flex w-12 cursor-pointer items-center justify-center rounded bg-secondary text-black"
      >
        <VersusIcon fill="black" height={20} />
      </div>
      {routes.slice(2, 4).map((route, index) => (
        <TabBarItem key={index} title={route.title} link={route.link} />
      ))}
      <div
        className={classNames("absolute top-0 flex justify-center transition-all duration-[300ms] ease-in-out")}
        style={{
          opacity: activeRouteIndex > -1 ? 1 : 0,
          width: `calc(calc(100% - 60px) / 4)`,
          marginLeft: marginLeft + "px",
        }}
      >
        <div className="h-1 w-12 rounded bg-navigation" />
      </div>
    </div>
  );
}
