import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetProduct } from '@/api/endpoints/fanalyst';
import AuthenticatedLayout from '../../components/layout/AuthenticatedLayout';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import ContentContainer from '../../components/ui/ContentContainer';
import { BackHeader } from '@/components/ui/header/BackHeader';
import OrderOverview from '../../components/ui/payment/OrderOverview';
import { SubscriptionCardRibbon } from '@/components/ui/subscription/SubscriptionCardRibbon';
import { SubscriptionCardTrialOverlay } from '@/components/ui/subscription/SubscriptionCardTrialOverlay';
import SubTitle from '../../components/ui/SubTitle';
import Title from '../../components/ui/Title';
import { Connectors } from '@/lib/web3/connect';

export default function SubscriptionCrypto() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const from = searchParams.get('from') || '/subscription';
  const { productId } = useParams();
  const { data, isLoading } = useGetProduct(productId ?? '');

  return (
    <AuthenticatedLayout headerComponent={<BackHeader backUrl={from} />}>
      <ContentContainer className="mt-16">
        <Title>Connect Wallet</Title>
        {isLoading && <ActivityIndicatorFullScreen />}
        <SubTitle className="whitespace-nowrap">Connect with one of the providers below</SubTitle>
        {data && (
          <div className="relative mt-4 flex flex-col">
            {data.trial && (
              <SubscriptionCardTrialOverlay
                trial={data.trial}
                className="absolute -top-3 left-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            )}
            <OrderOverview price={data.prices[0].price} title={data.name} className="pt-4">
              {data.mostPopular && <SubscriptionCardRibbon />}
            </OrderOverview>
            <div className="mt-4 flex flex-col">
              <Connectors
                onConnectorClick={() => {
                  alert('Crypto payments are currently disabled');
                  navigate(from);
                }}
              />
            </div>
          </div>
        )}
      </ContentContainer>
    </AuthenticatedLayout>
  );
}
