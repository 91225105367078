/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Fanalyst API
 * Every week, tune into Fanalyst Trivia and see if you have what it takes.
 * OpenAPI spec version: 0.1
 */

export type CheckoutCreateType = (typeof CheckoutCreateType)[keyof typeof CheckoutCreateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckoutCreateType = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  LIFE_LINE: 'LIFE_LINE',
  TOP_UP: 'TOP_UP',
  NONE: 'NONE',
} as const;
