export function ActivityIndicator({ className = "" }: { className?: string }) {
  return (
    <div className={"lds-spinner " + className}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
