import { useEffect, useState } from 'react';

function userAgenctIsSafari(): boolean {
  const ua = navigator.userAgent.toLowerCase();

  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      return false; // Chrome has 'safari' in its user agent string
    } else {
      return true; // Safari
    }
  }
  return false;
}

function useUserAgent() {
  const [isSafari, setIsSafari] = useState<boolean>(false);

  useEffect(() => {
    setIsSafari(userAgenctIsSafari());
  }, []);

  return {
    isSafari,
  };
}

export default useUserAgent;
