import useDebounce from '@/hooks/useDebounce';
import screens from '@/lib/general/screens.json';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const watchWindowResize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // buffer for 100ms
  const debouncedValue = useDebounce(size, 100);

  const update = useCallback(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, [setSize]);

  useEffect(() => {
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [update]);

  return debouncedValue;
};

export const createBreakpoint = (size: 'sm' | 'md' | 'lg' | 'xl' | '2xl') => {
  const sizes = watchWindowResize();

  return useMemo(() => window.matchMedia(`(min-width: ${screens[size]})`).matches, [sizes]);
};

export const useSmBreakpoint = () => createBreakpoint('sm');
export const useMdBreakpoint = () => createBreakpoint('md');
export const useLgBreakpoint = () => createBreakpoint('lg');
export const useXlBreakpoint = () => createBreakpoint('xl');
export const use2xlBreakpoint = () => createBreakpoint('2xl');

export { screens };
