import { ImageVideoBackground } from '@/components/ui/ImageVideoBackground';
import { useBrowserProvider } from '@/lib/providers/browser.provider';
import { RootState } from '@/store/store';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';

export default function UpcomingImageVideoBackground() {
  const { isSafari } = useBrowserProvider();
  const isVisible = useSelector((state: RootState) => state.background?.visible);
  const data = useSelector((state: RootState) => state.background?.imageVideoBackgroundProps);

  return (
    <AnimatePresence>
      {isVisible && data && (
        <motion.div
          className="absolute inset-0 z-0 opacity-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          exit={{ opacity: 0 }}
        >
          <ImageVideoBackground {...data} muted={isSafari} />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
