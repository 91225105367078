
      try {
        // todo: make dynamic
        window.APM = elasticApm.init({
          serviceName: import.meta.env.VITE_APP_SERVICE_NAME ?? 'fanalyst-browser',
          serverUrl: 'https://28dd324050894329966fc9eb804df6ce.apm.us-central1.gcp.cloud.es.io:443',
          distributedTracingOrigins: [
            'https://api.fanalyst.io',
            'https://dev-api.fanalyst.io',
            'http://localhost:3000',
          ],
        });
      } catch (e) {
        console.log('Unable to load Elastic APM');
      }
    