import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { Socket } from 'socket.io-client';

export interface IChatMessage {
  id: string;
  userId: string;
  profilePictureUrl: string;
  username: string;
  message: string;
  admin: boolean;
  hidden?: boolean;
}

export interface ChatState {
  connected: boolean;
  disconnect: Socket.DisconnectReason | null;
  serverDisconnect: string | null;
  focus: boolean;
  open: boolean;
  lastId: number;
  messages: IChatMessage[];
}

export const chat = createModel<RootModel>()({
  state: {
    connected: false,
    disconnect: null,
    serverDisconnect: null,
    focus: false,
    open: false,
    lastId: 0,
    messages: [],
  } as ChatState,
  reducers: {
    SET_CONNECTED: (state: ChatState): ChatState => ({
      ...state,
      disconnect: null,
      serverDisconnect: null,
      connected: true,
    }),
    SET_DISCONNECT: (state: ChatState, disconnect: Socket.DisconnectReason | null): ChatState => ({
      ...state,
      disconnect,
      connected: false,
    }),
    SET_SERVER_DISCONNECT: (state: ChatState, serverDisconnect: string | null): ChatState => ({
      ...state,
      serverDisconnect,
      connected: false,
      open: false,
      focus: false,
      messages: [],
    }),
    RESET_DISCONNECT: (state: ChatState): ChatState => ({
      ...state,
      disconnect: null,
      serverDisconnect: null,
    }),
    SET_FOCUS: (state: ChatState, focus: boolean): ChatState => ({
      ...state,
      focus,
    }),
    ADD_END_MESSAGES: (state: ChatState, newMessages: IChatMessage[]): ChatState => {
      const messages = [...state.messages, ...newMessages];
      const totalMessages = messages.length;
      const lastMessageIndex = totalMessages;
      const maxMessages = 100;
      const startIndexUnbound = totalMessages - maxMessages;
      const startIndex = startIndexUnbound > 0 ? startIndexUnbound : 0;

      return {
        ...state,
        messages: messages.slice(startIndex, lastMessageIndex),
      };
    },
    REMOVE_MESSAGE: (state: ChatState, messageId: string): ChatState => ({
      ...state,
      messages: state.messages.filter(({ id }) => id !== messageId),
    }),
    SET_CHAT_OPEN: (state: ChatState, open: boolean): ChatState => ({
      ...state,
      open,
    }),
  },
});
