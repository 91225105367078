import { useStream } from '@/lib/providers/stream.provider';
import { IChatMessage } from '@/store/models/chat';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import ChevronDownIcon from '../../ui/icon/ChevronDownIcon';
import { ChatMessage } from './ChatMessage';

function ChatControls({ message }: { message: IChatMessage }) {
  const { stream } = useStream();

  return (
    <div className="absolute right-1 top-1 hidden rounded border bg-white text-sm text-black group-hover:flex">
      <button
        className="ml-auto border-r px-2 py-2 disabled:cursor-not-allowed disabled:opacity-50"
        // disabled={message.admin}
        onClick={() => stream.chatSocket?.emit('fanalyst.chat.admin.remove-message', message.id)}
      >
        Remove
      </button>
      <button
        className="ml-auto px-2 py-2 disabled:cursor-not-allowed disabled:opacity-50"
        // disabled={message.admin}
        onClick={() => stream.chatSocket?.emit('fanalyst.chat.admin.disconnect', message.userId)}
      >
        Disconnect
      </button>
    </div>
  );
}

export function ChatContent({
  scrollToBottom,
  sticky,
  floating,
}: {
  scrollToBottom: () => void;
  sticky: boolean;
  floating?: boolean;
}) {
  const messages = useSelector((state: RootState) => state.chat?.messages);
  const admin = useSelector((state: RootState) => state.auth?.user?.admin);

  return (
    <>
      {!floating && (
        <div className="absolute left-0 right-0 top-0 h-8 bg-gradient-to-t from-transparent to-white dark:to-black"></div>
      )}
      {!sticky && (
        <>
          <button
            onClick={scrollToBottom}
            className="absolute bottom-3 right-[0.7rem] z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-white p-2 text-black dark:bg-black dark:text-white"
          >
            <ChevronDownIcon />
          </button>
          <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-b from-transparent to-white dark:to-black"></div>
        </>
      )}
      {messages?.length < 8 && <div className="h-full"></div>}
      {messages.map((message, index) => (
        <div
          key={index}
          className={`group relative flex flex-col border border-transparent last:mb-2 ${
            admin ? 'hover:border-blue-500' : ''
          }`.trim()}
        >
          <ChatMessage message={message} isSender={false} />
          {admin && <ChatControls message={message} />}
        </div>
      ))}
    </>
  );
}
