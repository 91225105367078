import { useGetUpcomingGames } from '@/api/endpoints/fanalyst';
import { GameState } from '@/api/model';
import UpCommingGameNotificationModal from '@/components/game/UpCommingGameNotificationModal';
import { VideoButtonBanner } from '@/components/game/video/VideoButtonBanner';
import { GameHeader } from '@/components/ui/header/GameHeader';
import { Dispatch, RootState } from '@/store/store';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import AuthenticatedLayout from '../../components/layout/AuthenticatedLayout';

export default function GameRouter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { gameId } = useParams();
  const dispatch = useDispatch<Dispatch>();

  const directStart = useSelector((state: RootState) => state.game?.directStart);
  const gameQuestion = useSelector((state: RootState) => state.game.question);
  const gameIsDoxing = useSelector((state: RootState) => state.game.isDoxingActive);

  const [showModal, setShowModal] = useState(false);
  const { data: games } = useGetUpcomingGames({
    onlyWithProductId: false,
  });

  const currentActiveGame = useMemo(() => {
    let game = null;

    if (games) {
      const foundGame = games.find((game) => game.state === GameState.ACTIVE);
      if (foundGame !== undefined) {
        game = foundGame;
      }
    }

    return game;
  }, [games]);

  useEffect(() => {
    if (currentActiveGame !== null) {
      if (currentActiveGame.id !== gameId) {
        if (gameQuestion === null) {
          if (!gameIsDoxing) {
            // setShowModal(true);
          }
        }
      }
    }
  }, [currentActiveGame, gameId, gameQuestion, gameIsDoxing]);

  useEffect(() => {
    if (searchParams.has('direct')) {
      dispatch.game.SET_DIRECT_START(true);
      setSearchParams(
        {},
        {
          replace: true,
        },
      );
    }
  }, [searchParams, dispatch.game, setSearchParams]);

  return (
    <AuthenticatedLayout disableBackground={true} headerComponent={<GameHeader />}>
      <UpCommingGameNotificationModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentActiveGame={currentActiveGame}
      />
      {directStart ? (
        <Outlet />
      ) : (
        <VideoButtonBanner
          center={true}
          explanation="Would you like to join this game?"
          text="Join"
          onClick={() => dispatch.game.SET_DIRECT_START(true)}
        />
      )}
    </AuthenticatedLayout>
  );
}
