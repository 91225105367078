import { acceptRequest, denyRequest } from "@/api/endpoints/fanalyst";
import { Notification } from "@/api/model";
import Check from "@/assets/icons/ui/check.svg?react";
import Cross from "@/assets/icons/ui/cross.svg?react";
import { BaseNotification } from "@/components/notification/BaseNotification";
import { getFirstErrorMessageFromAxios } from "@/lib/general/api-helper";
import { Dispatch } from "@/store/store";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export default function NotificationFriendRequest({
  notification,
  selected,
}: {
  notification: Notification;
  selected: boolean;
}) {
  const { alert } = useDispatch<Dispatch>();

  const acceptFriendRequest = useCallback(async () => {
    try {
      await acceptRequest({
        friendId: notification.payload.friendId,
        userId: notification.payload.userId,
      });

      alert.set("Request accepted");
    } catch (e) {
      alert.set(getFirstErrorMessageFromAxios(e, "Failed to accept friend request"), "error");
    }
  }, [notification]);

  const denyFriendRequest = useCallback(async () => {
    try {
      await denyRequest({
        friendId: notification.payload.friendId,
        userId: notification.payload.userId,
      });

      alert.set("Request denied");
    } catch (e) {
      alert.set(getFirstErrorMessageFromAxios(e, "Failed to deny friend request"), "error");
    }
  }, [notification]);

  return (
    <BaseNotification
      selected={selected}
      notification={notification}
      actions={
        <div className="flex items-center">
          <button
            className="bg-secondary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full md:h-10 md:w-10"
            onClick={acceptFriendRequest}
          >
            <Check />
          </button>
          <button
            className="ml-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-2 border-red-700 md:ml-4 md:h-10 md:w-10"
            onClick={denyFriendRequest}
          >
            <Cross />
          </button>
        </div>
      }
    >
      <p className="text-sm font-bold capitalize md:text-base">{notification.payload.username}</p>
      <p className="text-sm text-white/80 md:text-base">Sent you a friend request</p>
    </BaseNotification>
  );
}
