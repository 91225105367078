import { useShopCheckout } from '@/api/endpoints/fanalyst';
import { ActivityIndicator } from '@/components/ui/ActivityIndicator';
import { BottomSheet } from '@/components/ui/BottomSheet';
import { formatShopPrice } from '@/lib/general/currency-format';
import { Dispatch, RootState } from '@/store/store';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  show: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export const ShoppingCart: FC<Props> = ({ show, onClose, onOpen }) => {
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch<Dispatch>();
  const { mutateAsync, isLoading } = useShopCheckout();
  const [showSheet, setShowSheet] = useState(false);
  const subtotal = cart.products.reduce((acc, product) => acc + parseFloat(product.variant.price), 0);
  const tax = subtotal * 0.1;
  const total = subtotal + tax;

  const createCheckout = async () => {
    if (!cart) return;

    const data = await mutateAsync({
      data: cart.products.map((product) => ({
        quantity: product.amount,
        variantId: product.variant.id,
      })),
    });

    setTimeout(() => {
      window.open(data.checkoutUrl);
    });
  };

  useEffect(() => {
    if (show) animate();
  }, [show]);

  const animate = () => {
    setTimeout(() => {
      setShowSheet(true);
    }, 100);
  };

  const animateOnClose = () => {
    setShowSheet(false);
    setTimeout(() => {
      onClose();
    }, 100);
  };

  return show ? (
    <BottomSheet show={showSheet} onClose={animateOnClose} onOpen={onOpen}>
      <div className="flex flex-col gap-2 px-6">
        <div>
          <h1 className="mt-6 text-center text-base">Checkout</h1>
        </div>
        <div className="flex max-h-96 w-full flex-col divide-y-2 divide-[#1C1C1C] overflow-auto">
          {cart.products.map((product) => (
            <div
              key={product.variant.id}
              className="flex w-full flex-row items-center justify-between gap-2 py-3 font-lemonmilk"
            >
              <div className="flex w-full flex-row items-center gap-3">
                <img src={product.image.src} alt="" className="h-12 w-12 rounded object-cover" />
                <div className="flex w-full flex-col items-start justify-start text-left">
                  <p>{product.title}</p>
                  <p className="text-sm text-white/60">{product.variant.title}</p>
                </div>
              </div>
              <p>{formatShopPrice(product.variant.price)}</p>
              <p>
                {product.amount}
                <span className="text-xs">x</span>
              </p>
            </div>
          ))}
        </div>

        <div className="flex w-full flex-row items-center justify-between text-sm">
          <p>SUBTOTAL</p>
          <p>{formatShopPrice(subtotal)}</p>
        </div>
        <div className="flex w-full flex-row items-center justify-between text-sm">
          <p>TAX</p>
          <p>{formatShopPrice(tax)}</p>
        </div>
        <div className="flex w-full flex-row items-center justify-between text-sm font-bold">
          <p>TOTAL</p>
          <p>{formatShopPrice(total)}</p>
        </div>
        <div className="mt-4">
          <button
            disabled={cart?.products.length <= 0}
            className="btn btn-green flex h-12 w-full items-center justify-center"
            onClick={createCheckout}
          >
            {isLoading ? <ActivityIndicator className="scale-50" /> : `PURCHASE ${formatShopPrice(total)}`}
          </button>
          <button
            disabled={cart?.products.length <= 0}
            className="btn btn-guidance-cancel mt-4 flex h-12 w-full items-center justify-center"
            onClick={() => dispatch.cart.toggleShow(false)}
          >
            CONTINUE SHOPPING
          </button>
        </div>
      </div>
    </BottomSheet>
  ) : null;
};
