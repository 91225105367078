

import { Logger } from '@/lib/dolby/logger';
import isNil from 'lodash-es/isNil';

export interface DolbyMediaOptions {
  constraints?: MediaStreamConstraints;
}

export enum DeviceType {
  audioinput = 'audioinput',
  videoinput = 'videoinput',
  audiooutput = 'audiooutput',
}

export type DolbyMediaMap = {
  // key string should be one of the DeviceType
  [key in DeviceType]: MediaDeviceInfo[];
};

/**
 * @class DolbyMedia
 * @classdesc It's in charge of the devices, their respective streams, and the states of those streams.
 * @param {Object} options
 * @param {mediaStream} options.MediaStream - the mediaStream of the selected devices.
 * @param {Object} options.constraints - the selected options of the selected devices (audio and video controls).
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints constraints}
 * @example const millicastMedia = new MillicastMedia();
 * @constructor
 */

export default class DolbyMedia {
  private logger: Logger = new Logger('DolbyMedia');
  public constraints: MediaStreamConstraints = {
    audio: true,
    video: true,
  };
  public mediaStream: MediaStream | null = null;
  private devices: DolbyMediaMap = {
    videoinput: [],
    audiooutput: [],
    audioinput: [],
  };
  constructor(options: DolbyMediaOptions) {
    //constructor syntactic sugar
    this.mediaStream = null;

    /*Apply Options*/
    if (options && !!options.constraints) {
      this.constraints = {
        ...this.constraints,
        ...options.constraints,
      };
    }
  }

  /**
   * Get Enumerate Devices.
   * @example const devices = await millicastMedia.getDevices;
   * @returns {Promise} devices - sorted object containing arrays with audio devices and video devices.
   */

  getInput(kind: 'audio' | 'video') {
    let input = null;
    if (!kind) return input;
    if (this.mediaStream) {
      for (const track of this.mediaStream.getTracks()) {
        if (track.kind === kind) {
          input = track;
          break;
        }
      }
    }
    return input;
  }

  /**
   * Get active video device.
   * @example const videoInput = millicastMedia.videoInput;
   * @returns {MediaStreamTrack}
   */

  get videoInput() {
    return this.getInput('video');
  }

  /**
   * Get active audio device.
   * @example const audioInput = millicastMedia.audioInput;
   * @returns {MediaStreamTrack}
   */

  get audioInput() {
    return this.getInput('audio');
  }

  getDeviceId(kind: 'audio' | 'video') {
    const input = this.getInput(kind);

    if (input) {
      return input.getSettings().deviceId;
    }
  }

  isMediaDomStringMap(domString: ConstrainDOMString | undefined): domString is ConstrainDOMStringParameters {
    return (
      typeof domString !== 'string' &&
      !Array.isArray(domString) &&
      domString !== undefined &&
      domString.exact !== undefined
    );
  }

  isMediaTrackConstraints(
    constraints: boolean | MediaTrackConstraints | undefined,
  ): constraints is MediaTrackConstraints & { deviceId: ConstrainDOMStringParameters } {
    return (
      typeof constraints !== 'boolean' &&
      constraints !== undefined &&
      this.isMediaDomStringMap(constraints.deviceId) &&
      constraints.deviceId.exact !== undefined
    );
  }

  checkIfConstraintsValid(constraints: MediaStreamConstraints, kind: 'audio' | 'video'): MediaStreamConstraints {
    if (!isNil(constraints[kind])) {
      const constraint = constraints[kind];

      if (!this.isMediaTrackConstraints(constraint)) {
        this.logger.warn(`The ${kind} constraint is not valid. Using the default device.`, constraints);
        return constraints;
      }

      const { deviceId, ...otherConstraints } = constraint;
      const exactDeviceId = deviceId.exact;
      const hasInput = !isNil(this.devices[`${kind}input`].find((device) => device.deviceId === exactDeviceId));

      // If the device is not found, remove the deviceId constraint (it will use the default device)
      if (!hasInput) {
        this.logger.warn(`The ${kind} device with id ${exactDeviceId} was not found. Using the default device.`, {
          ...constraints,
          [kind]: otherConstraints,
        });
        return {
          ...constraints,
          [kind]: otherConstraints,
        };
      }

      this.logger.warn(`The ${kind} device with id ${exactDeviceId} was found. Using it.`, {
        ...constraints,
        [kind]: {
          ...otherConstraints,
          deviceId,
        },
      });

      // If the device is found, add the deviceId constraint
      return {
        ...constraints,
        [kind]: {
          ...otherConstraints,
          deviceId,
        },
      };
    }

    // If constraints is not defined, return the original constraints
    return constraints;
  }

  /**
   * Get User Media.
   * @example const media = await MillicastMedia.getMedia();
   * @returns {MediaStream}
   */
  async getMedia() {
    //gets user cam and mic
    try {
      if (this.devices.audioinput.length === 0 || this.devices.videoinput.length === 0) {
        await this.getMediaDevices();
      }
      this.constraints = this.checkIfConstraintsValid(this.constraints, 'audio');
      this.constraints = this.checkIfConstraintsValid(this.constraints, 'video');
      this.logger.warn('Constraints: ', this.constraints);
      this.mediaStream = await navigator.mediaDevices.getUserMedia(this.constraints);
      this.logger.warn('MediaStream: ', this.mediaStream);
      return this.mediaStream;
    } catch (error) {
      this.logger.warn('Could not get Media: ', error, this.constraints);
      throw error;
    }
  }

  async getMediaDevices() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices)
      throw new Error('Could not get list of media devices!  This might not be supported by this browser.');

    try {
      const items: DolbyMediaMap = { audioinput: [], videoinput: [], audiooutput: [] };
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      for (const device of mediaDevices) {
        if (items[device.kind]) {
          items[device.kind].push(device);
        }
      }
      this.devices = items;
    } catch (error) {
      this.logger.warn('Could not get Media: ', error);
      this.devices = { audioinput: [], videoinput: [], audiooutput: [] };
    }
    return this.devices;
  }

  addMediaDevicesToList(items: DolbyMediaMap, device: MediaDeviceInfo) {
    if (device.deviceId !== 'default' && items[device.kind]) items[device.kind].push(device);
  }

  async changeSource(id: string, sourceType: 'audio' | 'video') {
    if (!id) throw new Error('Required id');

    const constraint = this.constraints[sourceType];

    this.constraints[sourceType] =
      id === 'default'
        ? {
            deviceId: { exact: id },
          }
        : {
            ...(typeof constraint === 'object' ? constraint : {}),
            deviceId: {
              exact: id,
            },
          };

    this.logger.warn('new constraints: ', this.constraints);
    return await this.getMedia();
  }

  /**
   * @param {boolean} boolean - true if you want to mute the video, false for mute it.
   * @returns {boolean} - returns true if it was changed, otherwise returns false.
   */

  muteVideo(boolean = true) {
    let changed = false;
    if (this.mediaStream) {
      this.mediaStream.getVideoTracks()[0].enabled = !boolean;
      changed = true;
    } else {
      this.logger.warn('There is no media stream object.');
    }
    return changed;
  }

  /**
   * @param {boolean} boolean - true if you want to mute the audio, false for mute it.
   * @returns {boolean} - returns true if it was changed, otherwise returns false.
   */

  muteAudio(boolean = true) {
    let changed = false;
    if (this.mediaStream) {
      this.mediaStream.getAudioTracks()[0].enabled = !boolean;
      changed = true;
    } else {
      this.logger.warn('There is no media stream object.');
    }
    return changed;
  }
}
