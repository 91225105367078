import { ScrollContainer } from '@/components/ScrollContainer';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BaseLayout from '../components/layout/BaseLayout';
import Title from '../components/ui/Title';
import FullLogoHeader from '../components/ui/header/FullLogoHeader';

export default function NotFound() {
  const session = useSelector((state: RootState) => state.auth?.session);

  return (
    <BaseLayout headerComponent={<FullLogoHeader />}>
      <ScrollContainer className="justify-center px-4 text-center">
        <Title>Page not found</Title>
        <p>Sorry, but this page could not be found. Try clicking the link again or going back to the app.</p>
      </ScrollContainer>
      <div className="mx-6 flex flex-col text-center safe-margin-bottom">
        <Link to="/" className="btn btn-primary mb-1">
          {session ? 'BACK TO APP' : 'LOGIN & REGISTER'}
        </Link>
      </div>
    </BaseLayout>
  );
}
