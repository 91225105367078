import VersusIcon from "@/assets/icons/challenges/versus.svg?react";
import { ChallengeAvatar } from "@/components/ui/game/ChallengeAvatar";
import { RootState } from "@/store/store";
import { FC } from "react";
import { useSelector } from "react-redux";

export const InGameChallengers: FC = () => {
  const challenges = useSelector((state: RootState) => state.game?.challenges);
  const userId = useSelector((state: RootState) => state.auth.user?.id);

  return challenges.length > 0 ? (
    <div className="absolute right-2 top-20 flex flex-col gap-1.5">
      <VersusIcon fill="white" className="absolute right-12 h-8 w-8" />
      {challenges
        ?.slice(0, 3)
        .map(challenge => <ChallengeAvatar key={challenge.id} challenge={challenge} userId={userId ?? ""} />)}
      {challenges.length >= 4 && (
        <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-green-600 bg-black">
          <span className="font-bold text-white">{challenges.length - 3}</span>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
