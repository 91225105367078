import { createModel } from '@rematch/core';
import isNil from 'lodash-es/isNil';
import { RootModel } from '.';
import { me } from '@/api/endpoints/fanalyst';
import { File, FullUser, UserSession } from '../../api/model';

export type ImageVariant = 'thumb' | 'profile' | 'public' | 'episode';

export const defaultImages: Record<ImageVariant, string> = {
  thumb: '/img/defaultProfile.png', // TODO: Change to default thumb
  profile: '/img/defaultProfile.png',
  public: '',
  episode: '/white-logo.svg',
};

/**
 * Generate image url from image id (or default image if id is null or undefined)
 * @param id
 * @param variant
 */
export function generateImageUrl(id: string | null | undefined, variant: ImageVariant) {
  if (id) {
    return `https://imagedelivery.net/tKbxQlinYL0bJpOWkzj0xg/${id}/${variant}`;
  } else {
    return defaultImages[variant];
  }
}

export const auth = createModel<RootModel>()({
  state: {
    session: null,
    user: null,
    picture: null,
  } as {
    session: UserSession | null;
    user: FullUser | null;
    picture: {
      thumb: string;
      profile: string;
      default: string;
    } | null;
  },
  reducers: {
    setSession: (state, session: UserSession | null) => {
      console.log('Set Session!!');
      return {
        ...state,
        session,
      };
    },
    setUser: (state, user: FullUser) => ({
      ...state,
      user,
      picture: !isNil(user?.image?.id)
        ? {
            thumb: generateImageUrl(user.image?.id, 'thumb'),
            profile: generateImageUrl(user.image?.id, 'profile'),
            default: generateImageUrl(user.image?.id, 'public'),
          }
        : null,
    }),
    setImageFile: (state, image: File) => ({
      ...state,
      picture: !isNil(image?.id)
        ? {
            thumb: generateImageUrl(image?.id, 'thumb'),
            profile: generateImageUrl(image?.id, 'profile'),
            default: generateImageUrl(image?.id, 'public'),
          }
        : null,
    }),
  },
  effects: (dispatch) => ({
    logout() {
      dispatch({ type: 'RESET_APP' });
    },
    async fetchUser() {
      const user = await me();
      dispatch.auth.setUser(user);
    },
  }),
});
