import { Country, countries } from '@/assets/data/countries';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import isCreditCard from 'validator/es/lib/isCreditCard';
import { z } from 'zod';
import { FormInput } from './FormInput';
import { FormSelect, FormSelectOption } from './FormSelect';

function mapToOptions(regions: Array<Country>): Array<FormSelectOption> {
  return regions.map((region) => {
    return {
      label: region.en,
      value: region.code,
    };
  });
}

function getDefaultOption(): FormSelectOption {
  return {
    label: 'United States',
    value: 'US',
  };
}

interface CreditCardFormProps {
  onSubmit?: (formData: CreditCardFormData) => void;
}

const CreditCardFormSchema = z.object({
  fullName: z.string().min(1, { message: 'Full name must be at least 1 character' }),
  cardNumber: z.string().refine(isCreditCard, { message: 'Invalid card number' }),
  expiration: z.string().regex(/^(0[1-9]|1[0-2])\/\d{2}$/, { message: 'Invalid format' }),
  cvc: z.string().length(3, { message: 'CVC must be 3 characters' }),
  country: z.string(),
});

type CreditCardFormData = z.infer<typeof CreditCardFormSchema>;

export const CreditCardForm: FC<CreditCardFormProps> = (props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreditCardFormData>({
    resolver: zodResolver(CreditCardFormSchema),
  });

  function handleValidSubmit(validData: CreditCardFormData) {
    props.onSubmit?.(validData);
  }

  return (
    <form onSubmit={handleSubmit(handleValidSubmit, console.error)}>
      <h1>Card</h1>
      <div className="flex flex-col">
        <FormInput
          label="Full Name"
          className="m-2"
          {...register('fullName')}
          placeholder="John Doe"
          errorMessage={errors.fullName?.message}
        />
        <div className="flex justify-between">
          <FormInput
            label="Card Number"
            className="basis-1/2 m-2"
            {...register('cardNumber')}
            placeholder="1234 1234 1234 1234"
            maxLength={19}
            errorMessage={errors.cardNumber?.message}
          />

          <FormInput
            label="Expiration"
            className="basis-1/4 m-2"
            {...register('expiration')}
            placeholder="MM/YY"
            maxLength={5}
            errorMessage={errors.expiration?.message}
          />
          <FormInput
            label="CVC"
            className="basis-1/4 m-2"
            {...register('cvc')}
            placeholder="CVC"
            maxLength={3}
            errorMessage={errors.cvc?.message}
          />
        </div>

        <FormSelect
          options={mapToOptions(countries)}
          defaultOption={getDefaultOption()}
          className="m-2"
          label="Country"
          {...register('country')}
        />
        <button className="btn-primary p-3 px-8 self-center" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};
