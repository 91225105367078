import { loginPassword } from '@/api/endpoints/fanalyst';
import BaseLayout from '@/components/layout/BaseLayout';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import ContentContainer from '@/components/ui/ContentContainer';
import Input from '@/components/ui/Input';
import SubTitle from '@/components/ui/SubTitle';
import Title from '@/components/ui/Title';
import { BackHeader } from '@/components/ui/header/BackHeader';
import { Dispatch, RootState } from '@/store/store';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

interface LoginForm {
  username: string;
  password: string;
}

function translateError(error: string) {
  switch (error) {
    case 'PASSWORD_NOT_VALID':
      return 'Username and/or password is incorrect';
    default:
      return 'Unknown error occurred';
  }
}

export default function Login() {
  const { alert } = useDispatch<Dispatch>();
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from') || '/';
  const { register, handleSubmit, formState } = useForm<LoginForm>({
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { isSubmitting } = formState;

  useEffect(() => {
    if (token) {
      navigate({
        pathname: from,
      });
    }
  }, [token, navigate, from]);

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const session = await loginPassword(data);
      dispatch.auth.setSession(session);
    } catch (err: any) {
      alert.set(translateError(err?.response?.data?.message ?? err?.message ?? 'UNKNOWN_ERROR'), 'error');
    }
  };

  return (
    <BaseLayout headerComponent={<BackHeader backUrl={`/login?from=${encodeURIComponent(from)}`} homeUrl="/" />}>
      <ContentContainer className="flex h-full w-full flex-col items-center justify-end gap-8 sm:justify-center">
        <div>
          <Title>WELCOME BACK</Title>
          <SubTitle className="text-sm">Login with your username and password</SubTitle>
        </div>
        {isSubmitting && <ActivityIndicatorFullScreen />}
        <form onSubmit={handleSubmit(onSubmit)} className="relative flex w-full max-w-[420px] flex-col gap-4 px-2">
          <Input
            data-cy="username"
            required
            autoFocus
            type="text"
            minLength={3}
            className="px-3 py-2 text-sm"
            placeholder="USERNAME"
            {...register('username', {
              required: 'Please enter your username.',
            })}
          />
          <Input
            data-cy="password"
            required
            type="password"
            autoComplete="current-password"
            placeholder="PASSWORD"
            className="px-3 py-2 text-sm"
            minLength={3}
            {...register('password', {
              required: 'Please enter your password.',
            })}
          />
          <button type="submit" className="btn btn-primary mt-2 rounded uppercase" data-cy="login-button">
            Login
          </button>
        </form>
        <div className="mb-4 flex w-full max-w-[420px] flex-col gap-8 px-2">
          <Link
            to={`/login/reset-password?from=${encodeURIComponent(from)}`}
            className="py-4 text-center text-sm uppercase text-white"
          >
            Forgot password
          </Link>
          <Link
            to={`/login/register?from=${encodeURIComponent('/login/password')}`}
            className="btn btn-secondary rounded text-center uppercase"
            data-testid="password-register-btn"
          >
            Register account
          </Link>
        </div>
      </ContentContainer>
    </BaseLayout>
  );
}
