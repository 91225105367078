import React from 'react';

export interface BaseLayoutProps {
  headerComponent?: React.ReactElement;
}

export default function BaseLayout({ children, headerComponent }: { children: React.ReactNode } & BaseLayoutProps) {
  return (
    <div className="container z-10 mx-auto flex h-full flex-1 flex-col">
      {headerComponent && headerComponent}
      {children}
    </div>
  );
}
