import { ApiProduct } from '@/api/model';
import { createModel } from '@rematch/core';
import { RootModel } from './index';

export interface CheckoutProduct extends ApiProduct {}

export interface CreditCard {
  fullName: string;
  cardNumber: string;
  expiration: string;
  cvc: string;
  country: string;
}

export type CheckoutState = {
  product?: CheckoutProduct;
  creditCard?: CreditCard;
};

export const defaultCheckoutState: CheckoutState = {
  product: undefined,
  creditCard: undefined,
};

export const checkout = createModel<RootModel>()({
  state: defaultCheckoutState,
  reducers: {
    SET_PRODUCT(state: CheckoutState, product: CheckoutProduct): CheckoutState {
      state.product = product;
      return state;
    },
    SET_CARD(state: CheckoutState, creditCard: CreditCard): CheckoutState {
      state.creditCard = creditCard;
      return state;
    },
  },
});
