import { Answer } from "@/components/game/question/Answer";
import { useStream } from "@/lib/providers/stream.provider";
import { classNames } from "@/lib/util/classNames";
import { Dispatch, RootState } from "@/store/store";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { Countdown10 } from "../../ui/countdown/Countdown10";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

export function Question({ className, center }: { className?: string; center?: boolean }) {
  const { stream } = useStream();
  const dispatch = useDispatch<Dispatch>();
  const selectedAnswerId = useSelector((state: RootState) => state.game.selectedAnswerId);
  const question = useSelector((state: RootState) => state.game?.question);
  const isChatOpen = useSelector((state: RootState) => state.chat?.open);
  const questionTimeReceivedUTC = useSelector((state: RootState) => state.game?.questionTimeReceivedUTC);
  const hidden = useSelector((state: RootState) => state.game?.hidden);
  const correctAnswerIds = useSelector((state: RootState) => state.game.correctAnswerIds);
  const answerCounts = useSelector((state: RootState) => state.game?.answerCounts);
  const spectatorMode = useSelector((state: RootState) => state.game?.spectatorMode);
  const direction = 1;

  const selectAnswer = useCallback(
    (selectedAnswerId: string) => {
      if (
        selectedAnswerId &&
        stream.gameSocket &&
        stream.gameSocket.auth &&
        spectatorMode === false &&
        (
          stream.gameSocket.auth as {
            [key: string]: any;
          }
        )["token"]
      ) {
        stream.gameSocket.emit(
          "fanalyst.game.answer",
          {
            answerId: selectedAnswerId,
          },
          (resp: any) => console.log(resp),
        );
      }

      dispatch.game.SET_SELECTED_ANSWER_ID(selectedAnswerId);
    },
    [stream.gameSocket, dispatch],
  );

  return (
    <div className="flex flex-col transition-all">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={question?.id ?? "end"}
          custom={direction}
          className={twMerge(
            `absolute z-10 flex w-full flex-col`,
            center ? "top-1/2 -mt-[300px] justify-center" : "bottom-0 justify-end",
            className || "",
          )}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          {question ? (
            <>
              <Countdown10 projectedEndUTC={question.projectedEndUTC} />
              <h4 className="mx-12 text-center text-lg font-bold sm:text-xl">{question.text}</h4>
              <div
                className={classNames(
                  "mx-4 mt-4 grid grid-cols-2 grid-rows-2 gap-4",
                  isChatOpen ? "mb-[70px] sm:mb-[175px]" : "",
                )}
              >
                {question.options.data.map(({ id, text }, index) => (
                  <Answer
                    key={index}
                    selected={selectedAnswerId === id}
                    correct={correctAnswerIds.includes(id)}
                    count={answerCounts ? answerCounts[id] : null}
                    disabled={hidden}
                    finished={correctAnswerIds.length > 0}
                    shouldShowColor={
                      correctAnswerIds.includes(id) || selectedAnswerId === id || selectedAnswerId === null
                    }
                    onClick={() => selectAnswer(id)}
                  >
                    {text}
                  </Answer>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
