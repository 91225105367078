import { RootState } from '@/store/store';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CountdownProps, Time, findClosestIndex, formatTimeRemaining } from './CountdownUtil';

export const CountdownContext = React.createContext<{
  timeRemaining: Time | null;
}>({ timeRemaining: null });

export default function CountdownBase({
  startDate,
  rings,
  children,
  startingSoonTimeInMinutes,
  className,
  ...props
}: CountdownProps & {
  children: React.ReactNode;
  rings: string[];
}) {
  const [closestIndex, setClosestIndex] = useState<number>(0);
  const [timeRemaining, setTimeRemaining] = useState<Time | null>(null);
  const { colors } = useSelector((state: RootState) => state.config);

  // Temporary percentage calculation
  // todo: refactor this
  useEffect(() => {
    const date = new Date(startDate);
    const remainingTime = formatTimeRemaining(date, startingSoonTimeInMinutes);

    setTimeRemaining(remainingTime);
    setClosestIndex(
      remainingTime.remaining.days > 0
        ? findClosestIndex(0, 24, rings.length)
        : findClosestIndex(24 - remainingTime.remaining.hours, 24, rings.length),
    );

    if (remainingTime.remaining.days <= 1 && remainingTime.remaining.future) {
      const interval = setInterval(
        () => {
          setTimeRemaining(formatTimeRemaining(date, startingSoonTimeInMinutes));
          setClosestIndex(
            remainingTime.remaining.days > 0
              ? findClosestIndex(0, 24, rings.length)
              : findClosestIndex(24 - remainingTime.remaining.hours, 24, rings.length),
          );
        },
        remainingTime.remaining.days === 1 ? 2000 : 250,
      );
      return () => clearInterval(interval);
    }
  }, [startDate, rings]);

  return (
    <div className={`relative ${className ?? ''}`} {...props}>
      <CountdownContext.Provider value={{ timeRemaining }}>{children}</CountdownContext.Provider>
      <svg width="220px" height="220px" viewBox="0 0 257 257" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group" fillRule="nonzero">
            <circle
              id="Oval"
              fillOpacity="0.5"
              fill="#000000"
              opacity="0.5"
              cx="128.171428"
              cy="127.894713"
              r="120.014"
            ></circle>
            <g id="circle-blocks">
              {rings
                .slice()
                .reverse()
                .map((item, index) => (
                  <path
                    key={'item-' + index}
                    id={'item-' + index}
                    d={item}
                    fill={index < closestIndex ? '#FFF' : colors.ringColor}
                    opacity={index < closestIndex ? '0.25' : '1'}
                  />
                ))}
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
