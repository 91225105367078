import { createModel } from '@rematch/core';
import { RootModel } from './index';

export const core = createModel<RootModel>()({
  state: {
    authorizationSet: false,
    hasInteracted: false,
    videoRef: null,
  } as {
    authorizationSet: boolean;
    hasInteracted: boolean;
    videoRef: HTMLVideoElement | null;
  },
  reducers: {
    setVideoRef: (state, videoRef: HTMLVideoElement | null) => ({
      ...state,
      videoRef,
    }),
    updateAuthorization: (state, authorizationSet: boolean) => ({
      ...state,
      authorizationSet,
    }),
    hasInteracted: (state) => ({
      ...state,
      hasInteracted: true,
    }),
  },
  effects: () => ({}),
});
