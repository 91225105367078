import { useCancelSubscription, useCreateCheckout, useGetSubscriptions, useOnboarding } from "@/api/endpoints/fanalyst";
import { CheckoutCreateType, SubscriptionProduct } from "@/api/model";
import { ScrollContainer } from "@/components/ScrollContainer";
import AuthenticatedLayout from "@/components/layout/AuthenticatedLayout";
import { ActivityIndicatorFullScreen } from "@/components/ui/ActivityIndicatorFullScreen";
import ContentContainer from "@/components/ui/ContentContainer";
import { HeaderNavigation } from "@/components/ui/header/HeaderNavigation";
import { SubscriptionCard } from "@/components/ui/subscription/SubscriptionCard";
import { Dispatch, RootState } from "@/store/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SubscriptionCanceled } from "./SubscriptionCanceled";

export default function Subscription() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string | null>(null);
  const [canceled, setCanceled] = useState(false);
  const { data, isLoading, refetch } = useGetSubscriptions({
    query: {
      cacheTime: 0,
    },
  });
  const { mutateAsync, isLoading: isCreatingCheckout } = useCreateCheckout();
  const { mutateAsync: mutateAsyncOnboarding, isLoading: isLoadingBoarding } = useOnboarding();
  const { mutateAsync: mutateAsyncCancelSubscription, isLoading: isLoadingCancelSubscription } =
    useCancelSubscription();
  const user = useSelector((state: RootState) => state.auth?.user);
  const dispatch = useDispatch<Dispatch>();

  const currentSubscriptionId = useMemo(() => data?.current?.product?.id, [data]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!data?.current && user?.finishedOnboarding) {
      setSelected("payPerPlay");
    } else {
      if (data && !selected) {
        const first = data.subscriptions.find(subscription =>
          currentSubscriptionId ? currentSubscriptionId === subscription.id : subscription.mostPopular,
        );
        if (first) setSelected(first.id);
      }
    }
  }, [currentSubscriptionId, data]);

  const startSubscription = useCallback(async () => {
    if (!selected) return;

    if (data?.current && selected === "payPerPlay") {
      if (window.confirm("Are you sure you want to cancel your subscription?")) {
        await mutateAsyncCancelSubscription();
        setCanceled(true);
      }

      return;
    }

    if (selected === "payPerPlay") {
      await mutateAsyncOnboarding();
      await dispatch.auth.fetchUser();
      await navigate("/");
      return;
    }

    const checkout = await mutateAsync({
      data: {
        productId: selected,
        type: CheckoutCreateType.SUBSCRIPTION,
      },
    });
    navigate(`/checkout/${checkout.id}?from=/subscription&to=/checkout/subscription-added`);
  }, [data, selected, mutateAsync, mutateAsyncOnboarding, mutateAsyncCancelSubscription, refetch, navigate, dispatch]);

  const paymentWithCrypto = useCallback(() => {
    if (!selected) {
      return;
    }

    navigate(`/subscription/crypto/${selected}?from=/subscription&to=/checkout/subscription-added`);
  }, [selected, navigate]);

  const getTrialDays = (subscription: SubscriptionProduct) => {
    if (
      data?.current &&
      data?.current.product.trailEnd &&
      currentSubscriptionId === subscription.id &&
      !data.hasCanceled
    ) {
      const currentDate = new Date();
      const trialEnd = new Date(data.current.product.trailEnd * 1000);
      if (trialEnd.getTime() <= currentDate.getTime()) {
        return null;
      }
      const daysLeft = Math.round(Math.abs((trialEnd.getTime() - currentDate.getTime()) / (24 * 60 * 60 * 1000)));
      return daysLeft > 0 ? daysLeft : null;
    }

    return data?.current || data?.hasCanceled ? null : subscription.trial;
  };

  if (canceled) {
    return <SubscriptionCanceled />;
  }

  return (
    <AuthenticatedLayout headerComponent={<HeaderNavigation title="MEMBERSHIP" />}>
      <div className={"absolute top-0 -z-10 h-full w-full"}></div>
      {isLoading || isCreatingCheckout || isLoadingBoarding || isLoadingCancelSubscription ? (
        <ActivityIndicatorFullScreen />
      ) : (
        <>
          <ScrollContainer className="px-4 md:block md:flex-[0_auto] lg:max-w-6xl">
            <div className="grid grid-cols-1 md:my-10 md:grid-cols-2 md:gap-x-2 xl:grid-cols-3 xl:gap-x-4">
              {data?.subscriptions.map(subscription => (
                <SubscriptionCard
                  key={subscription.id}
                  title={subscription.name}
                  description={subscription.description}
                  price={subscription.prices[0].price}
                  onClick={() => setSelected(subscription.id)}
                  selected={subscription.id === selected}
                  monthPrice={subscription.prices[0].monthPrice}
                  hasRibbon={subscription.mostPopular}
                  trial={getTrialDays(subscription)}
                  isCurrent={currentSubscriptionId === subscription.id}
                />
              ))}
            </div>
          </ScrollContainer>
          <ContentContainer>
            {!data?.current && !user?.finishedOnboarding && selected === "payPerPlay" && (
              <button
                disabled={currentSubscriptionId === selected || !selected}
                className="btn btn-secondary my-2"
                onClick={() => navigate("/pay-per-play")}
              >
                CONTINUE WITHOUT SUBSCRIPTION
              </button>
            )}
            {(selected !== "payPerPlay" || data?.current) && (
              <button
                disabled={currentSubscriptionId === selected || !selected}
                className="btn btn-primary my-2 md:py-4"
                onClick={startSubscription}
                data-testid="pay-with-credit-card"
              >
                {data?.current && selected === "payPerPlay" ? "CANCEL SUBSCRIPTION" : "PAY WITH CREDIT CARD"}
              </button>
            )}
            {selected !== "payPerPlay" && (
              <button
                disabled={currentSubscriptionId === selected || !selected}
                className="btn btn-secondary my-2"
                onClick={paymentWithCrypto}
              >
                PAY WITH CRYPTO
              </button>
            )}
          </ContentContainer>
        </>
      )}
    </AuthenticatedLayout>
  );
}
