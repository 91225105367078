import { HelmetConfig } from '@/components/config/HelmetConfig';
import { ThemeConfig } from '@/components/config/ThemeConfig';
import { AddToHomeScreenPopup } from '@/components/ui/AddToHomeScreenPopup';
import UpcomingImageVideoBackground from '@/components/ui/UpcomingImageVideoBackground';
import { useMdBreakpoint } from '@/hooks/tailwind';
import { getPersistor } from '@rematch/persist';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { WagmiConfig } from 'wagmi';
import './app.css';
import NotificationManager from './components/ui/alert/AlertManager';
import { BrowserProvider } from './lib/providers/browser.provider';
import { StreamProvider } from './lib/providers/stream.provider';
import { client } from './lib/web3/wagmi';
import reportWebVitals from './reportWebVitals';
import AppRouter from './router';
import { Dispatch, RootState, store } from './store/store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const persistor = getPersistor();

function App() {
  const enabled = useSelector((state: RootState) => state.admin.enabled);
  const dispatch = useDispatch<Dispatch>();
  const md = useMdBreakpoint();

  useEffect(() => {
    const handle = () => {
      dispatch.core.hasInteracted();
      document.removeEventListener('click', handle);
      document.removeEventListener('touchstart', handle);
      document.removeEventListener('touchmove', handle);
    };

    document.addEventListener('click', handle);
    document.addEventListener('touchstart', handle);
    document.addEventListener('touchmove', handle);
    dispatch.background.loadBackground();

    return () => {
      document.removeEventListener('click', handle);
      document.removeEventListener('touchstart', handle);
      document.removeEventListener('touchmove', handle);
    };
  }, [dispatch]);

  return (
    <div className={enabled ? 'admin-container' : 'main-container'}>
      <HelmetConfig />
      <ThemeConfig />
      <AppRouter />
      {md ? <UpcomingImageVideoBackground /> : null}
      <AddToHomeScreenPopup />
    </div>
  );
}

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <StreamProvider>
          <BrowserProvider>
            <WagmiConfig client={client}>
              <App />
              <NotificationManager />
            </WagmiConfig>
          </BrowserProvider>
        </StreamProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
