/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Fanalyst API
 * Every week, tune into Fanalyst Trivia and see if you have what it takes.
 * OpenAPI spec version: 0.1
 */

export type GameState = (typeof GameState)[keyof typeof GameState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GameState = {
  NONE: 'NONE',
  ACTIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
} as const;
