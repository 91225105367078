import { useStream } from "@/lib/providers/stream.provider";
import { Dispatch } from "@/store/store";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ChevronDownIcon from "../../ui/icon/ChevronDownIcon";

export function ChatBar({ forceChatOpen }: { forceChatOpen?: boolean }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { stream } = useStream();
  const [text, setText] = useState<string>("");
  const dispatch = useDispatch<Dispatch>();

  const send = useCallback(() => {
    if (`${text}`.trim() === "") {
      return;
    }

    if (inputRef.current) {
      inputRef?.current?.focus();
    }

    setText("");
    stream.chatSocket?.emit("fanalyst.chat.message", text);
  }, [text, stream.chatSocket]);

  return (
    <div className="max-w-screen flex w-full px-5">
      <form
        onSubmit={event => {
          send();
          event.preventDefault();
        }}
        className="relative flex w-full"
      >
        <input
          ref={inputRef}
          onChange={({ target }) => setText(target.value)}
          onFocus={() => dispatch.chat.SET_FOCUS(true)}
          onBlur={() => dispatch.chat.SET_FOCUS(false)}
          value={text}
          type="text"
          placeholder="Comment"
          className="text-normal w-full rounded-full border bg-transparent p-3 px-4 pr-16 outline-none focus:outline-none focus:ring focus:ring-white/50"
        />
        <button
          onClick={() => send()}
          className="transform-translate absolute right-4 top-1/2 -translate-y-1/2 p-4 pr-0 font-bold"
        >
          SEND
        </button>
      </form>
      {!forceChatOpen && (
        <button className="p-4 pr-0" onClick={() => dispatch.chat.SET_CHAT_OPEN(false)}>
          <ChevronDownIcon />
        </button>
      )}
    </div>
  );
}
