import { useGetAuthenticatedGame } from '@/api/endpoints/fanalyst';
import { Game } from '@/api/model';
import IntroCountdown from './IntroCountdown';

export function IntroCountdownAccess({ game }: { game: Game }) {
  const { data: access, isLoading } = useGetAuthenticatedGame(game.id);

  return (
    <>
      {!isLoading && access ? (
        <div className={access.canAccess ?? true ? 'cursor-pointer' : 'cursor-not-allowed'}>
          <IntroCountdown game={game} access={access} />
        </div>
      ) : (
        <IntroCountdown game={game} />
      )}
    </>
  );
}
