import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function InputGroup({
  title,
  className,
  inputComponent,
}: {
  title: string;
  className?: string;
  inputComponent: React.ReactElement;
}) {
  return (
    <div className={twMerge('my-2', className)}>
      <p className="font-bold">{title}</p>
      {inputComponent}
    </div>
  );
}
