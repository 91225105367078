import CountdownBase, { CountdownContext } from './CountdownBase';
import { CountdownProps } from './CountdownUtil';

const countdownRingLarge: string[] = [
  'M59.251819,21.4226637 C58.8434222,20.7937761 59.02196,19.9521366 59.6550565,19.5503926 C77.1548858,8.4469693 97.0807929,1.74100724 117.734157,0.00477783828 C118.480893,-0.0579479973 119.131232,0.504412199 119.186898,1.2522345 L119.940423,11.5073655 C119.994731,12.2551878 119.432642,12.9051198 118.685906,12.9689318 C100.217085,14.5435947 82.3977881,20.5404289 66.7347939,30.4523329 C66.1011544,30.8532623 65.260601,30.6752676 64.8522043,30.0465157 L59.251819,21.4226637 Z',
  'M11.2599021,83.049711 C9.75407471,82.4713299 8.99892082,80.780312 9.61097291,79.2880616 C17.07494,61.0907808 28.6174442,44.8489983 43.3479685,31.8156015 C44.555916,30.7468185 46.4010332,30.9039046 47.4421191,32.1358834 L52.0618362,37.602535 C53.1029221,38.8345139 52.9454287,40.6744717 51.7401965,41.7463775 C38.6788311,53.3626049 28.4238358,67.7926698 21.7492367,83.947152 C21.1333831,85.4379089 19.4475244,86.1927913 17.941697,85.6144102 L11.2599021,83.049711 Z',
  'M7.1994225,160.555491 C5.64145938,160.972306 4.03665553,160.049068 3.65473394,158.480922 C-1.002863,139.372624 -1.21154181,119.448346 3.04485417,100.245008 C3.39391938,98.6700733 4.97903654,97.7128933 6.54541742,98.0984807 L13.4960374,99.8051122 C15.0624183,100.189342 16.0164755,101.771065 15.6714834,103.347357 C11.9325077,120.421818 12.117834,138.123538 16.2136138,155.116537 C16.5914623,156.684683 15.6708046,158.285413 14.1128414,158.702228 L7.1994225,160.555491 Z',
  'M47.7773357,224.252084 C46.7405944,225.487593 44.8958846,225.651875 43.6842713,224.587436 C28.9083997,211.605089 17.3092794,195.40363 9.78177183,177.232146 C9.1644247,175.742747 9.91374048,174.048334 11.4175313,173.46588 L18.0903654,170.876742 C19.5941562,170.294288 21.2825945,171.042382 21.9037432,172.530423 C28.634687,188.662639 38.9399172,203.055639 52.0417422,214.627334 C53.2507759,215.694488 53.4146505,217.534174 52.3779092,218.769683 L47.7773357,224.252084 Z',
  'M121.928098,253.460328 C121.843921,255.070563 120.469926,256.311503 118.861049,256.190667 C99.2476854,254.714845 80.2341541,248.757248 63.2867738,238.775423 C61.8970299,237.956728 61.4762781,236.152342 62.3261996,234.782421 L66.0977601,228.698558 C66.9476816,227.328637 68.7462296,226.909107 70.1381457,227.725086 C85.222623,236.556938 102.115152,241.850618 119.541258,243.206962 C121.150135,243.331871 122.387002,244.701792 122.302824,246.313385 L121.928098,253.460328 Z',
  'M196.894976,233.373126 C197.773409,234.725397 197.390537,236.536572 196.0179,237.385136 C179.284222,247.719964 160.398587,254.075367 140.820523,255.961216 C139.215719,256.115994 137.81457,254.903566 137.69645,253.294689 L137.172377,246.157249 C137.054257,244.548372 138.26397,243.152654 139.868774,242.993803 C157.263652,241.273595 174.042135,235.626912 188.937484,226.480073 C190.311478,225.63694 192.11858,226.017096 192.997013,227.370725 L196.894976,233.373126 Z',
  'M245.73695,173.055854 C247.242642,173.634235 247.997524,175.325932 247.3852,176.818047 C239.921912,195.015327 228.378729,211.25616 213.649019,224.290099 C212.440664,225.358611 210.595547,225.202475 209.55419,223.969682 L204.935287,218.503573 C203.89393,217.27078 204.051423,215.431093 205.257063,214.359866 C218.318157,202.743368 228.57288,188.31371 235.247344,172.158413 C235.863741,170.667656 237.548649,169.914132 239.055698,170.491155 L245.73695,173.055854 Z',
  'M250.349064,97.6721623 C251.914494,97.2825018 253.503006,98.2342509 253.857366,99.8078276 C258.180289,118.994873 258.041804,138.920509 253.451413,158.0451 C253.073972,159.614604 251.473241,160.543272 249.913242,160.131888 L242.993034,158.303063 C241.434392,157.89168 240.508439,156.293664 240.88045,154.724161 C244.916898,137.717585 245.040449,120.014507 241.241598,102.953623 C240.891311,101.378689 241.840345,99.7942506 243.404418,99.4045901 L250.349064,97.6721623 Z',
  'M209.221553,31.8534814 C210.258837,30.6179725 212.103954,30.4543694 213.315025,31.5189436 C228.090896,44.5007477 239.689745,60.7022065 247.218203,78.8734194 C247.8346,80.3634974 247.085148,82.0572308 245.582172,82.639685 L238.909066,85.2288228 C237.404732,85.811277 235.717109,85.0631831 235.095281,83.5751417 C228.365152,67.4437403 218.058836,53.0495188 204.957011,41.4789102 C203.748656,40.4112133 203.584374,38.5717985 204.621658,37.3362897 L209.221553,31.8534814 Z',
  'M137.257912,2.77937778 C137.370602,1.17036506 138.767677,-0.0461359893 140.372481,0.102939698 C159.957333,1.92035969 178.864692,8.20991452 195.635028,18.4860899 C197.01038,19.3288156 197.400041,21.1393113 196.525681,22.4951126 L192.648084,28.5112262 C191.775081,29.8670275 189.969338,30.2542441 188.59127,29.4150485 C173.664693,20.3207527 156.867203,14.7328583 139.465536,13.0730676 C137.860732,12.9199188 136.646947,11.5282741 136.759636,9.91926142 L137.257912,2.77937778 Z',
];

export default function CountdownLarge({ ...props }: CountdownProps) {
  return (
    <CountdownBase {...props} rings={countdownRingLarge}>
      <CountdownContext.Consumer>
        {({ timeRemaining }) =>
          timeRemaining && (
            <div className="absolute left-1/2 top-1/2 z-0 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center whitespace-nowrap">
              {timeRemaining.remaining.future && !timeRemaining.remaining.startingSoon ? (
                timeRemaining.remaining.days > 0 ? (
                  <>
                    <p className="mb-1 text-lg font-bold">NEXT GAME</p>
                    <p className="mb-1 text-lg">
                      {timeRemaining.date} &bull; {timeRemaining.time}
                    </p>
                    <p className="flex items-center text-2xl font-bold">
                      {timeRemaining.remaining.daysStr} DAY
                      {timeRemaining.remaining.days > 1 ? 'S' : ''} LEFT
                    </p>
                  </>
                ) : (
                  <p className="flex items-center text-2xl font-bold">
                    {timeRemaining.remaining.hoursStr}
                    <span className="mx-0.5 text-xl">:</span>
                    {timeRemaining.remaining.minutesStr}
                    <span className="mx-0.5 text-xl">:</span>
                    {timeRemaining.remaining.secondsStr}
                  </p>
                )
              ) : (
                <p className="flex items-center text-2xl font-bold">STARTING SOON</p>
              )}
            </div>
          )
        }
      </CountdownContext.Consumer>
    </CountdownBase>
  );
}
