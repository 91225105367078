/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Fanalyst API
 * Every week, tune into Fanalyst Trivia and see if you have what it takes.
 * OpenAPI spec version: 0.1
 */

export type NotificationType = (typeof NotificationType)[keyof typeof NotificationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationType = {
  FRIEND_REQUEST: 'FRIEND_REQUEST',
  FRIEND_REQUEST_ACCEPTED: 'FRIEND_REQUEST_ACCEPTED',
  CHALLENGE_REQUEST_OUTGOING: 'CHALLENGE_REQUEST_OUTGOING',
  CHALLENGE_REQUEST_INCOMING: 'CHALLENGE_REQUEST_INCOMING',
  CHALLENGE_REQUEST_ACCEPTED: 'CHALLENGE_REQUEST_ACCEPTED',
  CHALLENGE_REQUEST_DECLINED: 'CHALLENGE_REQUEST_DECLINED',
  GROUP_INVITE_INCOMING: 'GROUP_INVITE_INCOMING',
  GROUP_INVITE_ACCEPTED: 'GROUP_INVITE_ACCEPTED',
  GROUP_INVITE_DECLINED: 'GROUP_INVITE_DECLINED',
} as const;
