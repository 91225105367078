import { useSmBreakpoint } from "@/hooks/tailwind";
import { FC } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store/store';
import ChevronDownIcon from '../../ui/icon/ChevronDownIcon';
import { ChatHeight, ChatOpen } from './ChatOpen';

type Props = {
  forceChatOpen?: boolean;
  disableAnimation?: boolean;
  chatHeight?: ChatHeight;
  floating?: boolean;
}

export const Chat: FC<Props> = ({
  forceChatOpen,
  chatHeight,
  disableAnimation,
  floating,
}) => {
  const chatOpen = useSelector((state: RootState) => state.chat?.open);
  const dispatch = useDispatch<Dispatch>();
  const sm = useSmBreakpoint();

  const openHeight = chatHeight ? chatHeight : forceChatOpen ? '400px' : '200px'
  return (
    <div className={`mt-auto w-full ${floating ? 'absolute inset-x-0 bottom-0 mb-4' : 'safe-padding-bottom'}`.trim()}>
      {chatOpen || forceChatOpen ? (
        <ChatOpen
          forceChatOpen={forceChatOpen}
          disableAnimation={disableAnimation}
          floating={floating}
          openHeight={sm ? openHeight : "200px"}
        />
      ) : (
        <button
          onClick={() => dispatch.chat.SET_CHAT_OPEN(true)}
          className="flex w-full items-center justify-center border-t border-white/25 pt-2 font-bold"
        >
          OPEN CHAT <ChevronDownIcon className="ml-2 rotate-180" />
        </button>
      )}
    </div>
  );
}
