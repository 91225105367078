export const stylingElementId = 'upcoming-game-style';
export const themeConfig = {
  white: {
    background: '#ffffff',
    textColor: '#000000',
    gradientFade:
      'linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.8) 65%, rgba(255,255,255,0.65) 75%, rgba(255,255,255,0.15) 90%, rgba(255,255,255,0) 100%)',
  },
};

export function updateTheme(theme: 'white' = 'white') {
  let element = document.getElementById(stylingElementId);

  if (element === null) {
    element = document.createElement('style');
    element.id = stylingElementId;
    document.head.appendChild(element);
  }

  element.innerHTML = `
    body,
    html {
      background: ${themeConfig[theme].background};;
    }

    :root {
      --white-color: ${themeConfig[theme].textColor};
      --background-gradient: ${themeConfig[theme].gradientFade};
    }
  `;
}

export function removeThemeOverrides() {
  const element = document.getElementById(stylingElementId);

  if (element !== null) {
    element.remove();
  }
}
