import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_URL } from '@/lib/auth/api-url';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: API_URL,
});

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();

  return AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data);
};

export default customInstance;

export type ErrorType<Error> = AxiosError<Error>;
