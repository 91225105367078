import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function Referral() {
  const { referralId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('Fanalyst.Referral', referralId ?? '');
    navigate('/');
  }, [navigate, referralId]);

  return <></>;
}
