import { performChecksOnUserModule } from '@/api/endpoints/fanalyst';
import { SecurityPolicyDtoPolicyFlow } from '@/api/model';
import { AXIOS_INSTANCE } from '@/api/mutator/custom-instance';
import { SplashScreen } from '@/components/ui/SplashScreen';
import { Dispatch, RootState } from '@/store/store';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

export function atLeastTime(startTime: number, atLeast = 250) {
  const diff = new Date().getTime() - startTime;
  return diff >= atLeast ? 0 : atLeast;
}

export default function AuthInjector({ requireOnboarding = false }: { requireOnboarding: boolean }) {
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const authorizationSet = useSelector((state: RootState) => state.core?.authorizationSet);
  const [loadingState, setLoading] = useState<{
    error: string | null;
    loading: boolean;
  }>({
    loading: true,
    error: null,
  });
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.pathname || '/';
  const user = useSelector((state: RootState) => state.auth?.user);

  const verifyAuth = useCallback(async () => {
    const startTime = new Date().getTime();
    try {
      setLoading({
        loading: true,
        error: null,
      });
      await dispatch.auth.fetchUser();
      dispatch.core.updateAuthorization(true);
      setLoading({
        loading: false,
        error: null,
      });
    } catch (err: any) {
      if (err.isAxiosError) {
        const axiosError: AxiosError = err;
        if (err.response.status !== 401 && err.response.status !== 403) {
          setTimeout(
            () =>
              setLoading({
                loading: false,
                error: axiosError.message,
              }),
            atLeastTime(startTime),
          );
          throw err;
        } else {
          navigate(
            { pathname: '/welcome', search: `?from=${encodeURIComponent(from)}` },
            {
              replace: true,
            },
          );
          throw err;
        }
      }

      setTimeout(
        () =>
          setLoading({
            loading: false,
            error: 'Unknown Problem',
          }),
        atLeastTime(startTime),
      );
    }
  }, [dispatch.auth, dispatch.core, from, navigate]);

  const navigateBack = useCallback(
    (err: AxiosError) => {
      if (err.response?.status === 401) {
        dispatch.auth.setSession(null);
        navigate(
          {
            pathname: '/welcome',
            search: `?from=${encodeURIComponent(from)}`,
          },
          {
            replace: true,
          },
        );
        return Promise.reject(err);
      } else if (err.response?.status === 403 && (err.response?.data as any)?.message === 'NO_ROLES_FOR_ROUTE') {
        navigate(
          {
            pathname: '/',
            search: `?from=${encodeURIComponent(from)}`,
          },
          {
            replace: true,
          },
        );
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    },
    [dispatch.auth, from, navigate],
  );

  useEffect(() => {
    const requestId = AXIOS_INSTANCE.interceptors.request.use((config) => {
      if (token) config.headers.setAuthorization(`Bearer ${token}`);
      return config;
    });

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(requestId);
    };
  }, [token]);

  // TODO: Fix authorization here, it now updates every single time
  useEffect(() => {
    const responseId = AXIOS_INSTANCE.interceptors.response.use(
      (response) => response,
      (err) => navigateBack(err),
    );

    if (authorizationSet) {
      setLoading({
        loading: false,
        error: null,
      });
    } else {
      verifyAuth()
        .then(() => console.log('VerifyAuth'))
        .catch((err) => console.error('VerifyAuth error', { err }));
    }

    return () => {
      console.log('Removing auth...');
      AXIOS_INSTANCE.interceptors.response.eject(responseId);
    };
  }, [authorizationSet, navigateBack, verifyAuth]);

  if (loadingState.loading || loadingState.error) {
    return <SplashScreen loadingState={loadingState} verifyAuth={verifyAuth} />;
  }

  if (!authorizationSet) {
    return <Navigate to="/welcome" replace />;
  }

  const userModuleCheck = async (user: any) => {
    const userModules = await performChecksOnUserModule({
      userId: user.id,
      policyFlow: SecurityPolicyDtoPolicyFlow.REGISTRATION,
    });

    if (userModules.length > 0 && !userModules[0].completed) {
      navigate(
        { pathname: '/login/register?pid=true' },
        {
          state: {
            userModules,
            user,
          },
        },
      );
    }
  };

  if (user && (from === '/login' || from === '/login/register' || from === '/onboarding')) {
    userModuleCheck(user);
  }

  if (requireOnboarding && user && user.finishedOnboarding) {
    return <Outlet />;
  } else {
    return !requireOnboarding ? <Outlet /> : <Navigate to="/onboarding" replace />;
  }
}
