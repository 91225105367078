import BaseLayout from '@/components/layout/BaseLayout';
import ContentContainer from '@/components/ui/ContentContainer';
import SubTitle from '@/components/ui/SubTitle';
import Title from '@/components/ui/Title';
import { BackHeader } from '@/components/ui/header/BackHeader';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

export default function LoginSelect() {
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from') || '/welcome';

  useEffect(() => {
    if (token) {
      navigate({
        pathname: from,
      });
    }
  }, [token, navigate, from]);

  return (
    <BaseLayout headerComponent={<BackHeader backUrl="/" homeUrl="/" />}>
      <ContentContainer className="mt-8 flex flex-col items-center justify-center">
        <Title>SELECT METHOD</Title>
        <SubTitle className="max-w-[400px]">
          Please select how you would like to connect your account to Fanalyst.
        </SubTitle>
        <div className="mt-4 flex w-full flex-col gap-4 px-2">
          <Link
            to={'/login/crypto?from=' + encodeURIComponent(from)}
            data-cy="crypto-login"
            className="btn btn-secondary w-full text-center"
          >
            CRYPTO
          </Link>
          <Link
            to={'/login/password?from=' + encodeURIComponent(from)}
            data-testid="login-password-login-btn"
            className="btn btn-primary w-full text-center"
          >
            USERNAME
          </Link>
        </div>
      </ContentContainer>
    </BaseLayout>
  );
}
