import { useOnboarding } from "@/api/endpoints/fanalyst";
import AuthenticatedLayout from "@/components/layout/AuthenticatedLayout";
import { ProfileForm } from "@/components/profile/ProfileForm";
import Title from "@/components/ui/Title";
import SmallLogoHeader from "@/components/ui/header/SmallLogoHeader";
import {Dispatch, RootState} from "@/store/store";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Onboarding() {
  const dispatch = useDispatch<Dispatch>();
  const config = useSelector((state: RootState) => state.config);
  const { mutateAsync: mutateAsyncOnboarding } = useOnboarding();

  const navigate = useNavigate();

  const onSave = () => {
    if (config.settings.onboardingType === "PAYMENT") {
      navigate("/subscription");
    } else {
      mutateAsyncOnboarding().then(_ => {
        dispatch.auth.fetchUser().finally(() => {
          navigate("/");
        });
      });
    }
  }

  return (
    <AuthenticatedLayout headerComponent={<SmallLogoHeader />}>
      <Title>Profile</Title>
      <ProfileForm buttonText="CONTINUE" showModalOnSave={false} onSaveSuccessful={onSave} />
      <div className="h-2"></div>
    </AuthenticatedLayout>
  );
}
