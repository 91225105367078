import SmallLogo from '../logo/SmallLogo';
import BaseHeader from './BaseHeader';

export default function SmallLogoHeader() {
  return (
    <BaseHeader>
      <SmallLogo className="mx-auto" />
    </BaseHeader>
  );
}
