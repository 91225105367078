import { PressEvent } from '@react-types/shared/src/events';
import { MotionProps, motion } from 'framer-motion';
import React, { ButtonHTMLAttributes, FC, useRef } from 'react';
import { FocusRing, useButton } from 'react-aria';

type Props = {
  onClick: (e: PressEvent) => void;
  selected: boolean;
  finished?: boolean;
  disabled?: boolean;
  correct?: boolean;
  count?: number | null;
  shouldShowColor?: boolean;
  children: React.ReactNode;
};

export const Answer: FC<Props> = ({
  children,
  selected,
  shouldShowColor = false,
  finished = false,
  disabled = false,
  correct,
  count,
  onClick,
}) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(
    {
      onPress: (e: PressEvent) => {
        if (disabled || finished) return;
        onClick(e);
      },
    },
    ref,
  );

  return (
    <FocusRing focusRingClass="ring ring-offset-2 ring-offset-black">
      <motion.button
        {...(buttonProps as ButtonHTMLAttributes<HTMLButtonElement> & MotionProps)}
        style={{
          WebkitTapHighlightColor: 'transparent',
        }}
        disabled={disabled || finished}
        className={`touch-none select-none rounded border px-2 py-2 text-white focus:outline-none sm:py-6 ${
          disabled ? 'opacity-70' : ''
        } ${
          finished && shouldShowColor
            ? correct
              ? 'border-secondary bg-secondary'
              : 'border-primary bg-primary'
            : selected
            ? 'bg-white/30'
            : 'bg-black/50'
        }`}
      >
        {children}
        {count !== null ? <>&nbsp;({count})</> : null}
      </motion.button>
    </FocusRing>
  );
};
