import { Challenge, Question } from '@/api/model';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import { WS_URL } from '@/lib/auth/api-url';
import { useStream } from '@/lib/providers/stream.provider';
import { Dispatch, RootState } from '@/store/store';
import { isProduction } from '@/utils/utils';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'socket.io-client';

type GameConnectSettings = {
  gameId: string;
  token: string | null;
  connectAsSpectator: boolean;
  isAdmin: boolean;
  asUI: boolean;
};

const defaultGameConnectSettings: GameConnectSettings = {
  gameId: '',
  token: null,
  connectAsSpectator: false,
  isAdmin: false,
  asUI: false,
};

export function connectGameSocket(
  dispatch: Dispatch,
  navigate: NavigateFunction,
  settings = defaultGameConnectSettings,
) {
  const socket = connect(`${WS_URL}/game`, {
    forceNew: true,
    withCredentials: true,
    autoConnect: true,
    secure: isProduction(),
    // disable polling
    transports: ['websocket'],
    auth: {
      token: settings.token,
      asUI: settings.asUI,
    },
  });

  socket.on('disconnect', (_) => {
    dispatch.game.SET_DISCONNECT('io server disconnect');
  });

  socket.on('fanalyst.game.start', (_) => {});

  socket.on('fanalyst.round.result', (event) => {
    console.log(event);
    const { isEliminated } = event;
    console.log(event);

    if (isEliminated) {
      dispatch.game.SET_PLAYER_STATE('ELIMINATED');

      setTimeout(() => {
        dispatch.game.SET_SPECTATOR_MODE(true);
      }, 5500);
    }

    // Required to animate question.
    setTimeout(() => {
      dispatch.game.SET_QUESTION(null);
    }, 3500);
  });

  socket.on('fanalyst.game.eliminated', function () {
    dispatch.game.SET_PLAYER_STATE('ELIMINATED');
  });

  socket.on('connect', () => {
    dispatch.game.SET_CONNECTED();
  });

  socket.on('fanalyst.game.question', (data: { question: Question | null }) => {
    dispatch.game.SET_QUESTION(data.question);
  });

  socket.on('fanalyst.game.correctids', (event) => {
    console.log(event);
    dispatch.game.SET_CORRECT_ANSWER_IDS(event);
  });

  socket.on('fanalyst.game.hide-question', (isHidden: boolean) => dispatch.game.SET_HIDDEN(isHidden));

  // For maybe a late join
  socket.on('fanalyst.game.playertype', ({ playerType }) => {
    console.log('recieved p type', playerType);
    if (playerType === 'SPECTATOR') {
      // dispatch.game.SET_SPECTATOR_MODE(true);
    } else if (playerType === 'PLAYER') {
      dispatch.game.SET_SPECTATOR_MODE(false);
    }
  });

  socket.on('fanalyst.game.reconnect', (_) => {
    dispatch.game.SET_RECONNECTED(true);

    setTimeout(() => {
      dispatch.game.SET_RECONNECTED(false);
    }, 1500);
  });

  socket.on(
    'fanalyst.game.answers',
    ({ correctIds, answerTotal }: { correctIds: string[]; answerTotal: { [_: string]: number } }) => {
      dispatch.game.SET_CORRECT_ANSWER_IDS(correctIds);
      if (answerTotal) {
        dispatch.game.SET_ANSWER_COUNTS(answerTotal);
      }
    },
  );

  socket.on('fanalyst.game.join', ({ reason, success }) => {
    dispatch.game.SET_JOIN_SUCCESS(success);

    if (!success) {
      dispatch.game.SET_JOIN_REJECT_REASON(reason);
    }

    setTimeout(() => {
      dispatch.game.SET_JOIN_SUCCESS(null);
      dispatch.game.SET_JOIN_REJECT_REASON(null);
    }, 1200);
  });

  socket.on('fanalyst.game.winner.direct', () => {
    console.log('direct');
    dispatch.game.SET_PLAYER_STATE('WINNER');

    setTimeout(
      () => {
        // current base url (including port if any)
        // Always redirecting to https because most browsers only allow media devices on https
        const baseURL = 'https://' + window.location.host;
        window.location.href = `${baseURL}/game/${settings.gameId}/fantime?direct=true`;
        // 3 seconds for answers to be shown
        // 3-4 seconds for video to be shown
        // 1 second extra to be sure that the video is done
      },
      (4 + 3 + 1) * 1000,
    );
  });

  socket.on('fanalyst.game.lifelines', (data: { amount: number }) => dispatch.game.SET_LIFELINES(data.amount));

  socket.on('fanalyst.game.challenges', (challenges: Challenge[]) => dispatch.game.SET_CHALLENGES(challenges));
  // socket.on("fanalyst.game.server", (serverInfo: any) => dispatch.game.SET_SERVER_INFO(serverInfo));
  socket.on('fanalyst.game.winner', (playerWinnerId: string) => dispatch.game.SET_PLAYER_WINNER_ID(playerWinnerId));

  socket.on('fanalyst.game.status', (statusUpdate) => {
    const { gameStatus } = statusUpdate;
    console.log('stat update');
    console.log(statusUpdate);
    dispatch.game.ADD_SERVER_STATUS('all', gameStatus);
    dispatch.game.SET_GAME_STATUS(gameStatus);
  });

  socket.on('fanalyst.game.end', () => {
    dispatch.game.RESET();
    socket.disconnect();

    if (!settings.isAdmin) {
      navigate({ pathname: '/' }, { replace: true });
    }
  });

  return socket;
}

function translateHeader(message: 'PLAYER_ELIMINATED' | string | null) {
  switch (message) {
    case 'PLAYER_ELIMINATED':
      return 'Player eliminated.';
    default:
      return 'Cannot connect.';
  }
}

function translateError(message: 'STATE_NOT_ALLOWED' | 'PLAYER_ELIMINATED' | string | null) {
  switch (message) {
    case 'PLAYER_ELIMINATED':
      return 'Currently, we have not implemented you stay part of the game. Meaning you cannot see the game anymore.';
    case 'STATE_NOT_ALLOWED':
      return 'Currently, players are not allowed to join since the game has already started.';
    case 'PLAYER_ALREADY_REGISTERED':
      return 'You have already registered for this game, please make sure to close all other tabs. And try again.';
    default:
      return 'Unknown error while connecting.';
  }
}

type GameConnectorProps = {
  gameId: string;
  authenticate: boolean;
  className?: string;
};

export const GameConnector: FC<PropsWithChildren<GameConnectorProps>> = (props) => {
  // prettier-ignore
  const { 
    gameId, 
    children, 
    className, 
    authenticate 
  } = props;

  const { pathname } = useLocation();
  const { setGameSocket } = useStream();

  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const connected = useSelector((state: RootState) => state.game?.connected);
  const disconnect = useSelector((state: RootState) => state.game?.disconnect);
  const serverDisconnect = useSelector((state: RootState) => state.game?.serverDisconnect);
  const token = useSelector((state: RootState) => state.auth.session!.token);
  const isAdmin = useSelector((state: RootState) => state.auth?.user?.admin);

  useEffect(() => {
    dispatch.game.RESET_DISCONNECT();

    const socket = connectGameSocket(dispatch, navigate, {
      gameId,
      token: token,
      connectAsSpectator: false,
      isAdmin: isAdmin ?? false,
      asUI: pathname.includes('admin/game'),
    });

    if (socket) {
      setGameSocket(socket);
    }

    return () => {
      socket.disconnect();
      setGameSocket(null);
    };
  }, [authenticate, dispatch, gameId, isAdmin, navigate, pathname, setGameSocket, token]);

  return !connected ? (
    <div className="flex flex-1 flex-col items-center justify-center px-8 pt-16">
      <div className="flex flex-col text-center">
        {serverDisconnect || disconnect ? (
          <>
            <h3>{translateHeader(serverDisconnect ?? disconnect)}</h3>
            <p className="mt-2">{translateError(serverDisconnect ?? disconnect)}</p>
          </>
        ) : (
          <>
            <ActivityIndicatorFullScreen />
          </>
        )}
      </div>
    </div>
  ) : (
    <div className={className}>{children}</div>
  );
};
