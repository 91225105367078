import { classNames } from '@/lib/util/classNames';
import { Alert } from '@/store/models/alert';
import { Dispatch } from '@/store/store';
import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  alert: Alert;
};

export const AlertToast: FC<Props> = ({ alert }) => {
  const color = alert.type === 'error' ? 'bg-primary' : 'bg-secondary';
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    setTimeout(() => {
      dispatch.alert.clear();
    }, 3000);
  }, []);

  return (
    <motion.div
      onClick={() => dispatch.alert.clear()}
      initial={{ y: -50 }}
      animate={{ y: 0 }}
      exit={{ y: -50 }}
      transition={{ duration: 0.2 }}
      className={classNames(
        'absolute left-0 top-0 z-[1000000] flex h-12 w-[100vw] flex-row items-center justify-center px-4 py-1 text-center shadow-lg',
        color,
      )}
    >
      <p className="line-clamp-2 w-full break-all text-sm">{alert.message}</p>
    </motion.div>
  );
};
