import { InGameChallengers } from '@/components/ui/game/InGameChallengers';
import { Dispatch, RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import BackIcon from '../icon/BackIcon';
import { LifeLineIcon } from '../icon/LifeLineIcon';
import { PlayerCountIcon } from '../icon/PlayerCountIcon';
import BaseHeader from './BaseHeader';

export function GameHeader({ className }: { className?: string }) {
  const navigate = useNavigate();
  const status = useSelector((state: RootState) => state.game?.status);
  const lifelines = useSelector((state: RootState) => state.game?.lifeLines);
  const [countClick, setCountClick] = useState(0);
  const dispatch = useDispatch<Dispatch>();
  const [searchParams] = useSearchParams();
  const [from, setFrom] = useState<string>('/');

  useEffect(() => {
    if (searchParams.has('from')) {
      setFrom(searchParams.get('from') ?? '/');
    }
  }, [searchParams]);

  useEffect(() => {
    // reset after 1 second
    const timeout = setTimeout(() => {
      setCountClick(0);
    }, 1000);

    if (countClick === 3) {
      dispatch.game.TOGGLE_SHOW_SERVER_INFO();
      setCountClick(0);
    }

    // cleanup
    return () => {
      clearTimeout(timeout);
    };
  }, [countClick, dispatch.game]);

  return (
    <BaseHeader className={twMerge('container absolute z-50 mx-auto !items-start', className)}>
      <div className="my-auto ml-4 flex w-24 items-start">
        <button onClick={() => navigate(from)} className="inline-block">
          <BackIcon />
        </button>
      </div>
      <div className="mr-4 mt-4 flex w-24 justify-end" onClick={() => setCountClick(countClick + 1)}>
        <div className="flex flex-col text-left">
          <div className="flex items-center font-bold">
            <PlayerCountIcon className="mr-2 w-4" />
            <span className="flex-1">&nbsp;{status?.activePlayers ?? 0}</span>
          </div>
          <div className="flex items-center font-bold">
            <LifeLineIcon className="mr-2 w-4" />
            <span className="flex-1">&nbsp;{lifelines ?? 0}</span>
          </div>
        </div>
      </div>
      <InGameChallengers />
    </BaseHeader>
  );
}
