import { twMerge } from 'tailwind-merge';
import NotificationIcon from '@/components/ui/icon/NotificationIcon';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { useGetNotificationsCount } from '@/api/endpoints/fanalyst';

export function NotificationBell({ pathname }: { pathname?: string }) {
  const { data } = useGetNotificationsCount({
    query: {
      queryKey: 'getNotificationsCount',
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  });

  return (
    <NavLink
      to={`/notification/?from=${pathname}`}
      key="notification"
      className={({ isActive }) =>
        twMerge(
          'relative inline-flex h-8 w-8 items-center justify-center',
          isActive ? 'rounded-full ring-2 ring-primary ring-offset-4 ring-offset-black' : 'ring-0',
        )
      }
    >
      {data && data > 0 ? (
        <div className="absolute right-0 top-1 flex -translate-y-1/2 translate-x-1/2 items-center justify-center text-sm text-white">
          <div className="relative flex w-5 animate-bounce items-center justify-center p-0.5">
            <div className="absolute inset-0 z-0 rounded-full bg-primary" />
            <p className="z-10 text-white">{data}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      <NotificationIcon />
    </NavLink>
  );
}
