import Title from '../../components/ui/Title';

export function RuleItem({ index, title, description }: { index: number; title: string; description?: string }) {
  return (
    <div className="mx-2 my-5 flex items-center">
      <div className="mr-4 flex h-14 w-14 shrink-0 items-center justify-center rounded-full border-2 border-[#2C2C2C] text-white">
        <p>{index}</p>
      </div>
      <div>
        <h5 className="font-poppins text-sm font-bold">{title}</h5>
        {description && <p className="text-xs">{description}</p>}
      </div>
    </div>
  );
}

export const rules = [
  {
    title: 'Connect your wallet to verify your NFT ownership',
    description: 'Or Pay For A Single Game with Credit Card',
  },
  {
    title: 'Be ready for trivia to start (TUESDAYS @ 6:30 PM EST)',
    description: 'buy LifeLines and Eliminators for better odds',
  },
  {
    title: 'Answer every question correctly to win (ONLY 1 WINNER)',
    description: '1 incorrect or non-answer without a LifeLine and you’re out',
  },
  {
    title: 'Overtime starts if 2+ players remain after the final round',
    description: 'single round elimination with expert level questions',
  },
  {
    title: 'When one player remains, they are declared the winner',
    description: 'last player standing wins no matter what round it is',
  },
  {
    title: 'Come back the following week for another chance to win',
  },
];

export default function Rules() {
  return (
    <>
      <Title>HOW TO PLAY</Title>
      <div className={'px-4'}>
        {rules.map((item, index) => (
          <RuleItem key={index} index={index + 1} {...item} />
        ))}
      </div>
    </>
  );
}
