import { uploadImage } from '@/api/endpoints/fanalyst';
import { getFirstErrorMessageFromAxios } from '@/lib/general/api-helper';
import { Dispatch, RootState } from '@/store/store';
import { ChangeEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from './Avatar';

export default function AvatarUpload() {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.auth?.user);

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && !loading) {
      setLoading(true);
      const file = e.target.files[0];

      if (file === undefined) {
        window.alert('Failed to select image');
        return;
      }

      try {
        const fullUser = await uploadImage({
          file,
        });

        if (user) {
          dispatch.auth.setImageFile(fullUser.image);
        } else {
          dispatch.auth.setUser(fullUser);
        }
      } catch (e) {
        window.alert(getFirstErrorMessageFromAxios(e, 'Failed to update image'));
      } finally {
        setLoading(false);
      }
    }
  };
  const onFileUploadClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef?.current?.click();
  };

  const profilePicture = useSelector((state: RootState) => state.auth?.picture);
  return (
    <>
      <input type="file" accept="image/*" ref={inputFileRef} className="hidden" onChange={onFileChange} />
      <button onClick={onFileUploadClick}>
        <Avatar loading={loading} imageUrl={profilePicture?.profile} defaultText="ADD AVATAR" className="mx-auto" />
      </button>
    </>
  );
}
