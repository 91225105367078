import { useGetCards, useGetCheckout, useOnboarding, usePayCheckout } from '@/api/endpoints/fanalyst';
import { Card } from '@/api/model';
import { ScrollContainer } from '@/components/ScrollContainer';
import { FinishVerificationModal } from '@/components/security/FInishVerificationModal';
import { StartVerificationModal } from '@/components/security/StartVerificationModal';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import { HeaderNavigation } from '@/components/ui/header/HeaderNavigation';
import { InlineInquiry } from '@/pages/payment/InlineInquiry';
import { Dispatch, RootState } from '@/store/store';
import { useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AuthenticatedLayout from '../../components/layout/AuthenticatedLayout';
import ContentContainer from '../../components/ui/ContentContainer';
import NotFound from '../../components/ui/NotFound';
import Title from '../../components/ui/Title';
import CreditCardForm from '../../components/ui/payment/CreditCardForm';
import OrderOverview from '../../components/ui/payment/OrderOverview';
import PaymentCardSelect from '../../components/ui/payment/PaymentCardSelect';

export default function OldCheckout() {
  const { checkoutId } = useParams();
  const [generalLoading, setGeneralLoading] = useState(false);
  const stripe = useStripe();
  const [showCardAddScreen, setShowAddScreen] = useState(false);
  const { data, isLoading, refetch, isLoadingError } = useGetCheckout(checkoutId ?? '');
  const { data: cards, isLoading: isLoadingCards } = useGetCards();
  const [selectedCard, setSelectedCard] = useState<Card>();
  const { mutateAsync, isLoading: isSubmittingPayment } = usePayCheckout();
  const { mutateAsync: mutateAsyncOnboarding, isLoading: isLoadingBoarding } = useOnboarding();
  const user = useSelector((state: RootState) => state.auth?.user);
  const dispatch = useDispatch<Dispatch>();
  const [referenceId, setReferenceId] = useState<null | string>(null);
  const [continueVerification, setContinueVerification] = useState<null | boolean>(null);
  const [verified, setVerified] = useState<boolean>(false);
  //
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const to = searchParams.get('to') || '/checkout/completed';
  const from = searchParams.get('from') || '/';

  const complete = () => {
    setReferenceId(null);
    setContinueVerification(false);
    setVerified(true);
  };

  useEffect(() => {
    if (data?.status === 'PAID' && !user?.finishedOnboarding) {
      mutateAsyncOnboarding().then(() => {
        dispatch.auth.fetchUser();
      });
    }

    if (data?.status === 'PAID') {
      navigate(`${to}?from=${from}`);
    }
  }, [data?.status, dispatch.auth, mutateAsyncOnboarding, user?.finishedOnboarding]);

  useEffect(() => {
    if (cards !== undefined && cards.length > 0) {
      setSelectedCard(cards[0]);
    }
  }, [cards]);

  const submit = async () => {
    if (selectedCard !== undefined && stripe !== null) {
      setGeneralLoading(true);

      try {
        const data = await mutateAsync({
          checkoutId: checkoutId as string,
          params: {
            cardId: selectedCard.id,
          },
        });
        if (data.status) {
          await refetch();
        } else {
          if (data.securityModules.length > 0) {
            setReferenceId(data.securityModules[0].referenceId);
            setGeneralLoading(false);
            return;
          }

          const result = await stripe.confirmCardPayment(data.clientSecret, {
            payment_method: data.paymentMethodId,
          });

          if (result.error) {
            // Show error to your customer
            window.alert(result.error.message);
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // The payment is complete!
              const data = await mutateAsync({
                checkoutId: checkoutId as string,
                params: {
                  cardId: selectedCard.id,
                },
              });

              if (!data.status) {
                window.alert('Could not create payment');
              } else {
                await refetch();
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
        window.alert('A error occurred');
      }
    }
  };

  if (isLoading || isLoadingCards || isSubmittingPayment || generalLoading || isLoadingBoarding) {
    return <ActivityIndicatorFullScreen />;
  }

  if (isLoadingError || data === undefined || cards === undefined) return <NotFound />;

  return (
    <AuthenticatedLayout headerComponent={<HeaderNavigation />}>
      <FinishVerificationModal show={verified} confirm={() => setVerified(false)} />
      {referenceId && continueVerification ? (
        <InlineInquiry inquiryId={referenceId} done={() => complete()} />
      ) : (
        <>
          <Title>Checkout</Title>
          {referenceId && continueVerification === null && (
            <StartVerificationModal
              show={!!referenceId}
              confirm={() => setContinueVerification(true)}
              close={() => setContinueVerification(false)}
            />
          )}
          <ContentContainer className="mt-4 px-4 md:mx-auto md:max-w-2xl lg:max-w-2xl">
            <OrderOverview price={data.price} title={data.product.title} />
          </ContentContainer>
          <ScrollContainer className="px-4 md:block md:max-w-2xl md:flex-[0_auto] md:text-center lg:max-w-2xl">
            {showCardAddScreen ? (
              <CreditCardForm
                checkoutId={checkoutId as string}
                onCancel={() => {
                  setShowAddScreen(false);
                }}
              />
            ) : (
              <>
                {cards.length > 0 && (
                  <PaymentCardSelect
                    selectedCard={selectedCard as Card}
                    setSelectedCard={setSelectedCard}
                    cards={cards}
                  />
                )}
                {cards.length < 3 && (
                  <button className="btn btn-secondary my-4" onClick={() => setShowAddScreen(true)}>
                    ADD NEW CARD
                  </button>
                )}
              </>
            )}
          </ScrollContainer>
          {!showCardAddScreen && (
            <ContentContainer className="mt-2 px-4 safe-margin-bottom">
              <>
                <button className="btn btn-primary" onClick={submit} disabled={selectedCard === undefined}>
                  BUY NOW
                </button>
              </>
            </ContentContainer>
          )}
        </>
      )}
    </AuthenticatedLayout>
  );
}
