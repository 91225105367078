import { reset } from '@/api/endpoints/fanalyst';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import { BackHeader } from '@/components/ui/header/BackHeader';
import { Dispatch } from '@/store/store';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import BaseLayout from '../../../../components/layout/BaseLayout';
import ContentContainer from '../../../../components/ui/ContentContainer';
import Input from '../../../../components/ui/Input';
import SubTitle from '../../../../components/ui/SubTitle';
import Title from '../../../../components/ui/Title';

interface ResetForm {
  email: string;
}

function translateError(error: string) {
  switch (error) {
    case 'USER_NOT_FOUND':
      return 'No account found with this email';
    default:
      return 'Unknown error occurred';
  }
}

export function ResetWithEmail() {
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from') || '/';
  const { alert } = useDispatch<Dispatch>();

  const { register, handleSubmit, formState } = useForm<ResetForm>({
    defaultValues: {
      email: '',
    },
  });
  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler<ResetForm> = async (data) => {
    try {
      const resetResponse = await reset(data);
      console.log(resetResponse);
    } catch (err: any) {
      alert.set(translateError(err?.response?.data?.message ?? err?.message ?? 'UNKNOWN_ERROR'), 'error');
    }
  };

  return (
    <BaseLayout
      headerComponent={<BackHeader backUrl={`/login/password?from=${encodeURIComponent(from)}`} homeUrl="/" />}
    >
      <ContentContainer className="flex h-full w-full flex-col items-center justify-center gap-8">
        <div>
          <Title>Reset</Title>
          <SubTitle>Reset account with email address</SubTitle>
        </div>
        {isSubmitting && <ActivityIndicatorFullScreen />}
        <form onSubmit={handleSubmit(onSubmit)} className="relative flex flex-col gap-4 px-2">
          <Input
            data-cy="email"
            required
            type="email"
            minLength={3}
            placeholder="Email"
            {...register('email', {
              required: 'Please enter your email.',
            })}
          />
          <button type="submit" className="btn btn-primary rounded uppercase" data-cy="reset-button">
            Reset
          </button>
          <Link
            to={`/login/password?from=${encodeURIComponent(from)}`}
            className="py-4 text-center uppercase text-gray-400"
          >
            Back to login
          </Link>
        </form>
      </ContentContainer>
    </BaseLayout>
  );
}
