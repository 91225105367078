
      import { Buffer } from 'buffer/';
      var global = globalThis || global || window;
      global.Buffer = Buffer || window.Buffer;
      window.global = global;
      var process = process || {
        env: { DEBUG: undefined },
        version: [],
      };
      window.process = process;
    