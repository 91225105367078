import { Dispatch } from '@/store/store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Authenticated from '../../lib/auth/authenticated';
import BaseLayout, { BaseLayoutProps } from './BaseLayout';

export default function AuthenticatedLayout({
  children,
  disableBackground = false,
  ...props
}: {
  children: React.ReactNode;
  disableBackground?: boolean;
} & BaseLayoutProps) {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.background.SET_VISIBLE_STATE(!disableBackground);

    return () => {
      dispatch.background.SET_VISIBLE_STATE(false);
    };
  }, [disableBackground]);

  return (
    <Authenticated>
      <BaseLayout {...props}>{children}</BaseLayout>
    </Authenticated>
  );
}
