import AddIcon from '@/components/ui/icon/AddIcon';
import { ResponsiveLogo } from '@/components/ui/logo/ResponsiveLogo';
import { useMdBreakpoint } from '@/hooks/tailwind';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Title from '../Title';
import BackIcon from '../icon/BackIcon';
import BaseHeader from './BaseHeader';

type Props = {
  title?: string;
  hasPlusIcon?: boolean;
  hideLogo?: boolean;
  backTo?: string;
};

export const HeaderNavigation: FC<Props> = ({ title, hasPlusIcon = false, hideLogo = false, backTo = '/' }) => {
  const [searchParams] = useSearchParams();
  const md = useMdBreakpoint();
  const [from, setFrom] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setFrom(searchParams.get('from') ?? backTo);
  }, [searchParams]);

  return (
    <>
      <BaseHeader>
        <div className="flex w-24 items-center">
          {from && (
            <button onClick={() => navigate(from)} className="ml-4 inline-block rounded-full bg-black p-1">
              <BackIcon />
            </button>
          )}
        </div>
        {(title && !md) || hideLogo ? (
          <Title>{title}</Title>
        ) : (
          <Link to="/">
            <ResponsiveLogo />
          </Link>
        )}
        <div className="flex w-24 justify-end">{hasPlusIcon && <AddIcon className="cursor-pointer" />}</div>
      </BaseHeader>
      {md && !hideLogo ? <Title>{title}</Title> : null}
    </>
  );
};
