import { FC } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export type InputPhoneProps = {
  value: string;
  onChange: (value: string) => void;
  inputClass?: string;
};

const InputPhone: FC<InputPhoneProps> = ({ value, onChange, inputClass, ...props }) => {
  return (
    <div>
      <PhoneInput
        name="phoneNumber"
        defaultCountry="US"
        onChange={onChange}
        countries={['US', 'CA']}
        value={value}
        className="w-full rounded border border-white bg-transparent p-1"
        international
        addInternationalOption={false}
        numberInputProps={{
          className: 'w-full bg-transparent p-2 text-black text-slate-50 outline-none',
          ...props,
        }}
      />
    </div>
  );
};

export default InputPhone;
