import { useGetUpcomingGames } from '@/api/endpoints/fanalyst';
import { Game } from '@/api/model';
import { NoUpcomingGames } from '@/components/game/NoUpcomingGames';
import { IntroCountdownAccess } from '@/components/game/intro/IntroCountdownWithAccess';
import { ImageVideoBackground } from '@/components/ui/ImageVideoBackground';
import { removeThemeOverrides, updateTheme } from '@/lib/general/update-theme';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, PanInfo, motion } from 'framer-motion';
import { wrap } from 'popmotion';
import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import IntroCountdown from '../../components/game/intro/IntroCountdown';

const amount = 1000;

const variants = {
  enter: (direction: number) => {
    return {
      y: direction > 0 ? amount : amount * -1,
    };
  },
  center: {
    zIndex: 1,
    y: 0,
  },
  exit: (direction: number) => {
    return {
      y: direction < 0 ? amount : amount * -1,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const GameList = ({ games, withAccess }: { games: Game[]; withAccess: boolean }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [hasHadInitialAnimation, setHasHadInitialAnimation] = useState(false);

  const gameIndex = wrap(0, games.length, page);

  const paginate = useCallback(
    (newDirection: number) => {
      if (isAnimating && hasHadInitialAnimation) {
        return;
      }

      if (!hasHadInitialAnimation) {
        setHasHadInitialAnimation(true);
      }

      setPage([page + newDirection, newDirection]);
    },
    [page, setPage, isAnimating, hasHadInitialAnimation],
  );

  useEffect(() => {
    removeThemeOverrides();

    if (games[gameIndex] && games[gameIndex].inverted) {
      updateTheme();
    }

    return () => {
      removeThemeOverrides();
    };
  }, [gameIndex, games]);

  const onSwipe = useCallback(
    (swipe: number, invert: boolean) => {
      if (swipe < -swipeConfidenceThreshold) {
        paginate(invert ? -1 : 1);
      } else if (swipe > swipeConfidenceThreshold) {
        paginate(invert ? 1 : -1);
      }
    },
    [paginate, isAnimating],
  );

  const onWheel = useCallback(
    (e: React.WheelEvent<HTMLDivElement>) => onSwipe(swipePower(e.deltaY, e.deltaY), true),
    [onSwipe],
  );

  const onDragEnd = useCallback(
    (e: MouseEvent | TouchEvent | PointerEvent, { offset, velocity }: PanInfo) =>
      onSwipe(swipePower(offset.y, velocity.y), false),
    [onSwipe],
  );

  const setIsAnimatingTrue = useCallback(() => {
    setIsAnimating(true);
  }, [setIsAnimating]);

  const setIsAnimatingFalse = useCallback(() => {
    setIsAnimating(false);
  }, [setIsAnimating]);

  return (
    <>
      {
        <div className="absolute bottom-10 right-10 z-10 hidden flex-col md:flex">
          <button onClick={() => paginate(-1)}>
            <ArrowUpCircleIcon className={twMerge('h-10 w-10 text-white')} />
          </button>
          <button onClick={() => paginate(1)}>
            <ArrowDownCircleIcon className={twMerge('h-10 w-10 text-white')} />
          </button>
        </div>
      }
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className="absolute left-0 top-0 flex h-screen w-full flex-1 items-center justify-center"
          key={page}
          custom={direction}
          onAnimationComplete={setIsAnimatingFalse}
          onAnimationStart={setIsAnimatingTrue}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          onWheel={onWheel}
          transition={{
            y: { type: 'spring', stiffness: 300, damping: 30 },
          }}
          drag="y"
          dragConstraints={{ top: 0, bottom: 0 }}
          dragElastic={1}
          onDragEnd={onDragEnd}
        >
          {games[gameIndex] ? (
            <>
              <ImageVideoBackground
                desktopImageId={games[gameIndex].desktopGameImageId}
                mobileImageId={games[gameIndex].mobileGameImageId}
                desktopVideoUrl={games[gameIndex].desktopVideoUrl}
                mobileVideoUrl={games[gameIndex].mobileVideoUrl}
                fillScreen={true}
                muted={true}
              />
              {withAccess ? (
                <IntroCountdownAccess game={games[gameIndex]} />
              ) : (
                <IntroCountdown game={games[gameIndex]} />
              )}
            </>
          ) : (
            <NoUpcomingGames />
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default function Upcoming() {
  const { data: games } = useGetUpcomingGames({
    onlyWithProductId: false,
  });

  return <>{games && <GameList games={games} withAccess={true} />}</>;
}
