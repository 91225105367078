import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import { WS_URL } from '@/lib/auth/api-url';
import { useStream } from '@/lib/providers/stream.provider';
import { IChatMessage } from '@/store/models/chat';
import { Dispatch, RootState } from '@/store/store';
import { isProduction } from '@/utils/utils';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'socket.io-client';

export function connectChatSocket(gameId: string, token: string | null, dispatch: Dispatch) {
  const socket = connect(`${WS_URL}/chat`, {
    forceNew: true,
    withCredentials: true,
    autoConnect: true,
    secure: isProduction(),
    // disable polling
    transports: ['websocket'],
    auth: {
      token,
      gameId,
    },
    extraHeaders: token
      ? {
          Authorization: `Bearer ${token}`,
          'X-Game-Id': gameId,
        }
      : {},
  });

  socket.on('connect', () => {
    dispatch.chat.SET_CONNECTED();
  });
  socket.on('fanalyst.chat.new', (messages: IChatMessage[]) => {
    console.log(messages);
    dispatch.chat.ADD_END_MESSAGES(messages);
  });
  socket.on('fanalyst.chat.remove-message', (messageId) => {
    dispatch.chat.REMOVE_MESSAGE(messageId);
  });
  socket.on('fanalyst.disconnect', (reason) => {
    socket.disconnect();
    dispatch.chat.SET_SERVER_DISCONNECT(reason);
  });
  socket.on('disconnect', (data) => dispatch.chat.SET_DISCONNECT(data));

  return socket;
}

function translateHeader(message: 'ADMIN_DISCONNECT' | string | null) {
  switch (message) {
    case 'ADMIN_DISCONNECT':
      return 'Disconnected by admin.';
    default:
      return 'Cannot connect.';
  }
}

function translateError(message: 'ADMIN_DISCONNECT' | string | null) {
  switch (message) {
    case 'ADMIN_DISCONNECT':
      return 'You are no longer able to chat in the livestream.';
    default:
      return 'Unknown error while connecting.';
  }
}

export function ChatConnector({
  children,
  className,
  gameId,
  authenticate,
  ...props
}: {
  authenticate: boolean;
  gameId: string;
  children: React.ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const { setChatSocket } = useStream();
  const connected = useSelector((state: RootState) => state.chat?.connected);
  const disconnect = useSelector((state: RootState) => state.chat?.disconnect);
  const serverDisconnect = useSelector((state: RootState) => state.chat?.serverDisconnect);
  const token = useSelector((state: RootState) => state.game?.gameToken?.token);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.chat.RESET_DISCONNECT();
    const socket = connectChatSocket(gameId, authenticate ? token ?? null : null, dispatch);

    if (socket) {
      setChatSocket(socket);
    }

    return () => {
      // socket disconnect
      socket?.disconnect();
      setChatSocket(null);
    };
  }, [authenticate, dispatch, gameId, setChatSocket, token]);

  return !connected ? (
    <div className="flex flex-col p-4 text-center">
      {serverDisconnect || disconnect ? (
        <div className={className}>
          <h3>{translateHeader(serverDisconnect ?? disconnect)}</h3>
          <p className="mt-2">{translateError(serverDisconnect ?? disconnect)}</p>
        </div>
      ) : (
        <ActivityIndicatorFullScreen />
      )}
    </div>
  ) : (
    <div className={className} {...props}>
      {children}
    </div>
  );
}
