import React from 'react';

export function LifeLineIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.0234 6.24201e-05C14.75 -0.00418866 14.4883 0.209216 14.4315 0.48171L11.5068 14.3895L9.86855 6.03277C9.81929 5.77856 9.59265 5.57153 9.3397 5.54645C9.08676 5.52094 8.82839 5.67951 8.73155 5.91884L6.97473 10.1908H0.606062C0.448286 10.1886 0.289674 10.2537 0.177394 10.3663C0.0651141 10.479 0 10.638 0 10.7982C0 10.9585 0.0651141 11.12 0.177394 11.2327C0.289674 11.3453 0.448703 11.4083 0.606062 11.4061H7.37334C7.60667 11.4061 7.8329 11.2527 7.92305 11.0337L9.07883 8.22378L10.9033 17.5132C10.9575 17.7836 11.2126 17.9983 11.4835 18C11.7544 18.0017 12.0131 17.7904 12.0707 17.5205L15.0677 3.25894C15.7021 5.78238 16.3846 8.46014 17.004 10.927C17.0649 11.285 17.211 11.4899 17.6706 11.5107H24.4378C24.5956 11.5128 24.7542 11.4503 24.8665 11.3373C24.9788 11.2246 25.0435 11.0635 25.0435 10.9028C25.0435 10.7421 24.9784 10.5836 24.8665 10.4709C24.7542 10.3582 24.5952 10.2928 24.4378 10.2953H18.0738L15.5898 0.457904C15.5264 0.202839 15.2839 0.00643904 15.0259 6.24201e-05H15.0238H15.0234Z"
        fill="currentColor"
      />
    </svg>
  );
}
