import React from 'react';

export default function AddIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 11.25V16.419C11.25 17.2921 10.2425 18 9 18C7.75747 18 6.75 17.2921 6.75 16.419V11.25H1.58101C0.707921 11.25 0 10.2425 0 9C0 7.75747 0.707471 6.75 1.58101 6.75H6.75V1.58101C6.75 0.707921 7.75747 0 9 0C10.2425 0 11.25 0.707471 11.25 1.58101V6.75H16.419C17.2921 6.75 18 7.75747 18 9C18 10.2425 17.2921 11.25 16.419 11.25H11.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
