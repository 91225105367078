import { useSmBreakpoint, useXlBreakpoint } from '@/hooks/tailwind';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import ScrollToBottom, { FunctionContext, StateContext } from 'react-scroll-to-bottom';
import { RootState } from '../../../store/store';
import { ChatBar } from './ChatBar';
import { ChatContent } from './ChatContent';

export type ChatHeight = '200px' | '400px' | '500px';

export function ChatOpen({
  forceChatOpen,
  floating,
  disableAnimation,
  openHeight,
}: {
  forceChatOpen?: boolean;
  floating?: boolean;
  disableAnimation?: boolean;
  openHeight: ChatHeight;
}) {
  const focus = useSelector((state: RootState) => state.chat?.focus);
  const question = useSelector((state: RootState) => state.game?.question);
  const sm = useSmBreakpoint();
  const xl = useXlBreakpoint();

  const questionHeight = sm ? '200px' : '100px';
  const height = question ? questionHeight : openHeight;

  const animationProps: HTMLMotionProps<'div'> = disableAnimation
    ? {}
    : {
        initial: { minHeight: '0px', maxHeight: '0px' },
        animate: { minHeight: height, maxHeight: height },
        exit: { minHeight: '0px', maxHeight: '0px' },
        transition: { type: 'tween', duration: 0.2 },
      };


  return (
    <>
      <AnimatePresence>
        {(focus || !focus || xl || forceChatOpen) && (
          <motion.div
            key="chat"
            style={
              floating
                ? {
                    WebkitMaskImage: `linear-gradient(0deg, black 0%, black 80%, transparent 100%)`,
                    maskImage: `linear-gradient(0deg, black 0%, black 80%, transparent 100%)`,
                  }
                : {}
            }
            className="overflow-hidden flex flex-col justify-end"
            {...animationProps}
          >
            <ScrollToBottom
              className={`h-[${height}] max-h-[${height}]`}
              followButtonClassName="hidden"
            >
              <FunctionContext.Consumer>
                {({ scrollToBottom }) => (
                  <StateContext.Consumer>
                    {({ sticky }) => (
                      <ChatContent floating={floating} scrollToBottom={scrollToBottom} sticky={sticky} />
                    )}
                  </StateContext.Consumer>
                )}
              </FunctionContext.Consumer>
            </ScrollToBottom>
          </motion.div>
        )}
      </AnimatePresence>
      <ChatBar forceChatOpen={forceChatOpen} />
    </>
  );
}
