import React from 'react';

export function SmallVideoButton({
  center,
  onClick,
  children,
}: {
  chatEnabled?: boolean;
  onClick: () => void;
  center?: boolean;
  children: React.ReactNode;
}) {
  return null;

  // <motion.div
  //   initial={{ opacity: 0 }}
  //   animate={{ opacity: 1 }}
  //   exit={{ opacity: 0 }}
  //   onClick={onClick}
  //   transition={{ type: 'tween', duration: 0.15 }}
  //   className={`pointer-events-none z-10 flex flex-col items-center justify-center ${
  //     center ? 'my-auto' : 'mx-auto mt-20'
  //   }`}
  // >
  //   <button className="pointer-events-auto rounded-full border-2 border-primary bg-black/50 p-4">{children}</button>
  // </motion.div>;
}
