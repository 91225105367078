import React from 'react';

export interface TimeRemaining {
  days: number;
  daysStr: string;
  hours: number;
  hoursStr: string;
  minutes: number;
  minutesStr: string;
  seconds: number;
  secondsStr: string;
  future: boolean;
  startingSoon: boolean;
}

export interface Time {
  date: string;
  time: string;
  day: string;
  monthShort: string;
  month: string;
  remaining: TimeRemaining;
}

/**
 * Get the time remaining until a date
 * @param date
 * @param startingSoonTimeInMinutes - time in minutes before the event starts to show the starting soon message
 */
export function getTimeRemaining(date: Date, startingSoonTimeInMinutes?: number): TimeRemaining {
  const now = new Date().getTime();
  const timeLeft = date.getTime() - now;
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const daysStr = days.toString();
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const hoursStr = hours.toString().padStart(2, '0');
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const minutesStr = minutes.toString().padStart(2, '0');
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  const secondsStr = seconds.toString().padStart(2, '0');

  return {
    days,
    daysStr,
    hours,
    hoursStr,
    minutes,
    minutesStr,
    seconds,
    secondsStr,
    future: timeLeft > 0,
    startingSoon: startingSoonTimeInMinutes ? timeLeft / 1000 / 60 < startingSoonTimeInMinutes : false,
  };
}

/**
 * Find the closest index in an array
 * @param current - current value
 * @param max - max value
 * @param tiles - number of tiles
 */
export const findClosestIndex = (current: number, max: number, tiles: number) => {
  const percentage = current / max;
  const closestNumber = percentage * tiles;

  return current === 0 ? -1 : Math.floor(closestNumber);
};

/**
 * Format the time remaining until a date
 * @param date - date to format
 * @param startingSoonTimeInMinutes - time in minutes before the event starts to show the starting soon message
 */
export const formatTimeRemaining = (date: Date, startingSoonTimeInMinutes?: number) =>
  ({
    time: date.toLocaleString('en-US', { hour: 'numeric', hour12: true }).toUpperCase(),
    day: date.getDate().toString(10),
    monthShort: date
      .toLocaleString('en-US', {
        month: 'short',
      })
      .toUpperCase(),
    month: date
      .toLocaleString('en-US', {
        month: 'long',
      })
      .toUpperCase(),
    date: `${date.getDate()} ${date.toLocaleString('en-US', {
      month: 'short',
    })}`.toUpperCase(),
    remaining: getTimeRemaining(date, startingSoonTimeInMinutes),
  } as Time);

export type CountdownProps = {
  startDate: string;
  startingSoonTimeInMinutes?: number; // time in minutes before the event starts to show the starting soon message
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * Countdown SVG paths
 */
export const countDownRingSmall: string[] = [
  'M18.8054281,191.092713 C17.8933281,191.618713 16.7257281,191.306713 16.2127281,190.387713 C10.2915281,179.769713 5.91262814,168.361713 3.20812814,156.509713 C2.97382814,155.482713 3.63292814,154.469713 4.66312814,154.250713 L8.44702814,153.445713 C9.47722814,153.226713 10.4883281,153.884713 10.7235281,154.911713 C13.2592281,165.976713 17.3475281,176.626713 22.8666281,186.545713 C23.3787281,187.465713 23.0676281,188.631713 22.1555281,189.157713 L18.8054281,191.092713 Z',
  'M3.82322814,149.928713 C2.78602814,150.111713 1.79542814,149.419713 1.62802814,148.379713 C-0.304471861,136.376713 -0.517771861,124.159713 0.994728139,112.096713 C1.12572814,111.051713 2.09152814,110.325713 3.13452814,110.471713 L6.96532814,111.010713 C8.00822814,111.156713 8.73332814,112.120713 8.60322814,113.165713 C7.20172814,124.430713 7.40082814,135.836713 9.19462814,147.045713 C9.36102814,148.085713 8.67012814,149.074713 7.63292814,149.256713 L3.82322814,149.928713 Z',
  'M3.82302814,106.122713 C2.78582814,105.940713 2.09182814,104.950713 2.29012814,103.916713 C4.57932814,91.9767128 8.55742814,80.4237128 14.1044281,69.6057128 C14.5850281,68.6677128 15.7410281,68.3157128 16.6709281,68.8097128 L20.0865281,70.6257128 C21.0165281,71.1207128 21.3680281,72.2747128 20.8884281,73.2127128 C15.7187281,83.3177128 12.0047281,94.1047128 9.85672814,105.250713 C9.65742814,106.284713 8.66992814,106.977713 7.63272814,106.794713 L3.82302814,106.122713 Z',
  'M18.8056281,64.9597128 C17.8935281,64.4327128 17.5797281,63.2657128 18.1199281,62.3617128 C24.3547281,51.9247128 32.0443281,42.4291128 40.9568281,34.1605128 C41.7289281,33.4442128 42.9354281,33.5085128 43.6404281,34.2911128 L46.2294281,37.1659128 C46.9334281,37.9486128 46.8694281,39.1533128 46.0984281,39.8703128 C37.7837281,47.5985128 30.6045281,56.4637128 24.7738281,66.2037128 C24.2328281,67.1067128 23.0678281,67.4207128 22.1557281,66.8937128 L18.8056281,64.9597128 Z',
  'M46.9634281,31.4024128 C46.2864281,30.5956128 46.3904281,29.3916128 47.2074281,28.7267128 C56.6354281,21.0516128 67.1094281,14.7585128 78.3124281,10.0368128 C79.2824281,9.62781277 80.3954281,10.1009128 80.7894281,11.0774128 L82.2384281,14.6642128 C82.6334281,15.6407128 82.1604281,16.7507128 81.1904281,17.1607128 C70.7344281,21.5792128 60.9564281,27.4547128 52.1464281,34.6125128 C51.3284281,35.2766128 50.1264281,35.1726128 49.4494281,34.3658128 L46.9634281,31.4024128 Z',
  'M84.9004281,9.50001277 C84.5394281,8.51031277 85.0494281,7.41451277 86.0444281,7.06911277 C97.5294281,3.08161277 109.523428,0.750212767 121.666428,0.145012767 C122.718428,0.0926127672 123.601428,0.917512767 123.638428,1.97011277 L123.773428,5.83611277 C123.809428,6.88871277 122.986428,7.77021277 121.934428,7.82361277 C110.597428,8.39941277 99.3994281,10.5762128 88.6724281,14.2891128 C87.6774281,14.6336128 86.5834281,14.1248128 86.2234281,13.1351128 L84.9004281,9.50001277 Z',
  // Second half
  'M128.039428,1.89291277 C128.039428,0.839712767 128.893428,-0.0154872328 129.946428,0.000212767162 C142.102428,0.181212767 154.171428,2.09271277 165.788428,5.67691277 C166.794428,5.98741277 167.342428,7.06471277 167.017428,8.06631277 L165.821428,11.7454128 C165.496428,12.7471128 164.420428,13.2937128 163.414428,12.9842128 C152.564428,9.64791277 141.296428,7.86331277 129.946428,7.68351277 C128.893428,7.66681277 128.039428,6.81461277 128.039428,5.76141277 L128.039428,1.89291277 Z',
  'M171.180428,9.50001277 C171.540428,8.51031277 172.635428,7.99871277 173.619428,8.37361277 C184.980428,12.7013128 195.667428,18.6251128 205.357428,25.9665128 C206.197428,26.6025128 206.343428,27.8021128 205.695428,28.6321128 L203.313428,31.6804128 C202.665428,32.5104128 201.467428,32.6562128 200.627428,32.0210128 C191.572428,25.1751128 181.595428,19.6444128 170.991428,15.5935128 C170.007428,15.2177128 169.496428,14.1248128 169.856428,13.1351128 L171.180428,9.50001277 Z',
  'M209.116428,31.4025128 C209.793428,30.5957128 210.997428,30.4894128 211.794428,31.1783128 C220.989428,39.1308128 229.005428,48.3525128 235.601428,58.5657128 C236.172428,59.4497128 235.899428,60.6277128 235.006428,61.1857128 L231.726428,63.2357128 C230.832428,63.7937128 229.657428,63.5207128 229.085428,62.6367128 C222.918428,53.1067128 215.434428,44.4974128 206.855428,37.0640128 C206.059428,36.3743128 205.952428,35.1727128 206.629428,34.3659128 L209.116428,31.4025128 Z',
  'M237.274428,64.9597128 C238.186428,64.4327128 239.354428,64.7447128 239.867428,65.6647128 C245.788428,76.2827128 250.167428,87.6897128 252.871428,99.5427128 C253.105428,100.569713 252.446428,101.582713 251.416428,101.801713 L247.632428,102.605713 C246.602428,102.824713 245.591428,102.166713 245.356428,101.140713 C242.820428,90.0757128 238.732428,79.4257128 233.213428,69.5067128 C232.701428,68.5857128 233.012428,67.4207128 233.924428,66.8937128 L237.274428,64.9597128 Z',
  'M252.256428,106.122713 C253.293428,105.940713 254.284428,106.632713 254.451428,107.672713 C256.384428,119.675713 256.597428,131.892713 255.084428,143.955713 C254.953428,145.000713 253.988428,145.726713 252.945428,145.580713 L249.114428,145.041713 C248.071428,144.895713 247.346428,143.930713 247.476428,142.885713 C248.877428,131.621713 248.678428,120.215713 246.885428,109.006713 C246.718428,107.966713 247.409428,106.977713 248.446428,106.794713 L252.256428,106.122713 Z',
  'M252.256428,149.928713 C253.293428,150.111713 253.987428,151.101713 253.789428,152.135713 C251.500428,164.075713 247.522428,175.628713 241.975428,186.446713 C241.494428,187.383713 240.338428,187.736713 239.408428,187.241713 L235.993428,185.425713 C235.063428,184.931713 234.711428,183.777713 235.191428,182.839713 C240.360428,172.733713 244.075428,161.947713 246.223428,150.801713 C246.422428,149.767713 247.409428,149.074713 248.447428,149.257713 L252.256428,149.928713 Z',
];
