import { FacingMode } from '@/lib/dolby/DolbyPublisherDeviceInputSelector';
import { Publish, PublishConnectOptions, TokenGeneratorCallback } from '@millicast/sdk';
import DolbyMedia, { DolbyMediaOptions } from './DolbyMedia';

export interface DolbyPublishUserMediaOptions extends DolbyMediaOptions {
  streamName: string;
}

export default class DolbyPublishUserMedia extends Publish {
  public mediaManager: DolbyMedia;
  constructor(options: DolbyPublishUserMediaOptions, tokenGenerator: TokenGeneratorCallback, autoReconnect: boolean) {
    super(options.streamName, tokenGenerator, autoReconnect);
    this.mediaManager = new DolbyMedia(options);
  }

  static async build(
    options: DolbyPublishUserMediaOptions,
    tokenGenerator: TokenGeneratorCallback,
    autoReconnect = true,
  ) {
    const instance = new DolbyPublishUserMedia(options, tokenGenerator, autoReconnect);
    await instance.getMediaStream();
    return instance;
  }

  public async connect(
    options: Partial<PublishConnectOptions> = {
      bandwidth: 0,
      disableVideo: false,
      disableAudio: false,
    },
  ): Promise<void> {
    if (!this.mediaManager.mediaStream) throw new Error('MediaStream is not initialized. Call getMediaStream() first.');

    await super.connect({
      ...options,
      mediaStream: this.mediaManager.mediaStream,
    });
  }

  async getMediaStream() {
    try {
      return await this.mediaManager.getMedia();
    } catch (e) {
      throw e;
    }
  }

  public stop() {
    this.webRTCPeer?.closeRTCPeer();
    super.stop();
  }

  public stopAndClose() {
    this.mediaManager.mediaStream?.getTracks().forEach((track) => {
      track.stop();
      this.mediaManager.mediaStream?.removeTrack(track);
    });
    this.webRTCPeer?.getTracks()?.forEach((track) => {
      track.stop();
    });
    this.webRTCPeer?.closeRTCPeer();
    this.mediaManager.mediaStream = null;
    super.stop();
  }

  async updateMediaStream(type: 'audio' | 'video', id: string) {
    try {
      const stream: MediaStream = await this.mediaManager.changeSource(id, type);

      this.mediaManager.mediaStream = stream;
      if (this.isActive() && this.webRTCPeer) {
        const track = type === 'audio' ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];
        this.webRTCPeer.replaceTrack(track);
      }
      console.log(`Updated ${type} stream`);

      let selectedTrack = null;

      for (const track of stream.getTracks().filter((track) => track.kind === type)) {
        const trackDeviceId = track.getSettings().deviceId;
        if (trackDeviceId !== id) {
          console.warn(`Track ${track.kind} deviceId ${trackDeviceId} is not the same as required ${id}`);
        }

        selectedTrack = track;
      }

      // get facing mode
      const facingMode: FacingMode = (selectedTrack?.getSettings().facingMode ?? 'unknown') as FacingMode;
      console.log(`facingMode: ${facingMode}`);

      return stream;
    } catch (e) {
      console.error(`Could not update ${type}: `, e);
      throw e;
    }
  }

  muteMedia(type: 'audio' | 'video', state: boolean) {
    if (type === 'audio') {
      return this.mediaManager.muteAudio(state);
    } else if (type === 'video') {
      return this.mediaManager.muteVideo(state);
    } else {
      return false;
    }
  }
}
