import { getGameToken } from '@/api/endpoints/fanalyst';
import { GameState } from '@/api/model';
import { Chat } from '@/components/game/chat/Chat';
import { Question } from '@/components/game/question/Question';
import Video from '@/components/game/video/Video';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import CountdownLarge from '@/components/ui/countdown/CountdownLarge';
import { TabBarHome } from '@/components/ui/tab-bar/TabBarHome';
import DEFAULT_ROUTES from '@/router/routes';
import { Dispatch, RootState } from '@/store/store';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ChatConnector } from './ChatConnector';
import { GameConnector } from './GameConnector';

export function GameToken({
  gameId,
  children,
  withPublishToken,
}: {
  gameId: string;
  children: React.ReactNode;
  withPublishToken?: boolean;
}) {
  const gameToken = useSelector((state: RootState) => state.game?.gameToken);
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    getGameToken(gameId)
      .then((data) => {
        dispatch.game.SET_GAME_TOKEN(data);
        if (withPublishToken) {
          if (!data.publishToken) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (window.APM) window.APM.captureError(new Error('No publish token'));
          } else if (!data.data.streamName) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (window.APM) window.APM.captureError(new Error('No stream name'));
          }
        }
      })
      .catch((err) => {
        if (err.name === 'AbortError') return;
        console.error(err);
        setError(err.response?.data?.message ?? err.message);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.APM) window.APM.captureError(err);
        dispatch.game.SET_GAME_TOKEN(null);
      })
      .finally(() => setLoading(false));
  }, [dispatch.game, gameId]);

  useEffect(() => {
    return () => {
      dispatch.game.SET_GAME_TOKEN(null);
    };
  }, []);

  return (
    <>
      {isLoading && <ActivityIndicatorFullScreen />}
      {error && <h3 className="mx-auto mt-32 w-full px-4 text-center text-eliminated">{error}</h3>}
      {!isLoading && !error && gameToken ? (
        withPublishToken ? (
          gameToken?.publishToken && gameToken?.data?.streamName ? (
            <>{children}</>
          ) : (
            <div className="absolute inset-0 flex w-full flex-col items-center justify-center p-4 text-center">
              <h3>Missing publish rights, you are not allowed to publish</h3>
              <h4 className="mt-4 text-gray-300">Only winners are allowed to publish</h4>
              <p className="mt-8 px-16 text-gray-300">
                It could be possible that this is a mistake, try to refresh the page with the button below.
              </p>
              <button className="btn btn-primary mt-4" onClick={() => window.location.reload()}>
                Refresh
              </button>
            </div>
          )
        ) : (
          <>{children}</>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export const ReconnectedIndicator: FC = (_) => {
  return (
    <div className="absolute left-0 top-0 mt-16 flex w-full flex-col">
      <p className="right-0 mx-auto rounded px-2 py-1 text-center font-bold bg-secondary">Reconnected</p>
    </div>
  );
};

interface JoinRejectIndicatorProps {
  reason: string | null;
}

export const JoinRejectIndicator: FC<JoinRejectIndicatorProps> = (props) => {
  return (
    <div className="absolute left-0 top-0 mt-16 flex w-full flex-col">
      <p className="right-0 mx-auto rounded px-2 py-1 text-center font-bold bg-red-500">{props.reason}</p>
    </div>
  );
};

export function PlayerStateBanner({
  playerState,
  spectatorMode,
}: {
  playerState: 'WINNER' | 'ELIMINATED' | null;
  spectatorMode: boolean;
}) {
  const text = useMemo(() => {
    console.log('recomputing text');
    if (spectatorMode) return 'SPECTATOR MODE';
    if (playerState === 'ELIMINATED') return "YOU'RE OUT";
    if (playerState === 'WINNER') return 'YOU WON!';
    return 'Unkown error';
  }, [playerState, spectatorMode]);

  console.log(spectatorMode);
  console.log(playerState);

  return (
    <div className="absolute left-0 top-0 mt-16 flex w-full flex-col">
      <p
        className={twMerge(
          `right-0 mx-auto rounded px-2 py-1 text-center font-bold`,
          playerState === 'WINNER' ? 'bg-secondary' : '',
          playerState === 'ELIMINATED' ? 'bg-eliminated' : '',
          spectatorMode ? 'bg-blue-600' : '',
        )}
      >
        {text}
      </p>
    </div>
  );
}

export default function GameScreen({
  chatEnabled = true,
  authenticate = true,
  questionClassName = '',
  overrideGameId = '',
  supportDesktop = true,
}: {
  overrideGameId?: string;
  chatEnabled?: boolean;
  authenticate?: boolean;
  questionClassName?: string;
  supportDesktop?: boolean;
}) {
  const { gameId } = useParams();
  const dispatch = useDispatch<Dispatch>();
  const isDoxingActive = useSelector((state: RootState) => state.game?.isDoxingActive);
  const gameState = useSelector((state: RootState) => state.game?.status?.phase);
  const viewToken = useSelector((state: RootState) => state.game?.gameToken?.data?.viewToken);
  const startsAt = useSelector((state: RootState) => state.game?.gameToken?.data?.startsAt);
  const playerState = useSelector((state: RootState) => state.game?.playerState);
  const spectatorMode = useSelector((state: RootState) => state.game?.spectatorMode);
  const [searchParams, setSearchParams] = useSearchParams();
  const playerStateVideoActive = useSelector((state: RootState) => state.game?.playerStateVideoActive);
  const isPreStart = useSelector(
    (state: RootState) => (state?.game?.status?.phase ?? 'PRE_START') === 'PRE_START' && !state?.game?.spectatorMode,
  );

  const reconnected = useSelector((state: RootState) => state.game?.reconnected);

  const joinSuccess = useSelector((state: RootState) => state.game?.joinSuccess);
  const joinRejectReason = useSelector((state: RootState) => state.game?.joinRejectReason);

  const serverInfo = useSelector((state: RootState) => state.game?.serverInfo);
  const showServerInfo = useSelector((state: RootState) => state.game?.showServerInfo);

  const token = useSelector((state: RootState) => state.auth.session!.token);

  useEffect(() => {
    function listenForKeyPress(e: KeyboardEvent) {
      // (Cmd or Ctrl) + I
      if ((e.metaKey || e.ctrlKey) && e.key === 'i') {
        dispatch.game.TOGGLE_SHOW_SERVER_INFO();
      }
    }

    document.addEventListener('keydown', listenForKeyPress);

    return () => {
      document.removeEventListener('keydown', listenForKeyPress);
    };
  }, []);

  useEffect(() => {
    if (searchParams.has('direct')) {
      dispatch.game.SET_DIRECT_START(true);
      setSearchParams(
        {},
        {
          replace: true,
        },
      );
    }
  }, [searchParams, dispatch.game, setSearchParams]);

  return (
    <GameToken gameId={gameId ? gameId : overrideGameId}>
      {/* game info debug screen */}
      {showServerInfo && serverInfo ? (
        <div className="absolute top-0 z-20 mt-16 flex w-full flex-col text-center">
          <p>{serverInfo.server}</p>
        </div>
      ) : (
        <></>
      )}
      <GameConnector
        gameId={gameId ?? overrideGameId ?? ''}
        authenticate={authenticate}
        className={`dark flex ${authenticate ? 'h-screen' : 'flex-1'}`}
      >
        <div className="relative flex flex-1 flex-col">
          {startsAt && isPreStart && (
            <div className="absolute left-1/2 top-1/2 z-0 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
              <CountdownLarge startDate={startsAt} startingSoonTimeInMinutes={2} />
            </div>
          )}
          <div className="relative flex flex-1 flex-col">
            <div className="relative flex flex-1 flex-col">
              {reconnected && <ReconnectedIndicator />}
              {joinSuccess === false && <JoinRejectIndicator reason={joinRejectReason} />}
              {(playerState || spectatorMode) && (
                <PlayerStateBanner playerState={playerState} spectatorMode={spectatorMode} />
              )}
              <Question className={twMerge(questionClassName, 'mb-[100px]', supportDesktop ? 'xl:hidden' : '')} />
              {viewToken && (
                <Video
                  saveVideoRef={false}
                  chatEnabled={chatEnabled}
                  className={
                    supportDesktop &&
                    isDoxingActive &&
                    (gameState !== undefined ? gameState === GameState.FINISHED : true)
                      ? 'xl:grid xl:grid-cols-2'
                      : ''
                  }
                  alwaysMuted={!authenticate}
                />
              )}
              <div className="absolute -bottom-[1px] left-0 right-0 z-0 h-20 bg-gradient-to-b from-transparent to-black" />
            </div>
            {chatEnabled && (
              <ChatConnector authenticate={authenticate} gameId={gameId ?? overrideGameId ?? ''}>
                <Chat floating={true} forceChatOpen={isPreStart} />
              </ChatConnector>
            )}
          </div>
          {isPreStart && (
            <TabBarHome
              className="bg-black safe-padding-bottom xl:hidden"
              routes={DEFAULT_ROUTES}
              tooltipPlacement="top"
            />
          )}
        </div>
        <div
          className={twMerge(
            'relative mx-10 hidden w-full max-w-lg flex-col overflow-hidden',
            supportDesktop && !isDoxingActive && (gameState !== undefined ? gameState !== GameState.FINISHED : true)
              ? 'xl:flex'
              : '',
          )}
        >
          <Question className={questionClassName} center={true} />
        </div>
      </GameConnector>
    </GameToken>
  );
}
