import { cartTransform } from '@/store/models/cart';
import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import persistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import { models, RootModel } from './models';

export const store = init({
  models,
  plugins: [
    persistPlugin({
      key: 'auth',
      storage,
      // todo: add config to whitelist
      whitelist: ['auth', 'cart', 'checkout'],
      blacklist: ['challenge'],
      transforms: [cartTransform],
    }) as any,
  ],
  redux: {
    rootReducers: {
      RESET_APP: () => undefined,
    },
  },
});
export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
