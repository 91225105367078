import { getGames } from '@/api/endpoints/fanalyst';
import { useCallback, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateCheckout } from '@/api/endpoints/fanalyst';
import { CheckoutCreateType } from '@/api/model';
import { ScrollContainer } from '@/components/ScrollContainer';
import AuthenticatedLayout from '../../components/layout/AuthenticatedLayout';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import ContentContainer from '../../components/ui/ContentContainer';
import Title from '../../components/ui/Title';
import { BackHeader } from '@/components/ui/header/BackHeader';
import { SubscriptionSelectGameItem } from '@/components/subscription/SubscriptionSelectGameItem';
import { NoUpcomingGames } from '@/components/game/NoUpcomingGames';
import { AnimatePresence, motion } from 'framer-motion';

export default function SubscriptionPPP() {
  const { data, isLoading } = useInfiniteQuery(
    ['games'],
    async ({ pageParam = 0 }) => getGames({ perPage: 20, page: pageParam }),
    {
      getPreviousPageParam: (firstPage) =>
        (firstPage._pagination?.currentPage !== undefined && firstPage._pagination?.currentPage - 1) ?? undefined,
      getNextPageParam: (lastPage) =>
        (lastPage._pagination?.currentPage !== undefined && lastPage._pagination?.currentPage + 1) ?? undefined,
    },
  );

  const { mutateAsync: createCheckoutAsync, isLoading: isCreatingCheckout } = useCreateCheckout();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string | null>(null);
  const from = searchParams.get('from') || '/subscription';

  useEffect(() => {
    if (isLoading || selected) {
      return;
    }
    if (data && data.pages[0].items?.length && 0 > 0) {
      setSelected(data.pages[0].items[0].id);
    }
  }, [data, setSelected]);

  const buyProduct = useCallback(
    async (gameId: string | null) => {
      if (!gameId) {
        return;
      }

      const productId = data?.pages[0].items?.find((game) => game.id === gameId)?.productId;

      if (!productId) {
        return;
      }

      const checkout = await createCheckoutAsync({
        data: {
          productId,
          type: CheckoutCreateType.LIFE_LINE,
        },
      });
      navigate(`/checkout/${checkout.id}?from=/pay-per-play`);
    },
    [createCheckoutAsync, navigate, data],
  );

  return (
    <AuthenticatedLayout headerComponent={<BackHeader backUrl={from} />}>
      <Title>Select Game</Title>
      <ScrollContainer className="mt-5 justify-center px-4">
        <AnimatePresence>
          <motion.ul>
            {isLoading || isCreatingCheckout ? (
              <ActivityIndicatorFullScreen />
            ) : (
              <div className="flex flex-col items-center justify-center">
                {data?.pages[0].items?.length ? (
                  data?.pages.map(
                    (page) =>
                      page.items &&
                      page.items.map((game) => (
                        <SubscriptionSelectGameItem
                          key={game.id}
                          game={game}
                          onSelectGame={() => {
                            setSelected(game.id);
                          }}
                        />
                      )),
                  )
                ) : (
                  <NoUpcomingGames />
                )}
              </div>
            )}
          </motion.ul>
        </AnimatePresence>
      </ScrollContainer>
      <ContentContainer className="my-5 grid gap-2">
        <button className="btn btn-primary w-full" disabled={selected === null} onClick={() => buyProduct(selected)}>
          CONTINUE
        </button>
      </ContentContainer>
    </AuthenticatedLayout>
  );
}
