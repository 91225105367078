import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useGetIntent } from '../../../api/endpoints/fanalyst';
import { ActivityIndicatorFullScreen } from '../ActivityIndicatorFullScreen';
import SetupForm from './SetupForm';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? '');

const CreditCardForm = ({ checkoutId, onCancel }: { onCancel?: () => void; checkoutId: string }) => {
  const { data, isLoading: isLoadingSetup } = useGetIntent();

  if (isLoadingSetup || data === undefined) {
    return <ActivityIndicatorFullScreen />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: data.secret,
        appearance: {
          theme: 'none',
          variables: {
            fontWeightNormal: '700',
            fontSizeSm: '1rem',
            fontFamily: 'Poppins, sans-serif',
            colorText: '#ffffff',
            colorBackground: '#000000',
          },
          rules: {
            '.Input': {
              border: '1px solid #ffffff',
            },
            '.Dropdown': {
              border: '1px solid #ffffff',
            },
          },
        },
      }}
    >
      <SetupForm checkoutId={checkoutId} cancelAction={onCancel} />
    </Elements>
  );
};

export default CreditCardForm;
