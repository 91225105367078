import { ProductImageDto, ProductVariantDto, ShopProductDto } from '@/api/model';
import { createModel } from '@rematch/core';
import { createTransform } from 'redux-persist';
import { RootModel } from './index';

export type ProductWithVariant = {
  id: number;
  title: string;
  bodyHtml: string;
  image: ProductImageDto;
  variant: ProductVariantDto;
};

type CartStore = {
  products: (ProductWithVariant & { amount: number })[];
  showCart: boolean;
};

export const cart = createModel<RootModel>()({
  state: {
    products: [],
    showCart: false,
  } as CartStore,
  reducers: {
    toggleShow: (state, showCart: boolean) => ({
      ...state,
      showCart,
    }),
    add: (state, product: ShopProductDto, variant: ProductVariantDto) => {
      const index = state.products.findIndex((p) => p.variant.id === variant.id);

      if (index !== -1) {
        state.products[index].amount += 1;
      } else {
        const item = {
          id: product.id,
          title: product.title,
          bodyHtml: product.bodyHtml,
          image: product.image,
          variant,
        };

        state.products.push({ ...item, amount: 1 });
      }

      return state;
    },
    remove: (state, id: number) => {
      const index = state.products.findIndex((item) => item.id === id);

      if (index !== -1) {
        if (state.products[index].amount > 1) {
          state.products[index].amount -= 1;
        } else {
          state.products.splice(index, 1);
        }
      }

      return state;
    },
  },
  effects: () => ({}),
});

export const cartTransform = createTransform<CartStore, CartStore>(
  (inboundState) => ({
    ...inboundState,
    showCart: false,
  }),
  (outboundState) => {
    return outboundState;
  },
  { whitelist: ['cart'] },
);
