import { ForwardRefExoticComponent, ForwardedRef, HTMLProps, PropsWithoutRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export interface FormSelectOption {
  label: string;
  value: string;
}

interface FormSelectProps extends HTMLProps<HTMLSelectElement> {
  label?: string;
  defaultOption?: FormSelectOption;
  options: Array<FormSelectOption>;
}

export const FormSelect: ForwardRefExoticComponent<PropsWithoutRef<FormSelectProps>> = forwardRef(
  (props: FormSelectProps, ref: ForwardedRef<HTMLSelectElement>) => {
    const { label, defaultOption, options, ...rest } = props;

    return (
      <div className={twMerge('flex flex-col', props.className)}>
        {label && <label className="font-bold">{label}</label>}
        <select {...rest} className={`w-full rounded border bg-transparent p-2`} ref={ref}>
          {defaultOption && <option value={defaultOption.value}>{defaultOption.label}</option>}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  },
);
