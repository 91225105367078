import CheckIcon from "@/assets/icons/ui/check.svg?react";
import AuthenticatedLayout from "@/components/layout/AuthenticatedLayout";
import { ActivityIndicator } from "@/components/ui/ActivityIndicator";
import { Modal } from "@/components/ui/Modal";
import { HeaderNavigation } from "@/components/ui/header/HeaderNavigation";
import { classNames } from "@/lib/util/classNames";
import { useAnimate } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const FundsAdded = () => {
  const [modalScope, animateModal] = useAnimate();
  const [activityScope, animateActivity] = useAnimate();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");

  const triggerAnimation = () => {
    animateActivity(activityScope.current, { opacity: 0 }, { duration: 0.2, ease: "easeInOut" });
    animateModal(modalScope.current, { opacity: 1 }, { duration: 0.3, delay: 0.4, ease: "easeInOut" });
    animateModal("h3", { y: 0, opacity: 1 }, { duration: 0.2, delay: 0.8, ease: "easeInOut" });
    animateModal("svg", { y: 0, opacity: 1 }, { duration: 0.2, delay: 0.8, ease: "easeInOut" });
    animateModal("p", { y: 0, opacity: 1 }, { duration: 0.2, delay: 0.8, ease: "easeInOut" });
  };

  useEffect(() => {
    setTimeout(() => {
      triggerAnimation();
    }, 600);

    setTimeout(() => {
      setShow(false);
    }, 3200);

    setTimeout(() => {
      navigate(from || "/profile");
    }, 3500);
  }, []);

  const forceClose = () => {
    setShow(false);
    navigate(from || "/profile");
  };

  return (
    <AuthenticatedLayout headerComponent={<HeaderNavigation />}>
      <Modal className="flex h-[400px] w-[300px] items-center justify-center" show={show} onClose={forceClose}>
        <div
          ref={modalScope}
          className={classNames(
            "absolute z-50 flex h-full min-h-full w-full flex-col items-center justify-center bg-green-700 p-4 opacity-0",
          )}
        >
          <h3 className="opacity-0">YOU'RE RICH</h3>
          <CheckIcon className="my-6 h-20 w-20 rounded-full border border-white p-4 text-white opacity-0" />
          <p className="text-2xl font-bold opacity-0">Funds have been added!</p>
        </div>
        <div ref={activityScope}>
          <ActivityIndicator className="scale-50 self-center" />
        </div>
      </Modal>
    </AuthenticatedLayout>
  );
};
