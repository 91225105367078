import * as React from 'react';
import { createContext, useCallback, useContext } from 'react';
import { Socket } from 'socket.io-client';
import { TimeSync } from 'timesync';
import { View } from '@millicast/sdk';

type StreamStateType = {
  view: View | null;
  gameSocket: Socket | null;
  chatSocket: Socket | null;
  timeSync: TimeSync | null;
  videoNode: HTMLVideoElement | null;
};

type StreamActionType =
  | {
      type: 'SET_GAME_SOCKET';
      socket: StreamStateType['gameSocket'];
    }
  | {
      type: 'SET_CHAT_SOCKET';
      socket: StreamStateType['chatSocket'];
    }
  | {
      type: 'SET_TIME_SYNC';
      timeSync: StreamStateType['timeSync'];
    }
  | {
      type: 'SET_VIEW';
      view: StreamStateType['view'];
    }
  | {
      type: 'SET_VIDEO_NODE';
      videoNode: StreamStateType['videoNode'];
    };

export interface StreamContextType {
  stream: StreamStateType;
  setGameSocket: (socket: StreamStateType['gameSocket']) => any;
  setChatSocket: (socket: StreamStateType['chatSocket']) => any;
  setTimeSync: (socket: StreamStateType['timeSync']) => any;
  setView: (view: StreamStateType['view']) => any;
  setVideoNode: (videoNode: StreamStateType['videoNode']) => any;
}

const initialState: StreamStateType = {
  gameSocket: null,
  chatSocket: null,
  view: null,
  timeSync: null,
  videoNode: null,
};

const StreamContext = createContext<StreamContextType | undefined>(undefined);

function reducer(state: StreamStateType, action: StreamActionType): StreamStateType {
  switch (action.type) {
    case 'SET_GAME_SOCKET':
      return {
        ...state,
        gameSocket: action.socket,
      };
    case 'SET_CHAT_SOCKET':
      return {
        ...state,
        chatSocket: action.socket,
      };
    case 'SET_TIME_SYNC':
      return {
        ...state,
        timeSync: action.timeSync,
      };
    case 'SET_VIDEO_NODE':
      return {
        ...state,
        videoNode: action.videoNode,
      };
    default:
      throw new Error();
  }
}

function StreamProvider({ children }: { children: React.ReactNode }) {
  const [stream, dispatch] = React.useReducer(reducer, initialState);

  const setGameSocket = useCallback(
    async function (socket: StreamStateType['gameSocket']) {
      dispatch({
        type: 'SET_GAME_SOCKET',
        socket,
      });
    },
    [dispatch],
  );

  const setChatSocket = useCallback(
    async function (socket: StreamStateType['chatSocket']) {
      dispatch({
        type: 'SET_CHAT_SOCKET',
        socket,
      });
    },
    [dispatch],
  );

  const setTimeSync = useCallback(
    async function (timeSync: StreamStateType['timeSync']) {
      dispatch({
        type: 'SET_TIME_SYNC',
        timeSync,
      });
    },
    [dispatch],
  );

  const setView = useCallback(
    async function (view: StreamStateType['view']) {
      dispatch({
        type: 'SET_VIEW',
        view,
      });
    },
    [dispatch],
  );

  const setVideoNode = useCallback(
    async function (videoNode: StreamStateType['videoNode']) {
      dispatch({
        type: 'SET_VIDEO_NODE',
        videoNode,
      });
    },
    [dispatch],
  );

  const value: StreamContextType = {
    stream,
    setView,
    setGameSocket,
    setChatSocket,
    setTimeSync,
    setVideoNode,
  };

  return <StreamContext.Provider value={value}>{children}</StreamContext.Provider>;
}

function useStream() {
  const context = useContext<StreamContextType | undefined>(StreamContext);
  if (context === undefined) {
    throw new Error('useAuthentication must be used within a AuthenticationProvider.');
  }
  return context;
}

export { StreamProvider, useStream };
