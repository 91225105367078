import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import ContentContainer from '@/components/ui/ContentContainer';
import Persona from 'persona';
import { FC, useState } from 'react';

type Props = {
  inquiryId: string;
  done: () => void;
};

export const InlineInquiry: FC<Props> = ({ inquiryId, done }) => {
  const [isLoading, setLoading] = useState(true);

  return (
    <>
      {isLoading && (
        <ContentContainer>
          <ActivityIndicatorFullScreen />
        </ContentContainer>
      )}
      <div className="h-screen [&>iframe]:min-h-full [&>iframe]:min-w-full">
        <Persona.Inquiry
          frameHeight={650}
          frameWidth={400}
          inquiryId={inquiryId}
          onLoad={() => setLoading(false)}
          onComplete={done}
        />
      </div>
    </>
  );
};
