import AuthenticatedLayout from '@/components/layout/AuthenticatedLayout';
import { RedirectButton } from '@/components/ui/RedirectButton';
import SubTitle from '@/components/ui/SubTitle';
import Title from '@/components/ui/Title';
import { HeaderNavigation } from '@/components/ui/header/HeaderNavigation';
import { useSearchParams } from 'react-router-dom';

export const OldCheckoutCompleted = () => {
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from') || '/menu';

  return (
    <AuthenticatedLayout headerComponent={<HeaderNavigation />}>
      <Title>Checkout</Title>
      <div className="flex flex-1 flex-col items-center justify-center px-20">
        <SubTitle>Order has been paid</SubTitle>
        <RedirectButton to={from}>Back home</RedirectButton>
      </div>
    </AuthenticatedLayout>
  );
};
