import React from 'react';

export default function ChevronDownIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.56667 0L6.66667 4.94467L11.7667 0L13.3333 1.52227L6.66667 8L0 1.52227L1.56667 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
