import { useGetUpcomingGames } from '@/api/endpoints/fanalyst';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import BaseLayout from '../components/layout/BaseLayout';
import FullLogoHeader from '../components/ui/header/FullLogoHeader';
import { GameList } from './main/Upcoming';

export default function Welcome() {
  const { data: games, isLoading } = useGetUpcomingGames({
    onlyWithProductId: false,
  });
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate({
        pathname: '/',
      });
    }
  }, [token]);

  return (
    <BaseLayout headerComponent={<FullLogoHeader />}>
      {isLoading && <ActivityIndicatorFullScreen />}
      {!isLoading && games && (
        <div className="z-10 flex flex-[1_1_auto] touch-pan-x touch-pan-y flex-col overflow-y-auto">
          <GameList games={games} withAccess={false} />
        </div>
      )}
      <div className="z-10 flex flex-col bg-gradientFade px-6 text-center md:mx-auto md:w-80 md:bg-none">
        <Link to="/login" className="btn btn-primary mb-1 md:py-4" data-testid="welcome-login-register-button">
          LOGIN & REGISTER
        </Link>
        <Link to="/how-to-play" className="w-full pt-4 font-bold text-white safe-padding-bottom">
          HOW TO PLAY
        </Link>
      </div>
    </BaseLayout>
  );
}
