function getApiUrl(): string {
  const apiUrl = import.meta.env.VITE_API_URL;
  const regex = /https?:\/\/([^.:]+)(?:\.([^/:]+))?/;
  const match = window.location.href.match(regex);
  if (!match) return apiUrl ?? 'http://localhost:3000';

  const subDomain = match[1];

  switch (subDomain) {
    case 'localhost':
      return apiUrl ?? `http://localhost:3000`;
    case 'app':
      return 'https://api.fanalyst.io';
    case 'dev':
      return 'https://dev-api.fanalyst.io';
    default:
      return `https://${subDomain}-api.fanalyst.io`;
  }
}

function getWsUrl(): string {
  const regex = /https?:\/\/([^.:]+)(?:\.([^/:]+))?/;
  const match = window.location.href.match(regex);
  if (!match) return 'http://localhost:80';

  const subDomain = match[1];

  switch (subDomain) {
    case 'localhost':
      return `http://localhost:80`;
    case 'app':
      return 'https://api.fanalyst.io';
    case 'dev':
      return 'https://dev-api.fanalyst.io';
    default:
      return `https://${subDomain}-api.fanalyst.io`;
  }
}

export const API_URL = getApiUrl();
export const WS_URL = getWsUrl();
