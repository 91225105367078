import { Dispatch, RootState } from '@/store/store';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parseNestedQuery } from '@/lib/util/parseNestedQuery';
import { ConfigStore, DEFAULT_CONFIG } from '@/store/models/config';
import axios from 'axios';
import { SplashScreen } from '@/components/ui/SplashScreen';

type Props = { children: React.ReactNode };

export const ConfigInjector: FC<Props> = ({ children }) => {
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();
  const config = useSelector((state: RootState) => state.config);

  const initConfig = async () => {
    const queryParams = parseNestedQuery<ConfigStore>(location.search);
    const regex = /https?:\/\/([^.:]+)(?:\.([^/:]+))?/;
    const match = window.location.href.match(regex);

    try {
      if (!match) throw new Error('Subdomain not found');
      const response = await axios.get(`https://admin-api.fanalyst.io/api/instance-config/${match[1]}`);
      const config: ConfigStore = response.data;
      dispatch.config.setConfig({ ...config, ...queryParams, loading: false });
    } catch (error) {
      dispatch.config.setConfig({ ...DEFAULT_CONFIG, ...queryParams, loading: false });
      console.log('No custom config found. Using default config.');
    }
  };

  useEffect(() => {
    initConfig();
  }, []);

  if (config?.loading) {
    return <SplashScreen loadingState={{ error: null, loading: false }} />;
  }

  return <>{children}</>;
};
