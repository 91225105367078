import { ScrollContainer } from '@/components/ScrollContainer';
import { RuleItem, rules } from '@/pages/main/Rules';
import { Link } from 'react-router-dom';
import BaseLayout from '../components/layout/BaseLayout';
import Title from '../components/ui/Title';
import FullLogoHeader from '../components/ui/header/FullLogoHeader';

export const HowToPlay = () => (
  <BaseLayout headerComponent={<FullLogoHeader />}>
    <Title>How To play</Title>
    <ScrollContainer className="px-4">
      {rules.map((item, index) => (
        <RuleItem key={index} index={index + 1} {...item} />
      ))}
    </ScrollContainer>
    <div className="mx-6 flex flex-col text-center">
      <Link to="/login" className="btn btn-primary safe-margin-bottom">
        LOGIN & REGISTER
      </Link>
    </div>
  </BaseLayout>
);
