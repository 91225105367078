import { RootState } from '@/store/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

export const ThemeConfig: FC = () => {
  const { colors } = useSelector((state: RootState) => state.config);

  const GlobalStyles = createGlobalStyle`
    :root {
      --color-primary: ${colors.primaryColor};
      --color-secondary: ${colors.secondaryColor};
      --color-ring: ${colors.ringColor};
      --color-navigation: ${colors.navigationColor};
      --color-button: ${colors.buttonColor};
    }
  `;

  return <GlobalStyles />;
};
