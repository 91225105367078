import { Link } from 'react-router-dom';
import BaseHeader from './BaseHeader';
import { ResponsiveLogo } from '@/components/ui/logo/ResponsiveLogo';

export default function FullLogoHeader() {
  return (
    <BaseHeader className="!h-24">
      <Link to="/" className="mx-auto flex h-24 items-center">
        <ResponsiveLogo />
      </Link>
    </BaseHeader>
  );
}
