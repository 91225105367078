import { GameToken } from '@/api/model';
import { cdnUrl } from '@/lib/util/cdnUrl';
import { Dispatch, RootState } from '@/store/store';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useViewer from '../../../lib/dolby/useViewer';
import { StackedVideoSource, StackedVideoSources, VideoSourceOptions } from './VideoSource';

export default function Video({
  inline = false,
  alwaysMuted,
  chatEnabled,
  className,
  saveVideoRef = true,
}: {
  alwaysMuted?: boolean;
  inline?: boolean;
  className?: string;
  chatEnabled?: boolean;
  saveVideoRef?: boolean;
}) {
  const dispatch = useDispatch<Dispatch>();
  const status = useSelector((state: RootState) => state.game?.status);
  const spectatorMode = useSelector((state: RootState) => state.game?.spectatorMode);
  // we can assume there always is a game token as the game screen is not rendered without one
  const token: GameToken = useSelector((state: RootState) => state.game?.gameToken) as GameToken;
  const playerState = useSelector((state: RootState) => state.game?.playerState);
  const preRoll = useSelector((state: RootState) => state.game?.preRoll);
  const hasQuestion = useSelector((state: RootState) => !!state.game?.question);
  const playerStateVideoActive = useSelector((state: RootState) => state.game?.playerStateVideoActive);
  const playerStateVideo = useMemo(
    () => playerStateVideoActive && playerState && !hasQuestion,
    [playerStateVideoActive, playerState, hasQuestion],
  );
  const [sources, setSources] = useState<StackedVideoSources>({
    main: {
      type: VideoSourceOptions.FILE,
      file: `${cdnUrl}/video/game/loop-dust.mp4`,
    },
  });

  const showError = (error: string) => console.error(error);
  const { hostMediaStream, doxMediaStream, startViewer, stopViewer } = useViewer({
    handleError: showError,
    streamAccountId: token.data.accountId,
    streamName: token.data.streamName,
    subscriberToken: token.data.viewToken,
    mode: 'viewer',
  });

  useEffect(() => {
    startViewer();

    return () => {
      stopViewer();
    };
  }, []);

  useEffect(() => {
    dispatch.game.SET_DOXING_ACTIVE(sources.top !== undefined && sources.main !== undefined);
  }, [sources]);

  useEffect(() => {
    if (spectatorMode) {
      setSources({
        main: hostMediaStream
          ? {
              type: VideoSourceOptions.STREAM,
              stream: hostMediaStream,
            }
          : {
              type: VideoSourceOptions.EMPTY,
            },
        top: doxMediaStream
          ? {
              type: VideoSourceOptions.STREAM,
              stream: doxMediaStream,
            }
          : undefined,
      });
      return;
    }

    if ((status?.phase ?? 'PRE_START') === 'PRE_START') {
      setSources({
        main: {
          type: VideoSourceOptions.FILE,
          file: `${cdnUrl}/video/game/loop-dust.mp4`,
          loop: true,
        },
      });
    } else if (preRoll) {
      setSources({
        main: {
          type: VideoSourceOptions.FILE,
          file: `${cdnUrl}/video/game/pre-roll-high-compressed.mp4`,
        },
      });
    } else if (playerStateVideo) {
      setSources(() => ({
        main: {
          type: VideoSourceOptions.FILE,
          file:
            playerState === 'WINNER'
              ? `${cdnUrl}/video/game/youve-won-medium-compressed.mp4`
              : `${cdnUrl}/video/game/youre-out-medium-compressed.mp4`,
        },
      }));
    } else {
      setSources({
        main: hostMediaStream
          ? {
              type: VideoSourceOptions.STREAM,
              stream: hostMediaStream,
            }
          : {
              type: VideoSourceOptions.EMPTY,
            },
        top: doxMediaStream
          ? {
              type: VideoSourceOptions.STREAM,
              stream: doxMediaStream,
            }
          : undefined,
      });
    }
  }, [status, preRoll, doxMediaStream, hostMediaStream, playerState, playerStateVideo, spectatorMode]);

  return (
    <StackedVideoSource
      inline={inline}
      chatEnabled={chatEnabled}
      className={className}
      alwaysMuted={alwaysMuted ?? false}
      sources={sources}
      saveVideoRef={saveVideoRef}
    />
  );
}
