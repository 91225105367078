import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Portal } from '@/lib/admin/AdminGameDropdown';
import { classNames } from '@/lib/util/classNames';
import { PlaySolidIcon } from '../../ui/icon/PlaySolidIcon';
import { SmallVideoButton } from './SmallVideoButton';
import { VideoButtonBanner } from './VideoButtonBanner';

function VideoBanner({
  startVisible,
  portal,
  dynamicGameClassesDisabled,
  chatEnabled,
  onClickPlay,
}: {
  startVisible: boolean;
  portal?: boolean;
  chatEnabled?: boolean;
  dynamicGameClassesDisabled?: boolean;
  onClickPlay: () => void;
}) {
  return (
    <AnimatePresence>
      {portal
        ? startVisible && (
            <SmallVideoButton chatEnabled={chatEnabled} onClick={onClickPlay}>
              <PlaySolidIcon width="32px" height="32px" />
            </SmallVideoButton>
          )
        : startVisible && (
            <VideoButtonBanner
              chatEnabled={chatEnabled}
              dynamicGameClassesDisabled={dynamicGameClassesDisabled}
              explanation="PLEASE CLICK ON THE BUTTON BELOW TO START THE VIDEO"
              text="START VIDEO"
              onClick={onClickPlay}
            />
          )}
    </AnimatePresence>
  );
}

export function VideoContainer({
  startVisible,
  chatEnabled,
  onClickPlay,
  portal,
  top,
  inline,
  className,
  dynamicGameClassesDisabled,
  children,
}: {
  startVisible: boolean;
  chatEnabled?: boolean;
  onClickPlay: () => void;
  portal?: boolean;
  dynamicGameClassesDisabled?: boolean;
  top?: boolean;
  inline: boolean;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <div
        className={classNames(
          'pointer-events-none select-none',
          inline ? '' : 'absolute left-0 top-0 h-full w-full',
          top ? '-z-5' : '-z-10',
          className,
        )}
      >
        {children}
      </div>
      {portal ? (
        <Portal>
          <div className="pointer-events-none absolute left-0 right-0 top-0 z-10">
            <VideoBanner
              portal={portal}
              startVisible={startVisible}
              chatEnabled={chatEnabled}
              dynamicGameClassesDisabled={dynamicGameClassesDisabled}
              onClickPlay={onClickPlay}
            />
          </div>
        </Portal>
      ) : (
        <div className="absolute bottom-0 left-0 right-0 z-20">
          <VideoBanner
            startVisible={startVisible}
            chatEnabled={chatEnabled}
            dynamicGameClassesDisabled={dynamicGameClassesDisabled}
            onClickPlay={onClickPlay}
          />
        </div>
      )}
    </>
  );
}
