import AuthenticatedLayout from '@/components/layout/AuthenticatedLayout';
import { RedirectButton } from '@/components/ui/RedirectButton';
import SubTitle from '@/components/ui/SubTitle';
import Title from '@/components/ui/Title';
import { HeaderNavigation } from '@/components/ui/header/HeaderNavigation';

export const SubscriptionAdded = () => (
  <AuthenticatedLayout headerComponent={<HeaderNavigation />}>
    <Title>Checkout</Title>
    <div className="flex flex-1 flex-col items-center justify-center px-20">
      <SubTitle>Subscription activated</SubTitle>
      <RedirectButton to="/">Back home</RedirectButton>
    </div>
  </AuthenticatedLayout>
);
