import { AlertToast } from '@/components/ui/alert/Alert';
import { RootState } from '@/store/store';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

export default function AlertManager() {
  const alert = useSelector((state: RootState) => state.alert.alert);

  return <AnimatePresence>{alert && <AlertToast alert={alert} />}</AnimatePresence>;
}
