import { TimeSync } from 'timesync';
import EventEmitter from 'events';

export class Timer extends EventEmitter {
  private index = 0;
  private total = 0;
  private progress = 0;
  private timeouts: number[] = [];
  private stopped = true;

  constructor(public steps: number[], private parent: BaseTimer) {
    super();
  }

  start() {
    if (this.steps.length === 0) {
      throw new Error('CANNOT_START_WITH_NO_STEPS');
    }

    this.stopped = false;
    this.total = this.steps.length;
    this.progress = this.steps.length;
    this.index = 0;
    this.emit('start', {
      progress: this.progress,
      total: this.total,
    });
    this.cycle();
  }

  hasEnded() {
    return this.index > this.steps.length - 1;
  }

  cycle() {
    const countdown = this.steps[this.index] - this.parent.currentTime;

    const internalCycle = (countdown: number) => {
      this.progress--;
      this.emit('cycle', {
        index: this.index,
        progress: this.progress,
        total: this.total,
        countdown,
      });
      this.index++;
      if (!this.hasEnded()) {
        this.cycle();
      } else {
        this.stop();
      }
    };

    if (countdown <= 0) {
      internalCycle(countdown);
    } else {
      const id: number = setTimeout(() => internalCycle(countdown), countdown) as unknown as number;
      this.timeouts.push(id);
    }
  }

  stop() {
    if (this.stopped) {
      return;
    }

    this.stopped = true;
    this.emit('stop', {
      index: this.index,
      progress: this.progress,
      total: this.total,
    });
    this.index = 0;
    this.total = 0;
    this.progress = 0;
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
  }
}

export abstract class BaseTimer {
  abstract get currentTime(): number;

  generateStepsUntil(timestamp: number, interval = 1000): Timer {
    if (timestamp < this.currentTime) {
      throw new Error('TIME_NOT_IN_FUTURE');
    }

    const startTime = this.currentTime;
    let difference = timestamp - startTime;

    if (difference <= 0) {
      throw new Error('NO_DIFFERENCE_IN_TIME');
    }

    const steps: number[] = [];
    const rest = difference % interval;
    let current = 0;

    if (rest > 0) {
      current = startTime + rest;
      steps.push(current);
      difference = difference - rest;
    }

    for (let i = 0; i < difference / interval; i++) {
      current = current + interval;
      steps.push(current);
    }

    return new Timer(steps, this);
  }
}

/**
 * This timer is used to test the functionality
 */
export class TestableTimer extends BaseTimer {
  constructor(public currentTime: number) {
    super();
  }
}

/**
 * This timer uses the built-in Date
 */
export class DefaultTimer extends BaseTimer {
  get currentTime(): number {
    return Date.now();
  }
}

/**
 * This timer takes in the time sync argument which syncs the time with the server
 * through an algorithm
 *
 * A simple algorithm with these properties is as follows:
 *
 * 1. Client stamps current local time on a "time request" packet and sends to server
 * 2. Upon receipt by server, server stamps server-time and returns
 * 3. Upon receipt by client, client subtracts current time from sent time and divides by two to compute latency. It subtracts current time from server time to determine client-server time delta and adds in the half-latency to get the correct clock delta. (So far this algorithm is very similar to SNTP)
 * 4. The first result should immediately be used to update the clock since it will get the local clock into at least the right ballpark (at least the right timezone!)
 * 5. The client repeats steps 1 through 3 five or more times, pausing a few seconds each time. Other traffic may be allowed in the interim, but should be minimized for best results
 * 6. The results of the packet receipts are accumulated and sorted in lowest-latency to highest-latency order. The median latency is determined by picking the mid-point sample from this ordered list.
 * 7. All samples above approximately 1 standard-deviation from the median are discarded and the remaining samples are averaged using an arithmetic mean.
 */
export class SynchronisedTimer extends BaseTimer {
  constructor(private timeSync: TimeSync) {
    super();
  }

  get currentTime(): number {
    return this.timeSync.now();
  }
}
