import { Logger } from "@/lib/dolby/logger";
import { MillicastDirectorResponse } from "@millicast/sdk";
import { useCallback, useEffect, useState } from "react";
import DolbyPublishUserMedia from "../lib/dolby/DolbyPublishUserMedia";

export default function useLivestream(
  tokenGenerator: () => Promise<MillicastDirectorResponse>,
  streamName: string,
  defaultConstraints: MediaStreamConstraints = {},
) {
  const logger = new Logger("useLivestream");
  const [publisher, setPublisher] = useState<DolbyPublishUserMedia | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);
  const [streamState, setStreamState] = useState({
    active: false, // displayed on someone's screen so safe to assume it's broadcasting
    connected: false, // available to be displayed on someone's screen
    connecting: false,
  });
  const [viewerCount, setViewerCount] = useState(0);

  useEffect(() => {
    return () => {
      if (publisher) {
        logger.warn("closing publisher");
        publisher.stopAndClose();
      }
    };
  }, [publisher]);

  useEffect(() => {
    const createPublishTimeStop = logger.timeout("create publisher", 5000);
    DolbyPublishUserMedia.build({ streamName, constraints: defaultConstraints }, tokenGenerator, true)
      .then(dolbyPublishUserMedia => {
        logger.log("publisher created");
        createPublishTimeStop();
        setPublisher(dolbyPublishUserMedia);
        return dolbyPublishUserMedia;
      })
      .then(dolbyPublishUserMedia => dolbyPublishUserMedia.getMediaStream())
      .catch(error => {
        logger.error("error creating publisher", error);
        setError(error);
      });
  }, [setPublisher]);

  useEffect(() => {
    if (!publisher) return;

    publisher.on("broadcastEvent", ev => {
      console.log(ev);

      switch (ev.name) {
        case "active":
          logger.warn("broadcast active", ev);
          setStreamState({
            active: true,
            connected: true,
            connecting: false,
          });
          break;
        case "stopped":
        case "inactive":
          logger.warn(`broadcast ${ev.name}`, ev);
          setStreamState({
            connecting: false,
            connected: ev.name === "inactive", // inactive means it's still available to be displayed
            active: false,
          });
          break;
        case "viewercount":
          logger.warn("viewercount", ev);
          setViewerCount(ev.data.viewercount ?? 0);
          setStreamState(state => ({
            ...state,
            connecting: false,
          }));
          break;
      }
    });
  }, [publisher]);

  const connect = useCallback(
    async (sourceId: string) => {
      if (!publisher) return;

      setStreamState({
        connecting: true,
        active: false,
        connected: false,
      });

      await publisher
        .connect({
          events: ["active", "inactive", "stopped", "layers", "viewercount"],
          sourceId,
        })
        .then(() => {
          logger.log("connected");

          setStreamState(state => ({
            ...state,
            connecting: false,
            connected: true,
            active: true,
          }));
        });
    },
    [publisher],
  );

  const disconnect = useCallback(async () => {
    if (!publisher) return;

    setStreamState({
      active: false,
      connecting: false,
      connected: false,
    });

    await publisher.stop();
  }, [publisher]);

  return {
    publisher,
    connect,
    disconnect,
    streamState,
    viewerCount,
    error,
  };
}
