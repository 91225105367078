import { getChallenges, useCreateCheckout } from '@/api/endpoints/fanalyst';
import { CheckoutCreateType, Game, GameAccess } from '@/api/model';
import { IntroChallengers } from '@/components/game/intro/IntroChallengers';
import { formatPrice } from '@/lib/general/currency-format';
import { classNames } from '@/lib/util/classNames';
import { RootState } from '@/store/store';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ActivityIndicatorFullScreen } from '../../ui/ActivityIndicatorFullScreen';
import CountdownBase, { CountdownContext } from '../../ui/countdown/CountdownBase';
import { countDownRingSmall } from '../../ui/countdown/CountdownUtil';

export default function IntroCountdown({ access, game }: { game: Game; access?: GameAccess }) {
  const navigate = useNavigate();
  const config = useSelector((state: RootState) => state.config);
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const { data: challenges } = useQuery({
    queryFn: () => getChallenges({ accepted: true, gameId: game.id }),
    enabled: !!token,
  });

  const { mutateAsync, isLoading: isCreatingCheckout } = useCreateCheckout();
  const buyProduct = useCallback(
    async (productId: string) => {
      const checkout = await mutateAsync({
        data: {
          productId,
          type: CheckoutCreateType.LIFE_LINE,
        },
      });

      if (checkout?.id) {
        navigate(`/checkout/${checkout?.id}?from=/&to=/checkout/completed`);
      }
    },
    [mutateAsync, navigate],
  );

  const getLink = () => {
    if (access?.canAccess === false) return '#';
    if (challenges && challenges?.length > 0) return `/game/${game.id}/challenges`;

    return `/game/${game.id}?direct=true`;
  };

  return (
    <CountdownBase
      className="-mt-36 sm:-mt-28"
      startingSoonTimeInMinutes={2}
      startDate={game.startsAt}
      rings={countDownRingSmall}
      onClick={() => {
        if (access?.access === 'PRODUCT' && !access.canAccess && access.productId) {
          buyProduct(access.productId);
        }
      }}
    >
      <Link
        className={classNames(
          access?.canAccess ?? true ? '' : 'cursor-not-allowed',
          'absolute z-20 h-[500px] w-[257px]',
        )}
        to={getLink()}
      ></Link>
      {isCreatingCheckout && <ActivityIndicatorFullScreen />}
      {config.settings.showCountdownName ? (
        <>
          <div className="clip-path-parallelogram absolute left-1/2 top-[15rem] z-10 w-40 -translate-x-1/2 transform bg-black p-[0.3rem] text-center text-white">
            <h2 className="text-[1.15rem] font-bold">Host</h2>
          </div>
          <div className="clip-path-parallelogram absolute -left-[1.6rem] top-[17rem] h-[6rem] w-[17rem] rounded bg-white p-5 text-center text-black">
            <>
              <h2 className="mt-2 leading-[0.7]">{game.host.firstName}</h2>
              <h2 className="font-bold">{game.host.lastName}</h2>
            </>
          </div>
        </>
      ) : (
        <>
          <div className="clip-path-parallelogram absolute left-1/2 top-[15rem] z-10 w-40 -translate-x-1/2 transform bg-black p-[0.3rem] text-center text-white">
            <h2 className="text-[1.15rem] font-bold">game</h2>
          </div>
          <div className="clip-path-parallelogram absolute -left-[1.6rem] top-[17rem] h-[6rem] w-[17rem] rounded bg-white p-5 text-center text-black">
            <>
              <h2 className="mt-2 leading-[0.7]">{game.name}</h2>
            </>
          </div>
        </>
      )}

      <IntroChallengers className="absolute top-[22rem] z-30" gameId={game.id} challenges={challenges} />
      {access && (
        <div
          className={`absolute left-1/2 top-[395px] mt-2 flex h-12 w-48 -translate-x-1/2 items-center justify-center rounded-full p-2 text-center sm:top-[420px] ${
            access?.canAccess ? 'bg-primary' : 'bg-neutral-700'
          }`}
        >
          {access.access === 'FREE' && <h4 className="font-bold">JOIN GAME</h4>}
          {access.access === 'CONTRACT' && (
            <h4 className="font-bold">{access?.canAccess ? 'JOIN GAME' : "NO VALID NFT's"}</h4>
          )}
          {access.access === 'PRODUCT' && (
            <h4 className="font-bold">
              {access?.canAccess ? 'JOIN GAME' : `BUY ${formatPrice(access.productPrice ?? 0)}`}
            </h4>
          )}
        </div>
      )}
      <CountdownContext.Consumer>
        {({ timeRemaining }) =>
          timeRemaining && (
            <>
              <div className="relative left-1/2 top-[9px] -z-10 h-[90px] w-[100px] -translate-x-1/2 transform rounded pt-1 text-center text-white">
                <svg
                  width="100"
                  height="90"
                  viewBox="0 0 100 87"
                  fill="none"
                  className="absolute -z-0"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.88705 0C3.08344 0 0 3.08344 0 6.88706V84.1129C0 84.9265 0.141047 85.707 0.40002 86.4315C15.5212 78.492 32.736 74 51 74C68.5644 74 85.1585 78.1545 99.8531 85.5344C99.9494 85.0757 100 84.6002 100 84.1129V6.88705C100 3.08344 96.9166 0 93.1129 0H6.88705Z"
                    fill="black"
                  />
                </svg>
                <p className="relative mt-1.5 text-[1.34rem]">{timeRemaining.monthShort}</p>
                <p className="relative -mt-3 text-[2rem] font-bold">{timeRemaining.day}</p>
              </div>
              <div className="absolute left-1/2 top-44 z-10 -mt-6 flex -translate-x-1/2 transform flex-col items-center justify-center whitespace-nowrap text-white">
                <p className="text-[1.2rem]">
                  {timeRemaining.date} &bull; {timeRemaining.time}
                </p>
                {timeRemaining.remaining.future && !timeRemaining.remaining.startingSoon ? (
                  timeRemaining.remaining.days > 0 ? (
                    <p className="-mt-2 flex items-center text-[1.8rem] font-bold">
                      {timeRemaining.remaining.daysStr} DAY
                      {timeRemaining.remaining.days > 1 ? 'S' : ''} LEFT
                    </p>
                  ) : (
                    <p className="-mt-2 flex items-center justify-center text-[2.6rem] font-bold">
                      {timeRemaining.remaining.hoursStr}
                      <span className="mx-0.5 text-xl">:</span>
                      {timeRemaining.remaining.minutesStr}
                      <span className="mx-0.5 text-xl">:</span>
                      {timeRemaining.remaining.secondsStr}
                    </p>
                  )
                ) : (
                  <p className="flex items-center text-xl font-bold">STARTING SOON</p>
                )}
              </div>
            </>
          )
        }
      </CountdownContext.Consumer>
    </CountdownBase>
  );
}
