import { loginCrypto, signMessage } from "@/api/endpoints/fanalyst";
import { BackHeader } from "@/components/ui/header/BackHeader";
import { Connect } from "@/lib/web3/connect";
import { Dispatch, RootState } from "@/store/store";
import { verifyMessage } from "ethers/lib/utils.js";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAccount, useSignMessage } from "wagmi";
import BaseLayout from "../../../components/layout/BaseLayout";
import ContentContainer from "../../../components/ui/ContentContainer";
import SubTitle from "../../../components/ui/SubTitle";
import Title from "../../../components/ui/Title";

export default function LoginCrypto() {
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const dispatch = useDispatch<Dispatch>();
  const { address } = useAccount();
  const { error, isLoading, signMessageAsync } = useSignMessage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from") || "/login";

  const onLogin = useCallback(async () => {
    if (!address) {
      return;
    }

    const { message } = await signMessage({
      address,
    });

    const signedMessage = await signMessageAsync({
      message,
    });

    const recoveredAddress = verifyMessage(message, signedMessage);

    const loginResponse = await loginCrypto({
      address: recoveredAddress,
      message,
      signature: signedMessage,
      referralCode: localStorage.getItem("Fanalyst.Referral") ?? "",
    });

    console.log(loginResponse);
    dispatch.auth.setSession(loginResponse);
  }, [address, signMessageAsync, dispatch]);

  useEffect(() => {
    if (token) {
      navigate({
        pathname: "/",
      });
    }
  }, [token, navigate, from]);

  return (
    <BaseLayout headerComponent={<BackHeader backUrl={from} homeUrl="/" />}>
      <ContentContainer className="mt-40">
        <Connect>
          {error && <SubTitle className="text-red-500">{error.message}</SubTitle>}
          <Title>SIGN MESSAGE</Title>
          <SubTitle>Sign a message with your wallet to verify you are the owner of the address</SubTitle>
          <div className="h-10" />
          {!isLoading && (
            <button className="btn btn-primary px-2" onClick={onLogin}>
              SIGN MESSAGE
            </button>
          )}
        </Connect>
      </ContentContainer>
    </BaseLayout>
  );
}
