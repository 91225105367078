import { Menu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { classNames } from '../util/classNames';

export function Portal(props: { children: ReactNode }) {
  const { children } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (!mounted) return null;
  return createPortal(children, document.body);
}

export enum DropdownItemType {
  Click,
  Link,
  Hidden,
}

export interface DropdownClickItem {
  _type: DropdownItemType.Click;
  click: () => void;
  text: string;
}

export interface DropdownLinkItem {
  _type: DropdownItemType.Link;
  link: string;
  text: string;
}

export type DropdownItem = DropdownClickItem | DropdownLinkItem;

export default function AdminDropdown({
  container,
  trigger,
  items,
}: {
  container: (instance: HTMLElement | null) => void;
  trigger: (instance: Element | null) => void;
  items: DropdownItem[][];
}) {
  // const dispatch = useDispatch<Dispatch>();

  function resolveClass({ active, disabled }: { active: boolean; disabled: boolean }) {
    return classNames(
      'block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700',
      active ? 'bg-gray-100 text-gray-900' : '',
      disabled ? 'cursor-not-allowed opacity-50' : '',
    );
  }

  return (
    <Menu as="div" className="relative inline-flex h-8 items-center justify-center text-left">
      <span className="inline-flex rounded-md">
        <Menu.Button
          ref={trigger}
          className="flex h-full items-center justify-center text-sm font-medium text-gray-700"
        >
          <EllipsisVerticalIcon className="w-5" />
        </Menu.Button>
      </span>

      <Portal>
        <Menu.Items
          className="z-10 w-56 divide-y divide-gray-100 rounded-md border border-gray-200 bg-white text-black shadow-lg outline-none"
          ref={container}
        >
          {items.map((dropdownItems, groupIndex) => (
            <div className="py-1" key={groupIndex}>
              {dropdownItems.map((data: DropdownItem, index) => {
                switch (data._type) {
                  case DropdownItemType.Click:
                    return (
                      <Menu.Item
                        key={`${groupIndex}-${index}`}
                        as="button"
                        onClick={data.click}
                        className={resolveClass}
                      >
                        {data.text}
                      </Menu.Item>
                    );
                  default:
                  case DropdownItemType.Link:
                    return (
                      <Menu.Item as={Link} key={`${groupIndex}-${index}`} to={data.link} className={resolveClass}>
                        {data.text}
                      </Menu.Item>
                    );
                }
              })}
            </div>
          ))}
        </Menu.Items>
      </Portal>
    </Menu>
  );
}
