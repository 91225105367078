import { Notification } from '@/api/model';
import { BaseNotification } from '@/components/notification/BaseNotification';
import { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';

export function LinkWrapper({
  type,
  children,
}: {
  type:
    | 'CHALLENGE_REQUEST_ACCEPTED'
    | 'CHALLENGE_REQUEST_DECLINED'
    | 'CHALLENGE_REQUEST_INCOMING'
    | 'CHALLENGE_REQUEST_OUTGOING';
  children: ReactNode;
}) {
  const link = useMemo(() => {
    switch (type) {
      case 'CHALLENGE_REQUEST_OUTGOING':
      case 'CHALLENGE_REQUEST_INCOMING':
        // Go to pending challenges page
        return `/challenge/pending`;
      case 'CHALLENGE_REQUEST_ACCEPTED':
        // Go to accepted challenges page
        return `/challenge/accepted`;
      case 'CHALLENGE_REQUEST_DECLINED':
        // Should go to declined challenges page, but there is no such page
        return null;
    }
  }, [type]);

  return link ? <Link to={link}>{children}</Link> : <>{children}</>;
}
export default function NotificationChallengeRequest({
  notification,
  selected,
  type,
}: {
  notification: Notification;
  selected: boolean;
  type:
    | 'CHALLENGE_REQUEST_OUTGOING'
    | 'CHALLENGE_REQUEST_INCOMING'
    | 'CHALLENGE_REQUEST_ACCEPTED'
    | 'CHALLENGE_REQUEST_DECLINED';
}) {
  return (
    <LinkWrapper type={type}>
      <BaseNotification
        notification={notification}
        selected={selected}
        actions={
          <div className="flex items-end">
            {notification.payload.gameName && notification.payload.gameName.length > 0 && (
              <span className="bg-secondary w-[70px] truncate rounded p-1 text-center text-xs text-white  md:w-[200px] md:px-2 md:text-sm lg:w-[250px]">
                {notification.payload.gameName}
              </span>
            )}
          </div>
        }
      >
        <p className="text-sm font-bold capitalize md:text-base">{notification.payload.username}</p>
        {type === 'CHALLENGE_REQUEST_OUTGOING' && (
          <p className="text-sm text-white/80 md:text-base">You sent a challenge request</p>
        )}
        {type === 'CHALLENGE_REQUEST_INCOMING' && (
          <p className="text-sm text-white/80 md:text-base">Sent you a challenge request</p>
        )}
        {type === 'CHALLENGE_REQUEST_ACCEPTED' && (
          <p className="text-secondary text-sm md:text-base">Accepted your challenge request</p>
        )}
        {type === 'CHALLENGE_REQUEST_DECLINED' && (
          <p className="text-sm text-primary md:text-base">Declined your challenge request</p>
        )}
      </BaseNotification>
    </LinkWrapper>
  );
}
