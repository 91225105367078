import LoginLayout from "@/components/layout/LoginLayout";
import LoginSelect from "@/pages/auth/LoginSelect";
import LoginCrypto from "@/pages/auth/crypto/LoginCrypto";
import Login from "@/pages/auth/regular/Login";
import Register from "@/pages/auth/regular/Register";
import Reset from "@/pages/auth/regular/Reset";
import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";

export default function LoginRouter() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route index path="/" element={<LoginSelect />} />
        <Route element={<LoginLayout />}>
          <Route path="/crypto" element={<LoginCrypto />} />
          <Route path="/password" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<Reset />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}
