import { classNames } from '../../../lib/util/classNames';

export function SubscriptionCardTrialOverlay({ trial, className }: { trial: number | null; className?: string }) {
  return (
    <div
      className={classNames(
        'absolute -top-6 z-10 -mb-8 mt-3 w-40 rounded-full bg-red-600 py-1 text-center text-sm font-bold uppercase text-white',
        className ?? '',
      )}
    >
      {trial} day free trial
    </div>
  );
}
