import React from 'react';
import { classNames } from '@/lib/util/classNames';

export default function ContentContainer({
  children,
  className = '',
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={classNames('flex flex-col px-4', 'md:mx-auto md:w-full md:max-w-3xl lg:max-w-4xl', className)}>
      {children}
    </div>
  );
}
