import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export function VideoButtonBanner({
  text,
  chatEnabled,
  dynamicGameClassesDisabled,
  explanation,
  center,
  onClick,
}: {
  explanation: string;
  text: string;
  chatEnabled?: boolean;
  dynamicGameClassesDisabled?: boolean;
  onClick: () => void;
  center?: boolean;
}) {
  const chatOpen = useSelector((state: RootState) => state.chat?.open);
  const status = useSelector((state: RootState) => state.game?.status);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "tween", duration: 0.15 }}
      className={`z-10 flex flex-col items-center justify-center bg-gradient-to-b from-transparent to-black px-4 ${
        dynamicGameClassesDisabled
          ? ""
          : (status?.phase ?? "PRE_START") === "PRE_START"
            ? ""
            : chatOpen && chatEnabled
              ? "-mb-8"
              : "-mb-32"
      } ${center ? "my-auto" : "mt-auto pb-16"}`}
    >
      <h2 className="w-full p-4 text-center text-lg font-bold">{explanation}</h2>
      <button className={`btn btn-primary mx-auto !w-fit`} onClick={onClick}>
        {text}
      </button>
    </motion.div>
  );
}
