import ShoppingCartIcon from '@/assets/icons/menu/shopping-cart.svg?react';
import SoundMutedIcon from '@/assets/icons/ui/sound-muted-white.svg?react';
import SoundUnmutedIcon from '@/assets/icons/ui/sound-unmuted.svg?react';
import { NotificationBell } from '@/components/notification/NotificationBell';
import { ShoppingCart } from '@/components/shop/ShoppingCart';
import { Dispatch, RootState } from '@/store/store';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { LogoTabBar } from '../tab-bar/LogoTabBar';
import { TabBarItemInterface } from '../tab-bar/TabBarItem';
import BaseHeader from './BaseHeader';

export default function MenuHeader({
  routes,
  rightSide,
  className = '',
  gradient = false,
}: {
  routes: Pick<TabBarItemInterface, 'link' | 'title' | 'routerLink'>[];
  rightSide?: React.ReactNode;
  className?: string;
  gradient?: boolean;
}) {
  const { pathname } = useLocation();
  const profilePicture = useSelector((state: RootState) => state.auth?.picture);
  const username = useSelector((state: RootState) => state.auth?.user?.username);
  const videoRef = useSelector((state: RootState) => state.core?.videoRef);
  const [isMuted, setIsMuted] = useState(videoRef?.muted ?? true);
  const location = useLocation();
  const showCart = useSelector((state: RootState) => state.cart?.showCart);
  const { cart } = useDispatch<Dispatch>();
  const prevVideoRef = useRef<HTMLVideoElement | null>(null);

  const toggleSound = async () => {
    if (!videoRef) return;

    if (videoRef.paused) await videoRef.play();
    videoRef.muted = !videoRef?.muted;
    setIsMuted(videoRef.muted);
  };

  useEffect(() => {
    if (prevVideoRef.current && videoRef) {
      if (videoRef.muted) videoRef.muted = location.pathname !== '/';
    }

    setIsMuted(videoRef?.muted ?? true);
    prevVideoRef.current = videoRef;
  }, [location.pathname, videoRef]);

  return (
    <>
      {gradient && (
        <div className="pointer-events-none absolute left-0 right-0 top-0 z-20 hidden min-h-[113px] w-full bg-gradient-to-b from-black to-transparent md:flex"></div>
      )}
      <ShoppingCart show={showCart} onClose={() => cart.toggleShow(false)} onOpen={() => cart.toggleShow(true)} />
      <BaseHeader className={className}>
        <div className="min-w-[85px]">
          <NavLink
            to={`/profile?from=${pathname}`}
            className={({ isActive }) =>
              twMerge(
                'ml-4 inline-block',
                isActive ? 'rounded-full ring-2 ring-primary ring-offset-4 ring-offset-black' : 'ring-0',
              )
            }
            data-cy="profile"
          >
            <img
              src={profilePicture?.thumb ?? '/img/defaultProfile.png'}
              alt="profile"
              className="inline-block h-8 w-8 rounded-full border-2 border-white bg-white object-contain"
              title={username}
            />
          </NavLink>
        </div>
        <LogoTabBar routes={routes} />
        <div className="flex min-w-[85px] justify-end">
          <AnimatePresence mode="wait">
            {rightSide ? (
              rightSide
            ) : (
              <div className="mr-4 flex flex-row gap-0.5 md:gap-4">
                <div className="inline-flex h-8 w-8 items-center justify-center">
                  {location.pathname === '/' &&
                    (isMuted ? (
                      <SoundMutedIcon className="cursor-pointer" fill="white" onClick={toggleSound} />
                    ) : (
                      <SoundUnmutedIcon className="cursor-pointer" fill="white" onClick={toggleSound} />
                    ))}
                  {location.pathname.includes('shop') && (
                    <ShoppingCartIcon className="cursor-pointer" onClick={() => cart.toggleShow(true)} />
                  )}
                </div>
                <NotificationBell pathname={pathname} />
              </div>
            )}
          </AnimatePresence>
        </div>
      </BaseHeader>
    </>
  );
}
