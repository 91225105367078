import React from 'react';
import { formatPrice } from '@/lib/general/currency-format';
import { classNames } from '@/lib/util/classNames';

export default function OrderOverview({
  price,
  title,
  className,
  children,
}: {
  price: number;
  title: string;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={classNames('relative overflow-hidden rounded bg-white text-black', className ?? '')}>
      <div className="py-2 text-center">
        <h3 className="whitespace-pre-line font-bold">{title}</h3>
      </div>
      <div className="px-4">
        <div className="flex items-center justify-between border-b border-[#DDDDDD]">
          <p className="w-20 text-right text-sm">SUBTOTAL</p>
          <p>{formatPrice(price)}</p>
        </div>
        <div className="flex items-center justify-between border-b border-[#DDDDDD] pt-1">
          <p className="w-20 text-right text-sm">TAX</p>
          <p>{formatPrice(0)}</p>
        </div>
        <div className="flex items-center justify-between pb-2 pt-1">
          <p className="w-20 text-right text-sm font-bold">TOTAL</p>
          <p>{formatPrice(price)}</p>
        </div>
      </div>
      {children}
    </div>
  );
}
