import SubTitle from './SubTitle';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="flex flex-col justify-center">
        <SubTitle>Not found</SubTitle>
        <button onClick={() => navigate('/')} className="btn btn-primary">
          Back home
        </button>
      </div>
    </div>
  );
}
