import { GameTokenData, GameToken as GameTokenType } from '@/api/model';
import { DolbyVideoStream } from '@/components/game/video/DolbyVideoStream';
import { VideoSource, VideoSourceOptions, VideoSourceType } from '@/components/game/video/VideoSource';
import { ActivityIndicator } from '@/components/ui/ActivityIndicator';
import SubTitle from '@/components/ui/SubTitle';
import Title from '@/components/ui/Title';
import DolbyPublishUserMedia from '@/lib/dolby/DolbyPublishUserMedia';
import { GameToken } from '@/pages/game/GameScreen';
import { RootState } from '@/store/store';
import { SignalIcon, SignalSlashIcon } from '@heroicons/react/24/outline';
import { Director } from '@millicast/sdk';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import useLivestream from '../../hooks/useLivestream';
import DolbyPublisherDeviceInputSelector, { FacingMode } from '../../lib/dolby/DolbyPublisherDeviceInputSelector';

export function ConnectScreen({
  publisher,
  facingMode,
  mediaStream,
  error,
  setFacingMode,
  state,
  onConnect,
  setMediaStream,
}: {
  publisher: DolbyPublishUserMedia | undefined;
  facingMode: FacingMode;
  mediaStream: MediaStream | undefined;
  error: Error | null;
  setFacingMode: (facingMode: FacingMode) => void;
  state:
    | {
        active: boolean;
        connected: boolean;
        connecting: boolean;
      }
    | undefined;
  setMediaStream: (mediaStream: MediaStream) => void;
  onConnect: () => void;
}) {
  const source: VideoSourceType = useMemo(() => {
    if (mediaStream) {
      return {
        mirror: facingMode === 'user',
        stream: mediaStream,
        type: VideoSourceOptions.STREAM,
      };
    } else {
      return {
        type: VideoSourceOptions.EMPTY,
      };
    }
  }, [mediaStream, facingMode]);

  return (
    <motion.div
      key="connect-div"
      className="absolute inset-2 z-10 m-2 flex flex-col items-center justify-center"
      initial={{ opacity: 0, y: '-100%', scale: 0.9 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: '-100%', scale: 0.9 }}
    >
      <div className="flex flex-col items-center rounded-md border border-white/25 bg-black p-4">
        <Title>You've Won FanTime!</Title>
        <SubTitle className="max-w-[420px]">Connect your video feed to meet the host, and go down in history as the winner! 🏆</SubTitle>
        <p className="text-xs opacity-75">You'll be live on broadcast for a few minutes.</p>
        <div className="flex h-[280px] w-full max-w-[400px] flex-col items-center justify-center">
          {publisher ? (
            <>
              <div className="relative z-50 mt-2 flex h-[200px] w-[200px] flex-col overflow-hidden rounded-md">
                <VideoSource alwaysMuted={true} portal={true} source={source} />
              </div>
              <DolbySettings
                className="mt-auto w-full p-0"
                publisher={publisher}
                setMediaStream={setMediaStream}
                setFacingMode={setFacingMode}
              />
            </>
          ) : (
            <>
              <ActivityIndicator />
              <SubTitle>Please give access to your camera and microphone.</SubTitle>
              {error ? <SubTitle className="text-red-500">{error.message}</SubTitle> : <></>}
            </>
          )}
        </div>
        <div className="mt-4 flex flex-col items-center justify-center">
          <button
            className="btn btn-primary w-[200px] min-w-fit"
            onClick={onConnect}
            disabled={state?.connecting || !!error}
          >
            <SignalIcon className="inline-flex h-5 w-5" />
            <span className="ml-2 inline-flex text-white">Connect</span>
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export function DolbySettings({
  className,
  publisher,
  setMediaStream,
  setFacingMode,
}: {
  className?: string;
  publisher: DolbyPublishUserMedia;
  setFacingMode: (facingMode: FacingMode) => void;
  setMediaStream: (mediaStream: MediaStream) => void;
}) {
  const onUpdateMediaStream = useCallback(
    (mediaStream: MediaStream) => {
      setMediaStream(mediaStream);

      const facingMode = (mediaStream.getVideoTracks()[0].getSettings().facingMode ?? 'unknown') as FacingMode;

      if (facingMode) {
        setFacingMode(facingMode);
      } else {
        setFacingMode('unknown');
      }
    },
    [publisher],
  );

  return (
    <div className={twMerge('grid grid-cols-2 gap-2 px-2 py-4 text-black', className)}>
      <div className="flex flex-col">
        <label className="text-sm font-medium text-white">Video Device</label>
        <DolbyPublisherDeviceInputSelector
          kind="video"
          publisher={publisher}
          onUpdateMediaStream={onUpdateMediaStream}
        />
      </div>
      <div className="flex flex-col">
        <label className="text-sm font-medium text-white">Audio Device</label>
        <DolbyPublisherDeviceInputSelector
          kind="audio"
          publisher={publisher}
          onUpdateMediaStream={onUpdateMediaStream}
        />
      </div>
    </div>
  );
}

export function GameDox({}) {
  // Since card is wrapped in a suspense, we can assume that the gameToken is available
  const gameToken: GameTokenType = useSelector((state: RootState) => state.game?.gameToken) as GameTokenType;
  const gameTokenData: GameTokenData = useSelector((state: RootState) => state.game?.gameToken?.data) as GameTokenData;
  const [mediaStream, setMediaStream] = useState<MediaStream | undefined>(undefined);
  const { publisher, disconnect, error, streamState, connect } = useLivestream(
    () =>
      Director.getPublisher({
        streamName: gameToken.data.streamName,
        token: gameToken.publishToken,
        streamType: 'WebRtc',
      }),
    gameToken.data.streamName,
    {
      video: {
        facingMode: { ideal: 'user' },
      },
      audio: true,
    },
  );
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [facingMode, setFacingMode] = useState<FacingMode>('unknown');

  const onUpdateMediaStream = useCallback(
    (mediaStream: MediaStream) => {
      setMediaStream(mediaStream);

      const facingMode = (mediaStream.getVideoTracks()[0].getSettings().facingMode ?? 'unknown') as FacingMode;

      if (facingMode) {
        setFacingMode(facingMode);
      } else {
        setFacingMode('unknown');
      }
    },
    [publisher],
  );

  const source: VideoSourceType = useMemo(() => {
    if (mediaStream) {
      return {
        mirror: facingMode === 'user',
        stream: mediaStream,
        type: VideoSourceOptions.STREAM,
      };
    } else {
      return {
        type: VideoSourceOptions.EMPTY,
      };
    }
  }, [mediaStream, facingMode]);

  return (
    <AnimatePresence>
      {!streamState.connected ? (
        <ConnectScreen
          key="connect"
          publisher={publisher}
          facingMode={facingMode}
          state={streamState}
          error={error}
          onConnect={() => connect('dox')}
          mediaStream={mediaStream}
          setFacingMode={setFacingMode}
          setMediaStream={onUpdateMediaStream}
        />
      ) : (
        <motion.div
          className="flex flex-1 flex-col"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="relative flex h-full flex-1 flex-col gap-4 lg:grid lg:grid-cols-2">
            <div className="relative flex flex-1 flex-col">
              <DolbyVideoStream
                subscriberToken={gameTokenData.viewToken}
                streamName={gameTokenData.streamName}
                streamAccountId={gameTokenData.accountId}
                mode="dox"
              />
            </div>
            <div className="absolute bottom-2 left-2 flex h-32 w-32 flex-col lg:relative lg:h-full lg:w-full lg:flex-1">
              <div className="relative flex h-full w-full flex-1 flex-col overflow-hidden rounded-md lg:overflow-auto lg:rounded-none">
                <VideoSource alwaysMuted={true} source={source} portal={true} />
              </div>
            </div>
          </div>
          <div className="relative flex flex-col lg:ml-auto lg:grid lg:w-1/2">
            {((!streamState.connected && !streamState.connecting) || showSettings) && publisher ? (
              <DolbySettings
                className="absolute bottom-full left-0 right-0 border-t border-white/25 bg-black/50 p-2 lg:rounded-md lg:border"
                publisher={publisher}
                setFacingMode={setFacingMode}
                setMediaStream={setMediaStream}
              />
            ) : (
              <></>
            )}
            <div className="grid grid-cols-2 gap-2 border-t border-white/25 p-2 lg:col-start-2 lg:border-t-0">
              <button className="btn btn-secondary" onClick={() => setShowSettings(!showSettings)}>
                Settings
              </button>
              <button
                className={twMerge('btn btn-primary flex items-center justify-center transition')}
                disabled={streamState.connecting || showSettings}
                onClick={() => (streamState.connected ? disconnect() : connect('dox'))}
              >
                {streamState.connected ? (
                  <>
                    <SignalSlashIcon className="h-5 w-5" />
                    <span className="ml-2 inline-flex text-white">Disconnect</span>
                  </>
                ) : (
                  <>
                    <SignalIcon className="inline-flex h-5 w-5" />
                    <span className="ml-2 inline-flex text-white">Connect</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default function GameDoxRoute() {
  const { gameId } = useParams();

  return gameId ? (
    <GameToken gameId={gameId} withPublishToken={true}>
      <GameDox />
    </GameToken>
  ) : (
    <h1>Game not found</h1>
  );
}
