import DolbyPublishUserMedia from '@/lib/dolby/DolbyPublishUserMedia';
import { useEffect, useState } from 'react';

export default function useMediaDevices(kind: MediaDeviceKind, publisher: DolbyPublishUserMedia) {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [error, setError] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(localStorage.getItem(`Devices.${kind}.selected`) || '');

  useEffect(() => {
    publisher.mediaManager
      .getMediaDevices()
      .then((devices) => {
        let kindDevices: MediaDeviceInfo[] = [];
        if (kind) {
          kindDevices = devices[kind];
        }
        if (!selectedDeviceId && kindDevices.length > 0) {
          setSelectedDeviceId(kindDevices[0].deviceId);
        }
        setDevices(kindDevices);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
      });
  }, [kind]);

  useEffect(() => {
    localStorage.setItem(`Devices.${kind}.selected`, selectedDeviceId);
  }, [selectedDeviceId]);

  return {
    devices,
    error,
    selectedDeviceId,
    setSelectedDeviceId,
  };
}
