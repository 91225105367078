export type Country = {
  en: string;
  code: string;
  nl: string;
  fr: string;
  de: string;
};

export const countries = [
  {
    en: 'Afghanistan',
    code: 'AF',
    nl: 'Afghanistan',
    fr: 'Afghanistan',
    de: 'Afghanistan',
  },
  {
    en: 'Aland Islands',
    code: 'AX',
    nl: 'Ålandeilanden',
    fr: 'Îles Åland',
    de: 'Ålandinseln',
  },
  {
    en: 'Albania',
    code: 'AL',
    nl: 'Albanië',
    fr: 'Albanie',
    de: 'Albanien',
  },
  {
    en: 'Algeria',
    code: 'DZ',
    nl: 'Algerije',
    fr: 'Algérie',
    de: 'Algerien',
  },
  {
    en: 'American Samoa',
    code: 'AS',
    nl: 'Amerikaans-Samoa',
    fr: 'Samoa américaines',
    de: 'Amerikanisch-Samoa',
  },
  {
    en: 'Andorra',
    code: 'AD',
    nl: 'Andorra',
    fr: 'Andorre',
    de: 'Andorra',
  },
  {
    en: 'Angola',
    code: 'AO',
    nl: 'Angola',
    fr: 'Angola',
    de: 'Angola',
  },
  {
    en: 'Anguilla',
    code: 'AI',
    nl: 'Anguilla',
    fr: 'Anguilla',
    de: 'Anguilla',
  },
  {
    en: 'Antarctica',
    code: 'AQ',
    nl: 'Antarctica',
    fr: 'Antarctique',
    de: 'Antarktis',
  },
  {
    en: 'Antigua and Barbuda',
    code: 'AG',
    nl: 'Antigua en Barbuda',
    fr: 'Antigua-et-Barbuda',
    de: 'Antigua und Barbuda',
  },
  {
    en: 'Argentina',
    code: 'AR',
    nl: 'Argentinië',
    fr: 'Argentine',
    de: 'Argentinien',
  },
  {
    en: 'Armenia',
    code: 'AM',
    nl: 'Armenië',
    fr: 'Arménie',
    de: 'Armenien',
  },
  {
    en: 'Aruba',
    code: 'AW',
    nl: 'Aruba',
    fr: 'Aruba',
    de: 'Aruba',
  },
  {
    en: 'Australia',
    code: 'AU',
    nl: 'Australië',
    fr: 'Australie',
    de: 'Australien',
  },
  {
    en: 'Austria',
    code: 'AT',
    nl: 'Oostenrijk',
    fr: 'Autriche',
    de: 'Österreich',
  },
  {
    en: 'Azerbaijan',
    code: 'AZ',
    nl: 'Azerbeidzjan',
    fr: 'Azerbaïdjan',
    de: 'Aserbaidschan',
  },
  {
    en: 'Bahamas',
    code: 'BS',
    nl: "Bahama's",
    fr: 'Bahamas',
    de: 'Bahamas',
  },
  {
    en: 'Bahrain',
    code: 'BH',
    nl: 'Bahrein',
    fr: 'Bahreïn',
    de: 'Bahrain',
  },
  {
    en: 'Bangladesh',
    code: 'BD',
    nl: 'Bangladesh',
    fr: 'Bangladesh',
    de: 'Bangladesch',
  },
  {
    en: 'Barbados',
    code: 'BB',
    nl: 'Barbados',
    fr: 'Barbade',
    de: 'Barbados',
  },
  {
    en: 'Belarus',
    code: 'BY',
    nl: 'Wit-Rusland',
    fr: 'Biélorussie',
    de: 'Belarus',
  },
  {
    en: 'Belgium',
    code: 'BE',
    nl: 'België',
    fr: 'Belgique',
    de: 'Belgien',
  },
  {
    en: 'Belize',
    code: 'BZ',
    nl: 'Belize',
    fr: 'Belize',
    de: 'Belize',
  },
  {
    en: 'Benin',
    code: 'BJ',
    nl: 'Benin',
    fr: 'Bénin',
    de: 'Benin',
  },
  {
    en: 'Bermuda',
    code: 'BM',
    nl: 'Bermuda',
    fr: 'Bermudes',
    de: 'Bermuda',
  },
  {
    en: 'Bhutan',
    code: 'BT',
    nl: 'Bhutan',
    fr: 'Bhoutan',
    de: 'Bhutan',
  },
  {
    en: 'Bosnia and Herzegovina',
    code: 'BA',
    nl: 'Bosnië en Herzegovina',
    fr: 'Bosnie-Herzégovine',
    de: 'Bosnien und Herzegowina',
  },
  {
    en: 'Botswana',
    code: 'BW',
    nl: 'Botswana',
    fr: 'Botswana',
    de: 'Botswana',
  },
  {
    en: 'Bouvet Island',
    code: 'BV',
    nl: 'Bouveteiland',
    fr: 'Île Bouvet',
    de: 'Bouvetinsel',
  },
  {
    en: 'Brazil',
    code: 'BR',
    nl: 'Brazilië',
    fr: 'Brésil',
    de: 'Brasilien',
  },
  {
    en: 'British Indian Ocean Territory',
    code: 'IO',
    nl: 'Brits Indische Oceaanterritorium',
    fr: "Territoire britannique de l'océan Indien",
    de: 'Britisches Territorium im Indischen Ozean',
  },
  {
    en: 'Brunei Darussalam',
    code: 'BN',
    nl: 'Brunei',
    fr: 'Brunei',
    de: 'Brunei',
  },
  {
    en: 'Bulgaria',
    code: 'BG',
    nl: 'Bulgarije',
    fr: 'Bulgarie',
    de: 'Bulgarien',
  },
  {
    en: 'Burkina Faso',
    code: 'BF',
    nl: 'Burkina Faso',
    fr: 'Burkina Faso',
    de: 'Burkina Faso',
  },
  {
    en: 'Burundi',
    code: 'BI',
    nl: 'Burundi',
    fr: 'Burundi',
    de: 'Burundi',
  },
  {
    en: 'Cabo Verde',
    code: 'CV',
    nl: 'Kaapverdië',
    fr: 'Cap-Vert',
    de: 'Kap Verde',
  },
  {
    en: 'Cambodia',
    code: 'KH',
    nl: 'Cambodja',
    fr: 'Cambodge',
    de: 'Kambodscha',
  },
  {
    en: 'Cameroon',
    code: 'CM',
    nl: 'Kameroen',
    fr: 'Cameroun',
    de: 'Kamerun',
  },
  {
    en: 'Canada',
    code: 'CA',
    nl: 'Canada',
    fr: 'Canada',
    de: 'Kanada',
  },
  {
    en: 'Cayman Islands',
    code: 'KY',
    nl: 'Kaaimaneilanden',
    fr: 'Îles Caïmans',
    de: 'Kaimaninseln',
  },
  {
    en: 'Central African Republic',
    code: 'CF',
    nl: 'Centraal-Afrikaanse Republiek',
    fr: 'République centrafricaine',
    de: 'Zentralafrikanische Republik',
  },
  {
    en: 'Chad',
    code: 'TD',
    nl: 'Tsjaad',
    fr: 'Tchad',
    de: 'Tschad',
  },
  {
    en: 'Chile',
    code: 'CL',
    nl: 'Chili',
    fr: 'Chili',
    de: 'Chile',
  },
  {
    en: 'China',
    code: 'CN',
    nl: 'China',
    fr: 'Chine',
    de: 'China',
  },
  {
    en: 'Christmas Island',
    code: 'CX',
    nl: 'Christmaseiland',
    fr: 'Île Christmas',
    de: 'Weihnachtsinsel',
  },
  {
    en: 'Colombia',
    code: 'CO',
    nl: 'Colombia',
    fr: 'Colombie',
    de: 'Kolumbien',
  },
  {
    en: 'Comoros',
    code: 'KM',
    nl: 'Comoren',
    fr: 'Comores',
    de: 'Komoren',
  },
  {
    en: 'Congo',
    code: 'CG',
    nl: 'Congo',
    fr: 'Congo',
    de: 'Kongo',
  },
  {
    en: 'Cook Islands',
    code: 'CK',
    nl: 'Cookeilanden',
    fr: 'Îles Cook',
    de: 'Cookinseln',
  },
  {
    en: 'Costa Rica',
    code: 'CR',
    nl: 'Costa Rica',
    fr: 'Costa Rica',
    de: 'Costa Rica',
  },
  {
    en: "Côte d'Ivoire",
    code: 'CI',
    nl: 'Ivoorkust',
    fr: "Côte d'Ivoire",
    de: 'Elfenbeinküste',
  },
  {
    en: 'Croatia',
    code: 'HR',
    nl: 'Kroatië',
    fr: 'Croatie',
    de: 'Kroatien',
  },
  {
    en: 'Cuba',
    code: 'CU',
    nl: 'Cuba',
    fr: 'Cuba',
    de: 'Kuba',
  },
  {
    en: 'Curaçao',
    code: 'CW',
    nl: 'Curaçao',
    fr: 'Curaçao',
    de: 'Curaçao',
  },
  {
    en: 'Cyprus',
    code: 'CY',
    nl: 'Cyprus',
    fr: 'Chypre',
    de: 'Zypern',
  },
  {
    en: 'Czechia',
    code: 'CZ',
    nl: 'Tsjechië',
    fr: 'Tchéquie',
    de: 'Tschechien',
  },
  {
    en: 'Denmark',
    code: 'DK',
    nl: 'Denemarken',
    fr: 'Danemark',
    de: 'Dänemark',
  },
  {
    en: 'Djibouti',
    code: 'DJ',
    nl: 'Djibouti',
    fr: 'Djibouti',
    de: 'Dschibuti',
  },
  {
    en: 'Dominica',
    code: 'DM',
    nl: 'Dominica',
    fr: 'Dominique',
    de: 'Dominica',
  },
  {
    en: 'Dominican Republic',
    code: 'DO',
    nl: 'Dominicaanse Republiek',
    fr: 'République dominicaine',
    de: 'Dominikanische Republik',
  },
  {
    en: 'Ecuador',
    code: 'EC',
    nl: 'Ecuador',
    fr: 'Équateur',
    de: 'Ecuador',
  },
  {
    en: 'Egypt',
    code: 'EG',
    nl: 'Egypte',
    fr: 'Égypte',
    de: 'Ägypten',
  },
  {
    en: 'El Salvador',
    code: 'SV',
    nl: 'El Salvador',
    fr: 'El Salvador',
    de: 'El Salvador',
  },
  {
    en: 'Equatorial Guinea',
    code: 'GQ',
    nl: 'Equatoriaal-Guinea',
    fr: 'Guinée équatoriale',
    de: 'Äquatorialguinea',
  },
  {
    en: 'Eritrea',
    code: 'ER',
    nl: 'Eritrea',
    fr: 'Érythrée',
    de: 'Eritrea',
  },
  {
    en: 'Estonia',
    code: 'EE',
    nl: 'Estland',
    fr: 'Estonie',
    de: 'Estland',
  },
  {
    en: 'Eswatini',
    code: 'SZ',
    nl: 'eSwatini',
    fr: 'Eswatini',
    de: 'Eswatini',
  },
  {
    en: 'Ethiopia',
    code: 'ET',
    nl: 'Ethiopië',
    fr: 'Éthiopie',
    de: 'Äthiopien',
  },
  {
    en: 'Faroe Islands',
    code: 'FO',
    nl: 'Faeröer',
    fr: 'Îles Féroé',
    de: 'Färöer',
  },
  {
    en: 'Fiji',
    code: 'FJ',
    nl: 'Fiji',
    fr: 'Fidji',
    de: 'Fidschi',
  },
  {
    en: 'Finland',
    code: 'FI',
    nl: 'Finland',
    fr: 'Finlande',
    de: 'Finnland',
  },
  {
    en: 'France',
    code: 'FR',
    nl: 'Frankrijk',
    fr: 'France',
    de: 'Frankreich',
  },
  {
    en: 'French Guiana',
    code: 'GF',
    nl: 'Frans-Guyana',
    fr: 'Guyane française',
    de: 'Französisch-Guayana',
  },
  {
    en: 'French Polynesia',
    code: 'PF',
    nl: 'Frans-Polynesië',
    fr: 'Polynésie française',
    de: 'Französisch-Polynesien',
  },
  {
    en: 'French Southern Territories',
    code: 'TF',
    nl: 'Franse Zuidelijke en Antarctische Gebieden',
    fr: 'Terres australes et antarctiques françaises',
    de: 'Französische Süd- und Antarktisgebiete',
  },
  {
    en: 'Gabon',
    code: 'GA',
    nl: 'Gabon',
    fr: 'Gabon',
    de: 'Gabun',
  },
  {
    en: 'Gambia',
    code: 'GM',
    nl: 'Gambia',
    fr: 'Gambie',
    de: 'Gambia',
  },
  {
    en: 'Georgia',
    code: 'GE',
    nl: 'Georgië',
    fr: 'Géorgie',
    de: 'Georgien',
  },
  {
    en: 'Germany',
    code: 'DE',
    nl: 'Duitsland',
    fr: 'Allemagne',
    de: 'Deutschland',
  },
  {
    en: 'Ghana',
    code: 'GH',
    nl: 'Ghana',
    fr: 'Ghana',
    de: 'Ghana',
  },
  {
    en: 'Gibraltar',
    code: 'GI',
    nl: 'Gibraltar',
    fr: 'Gibraltar',
    de: 'Gibraltar',
  },
  {
    en: 'Greece',
    code: 'GR',
    nl: 'Griekenland',
    fr: 'Grèce',
    de: 'Griechenland',
  },
  {
    en: 'Greenland',
    code: 'GL',
    nl: 'Groenland',
    fr: 'Groenland',
    de: 'Grönland',
  },
  {
    en: 'Grenada',
    code: 'GD',
    nl: 'Grenada',
    fr: 'Grenade',
    de: 'Grenada',
  },
  {
    en: 'Guadeloupe',
    code: 'GP',
    nl: 'Guadeloupe',
    fr: 'Guadeloupe',
    de: 'Guadeloupe',
  },
  {
    en: 'Guam',
    code: 'GU',
    nl: 'Guam',
    fr: 'Guam',
    de: 'Guam',
  },
  {
    en: 'Guatemala',
    code: 'GT',
    nl: 'Guatemala',
    fr: 'Guatemala',
    de: 'Guatemala',
  },
  {
    en: 'Guernsey',
    code: 'GG',
    nl: 'Guernsey',
    fr: 'Guernesey',
    de: 'Guernsey',
  },
  {
    en: 'Guinea',
    code: 'GN',
    nl: 'Guinee',
    fr: 'Guinée',
    de: 'Guinea',
  },
  {
    en: 'Guinea-Bissau',
    code: 'GW',
    nl: 'Guinee-Bissau',
    fr: 'Guinée-Bissau',
    de: 'Guinea-Bissau',
  },
  {
    en: 'Guyana',
    code: 'GY',
    nl: 'Guyana',
    fr: 'Guyana',
    de: 'Guyana',
  },
  {
    en: 'Haiti',
    code: 'HT',
    nl: 'Haïti',
    fr: 'Haïti',
    de: 'Haiti',
  },
  {
    en: 'Heard Island and McDonald Islands',
    code: 'HM',
    nl: 'Heard- en McDonaldeilanden',
    fr: 'Îles Heard et McDonald',
    de: 'Heard und McDonaldinseln',
  },
  {
    en: 'Holy See',
    code: 'VA',
    nl: 'Heilige Stoel',
    fr: 'Saint-Siège',
    de: 'Heiliger Stuhl',
  },
  {
    en: 'Honduras',
    code: 'HN',
    nl: 'Honduras',
    fr: 'Honduras',
    de: 'Honduras',
  },
  {
    en: 'Hong Kong',
    code: 'HK',
    nl: 'Hongkong',
    fr: 'Hong Kong',
    de: 'Hongkong',
  },
  {
    en: 'Hungary',
    code: 'HU',
    nl: 'Hongarije',
    fr: 'Hongrie',
    de: 'Ungarn',
  },
  {
    en: 'Iceland',
    code: 'IS',
    nl: 'IJsland',
    fr: 'Islande',
    de: 'Island',
  },
  {
    en: 'India',
    code: 'IN',
    nl: 'India',
    fr: 'Inde',
    de: 'Indien',
  },
  {
    en: 'Indonesia',
    code: 'ID',
    nl: 'Indonesië',
    fr: 'Indonésie',
    de: 'Indonesien',
  },
  {
    en: 'Iraq',
    code: 'IQ',
    nl: 'Irak',
    fr: 'Irak',
    de: 'Irak',
  },
  {
    en: 'Ireland',
    code: 'IE',
    nl: 'Ierland',
    fr: 'Irlande',
    de: 'Irland',
  },
  {
    en: 'Isle of Man',
    code: 'IM',
    nl: 'Isle of Man',
    fr: 'Île de Man',
    de: 'Isle of Man',
  },
  {
    en: 'Israel',
    code: 'IL',
    nl: 'Israël',
    fr: 'Israël',
    de: 'Israel',
  },
  {
    en: 'Italy',
    code: 'IT',
    nl: 'Italië',
    fr: 'Italie',
    de: 'Italien',
  },
  {
    en: 'Jamaica',
    code: 'JM',
    nl: 'Jamaica',
    fr: 'Jamaïque',
    de: 'Jamaika',
  },
  {
    en: 'Japan',
    code: 'JP',
    nl: 'Japan',
    fr: 'Japon',
    de: 'Japan',
  },
  {
    en: 'Jersey',
    code: 'JE',
    nl: 'Jersey',
    fr: 'Jersey',
    de: 'Jersey',
  },
  {
    en: 'Jordan',
    code: 'JO',
    nl: 'Jordanië',
    fr: 'Jordanie',
    de: 'Jordanien',
  },
  {
    en: 'Kazakhstan',
    code: 'KZ',
    nl: 'Kazachstan',
    fr: 'Kazakhstan',
    de: 'Kasachstan',
  },
  {
    en: 'Kenya',
    code: 'KE',
    nl: 'Kenia',
    fr: 'Kenya',
    de: 'Kenia',
  },
  {
    en: 'Kiribati',
    code: 'KI',
    nl: 'Kiribati',
    fr: 'Kiribati',
    de: 'Kiribati',
  },
  {
    en: 'Bonaire',
    code: 'BQ',
    nl: 'Bonaire',
    fr: 'Bonaire',
    de: 'Bonaire',
  },
  {
    en: 'Falkland Islands',
    code: 'FK',
    nl: 'Falklandeilanden',
    fr: 'Îles Malouines',
    de: 'Falklandinseln',
  },
  {
    en: 'Iran',
    code: 'IR',
    nl: 'Iran',
    fr: 'Iran',
    de: 'Iran',
  },
  {
    en: 'Korea',
    code: 'KP',
    nl: 'Noord-Korea',
    fr: 'Corée du Nord',
    de: 'Nordkorea',
  },
  {
    en: 'Bolivia',
    code: 'BO',
    nl: 'Bolivia',
    fr: 'Bolivie',
    de: 'Bolivien',
  },
  {
    en: 'Kuwait',
    code: 'KW',
    nl: 'Koeweit',
    fr: 'Koweït',
    de: 'Kuwait',
  },
  {
    en: 'Kyrgyzstan',
    code: 'KG',
    nl: 'Kirgizië',
    fr: 'Kirghizistan',
    de: 'Kirgisistan',
  },
  {
    en: 'Latvia',
    code: 'LV',
    nl: 'Letland',
    fr: 'Lettonie',
    de: 'Lettland',
  },
  {
    en: 'Lebanon',
    code: 'LB',
    nl: 'Libanon',
    fr: 'Liban',
    de: 'Libanon',
  },
  {
    en: 'Lesotho',
    code: 'LS',
    nl: 'Lesotho',
    fr: 'Lesotho',
    de: 'Lesotho',
  },
  {
    en: 'Liberia',
    code: 'LR',
    nl: 'Liberia',
    fr: 'Libéria',
    de: 'Liberia',
  },
  {
    en: 'Libya',
    code: 'LY',
    nl: 'Libië',
    fr: 'Libye',
    de: 'Libyen',
  },
  {
    en: 'Liechtenstein',
    code: 'LI',
    nl: 'Liechtenstein',
    fr: 'Liechtenstein',
    de: 'Liechtenstein',
  },
  {
    en: 'Lithuania',
    code: 'LT',
    nl: 'Litouwen',
    fr: 'Lituanie',
    de: 'Litauen',
  },
  {
    en: 'Luxembourg',
    code: 'LU',
    nl: 'Luxemburg',
    fr: 'Luxembourg',
    de: 'Luxemburg',
  },
  {
    en: 'Macao',
    code: 'MO',
    nl: 'Macau',
    fr: 'Macao',
    de: 'Macao',
  },
  {
    en: 'Madagascar',
    code: 'MG',
    nl: 'Madagaskar',
    fr: 'Madagascar',
    de: 'Madagaskar',
  },
  {
    en: 'Malawi',
    code: 'MW',
    nl: 'Malawi',
    fr: 'Malawi',
    de: 'Malawi',
  },
  {
    en: 'Malaysia',
    code: 'MY',
    nl: 'Maleisië',
    fr: 'Malaisie',
    de: 'Malaysia',
  },
  {
    en: 'Maldives',
    code: 'MV',
    nl: 'Maldiven',
    fr: 'Maldives',
    de: 'Malediven',
  },
  {
    en: 'Mali',
    code: 'ML',
    nl: 'Mali',
    fr: 'Mali',
    de: 'Mali',
  },
  {
    en: 'Malta',
    code: 'MT',
    nl: 'Malta',
    fr: 'Malte',
    de: 'Malta',
  },
  {
    en: 'Marshall Islands',
    code: 'MH',
    nl: 'Marshalleilanden',
    fr: 'Îles Marshall',
    de: 'Marshallinseln',
  },
  {
    en: 'Martinique',
    code: 'MQ',
    nl: 'Martinique',
    fr: 'Martinique',
    de: 'Martinique',
  },
  {
    en: 'Mauritania',
    code: 'MR',
    nl: 'Mauritanië',
    fr: 'Mauritanie',
    de: 'Mauretanien',
  },
  {
    en: 'Mauritius',
    code: 'MU',
    nl: 'Mauritius',
    fr: 'Maurice',
    de: 'Mauritius',
  },
  {
    en: 'Mayotte',
    code: 'YT',
    nl: 'Mayotte',
    fr: 'Mayotte',
    de: 'Mayotte',
  },
  {
    en: 'Mexico',
    code: 'MX',
    nl: 'Mexico',
    fr: 'Mexique',
    de: 'Mexiko',
  },
  {
    en: 'Monaco',
    code: 'MC',
    nl: 'Monaco',
    fr: 'Monaco',
    de: 'Monaco',
  },
  {
    en: 'Mongolia',
    code: 'MN',
    nl: 'Mongolië',
    fr: 'Mongolie',
    de: 'Mongolei',
  },
  {
    en: 'Montenegro',
    code: 'ME',
    nl: 'Montenegro',
    fr: 'Monténégro',
    de: 'Montenegro',
  },
  {
    en: 'Montserrat',
    code: 'MS',
    nl: 'Montserrat',
    fr: 'Montserrat',
    de: 'Montserrat',
  },
  {
    en: 'Morocco',
    code: 'MA',
    nl: 'Marokko',
    fr: 'Maroc',
    de: 'Marokko',
  },
  {
    en: 'Mozambique',
    code: 'MZ',
    nl: 'Mozambique',
    fr: 'Mozambique',
    de: 'Mosambik',
  },
  {
    en: 'Myanmar',
    code: 'MM',
    nl: 'Myanmar',
    fr: 'Myanmar',
    de: 'Myanmar',
  },
  {
    en: 'Namibia',
    code: 'NA',
    nl: 'Namibië',
    fr: 'Namibie',
    de: 'Namibia',
  },
  {
    en: 'Nauru',
    code: 'NR',
    nl: 'Nauru',
    fr: 'Nauru',
    de: 'Nauru',
  },
  {
    en: 'Nepal',
    code: 'NP',
    nl: 'Nepal',
    fr: 'Népal',
    de: 'Nepal',
  },
  {
    en: 'Netherlands',
    code: 'NL',
    nl: 'Nederland',
    fr: 'Pays-Bas',
    de: 'Niederlande',
  },
  {
    en: 'New Caledonia',
    code: 'NC',
    nl: 'Nieuw-Caledonië',
    fr: 'Nouvelle-Calédonie',
    de: 'Neukaledonien',
  },
  {
    en: 'New Zealand',
    code: 'NZ',
    nl: 'Nieuw-Zeeland',
    fr: 'Nouvelle-Zélande',
    de: 'Neuseeland',
  },
  {
    en: 'Nicaragua',
    code: 'NI',
    nl: 'Nicaragua',
    fr: 'Nicaragua',
    de: 'Nicaragua',
  },
  {
    en: 'Niger',
    code: 'NE',
    nl: 'Niger',
    fr: 'Niger',
    de: 'Niger',
  },
  {
    en: 'Nigeria',
    code: 'NG',
    nl: 'Nigeria',
    fr: 'Nigéria',
    de: 'Nigeria',
  },
  {
    en: 'Niue',
    code: 'NU',
    nl: 'Niue',
    fr: 'Niue',
    de: 'Niue',
  },
  {
    en: 'Norfolk Island',
    code: 'NF',
    nl: 'Norfolkeiland',
    fr: 'Île Norfolk',
    de: 'Norfolkinsel',
  },
  {
    en: 'North Macedonia',
    code: 'MK',
    nl: 'Noord-Macedonië',
    fr: 'Macédoine du Nord',
    de: 'Nordmazedonien',
  },
  {
    en: 'Northern Mariana Islands',
    code: 'MP',
    nl: 'Noordelijke Marianeneilanden',
    fr: 'Îles Mariannes du Nord',
    de: 'Nördliche Marianen',
  },
  {
    en: 'Norway',
    code: 'NO',
    nl: 'Noorwegen',
    fr: 'Norvège',
    de: 'Norwegen',
  },
  {
    en: 'Oman',
    code: 'OM',
    nl: 'Oman',
    fr: 'Oman',
    de: 'Oman',
  },
  {
    en: 'Pakistan',
    code: 'PK',
    nl: 'Pakistan',
    fr: 'Pakistan',
    de: 'Pakistan',
  },
  {
    en: 'Palau',
    code: 'PW',
    nl: 'Palau',
    fr: 'Palaos',
    de: 'Palau',
  },
  {
    en: 'Panama',
    code: 'PA',
    nl: 'Panama',
    fr: 'Panama',
    de: 'Panama',
  },
  {
    en: 'Papua New Guinea',
    code: 'PG',
    nl: 'Papoea-Nieuw-Guinea',
    fr: 'Papouasie-Nouvelle-Guinée',
    de: 'Papua-Neuguinea',
  },
  {
    en: 'Paraguay',
    code: 'PY',
    nl: 'Paraguay',
    fr: 'Paraguay',
    de: 'Paraguay',
  },
  {
    en: 'Peru',
    code: 'PE',
    nl: 'Peru',
    fr: 'Pérou',
    de: 'Peru',
  },
  {
    en: 'Philippines',
    code: 'PH',
    nl: 'Filipijnen',
    fr: 'Philippines',
    de: 'Philippinen',
  },
  {
    en: 'Pitcairn',
    code: 'PN',
    nl: 'Pitcairneilanden',
    fr: 'Îles Pitcairn',
    de: 'Pitcairninseln',
  },
  {
    en: 'Poland',
    code: 'PL',
    nl: 'Polen',
    fr: 'Pologne',
    de: 'Polen',
  },
  {
    en: 'Portugal',
    code: 'PT',
    nl: 'Portugal',
    fr: 'Portugal',
    de: 'Portugal',
  },
  {
    en: 'Puerto Rico',
    code: 'PR',
    nl: 'Puerto Rico',
    fr: 'Porto Rico',
    de: 'Puerto Rico',
  },
  {
    en: 'Qatar',
    code: 'QA',
    nl: 'Qatar',
    fr: 'Qatar',
    de: 'Katar',
  },
  {
    en: 'Réunion',
    code: 'RE',
    nl: 'Réunion',
    fr: 'La Réunion',
    de: 'Réunion',
  },
  {
    en: 'Romania',
    code: 'RO',
    nl: 'Roemenië',
    fr: 'Roumanie',
    de: 'Rumänien',
  },
  {
    en: 'Russian Federation',
    code: 'RU',
    nl: 'Rusland',
    fr: 'Fédération de Russie',
    de: 'Russische Föderation',
  },
  {
    en: 'Rwanda',
    code: 'RW',
    nl: 'Rwanda',
    fr: 'Rwanda',
    de: 'Ruanda',
  },
  {
    en: 'Saint Barthélemy',
    code: 'BL',
    nl: 'Saint-Barthélemy',
    fr: 'Saint-Barthélemy',
    de: 'Saint-Barthélemy',
  },
  {
    en: 'Saint Kitts and Nevis',
    code: 'KN',
    nl: 'Saint Kitts en Nevis',
    fr: 'Saint-Kitts-et-Nevis',
    de: 'St. Kitts und Nevis',
  },
  {
    en: 'Saint Lucia',
    code: 'LC',
    nl: 'Saint Lucia',
    fr: 'Sainte-Lucie',
    de: 'St. Lucia',
  },
  {
    en: 'Saint Pierre and Miquelon',
    code: 'PM',
    nl: 'Saint-Pierre en Miquelon',
    fr: 'Saint-Pierre-et-Miquelon',
    de: 'Saint-Pierre und Miquelon',
  },
  {
    en: 'Saint Vincent and the Grenadines',
    code: 'VC',
    nl: 'Saint Vincent en de Grenadines',
    fr: 'Saint-Vincent-et-les Grenadines',
    de: 'St. Vincent und die Grenadinen',
  },
  {
    en: 'Samoa',
    code: 'WS',
    nl: 'Samoa',
    fr: 'Samoa',
    de: 'Samoa',
  },
  {
    en: 'San Marino',
    code: 'SM',
    nl: 'San Marino',
    fr: 'Saint-Marin',
    de: 'San Marino',
  },
  {
    en: 'Sao Tome and Principe',
    code: 'ST',
    nl: 'Sao Tomé en Principe',
    fr: 'Sao Tomé-et-Principe',
    de: 'São Tomé und Príncipe',
  },
  {
    en: 'Saudi Arabia',
    code: 'SAU',
    nl: 'Saoedi-Arabië',
    fr: 'Arabie Saoudite',
    de: 'Saudi-Arabien',
  },
  {
    en: 'Senegal',
    code: 'SN',
    nl: 'Senegal',
    fr: 'Sénégal',
    de: 'Senegal',
  },
  {
    en: 'Serbia',
    code: 'RS',
    nl: 'Servië',
    fr: 'Serbie',
    de: 'Serbien',
  },
  {
    en: 'Seychelles',
    code: 'SC',
    nl: 'Seychellen',
    fr: 'Seychelles',
    de: 'Seychellen',
  },
  {
    en: 'Sierra Leone',
    code: 'SL',
    nl: 'Sierra Leone',
    fr: 'Sierra Leone',
    de: 'Sierra Leone',
  },
  {
    en: 'Singapore',
    code: 'SG',
    nl: 'Singapore',
    fr: 'Singapour',
    de: 'Singapur',
  },
  {
    en: 'Slovakia',
    code: 'SK',
    nl: 'Slowakije',
    fr: 'Slovaquie',
    de: 'Slowakei',
  },
  {
    en: 'Slovenia',
    code: 'SI',
    nl: 'Slovenië',
    fr: 'Slovénie',
    de: 'Slowenien',
  },
  {
    en: 'Solomon Islands',
    code: 'SB',
    nl: 'Salomonseilanden',
    fr: 'Îles Salomon',
    de: 'Salomonen',
  },
  {
    en: 'Somalia',
    code: 'SO',
    nl: 'Somalië',
    fr: 'Somalie',
    de: 'Somalia',
  },
  {
    en: 'South Africa',
    code: 'ZA',
    nl: 'Zuid-Afrika',
    fr: 'Afrique du Sud',
    de: 'Südafrika',
  },
  {
    en: 'South Sudan',
    code: 'SS',
    nl: 'Zuid-Soedan',
    fr: 'Soudan du Sud',
    de: 'Südsudan',
  },
  {
    en: 'Spain',
    code: 'ES',
    nl: 'Spanje',
    fr: 'Espagne',
    de: 'Spanien',
  },
  {
    en: 'Sri Lanka',
    code: 'LK',
    nl: 'Sri Lanka',
    fr: 'Sri Lanka',
    de: 'Sri Lanka',
  },
  {
    en: 'Sudan',
    code: 'SD',
    nl: 'Soedan',
    fr: 'Soudan',
    de: 'Sudan',
  },
  {
    en: 'Suriname',
    code: 'SR',
    nl: 'Suriname',
    fr: 'Suriname',
    de: 'Suriname',
  },
  {
    en: 'Sweden',
    code: 'SE',
    nl: 'Zweden',
    fr: 'Suède',
    de: 'Schweden',
  },
  {
    en: 'Switzerland',
    code: 'CH',
    nl: 'Zwitserland',
    fr: 'Suisse',
    de: 'Schweiz',
  },
  {
    en: 'Syrian Arab Republic',
    code: 'SY',
    nl: 'Syrië',
    fr: 'République arabe syrienne',
    de: 'Syrien',
  },
  {
    en: 'Tajikistan',
    code: 'TJ',
    nl: 'Tadzjikistan',
    fr: 'Tadjikistan',
    de: 'Tadschikistan',
  },
  {
    en: 'Thailand',
    code: 'TH',
    nl: 'Thailand',
    fr: 'Thaïlande',
    de: 'Thailand',
  },
  {
    en: 'Timor-Leste',
    code: 'TL',
    nl: 'Oost-Timor',
    fr: 'Timor oriental',
    de: 'Osttimor',
  },
  {
    en: 'Togo',
    code: 'TG',
    nl: 'Togo',
    fr: 'Togo',
    de: 'Togo',
  },
  {
    en: 'Tokelau',
    code: 'TK',
    nl: 'Tokelau',
    fr: 'Tokelau',
    de: 'Tokelau',
  },
  {
    en: 'Tonga',
    code: 'TO',
    nl: 'Tonga',
    fr: 'Tonga',
    de: 'Tonga',
  },
  {
    en: 'Tunisia',
    code: 'TN',
    nl: 'Tunesië',
    fr: 'Tunisie',
    de: 'Tunesien',
  },
  {
    en: 'Turkey',
    code: 'TR',
    nl: 'Turkije',
    fr: 'Turquie',
    de: 'Türkei',
  },
  {
    en: 'Turkmenistan',
    code: 'TM',
    nl: 'Turkmenistan',
    fr: 'Turkménistan',
    de: 'Turkmenistan',
  },
  {
    en: 'Turks and Caicos Islands',
    code: 'TC',
    nl: 'Turks- en Caicoseilanden',
    fr: 'Îles Turques-et-Caïques',
    de: 'Turks- und Caicosinseln',
  },
  {
    en: 'Tuvalu',
    code: 'TV',
    nl: 'Tuvalu',
    fr: 'Tuvalu',
    de: 'Tuvalu',
  },
  {
    en: 'Uganda',
    code: 'UG',
    nl: 'Oeganda',
    fr: 'Ouganda',
    de: 'Uganda',
  },
  {
    en: 'Ukraine',
    code: 'UA',
    nl: 'Oekraïne',
    fr: 'Ukraine',
    de: 'Ukraine',
  },
  {
    en: 'United Arab Emirates',
    code: 'AE',
    nl: 'Verenigde Arabische Emiraten',
    fr: 'Émirats arabes unis',
    de: 'Vereinigte Arabische Emirate',
  },
  {
    en: 'Micronesia',
    code: 'FM',
    nl: 'Micronesië',
    fr: 'Micronésie',
    de: 'Mikronesien',
  },
  {
    en: 'Laos',
    code: 'LA',
    nl: 'Laos',
    fr: 'Laos',
    de: 'Laos',
  },
  {
    en: 'Saint Martin',
    code: 'MF',
    nl: 'Sint Maarten',
    fr: 'Saint-Martin',
    de: 'Saint-Martin',
  },
  {
    en: 'Sint Maarten',
    code: 'SX',
    nl: 'Sint Maarten',
    fr: 'Saint-Martin',
    de: 'Sint Maarten',
  },
  {
    en: 'South Georgia Island',
    code: 'GS',
    nl: 'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
    fr: 'Géorgie du Sud et les Îles Sandwich du Sud',
    de: 'Südgeorgien und die Südlichen Sandwichinseln',
  },
  {
    en: 'Svalbard',
    code: 'SJ',
    nl: 'Svalbard',
    fr: 'Svalbard',
    de: 'Spitzbergen',
  },
  {
    en: 'Trinidad',
    code: 'TT',
    nl: 'Trinidad en Tobago',
    fr: 'Trinité-et-Tobago',
    de: 'Trinidad und Tobago',
  },
  {
    en: 'United States Minor Outlying Islands',
    code: 'UM',
    nl: 'Kleine afgelegen eilanden van de Verenigde Staten',
    fr: 'Îles mineures éloignées des États-Unis',
    de: 'United States Minor Outlying Islands',
  },
  {
    en: 'Uruguay',
    code: 'UY',
    nl: 'Uruguay',
    fr: 'Uruguay',
    de: 'Uruguay',
  },
  {
    en: 'Uzbekistan',
    code: 'UZ',
    nl: 'Oezbekistan',
    fr: 'Ouzbékistan',
    de: 'Usbekistan',
  },
  {
    en: 'Vanuatu',
    code: 'VU',
    nl: 'Vanuatu',
    fr: 'Vanuatu',
    de: 'Vanuatu',
  },
  {
    en: 'Tanzania',
    code: 'TZ',
    nl: 'Tanzania',
    fr: 'Tanzanie',
    de: 'Tansania',
  },
  {
    en: 'Taiwan',
    code: 'TW',
    nl: 'Taiwan',
    fr: 'Taïwan',
    de: 'Taiwan',
  },
  {
    en: 'Saint Helena',
    code: 'SH',
    nl: 'Sint-Helena',
    fr: 'Sainte-Hélène',
    de: 'St. Helena',
  },
  {
    en: 'Palestine',
    code: 'PS',
    nl: 'Palestina',
    fr: 'Palestine',
    de: 'Palästina',
  },
  {
    en: 'Moldova',
    code: 'MD',
    nl: 'Moldavië',
    fr: 'Moldavie',
    de: 'Moldau',
  },
  {
    en: 'Korea',
    code: 'KR',
    nl: 'Zuid-Korea',
    fr: 'Corée du Sud',
    de: 'Südkorea',
  },
  {
    en: 'Virgin Islands (British)',
    code: 'VG',
    nl: 'Britse Maagdeneilanden',
    fr: 'Îles Vierges britanniques',
    de: 'Britische Jungferninseln',
  },
  {
    en: 'Virgin Islands (U.S.)',
    code: 'VI',
    nl: 'Amerikaanse Maagdeneilanden',
    fr: 'Îles Vierges des États-Unis',
    de: 'Amerikanische Jungferninseln',
  },
  {
    en: 'Wallis and Futuna',
    code: 'WF',
    nl: 'Wallis en Futuna',
    fr: 'Wallis-et-Futuna',
    de: 'Wallis und Futuna',
  },
  {
    en: 'Western Sahara',
    code: 'EH',
    nl: 'Westelijke Sahara',
    fr: 'Sahara Occidental',
    de: 'Westsahara',
  },
  {
    en: 'Yemen',
    code: 'YE',
    nl: 'Jemen',
    fr: 'Yémen',
    de: 'Jemen',
  },
  {
    en: 'Zambia',
    code: 'ZM',
    nl: 'Zambia',
    fr: 'Zambie',
    de: 'Sambia',
  },
  {
    en: 'Congo',
    code: 'CD',
    nl: 'Congo',
    fr: 'Congo',
    de: 'Kongo',
  },
  {
    en: 'Venezuela',
    code: 'VE',
    nl: 'Venezuela',
    fr: 'Venezuela',
    de: 'Venezuela',
  },
  {
    en: 'Vietnam',
    code: 'VN',
    nl: 'Vietnam',
    fr: 'Vietnam',
    de: 'Vietnam',
  },
  {
    en: 'United Kingdom',
    code: 'GB',
    nl: 'Verenigd Koninkrijk',
    fr: 'Royaume-Uni',
    de: 'Vereinigtes Königreich',
  },
  {
    en: 'United States',
    code: 'US',
    nl: 'Verenigde Staten',
    fr: 'États-Unis',
    de: 'Vereinigte Staaten',
  },
  {
    en: 'Cocos Islands',
    code: 'CC',
    nl: 'Cocoseilanden',
    fr: 'Îles Cocos',
    de: 'Kokosinseln',
  },
];
