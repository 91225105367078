import { Logger } from '@/lib/dolby/logger';
import { RemoteTrackSources, ViewerAction, ViewerActionType } from './types';

const logger = new Logger('useViewer::reducer');

const reducer = (state: RemoteTrackSources, action: ViewerAction): RemoteTrackSources => {
  switch (action.type) {
    case ViewerActionType.ADD_SOURCE: {
      const { sourceId, remoteTrackSource } = action;

      logger.log('adding source', sourceId, remoteTrackSource);

      const newState = new Map([...state, [sourceId, remoteTrackSource]]);

      logger.log('new state', newState);

      return newState;
    }

    case ViewerActionType.REMOVE_SOURCE: {
      const newState = new Map(state);

      logger.log('removing source', action.sourceId);

      newState.delete(action.sourceId);

      logger.log('new state', newState);

      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
