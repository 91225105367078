import { Challenge } from '@/api/model';
import { Avatar } from '@/components/profile/Avatar';
import { classNames } from '@/lib/util/classNames';
import { generateImageUrl } from '@/store/models/auth';
import { FC } from 'react';

type Props = {
  challenge: Challenge;
  userId: string;
};

export const ChallengeAvatar: FC<Props> = ({ challenge, userId }) => {
  const { challenger, challengee, winnerId, tied } = challenge;
  const opponent = challenger.id === userId ? challengee : challenger;

  return (
    <Avatar
      size="h-10 w-10"
      imageUrl={generateImageUrl(opponent.image?.id, 'thumb')}
      className={classNames(
        'rounded-full border-2 !border-solid',
        tied ? 'border-blue-600' : winnerId === userId ? 'border-red-600' : '!border-secondary',
      )}
    />
  );
};
