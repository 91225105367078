import { register } from "@/api/endpoints/fanalyst";
import { User, UserModuleDto } from "@/api/model";
import { StartVerificationModal } from "@/components/security/StartVerificationModal";
import { ActivityIndicatorFullScreen } from "@/components/ui/ActivityIndicatorFullScreen";
import { BackHeader } from "@/components/ui/header/BackHeader";
import { InlineInquiry } from "@/pages/payment/InlineInquiry";
import { Dispatch, RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BaseLayout from "../../../components/layout/BaseLayout";
import ContentContainer from "../../../components/ui/ContentContainer";
import Input from "../../../components/ui/Input";
import SubTitle from "../../../components/ui/SubTitle";
import Title from "../../../components/ui/Title";

interface LoginForm {
  username: string;
  password: string;
}

interface userModulesState {
  userModules: UserModuleDto[];
  user: User;
}

function translateError(error: string) {
  if (Array.isArray(error)) {
    return error.join(", ");
  }

  switch (error) {
    case "EMAIL_ALREADY_EXISTS":
      return "This email is already in use";
    case "USERNAME_ALREADY_EXISTS":
      return "This username is already in use";
    default:
      return "Unknown error occurred";
  }
}

export default function Register() {
  const { alert } = useDispatch<Dispatch>();
  const [referenceId, setReferenceId] = useState<null | string>(null);
  const [startVerification, setStartVerification] = useState<boolean | null>(null);
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const from = searchParams.get("from") || "/";
  const {
    register: registerInput,
    handleSubmit,
    formState,
  } = useForm<LoginForm>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { isSubmitting } = formState;

  const createSession = (session: any) => {
    dispatch.auth.setSession(session);
    navigate({
      pathname: "/",
    });
  };

  const completeVerification = () => {
    setStartVerification(null);
    setReferenceId(null);

    const stateValue = location.state as userModulesState;

    if (stateValue.userModules.length > 0) {
      const data: LoginForm = {
        username: stateValue.user.username,
        password: "",
      };
      onSubmit(data);
    }
  };

  const onSubmit: SubmitHandler<LoginForm> = async data => {
    try {
      const session = await register({
        ...data,
        referralCode: localStorage.getItem("Fanalyst.Referral") ?? "",
      });

      createSession(session);
    } catch (err: any) {
      const response = err.response.data.message;

      if (response.hasOwnProperty("securityModules") && response.securityModules.length > 0) {
        setReferenceId(response.securityModules[0].referenceId);
        return;
      }

      alert.set(translateError(err?.response?.data?.message ?? err?.message ?? "UNKNOWN_ERROR"));
    }
  };

  useEffect(() => {
    if (token) {
      navigate({
        pathname: "/",
      });
    }
  }, [token, navigate, from]);

  return (
    <BaseLayout headerComponent={<BackHeader backUrl={from} homeUrl="/" />}>
      {referenceId && startVerification ? (
        <InlineInquiry inquiryId={referenceId} done={completeVerification} />
      ) : (
        <>
          {referenceId && startVerification === null && (
            <StartVerificationModal
              show={!!referenceId}
              confirm={() => setStartVerification(true)}
              close={() => setStartVerification(false)}
            />
          )}
          <ContentContainer className="flex h-full w-full flex-col items-center justify-end gap-8 sm:justify-center">
            <div>
              <Title>WELCOME</Title>
              <SubTitle className="text-sm">Register an account with username and password</SubTitle>
            </div>
            {isSubmitting && <ActivityIndicatorFullScreen />}
            <form onSubmit={handleSubmit(onSubmit)} className="relative flex w-full max-w-[420px] flex-col gap-4 px-2">
              <Input
                data-testid="password-username-register-input"
                required
                type="text"
                minLength={3}
                className="px-3 py-2 text-sm"
                placeholder="USERNAME"
                {...registerInput("username", {
                  required: "Please enter your username.",
                })}
              />
              <Input
                data-testid="password-password-register-input"
                required
                type="password"
                autoComplete="current-password"
                placeholder="PASSWORD"
                className="px-3 py-2 text-sm"
                minLength={3}
                {...registerInput("password", {
                  required: "Please enter your password.",
                })}
              />
              <button type="submit" className="btn btn-primary mt-4 uppercase" data-testid="password-submit-btn">
                Register
              </button>
              <Link
                to={`/login/password?from=${encodeURIComponent(from)}`}
                className="mb-4 py-4 text-center text-sm uppercase text-gray-400"
              >
                Already have an account? <span className="text-white">Login</span>
              </Link>
            </form>
          </ContentContainer>
        </>
      )}
    </BaseLayout>
  );
}
