import { Game } from '@/api/model';
import formatDate from '@/lib/general/date-format';
import { generateImageUrl } from '@/store/models/auth';
import { Dialog } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../ui/Modal';

const UpCommingGameNotificationModal = ({
  showModal,
  setShowModal,
  currentActiveGame,
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  currentActiveGame: Game | null;
}) => {
  const navigate = useNavigate();

  return (
    <Modal show={showModal && currentActiveGame !== null} onClose={() => setShowModal(false)}>
      <div className="-m-6">
        {currentActiveGame && (
          <div className="flex min-w-[275px] flex-col items-center justify-between rounded">
            {currentActiveGame.desktopVideoUrl ? (
              <video className="aspect-video  w-full rounded-t object-cover" controls={false} muted autoPlay loop>
                <source src={currentActiveGame.desktopVideoUrl} type="video/mp4" />
              </video>
            ) : (
              <img
                src={generateImageUrl(currentActiveGame.desktopMobileGameSelectImageId || '', 'public')}
                className="aspect-[8/3]  w-full rounded-t object-cover"
              />
            )}
            <Dialog.Title as="h3" className="mb-2 mt-4 text-lg font-bold leading-6 text-white">
              JOIN ACTIVE GAME
            </Dialog.Title>
            <div className="mb-4 flex flex-col items-center">
              <p className="mb-1">
                {currentActiveGame.host.firstName} {currentActiveGame.host.lastName}
              </p>
              <p className="text-xs">{formatDate(currentActiveGame.startsAt, true)}</p>
            </div>
            <button
              className="btn btn-primary mb-2 w-full"
              onClick={() => {
                setShowModal(false);
                navigate(`/game/${currentActiveGame.id}`);
              }}
            >
              JOIN
            </button>
          </div>
        )}

        <button
          type="button"
          className="focus:ring-white-500 mb-6 rounded-full  bg-transparent px-4 py-2 text-white focus:outline-none sm:text-sm"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
export default UpCommingGameNotificationModal;
