import { Challenge } from "@/api/model";
import PlusIcon from "@/assets/icons/ui/plus.svg?react";
import { ChallengeAvatar } from "@/components/ui/game/ChallengeAvatar";
import { classNames } from "@/lib/util/classNames";
import { RootState } from "@/store/store";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

type Props = {
  gameId: string;
  className: string;
  challenges: Challenge[] | undefined;
};

export const IntroChallengers: FC<Props> = ({ gameId, challenges, className, ...props }) => {
  const userFriends = useSelector((state: RootState) => state.auth?.user?.userFriends.length);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const config = useSelector((state: RootState) => state.config);

  return token ? (
    <div
      className={classNames(
        className,
        "flex w-full items-center justify-center",
        challenges && challenges.length !== 0 ? "gap-2" : "",
      )}
    >
      {challenges && (
        <Link to="/challenge/accepted" className="flex gap-2" {...props}>
          {challenges.slice(0, 2).map(challenge => (
            <ChallengeAvatar key={challenge.id} challenge={challenge} userId={userId ?? ""} />
          ))}
          {challenges.length >= 3 && (
            <div className="border-secondary flex h-10 w-10 items-center justify-center rounded-full border-2 bg-black">
              <span className="font-bold text-white">{challenges.length - 2}</span>
            </div>
          )}
        </Link>
      )}
      {config?.settings.challengesEnabled && (
        <Link
          to={
            (userFriends ?? 0) < 2
              ? `/challenge/select-opponent/all-players?game=${gameId}&from=/`
              : `/challenge/select-opponent/friends?game=${gameId}&from=/`
          }
          className="border-secondary bg-secondary flex h-10 w-10 items-center justify-center rounded-full border-2"
        >
          <PlusIcon />
        </Link>
      )}
    </div>
  ) : null;
};
