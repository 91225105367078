import { alert } from '@/store/models/alert';
import { background } from '@/store/models/background';
import { cart } from '@/store/models/cart';
import { challenge } from '@/store/models/challenge';
import { config } from '@/store/models/config';
import { menu } from '@/store/models/menu';
import { Models } from '@rematch/core';
import { admin } from './admin';
import { auth } from './auth';
import { chat } from './chat';
import { checkout } from './checkout';
import { core } from './core';
import { game } from './game';
import { search } from './search';

export interface RootModel extends Models<RootModel> {
  config: typeof config;
  core: typeof core;
  game: typeof game;
  auth: typeof auth;
  chat: typeof chat;
  admin: typeof admin;
  background: typeof background;
  challenge: typeof challenge;
  menu: typeof menu;
  cart: typeof cart;
  alert: typeof alert;
  search: typeof search;
  checkout: typeof checkout;
}

export const models: RootModel = {
  config,
  core,
  game,
  auth,
  chat,
  admin,
  background,
  challenge,
  menu,
  cart,
  alert,
  search,
  checkout,
};
