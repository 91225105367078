import React from 'react';

export default function SubTitle({
  children,
  className,
  ...props
}: { children: React.ReactNode } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) {
  return (
    <p className={`px-6 py-2 text-center font-poppins ${className ?? ''}`.trim()} {...props}>
      {children}
    </p>
  );
}
