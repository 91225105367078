import { createModel } from '@rematch/core';
import { RootModel } from './index';

const DEFAULT_SEARCH_CONFIG = {
  query: ''
};

export const search = createModel<RootModel>()({
  state: DEFAULT_SEARCH_CONFIG,
  reducers: {
    setQuery: (state, query: string) => ({
      query
    }),
    clearQuery: (state) => ({
      query: ''
    })
  },
  effects: () => ({}),
});
