import { createModel } from '@rematch/core';
import { RootModel } from './index';

type AlertType = 'info' | 'error';

export type Alert = {
  message: string;
  type: AlertType;
};

type AlertStore = {
  alert: Alert | undefined;
};

export const alert = createModel<RootModel>()({
  state: {
    alert: undefined,
  } as AlertStore,
  reducers: {
    set: (state, message: string, type: AlertType = 'info') => ({
      alert: {
        message,
        type,
      },
    }),
    clear: () => ({
      alert: undefined,
    }),
  },
});
