import { formatPrice } from '../../../lib/general/currency-format';
import { classNames } from '../../../lib/util/classNames';
import { SubscriptionCardRibbon } from './SubscriptionCardRibbon';
import { SubscriptionCardTrialOverlay } from './SubscriptionCardTrialOverlay';

type Props = {
  title: string;
  description: string;
  price: number;
  onClick: () => void;
  selected: boolean;
  monthPrice?: number;
  hasRibbon?: boolean;
  trial?: number | null;
  isCurrent?: boolean;
};

export function SubscriptionCard({
  title,
  description,
  price,
  monthPrice,
  trial,
  onClick,
  selected,
  hasRibbon = true,
  isCurrent = false,
}: Props) {
  const isDiscounted = monthPrice && price !== monthPrice;

  return (
    <div
      className={`relative mt-4 flex w-full flex-col items-center rounded border border-white/20 p-4 pt-8 text-center ${
        selected && 'bg-white text-black'
      }`}
      onClick={onClick}
    >
      {trial !== null && trial !== undefined && trial > 0 && !isCurrent && (
        <SubscriptionCardTrialOverlay trial={trial} />
      )}
      {isCurrent && (
        <div
          className={classNames(
            trial ? '-top-8 -mb-9' : '-top-6 -mb-8',
            'absolute z-10 mt-3 rounded-full bg-red-600 px-3 py-1 text-center text-sm font-bold uppercase text-white',
          )}
        >
          Current plan {trial && <div className="text-xs">({trial} trial day(s) left)</div>}
        </div>
      )}
      {hasRibbon && (
        <div className="absolute top-0 h-full w-full overflow-hidden">
          <SubscriptionCardRibbon />
        </div>
      )}
      <p className="ml-1 text-2xl font-bold uppercase">{title}</p>
      {isDiscounted && <p className="price-strikethrough w-min px-1 py-1 font-semibold ">{formatPrice(price)}</p>}
      <p className={`mt-1 text-2xl font-bold ${isDiscounted && 'text-green-600'}`}>
        {monthPrice ? `${formatPrice(monthPrice)} / MONTH` : formatPrice(price)}
      </p>
      <p className="my-2">{description}</p>
    </div>
  );
}
