import { Player } from '@/api/model';
import { memo, useEffect } from 'react';
import useViewer from '../../../lib/dolby/useViewer';
import { StackedVideoSource, VideoSource, VideoSourceOptions } from './VideoSource';

const WinnerBanner = memo(({ winnerName }: { winnerName: string }) => {
  return (
    <div className="absolute bottom-4 left-1/2 z-10 flex -translate-x-1/2 flex-col">
      <div className="relative z-10 mx-auto -mb-2 w-32">
        <h5 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap text-center font-poppins text-base font-bold text-white">
          Winner
        </h5>
        <svg width="100%" height="29" viewBox="0 0 109 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.92552 1.71519C4.06715 0.730779 4.9106 0 5.90514 0H106.692C107.91 0 108.845 1.07932 108.671 2.28481L105.074 27.2848C104.933 28.2692 104.089 29 103.095 29H2.30834C1.09044 29 0.155285 27.9207 0.328721 26.7152L3.92552 1.71519Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="relative mx-auto w-64">
        <h4 className="absolute left-1/2 top-1/2 mt-1 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap text-center font-poppins text-2xl font-bold text-black text-white">
          {winnerName}
        </h4>
        <svg width="100%" height="44" viewBox="0 0 215 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.31816 3.20092C8.69795 1.33812 10.3364 0 12.2375 0H210.102C212.632 0 214.527 2.31982 214.022 4.79909L206.682 40.7991C206.302 42.6619 204.664 44 202.762 44H4.89781C2.36754 44 0.472966 41.6802 0.978443 39.2009L8.31816 3.20092Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
});

const BaseDolbyVideoStream = ({
  streamAccountId,
  streamName,
  subscriberToken,
  chatEnabled,
  mode = 'viewer',
}: {
  winner?: Player;
  streamAccountId: string;
  streamName: string;
  subscriberToken: string;
  chatEnabled?: boolean;
  mode?: 'viewer' | 'dox' | 'host';
}) => {
  const showError = (error: string) => console.error(error);
  const { hostMediaStream, doxMediaStream, startViewer, stopViewer } = useViewer({
    handleError: showError,
    streamAccountId,
    streamName,
    subscriberToken,
    mode,
  });

  useEffect(() => {
    startViewer();

    return () => {
      stopViewer();
    };
  }, []);

  if (mode === 'host') {
    return (
      <VideoSource
        chatEnabled={chatEnabled}
        alwaysMuted={false}
        dynamicGameClassesDisabled={true}
        saveVideoRef={true}
        source={
          doxMediaStream
            ? {
                type: VideoSourceOptions.STREAM,
                stream: doxMediaStream,
              }
            : {
                type: VideoSourceOptions.EMPTY,
              }
        }
      />
    );
  }

  return (
    <StackedVideoSource
      saveVideoRef={false}
      chatEnabled={chatEnabled}
      sources={{
        top: doxMediaStream
          ? {
              type: VideoSourceOptions.STREAM,
              stream: doxMediaStream,
            }
          : undefined,
        main: hostMediaStream
          ? {
              type: VideoSourceOptions.STREAM,
              stream: hostMediaStream,
            }
          : {
              type: VideoSourceOptions.EMPTY,
            },
      }}
    />
  );
};

// memo as DolbyVideoStream is used in a list
export const DolbyVideoStream = memo(BaseDolbyVideoStream);
