/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Fanalyst API
 * Every week, tune into Fanalyst Trivia and see if you have what it takes.
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import type {
  AddAdditionalInfoDto,
  AddGroupMemberDto,
  AdminGetGames200,
  AdminGetGamesParams,
  AdminGetGroups200,
  AdminGetGroupsParams,
  AdminMove,
  AdminWalletAction,
  ApiFormattedPaymentMethod,
  ApiProduct,
  ApiProductOrder,
  Boolean,
  Card,
  CardSetup,
  Challenge,
  ChallengeWithWinsAndLosses,
  Checkout,
  CheckoutCreate,
  CheckoutItemDto,
  CheckoutPayResponse,
  CreateChallenge,
  CreateProductOrderRequestBody,
  CreateProductOrderResponseBody,
  CreateQuestion,
  CreateSecurityPolicyDto,
  CreateUpdateUser,
  Episode,
  File,
  FileUpload,
  FriendDTO,
  FriendUser,
  FullGame,
  FullGroupDto,
  FullUser,
  Game,
  GameAccess,
  GameChallengePaginationDto,
  GameInfo,
  GameStatus,
  GameToken,
  GenerateQuestionsRequest,
  GetChallengesParams,
  GetEpisodes200,
  GetEpisodesParams,
  GetFriends200,
  GetFriendsParams,
  GetGames200,
  GetGamesParams,
  GetGroupsLeaderboardParams,
  GetHosts200,
  GetHostsParams,
  GetLoadedGameResponse,
  GetNotifications200,
  GetNotificationsParams,
  GetOrders200,
  GetOrdersParams,
  GetPastGamesAndChallengesParams,
  GetPublicUsers200,
  GetPublicUsersParams,
  GetShopProductsParams,
  GetTransactionListParams,
  GetTransactionsByUserParams,
  GetUpcomingGamesParams,
  GetUserLeaderboardParams,
  GetUsers200,
  GetUsersParams,
  GroupDto,
  GroupInfo,
  GroupMemberInfo,
  HandleGroupInviteDto,
  Host,
  HttpExceptionResponse,
  Invitations,
  LeaderBoardGroupResult,
  LeaderBoardUserResult,
  Login,
  Number,
  PayCheckoutParams,
  PendingRequests200,
  PendingRequestsParams,
  PersonaWebhookDto,
  Question,
  QuestionCandidate,
  ReactChallenge,
  ReferralStatus,
  RefundBatch,
  Register,
  Reset,
  ResetPassword,
  Search,
  SecurityModuleDto,
  SecurityPolicyDto,
  ShopCheckoutDto,
  ShopOrderDto,
  ShopProductDto,
  ShopProductPagination,
  SignMessage,
  SignMessageParams,
  SignMessageResponse,
  Subscription,
  SubscriptionProduct,
  SubscriptionRequest,
  TransactionHistory,
  UpdateGroupMemberDto,
  UpdateUser,
  User,
  UserBalance,
  UserModuleCheck,
  UserModuleDto,
  UserSession,
  UserSessionSecurityModules,
  UserWalletBalance,
} from '../model';
import { customInstance } from '../mutator/custom-instance';
import type { ErrorType } from '../mutator/custom-instance';

export const getHello = (signal?: AbortSignal) => {
  return customInstance<void>({ url: `/api/hello`, method: 'GET', signal });
};

export const getGetHelloQueryKey = () => {
  return [`/api/hello`] as const;
};

export const getGetHelloQueryOptions = <
  TData = Awaited<ReturnType<typeof getHello>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getHello>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHelloQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHello>>> = ({ signal }) => getHello(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getHello>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetHelloQueryResult = NonNullable<Awaited<ReturnType<typeof getHello>>>;
export type GetHelloQueryError = ErrorType<unknown>;

export const useGetHello = <TData = Awaited<ReturnType<typeof getHello>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getHello>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHelloQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const me = (signal?: AbortSignal) => {
  return customInstance<FullUser>({ url: `/api/auth`, method: 'GET', signal });
};

export const getMeQueryKey = () => {
  return [`/api/auth`] as const;
};

export const getMeQueryOptions = <
  TData = Awaited<ReturnType<typeof me>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof me>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof me>>> = ({ signal }) => me(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof me>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type MeQueryResult = NonNullable<Awaited<ReturnType<typeof me>>>;
export type MeQueryError = ErrorType<HttpExceptionResponse>;

export const useMe = <TData = Awaited<ReturnType<typeof me>>, TError = ErrorType<HttpExceptionResponse>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof me>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const signMessage = (params: SignMessageParams, signal?: AbortSignal) => {
  return customInstance<SignMessage>({ url: `/api/auth/message`, method: 'GET', params, signal });
};

export const getSignMessageQueryKey = (params: SignMessageParams) => {
  return [`/api/auth/message`, ...(params ? [params] : [])] as const;
};

export const getSignMessageQueryOptions = <
  TData = Awaited<ReturnType<typeof signMessage>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: SignMessageParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof signMessage>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSignMessageQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof signMessage>>> = ({ signal }) => signMessage(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof signMessage>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SignMessageQueryResult = NonNullable<Awaited<ReturnType<typeof signMessage>>>;
export type SignMessageQueryError = ErrorType<HttpExceptionResponse>;

export const useSignMessage = <
  TData = Awaited<ReturnType<typeof signMessage>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: SignMessageParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof signMessage>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSignMessageQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const register = (register: Register) => {
  return customInstance<UserSessionSecurityModules>({
    url: `/api/auth/register`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: register,
  });
};

export const getRegisterMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof register>>, TError, { data: Register }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof register>>, TError, { data: Register }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof register>>, { data: Register }> = (props) => {
    const { data } = props ?? {};

    return register(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterMutationResult = NonNullable<Awaited<ReturnType<typeof register>>>;
export type RegisterMutationBody = Register;
export type RegisterMutationError = ErrorType<HttpExceptionResponse>;

export const useRegister = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof register>>, TError, { data: Register }, TContext>;
}) => {
  const mutationOptions = getRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

export const reset = (reset: Reset) => {
  return customInstance<boolean>({
    url: `/api/auth/reset`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: reset,
  });
};

export const getResetMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof reset>>, TError, { data: Reset }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof reset>>, TError, { data: Reset }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof reset>>, { data: Reset }> = (props) => {
    const { data } = props ?? {};

    return reset(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetMutationResult = NonNullable<Awaited<ReturnType<typeof reset>>>;
export type ResetMutationBody = Reset;
export type ResetMutationError = ErrorType<HttpExceptionResponse>;

export const useReset = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof reset>>, TError, { data: Reset }, TContext>;
}) => {
  const mutationOptions = getResetMutationOptions(options);

  return useMutation(mutationOptions);
};

export const resetPassword = (resetPassword: ResetPassword) => {
  return customInstance<UserSession>({
    url: `/api/auth/reset/complete`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: resetPassword,
  });
};

export const getResetPasswordMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError, { data: ResetPassword }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError, { data: ResetPassword }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetPassword>>, { data: ResetPassword }> = (props) => {
    const { data } = props ?? {};

    return resetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>;
export type ResetPasswordMutationBody = ResetPassword;
export type ResetPasswordMutationError = ErrorType<HttpExceptionResponse>;

export const useResetPassword = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError, { data: ResetPassword }, TContext>;
}) => {
  const mutationOptions = getResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const loginCrypto = (signMessageResponse: SignMessageResponse) => {
  return customInstance<UserSession>({
    url: `/api/auth/login/crypto`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: signMessageResponse,
  });
};

export const getLoginCryptoMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCrypto>>,
    TError,
    { data: SignMessageResponse },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof loginCrypto>>, TError, { data: SignMessageResponse }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginCrypto>>, { data: SignMessageResponse }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return loginCrypto(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginCryptoMutationResult = NonNullable<Awaited<ReturnType<typeof loginCrypto>>>;
export type LoginCryptoMutationBody = SignMessageResponse;
export type LoginCryptoMutationError = ErrorType<HttpExceptionResponse>;

export const useLoginCrypto = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCrypto>>,
    TError,
    { data: SignMessageResponse },
    TContext
  >;
}) => {
  const mutationOptions = getLoginCryptoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const loginPassword = (login: Login) => {
  return customInstance<UserSession>({
    url: `/api/auth/login/password`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: login,
  });
};

export const getLoginPasswordMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof loginPassword>>, TError, { data: Login }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof loginPassword>>, TError, { data: Login }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginPassword>>, { data: Login }> = (props) => {
    const { data } = props ?? {};

    return loginPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof loginPassword>>>;
export type LoginPasswordMutationBody = Login;
export type LoginPasswordMutationError = ErrorType<HttpExceptionResponse>;

export const useLoginPassword = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof loginPassword>>, TError, { data: Login }, TContext>;
}) => {
  const mutationOptions = getLoginPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getGames = (params: GetGamesParams, signal?: AbortSignal) => {
  return customInstance<GetGames200>({ url: `/api/game`, method: 'GET', params, signal });
};

export const getGetGamesQueryKey = (params: GetGamesParams) => {
  return [`/api/game`, ...(params ? [params] : [])] as const;
};

export const getGetGamesQueryOptions = <
  TData = Awaited<ReturnType<typeof getGames>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetGamesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGames>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGamesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGames>>> = ({ signal }) => getGames(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGames>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGamesQueryResult = NonNullable<Awaited<ReturnType<typeof getGames>>>;
export type GetGamesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetGames = <TData = Awaited<ReturnType<typeof getGames>>, TError = ErrorType<HttpExceptionResponse>>(
  params: GetGamesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGames>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGamesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGame = (gameId: string, signal?: AbortSignal) => {
  return customInstance<Game>({ url: `/api/game/${gameId}`, method: 'GET', signal });
};

export const getGetGameQueryKey = (gameId: string) => {
  return [`/api/game/${gameId}`] as const;
};

export const getGetGameQueryOptions = <
  TData = Awaited<ReturnType<typeof getGame>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  gameId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGame>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameQueryKey(gameId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGame>>> = ({ signal }) => getGame(gameId, signal);

  return { queryKey, queryFn, enabled: !!gameId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGame>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGameQueryResult = NonNullable<Awaited<ReturnType<typeof getGame>>>;
export type GetGameQueryError = ErrorType<HttpExceptionResponse>;

export const useGetGame = <TData = Awaited<ReturnType<typeof getGame>>, TError = ErrorType<HttpExceptionResponse>>(
  gameId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGame>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGameQueryOptions(gameId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGameToken = (gameId: string, signal?: AbortSignal) => {
  return customInstance<GameToken>({ url: `/api/game/${gameId}/token`, method: 'GET', signal });
};

export const getGetGameTokenQueryKey = (gameId: string) => {
  return [`/api/game/${gameId}/token`] as const;
};

export const getGetGameTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getGameToken>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  gameId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGameToken>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameTokenQueryKey(gameId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGameToken>>> = ({ signal }) => getGameToken(gameId, signal);

  return { queryKey, queryFn, enabled: !!gameId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGameToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGameTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getGameToken>>>;
export type GetGameTokenQueryError = ErrorType<HttpExceptionResponse>;

export const useGetGameToken = <
  TData = Awaited<ReturnType<typeof getGameToken>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  gameId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGameToken>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGameTokenQueryOptions(gameId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAuthenticatedGame = (gameId: string, signal?: AbortSignal) => {
  return customInstance<GameAccess>({ url: `/api/game/${gameId}/authenticated`, method: 'GET', signal });
};

export const getGetAuthenticatedGameQueryKey = (gameId: string) => {
  return [`/api/game/${gameId}/authenticated`] as const;
};

export const getGetAuthenticatedGameQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthenticatedGame>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  gameId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAuthenticatedGame>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAuthenticatedGameQueryKey(gameId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthenticatedGame>>> = ({ signal }) =>
    getAuthenticatedGame(gameId, signal);

  return { queryKey, queryFn, enabled: !!gameId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuthenticatedGame>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAuthenticatedGameQueryResult = NonNullable<Awaited<ReturnType<typeof getAuthenticatedGame>>>;
export type GetAuthenticatedGameQueryError = ErrorType<HttpExceptionResponse>;

export const useGetAuthenticatedGame = <
  TData = Awaited<ReturnType<typeof getAuthenticatedGame>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  gameId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAuthenticatedGame>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuthenticatedGameQueryOptions(gameId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUpcomingGames = (params: GetUpcomingGamesParams, signal?: AbortSignal) => {
  return customInstance<Game[]>({ url: `/api/game/upcoming`, method: 'GET', params, signal });
};

export const getGetUpcomingGamesQueryKey = (params: GetUpcomingGamesParams) => {
  return [`/api/game/upcoming`, ...(params ? [params] : [])] as const;
};

export const getGetUpcomingGamesQueryOptions = <
  TData = Awaited<ReturnType<typeof getUpcomingGames>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetUpcomingGamesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUpcomingGames>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUpcomingGamesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUpcomingGames>>> = ({ signal }) =>
    getUpcomingGames(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUpcomingGames>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUpcomingGamesQueryResult = NonNullable<Awaited<ReturnType<typeof getUpcomingGames>>>;
export type GetUpcomingGamesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetUpcomingGames = <
  TData = Awaited<ReturnType<typeof getUpcomingGames>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetUpcomingGamesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUpcomingGames>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUpcomingGamesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getQuestionHistory = () => {
  return customInstance<void>({ url: `/api/game/question-history`, method: 'POST' });
};

export const getGetQuestionHistoryMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof getQuestionHistory>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof getQuestionHistory>>, TError, void, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof getQuestionHistory>>, void> = () => {
    return getQuestionHistory();
  };

  return { mutationFn, ...mutationOptions };
};

export type GetQuestionHistoryMutationResult = NonNullable<Awaited<ReturnType<typeof getQuestionHistory>>>;

export type GetQuestionHistoryMutationError = ErrorType<unknown>;

export const useGetQuestionHistory = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof getQuestionHistory>>, TError, void, TContext>;
}) => {
  const mutationOptions = getGetQuestionHistoryMutationOptions(options);

  return useMutation(mutationOptions);
};

export const inviteGroupMember = (groupId: string, addGroupMemberDto: AddGroupMemberDto) => {
  return customInstance<void>({
    url: `/api/group/${groupId}/member`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: addGroupMemberDto,
  });
};

export const getInviteGroupMemberMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteGroupMember>>,
    TError,
    { groupId: string; data: AddGroupMemberDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inviteGroupMember>>,
  TError,
  { groupId: string; data: AddGroupMemberDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inviteGroupMember>>,
    { groupId: string; data: AddGroupMemberDto }
  > = (props) => {
    const { groupId, data } = props ?? {};

    return inviteGroupMember(groupId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InviteGroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof inviteGroupMember>>>;
export type InviteGroupMemberMutationBody = AddGroupMemberDto;
export type InviteGroupMemberMutationError = ErrorType<unknown>;

export const useInviteGroupMember = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteGroupMember>>,
    TError,
    { groupId: string; data: AddGroupMemberDto },
    TContext
  >;
}) => {
  const mutationOptions = getInviteGroupMemberMutationOptions(options);

  return useMutation(mutationOptions);
};

export const handleGroupInvite = (groupId: string, handleGroupInviteDto: HandleGroupInviteDto) => {
  return customInstance<void>({
    url: `/api/group/${groupId}/member`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: handleGroupInviteDto,
  });
};

export const getHandleGroupInviteMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof handleGroupInvite>>,
    TError,
    { groupId: string; data: HandleGroupInviteDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof handleGroupInvite>>,
  TError,
  { groupId: string; data: HandleGroupInviteDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof handleGroupInvite>>,
    { groupId: string; data: HandleGroupInviteDto }
  > = (props) => {
    const { groupId, data } = props ?? {};

    return handleGroupInvite(groupId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type HandleGroupInviteMutationResult = NonNullable<Awaited<ReturnType<typeof handleGroupInvite>>>;
export type HandleGroupInviteMutationBody = HandleGroupInviteDto;
export type HandleGroupInviteMutationError = ErrorType<unknown>;

export const useHandleGroupInvite = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof handleGroupInvite>>,
    TError,
    { groupId: string; data: HandleGroupInviteDto },
    TContext
  >;
}) => {
  const mutationOptions = getHandleGroupInviteMutationOptions(options);

  return useMutation(mutationOptions);
};

export const joinGroup = (groupId: string) => {
  return customInstance<void>({ url: `/api/group/${groupId}/member/join`, method: 'POST' });
};

export const getJoinGroupMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof joinGroup>>, TError, { groupId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof joinGroup>>, TError, { groupId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof joinGroup>>, { groupId: string }> = (props) => {
    const { groupId } = props ?? {};

    return joinGroup(groupId);
  };

  return { mutationFn, ...mutationOptions };
};

export type JoinGroupMutationResult = NonNullable<Awaited<ReturnType<typeof joinGroup>>>;

export type JoinGroupMutationError = ErrorType<unknown>;

export const useJoinGroup = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof joinGroup>>, TError, { groupId: string }, TContext>;
}) => {
  const mutationOptions = getJoinGroupMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateGroupMember = (groupId: string, userId: string, updateGroupMemberDto: UpdateGroupMemberDto) => {
  return customInstance<void>({
    url: `/api/group/${groupId}/member/${userId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateGroupMemberDto,
  });
};

export const getUpdateGroupMemberMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGroupMember>>,
    TError,
    { groupId: string; userId: string; data: UpdateGroupMemberDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGroupMember>>,
  TError,
  { groupId: string; userId: string; data: UpdateGroupMemberDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateGroupMember>>,
    { groupId: string; userId: string; data: UpdateGroupMemberDto }
  > = (props) => {
    const { groupId, userId, data } = props ?? {};

    return updateGroupMember(groupId, userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof updateGroupMember>>>;
export type UpdateGroupMemberMutationBody = UpdateGroupMemberDto;
export type UpdateGroupMemberMutationError = ErrorType<unknown>;

export const useUpdateGroupMember = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGroupMember>>,
    TError,
    { groupId: string; userId: string; data: UpdateGroupMemberDto },
    TContext
  >;
}) => {
  const mutationOptions = getUpdateGroupMemberMutationOptions(options);

  return useMutation(mutationOptions);
};

export const removeGroupMember = (groupId: string, userId: string) => {
  return customInstance<void>({ url: `/api/group/${groupId}/member/${userId}`, method: 'DELETE' });
};

export const getRemoveGroupMemberMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeGroupMember>>,
    TError,
    { groupId: string; userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeGroupMember>>,
  TError,
  { groupId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeGroupMember>>,
    { groupId: string; userId: string }
  > = (props) => {
    const { groupId, userId } = props ?? {};

    return removeGroupMember(groupId, userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveGroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof removeGroupMember>>>;

export type RemoveGroupMemberMutationError = ErrorType<unknown>;

export const useRemoveGroupMember = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeGroupMember>>,
    TError,
    { groupId: string; userId: string },
    TContext
  >;
}) => {
  const mutationOptions = getRemoveGroupMemberMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getNotifications = (params: GetNotificationsParams, signal?: AbortSignal) => {
  return customInstance<GetNotifications200>({ url: `/api/notifications`, method: 'GET', params, signal });
};

export const getGetNotificationsQueryKey = (params: GetNotificationsParams) => {
  return [`/api/notifications`, ...(params ? [params] : [])] as const;
};

export const getGetNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotifications>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetNotificationsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getNotifications>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotifications>>> = ({ signal }) =>
    getNotifications(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getNotifications>>>;
export type GetNotificationsQueryError = ErrorType<HttpExceptionResponse>;

export const useGetNotifications = <
  TData = Awaited<ReturnType<typeof getNotifications>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetNotificationsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getNotifications>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNotificationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationsCount = (signal?: AbortSignal) => {
  return customInstance<number>({ url: `/api/notifications/count-unread`, method: 'GET', signal });
};

export const getGetNotificationsCountQueryKey = () => {
  return [`/api/notifications/count-unread`] as const;
};

export const getGetNotificationsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotificationsCount>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getNotificationsCount>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificationsCountQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationsCount>>> = ({ signal }) =>
    getNotificationsCount(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotificationsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNotificationsCountQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationsCount>>>;
export type GetNotificationsCountQueryError = ErrorType<HttpExceptionResponse>;

export const useGetNotificationsCount = <
  TData = Awaited<ReturnType<typeof getNotificationsCount>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getNotificationsCount>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNotificationsCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const makeNotificationsViewed = () => {
  return customInstance<Boolean>({ url: `/api/notifications/viewed`, method: 'PUT' });
};

export const getMakeNotificationsViewedMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof makeNotificationsViewed>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof makeNotificationsViewed>>, TError, void, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof makeNotificationsViewed>>, void> = () => {
    return makeNotificationsViewed();
  };

  return { mutationFn, ...mutationOptions };
};

export type MakeNotificationsViewedMutationResult = NonNullable<Awaited<ReturnType<typeof makeNotificationsViewed>>>;

export type MakeNotificationsViewedMutationError = ErrorType<HttpExceptionResponse>;

export const useMakeNotificationsViewed = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof makeNotificationsViewed>>, TError, void, TContext>;
}) => {
  const mutationOptions = getMakeNotificationsViewedMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteNotification = (notificationId: string) => {
  return customInstance<Boolean>({ url: `/api/notifications/${notificationId}`, method: 'DELETE' });
};

export const getDeleteNotificationMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotification>>,
    TError,
    { notificationId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteNotification>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNotification>>, { notificationId: string }> = (
    props,
  ) => {
    const { notificationId } = props ?? {};

    return deleteNotification(notificationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNotification>>>;

export type DeleteNotificationMutationError = ErrorType<HttpExceptionResponse>;

export const useDeleteNotification = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotification>>,
    TError,
    { notificationId: string },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getGroup = (id: string, signal?: AbortSignal) => {
  return customInstance<FullGroupDto>({ url: `/api/groups/${id}`, method: 'GET', signal });
};

export const getGetGroupQueryKey = (id: string) => {
  return [`/api/groups/${id}`] as const;
};

export const getGetGroupQueryOptions = <TData = Awaited<ReturnType<typeof getGroup>>, TError = ErrorType<unknown>>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGroup>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGroupQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroup>>> = ({ signal }) => getGroup(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGroup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getGroup>>>;
export type GetGroupQueryError = ErrorType<unknown>;

export const useGetGroup = <TData = Awaited<ReturnType<typeof getGroup>>, TError = ErrorType<unknown>>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGroup>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGroupQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const editGroup = (id: string, groupInfo: GroupInfo) => {
  return customInstance<GroupDto>({
    url: `/api/groups/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: groupInfo,
  });
};

export const getEditGroupMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editGroup>>,
    TError,
    { id: string; data: GroupInfo },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof editGroup>>, TError, { id: string; data: GroupInfo }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof editGroup>>, { id: string; data: GroupInfo }> = (
    props,
  ) => {
    const { id, data } = props ?? {};

    return editGroup(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditGroupMutationResult = NonNullable<Awaited<ReturnType<typeof editGroup>>>;
export type EditGroupMutationBody = GroupInfo;
export type EditGroupMutationError = ErrorType<unknown>;

export const useEditGroup = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editGroup>>,
    TError,
    { id: string; data: GroupInfo },
    TContext
  >;
}) => {
  const mutationOptions = getEditGroupMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteGroup = (id: string) => {
  return customInstance<void>({ url: `/api/groups/${id}`, method: 'DELETE' });
};

export const getDeleteGroupMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGroup>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return deleteGroup(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGroup>>>;

export type DeleteGroupMutationError = ErrorType<unknown>;

export const useDeleteGroup = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError, { id: string }, TContext>;
}) => {
  const mutationOptions = getDeleteGroupMutationOptions(options);

  return useMutation(mutationOptions);
};

export const createGroup = (groupInfo: GroupInfo) => {
  return customInstance<GroupDto>({
    url: `/api/groups`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: groupInfo,
  });
};

export const getCreateGroupMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError, { data: GroupInfo }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError, { data: GroupInfo }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGroup>>, { data: GroupInfo }> = (props) => {
    const { data } = props ?? {};

    return createGroup(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGroupMutationResult = NonNullable<Awaited<ReturnType<typeof createGroup>>>;
export type CreateGroupMutationBody = GroupInfo;
export type CreateGroupMutationError = ErrorType<unknown>;

export const useCreateGroup = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError, { data: GroupInfo }, TContext>;
}) => {
  const mutationOptions = getCreateGroupMutationOptions(options);

  return useMutation(mutationOptions);
};

export const groupUpload = (fileUpload: FileUpload) => {
  const formData = new FormData();
  formData.append('file', fileUpload.file);

  return customInstance<File>({
    url: `/api/groups/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getGroupUploadMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof groupUpload>>, TError, { data: FileUpload }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof groupUpload>>, TError, { data: FileUpload }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupUpload>>, { data: FileUpload }> = (props) => {
    const { data } = props ?? {};

    return groupUpload(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GroupUploadMutationResult = NonNullable<Awaited<ReturnType<typeof groupUpload>>>;
export type GroupUploadMutationBody = FileUpload;
export type GroupUploadMutationError = ErrorType<HttpExceptionResponse>;

export const useGroupUpload = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof groupUpload>>, TError, { data: FileUpload }, TContext>;
}) => {
  const mutationOptions = getGroupUploadMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getPublicUsers = (params: GetPublicUsersParams, signal?: AbortSignal) => {
  return customInstance<GetPublicUsers200>({ url: `/api/user`, method: 'GET', params, signal });
};

export const getGetPublicUsersQueryKey = (params: GetPublicUsersParams) => {
  return [`/api/user`, ...(params ? [params] : [])] as const;
};

export const getGetPublicUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getPublicUsers>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetPublicUsersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicUsers>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPublicUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicUsers>>> = ({ signal }) =>
    getPublicUsers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPublicUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPublicUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicUsers>>>;
export type GetPublicUsersQueryError = ErrorType<HttpExceptionResponse>;

export const useGetPublicUsers = <
  TData = Awaited<ReturnType<typeof getPublicUsers>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetPublicUsersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicUsers>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPublicUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserBalance = (signal?: AbortSignal) => {
  return customInstance<UserBalance>({ url: `/api/user/balance`, method: 'GET', signal });
};

export const getGetUserBalanceQueryKey = () => {
  return [`/api/user/balance`] as const;
};

export const getGetUserBalanceQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserBalance>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserBalance>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserBalanceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserBalance>>> = ({ signal }) => getUserBalance(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserBalance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserBalanceQueryResult = NonNullable<Awaited<ReturnType<typeof getUserBalance>>>;
export type GetUserBalanceQueryError = ErrorType<HttpExceptionResponse>;

export const useGetUserBalance = <
  TData = Awaited<ReturnType<typeof getUserBalance>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserBalance>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserBalanceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLifelines = (signal?: AbortSignal) => {
  return customInstance<Number>({ url: `/api/user/lifelines`, method: 'GET', signal });
};

export const getGetLifelinesQueryKey = () => {
  return [`/api/user/lifelines`] as const;
};

export const getGetLifelinesQueryOptions = <
  TData = Awaited<ReturnType<typeof getLifelines>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLifelines>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLifelinesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLifelines>>> = ({ signal }) => getLifelines(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLifelines>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLifelinesQueryResult = NonNullable<Awaited<ReturnType<typeof getLifelines>>>;
export type GetLifelinesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetLifelines = <
  TData = Awaited<ReturnType<typeof getLifelines>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLifelines>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLifelinesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const addAdditionalInfo = (id: string, addAdditionalInfoDto: AddAdditionalInfoDto) => {
  return customInstance<User>({
    url: `/api/user/${id}/additional`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: addAdditionalInfoDto,
  });
};

export const getAddAdditionalInfoMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addAdditionalInfo>>,
    TError,
    { id: string; data: AddAdditionalInfoDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addAdditionalInfo>>,
  TError,
  { id: string; data: AddAdditionalInfoDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addAdditionalInfo>>,
    { id: string; data: AddAdditionalInfoDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return addAdditionalInfo(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddAdditionalInfoMutationResult = NonNullable<Awaited<ReturnType<typeof addAdditionalInfo>>>;
export type AddAdditionalInfoMutationBody = AddAdditionalInfoDto;
export type AddAdditionalInfoMutationError = ErrorType<HttpExceptionResponse>;

export const useAddAdditionalInfo = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addAdditionalInfo>>,
    TError,
    { id: string; data: AddAdditionalInfoDto },
    TContext
  >;
}) => {
  const mutationOptions = getAddAdditionalInfoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getChallenges = (params?: GetChallengesParams, signal?: AbortSignal) => {
  return customInstance<ChallengeWithWinsAndLosses[]>({ url: `/api/challenge`, method: 'GET', params, signal });
};

export const getGetChallengesQueryKey = (params?: GetChallengesParams) => {
  return [`/api/challenge`, ...(params ? [params] : [])] as const;
};

export const getGetChallengesQueryOptions = <
  TData = Awaited<ReturnType<typeof getChallenges>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params?: GetChallengesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getChallenges>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChallengesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChallenges>>> = ({ signal }) =>
    getChallenges(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getChallenges>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetChallengesQueryResult = NonNullable<Awaited<ReturnType<typeof getChallenges>>>;
export type GetChallengesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetChallenges = <
  TData = Awaited<ReturnType<typeof getChallenges>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params?: GetChallengesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getChallenges>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetChallengesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postChallenge = (createChallenge: CreateChallenge) => {
  return customInstance<void>({
    url: `/api/challenge`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createChallenge,
  });
};

export const getPostChallengeMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postChallenge>>, TError, { data: CreateChallenge }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof postChallenge>>, TError, { data: CreateChallenge }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postChallenge>>, { data: CreateChallenge }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return postChallenge(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostChallengeMutationResult = NonNullable<Awaited<ReturnType<typeof postChallenge>>>;
export type PostChallengeMutationBody = CreateChallenge;
export type PostChallengeMutationError = ErrorType<HttpExceptionResponse>;

export const usePostChallenge = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postChallenge>>, TError, { data: CreateChallenge }, TContext>;
}) => {
  const mutationOptions = getPostChallengeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getChallengesBetweenUsers = (userId: string, signal?: AbortSignal) => {
  return customInstance<Challenge[]>({ url: `/api/challenge/${userId}`, method: 'GET', signal });
};

export const getGetChallengesBetweenUsersQueryKey = (userId: string) => {
  return [`/api/challenge/${userId}`] as const;
};

export const getGetChallengesBetweenUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getChallengesBetweenUsers>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getChallengesBetweenUsers>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChallengesBetweenUsersQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChallengesBetweenUsers>>> = ({ signal }) =>
    getChallengesBetweenUsers(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getChallengesBetweenUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetChallengesBetweenUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getChallengesBetweenUsers>>>;
export type GetChallengesBetweenUsersQueryError = ErrorType<HttpExceptionResponse>;

export const useGetChallengesBetweenUsers = <
  TData = Awaited<ReturnType<typeof getChallengesBetweenUsers>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getChallengesBetweenUsers>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetChallengesBetweenUsersQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTopChallenge = (signal?: AbortSignal) => {
  return customInstance<Challenge>({ url: `/api/challenge/top`, method: 'GET', signal });
};

export const getGetTopChallengeQueryKey = () => {
  return [`/api/challenge/top`] as const;
};

export const getGetTopChallengeQueryOptions = <
  TData = Awaited<ReturnType<typeof getTopChallenge>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTopChallenge>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopChallengeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTopChallenge>>> = ({ signal }) => getTopChallenge(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTopChallenge>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTopChallengeQueryResult = NonNullable<Awaited<ReturnType<typeof getTopChallenge>>>;
export type GetTopChallengeQueryError = ErrorType<HttpExceptionResponse>;

export const useGetTopChallenge = <
  TData = Awaited<ReturnType<typeof getTopChallenge>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTopChallenge>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTopChallengeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const reactChallenge = (challengeId: string, reactChallenge: ReactChallenge) => {
  return customInstance<void>({
    url: `/api/challenge/${challengeId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: reactChallenge,
  });
};

export const getReactChallengeMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reactChallenge>>,
    TError,
    { challengeId: string; data: ReactChallenge },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reactChallenge>>,
  TError,
  { challengeId: string; data: ReactChallenge },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reactChallenge>>,
    { challengeId: string; data: ReactChallenge }
  > = (props) => {
    const { challengeId, data } = props ?? {};

    return reactChallenge(challengeId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReactChallengeMutationResult = NonNullable<Awaited<ReturnType<typeof reactChallenge>>>;
export type ReactChallengeMutationBody = ReactChallenge;
export type ReactChallengeMutationError = ErrorType<HttpExceptionResponse>;

export const useReactChallenge = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reactChallenge>>,
    TError,
    { challengeId: string; data: ReactChallenge },
    TContext
  >;
}) => {
  const mutationOptions = getReactChallengeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteChallenge = (challengeId: string) => {
  return customInstance<void>({ url: `/api/challenge/${challengeId}`, method: 'DELETE' });
};

export const getDeleteChallengeMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteChallenge>>, TError, { challengeId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteChallenge>>, TError, { challengeId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteChallenge>>, { challengeId: string }> = (
    props,
  ) => {
    const { challengeId } = props ?? {};

    return deleteChallenge(challengeId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteChallengeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteChallenge>>>;

export type DeleteChallengeMutationError = ErrorType<HttpExceptionResponse>;

export const useDeleteChallenge = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteChallenge>>, TError, { challengeId: string }, TContext>;
}) => {
  const mutationOptions = getDeleteChallengeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const search = (search: Search) => {
  return customInstance<FriendUser[]>({
    url: `/api/friends/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: search,
  });
};

export const getSearchMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof search>>, TError, { data: Search }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof search>>, TError, { data: Search }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof search>>, { data: Search }> = (props) => {
    const { data } = props ?? {};

    return search(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchMutationResult = NonNullable<Awaited<ReturnType<typeof search>>>;
export type SearchMutationBody = Search;
export type SearchMutationError = ErrorType<HttpExceptionResponse>;

export const useSearch = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof search>>, TError, { data: Search }, TContext>;
}) => {
  const mutationOptions = getSearchMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getFriends = (params: GetFriendsParams, signal?: AbortSignal) => {
  return customInstance<GetFriends200>({ url: `/api/friends`, method: 'GET', params, signal });
};

export const getGetFriendsQueryKey = (params: GetFriendsParams) => {
  return [`/api/friends`, ...(params ? [params] : [])] as const;
};

export const getGetFriendsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFriends>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetFriendsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getFriends>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFriendsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFriends>>> = ({ signal }) => getFriends(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFriends>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFriendsQueryResult = NonNullable<Awaited<ReturnType<typeof getFriends>>>;
export type GetFriendsQueryError = ErrorType<HttpExceptionResponse>;

export const useGetFriends = <
  TData = Awaited<ReturnType<typeof getFriends>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetFriendsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getFriends>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFriendsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pendingRequests = (params: PendingRequestsParams, signal?: AbortSignal) => {
  return customInstance<PendingRequests200>({ url: `/api/friends/pending`, method: 'GET', params, signal });
};

export const getPendingRequestsQueryKey = (params: PendingRequestsParams) => {
  return [`/api/friends/pending`, ...(params ? [params] : [])] as const;
};

export const getPendingRequestsQueryOptions = <
  TData = Awaited<ReturnType<typeof pendingRequests>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: PendingRequestsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof pendingRequests>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPendingRequestsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pendingRequests>>> = ({ signal }) =>
    pendingRequests(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pendingRequests>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PendingRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof pendingRequests>>>;
export type PendingRequestsQueryError = ErrorType<HttpExceptionResponse>;

export const usePendingRequests = <
  TData = Awaited<ReturnType<typeof pendingRequests>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: PendingRequestsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof pendingRequests>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPendingRequestsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const requestFriend = (friendDTO: FriendDTO) => {
  return customInstance<void>({
    url: `/api/friends/request`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: friendDTO,
  });
};

export const getRequestFriendMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof requestFriend>>, TError, { data: FriendDTO }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof requestFriend>>, TError, { data: FriendDTO }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof requestFriend>>, { data: FriendDTO }> = (props) => {
    const { data } = props ?? {};

    return requestFriend(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestFriendMutationResult = NonNullable<Awaited<ReturnType<typeof requestFriend>>>;
export type RequestFriendMutationBody = FriendDTO;
export type RequestFriendMutationError = ErrorType<HttpExceptionResponse>;

export const useRequestFriend = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof requestFriend>>, TError, { data: FriendDTO }, TContext>;
}) => {
  const mutationOptions = getRequestFriendMutationOptions(options);

  return useMutation(mutationOptions);
};

export const acceptRequest = (friendDTO: FriendDTO) => {
  return customInstance<void>({
    url: `/api/friends/pending/accept`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: friendDTO,
  });
};

export const getAcceptRequestMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof acceptRequest>>, TError, { data: FriendDTO }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof acceptRequest>>, TError, { data: FriendDTO }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptRequest>>, { data: FriendDTO }> = (props) => {
    const { data } = props ?? {};

    return acceptRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptRequestMutationResult = NonNullable<Awaited<ReturnType<typeof acceptRequest>>>;
export type AcceptRequestMutationBody = FriendDTO;
export type AcceptRequestMutationError = ErrorType<HttpExceptionResponse>;

export const useAcceptRequest = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof acceptRequest>>, TError, { data: FriendDTO }, TContext>;
}) => {
  const mutationOptions = getAcceptRequestMutationOptions(options);

  return useMutation(mutationOptions);
};

export const denyRequest = (friendDTO: FriendDTO) => {
  return customInstance<void>({
    url: `/api/friends/pending/deny`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: friendDTO,
  });
};

export const getDenyRequestMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof denyRequest>>, TError, { data: FriendDTO }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof denyRequest>>, TError, { data: FriendDTO }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof denyRequest>>, { data: FriendDTO }> = (props) => {
    const { data } = props ?? {};

    return denyRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DenyRequestMutationResult = NonNullable<Awaited<ReturnType<typeof denyRequest>>>;
export type DenyRequestMutationBody = FriendDTO;
export type DenyRequestMutationError = ErrorType<HttpExceptionResponse>;

export const useDenyRequest = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof denyRequest>>, TError, { data: FriendDTO }, TContext>;
}) => {
  const mutationOptions = getDenyRequestMutationOptions(options);

  return useMutation(mutationOptions);
};

export const revokeRequest = (friendDTO: FriendDTO) => {
  return customInstance<void>({
    url: `/api/friends/pending/revoke`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: friendDTO,
  });
};

export const getRevokeRequestMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof revokeRequest>>, TError, { data: FriendDTO }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof revokeRequest>>, TError, { data: FriendDTO }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeRequest>>, { data: FriendDTO }> = (props) => {
    const { data } = props ?? {};

    return revokeRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeRequestMutationResult = NonNullable<Awaited<ReturnType<typeof revokeRequest>>>;
export type RevokeRequestMutationBody = FriendDTO;
export type RevokeRequestMutationError = ErrorType<HttpExceptionResponse>;

export const useRevokeRequest = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof revokeRequest>>, TError, { data: FriendDTO }, TContext>;
}) => {
  const mutationOptions = getRevokeRequestMutationOptions(options);

  return useMutation(mutationOptions);
};

export const removeFriend = (friendDTO: FriendDTO) => {
  return customInstance<void>({
    url: `/api/friends/remove`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: friendDTO,
  });
};

export const getRemoveFriendMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeFriend>>, TError, { data: FriendDTO }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof removeFriend>>, TError, { data: FriendDTO }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeFriend>>, { data: FriendDTO }> = (props) => {
    const { data } = props ?? {};

    return removeFriend(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveFriendMutationResult = NonNullable<Awaited<ReturnType<typeof removeFriend>>>;
export type RemoveFriendMutationBody = FriendDTO;
export type RemoveFriendMutationError = ErrorType<HttpExceptionResponse>;

export const useRemoveFriend = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeFriend>>, TError, { data: FriendDTO }, TContext>;
}) => {
  const mutationOptions = getRemoveFriendMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getProductOrderById = (id: string, signal?: AbortSignal) => {
  return customInstance<ApiProductOrder>({ url: `/api/product/orders/${id}`, method: 'GET', signal });
};

export const getGetProductOrderByIdQueryKey = (id: string) => {
  return [`/api/product/orders/${id}`] as const;
};

export const getGetProductOrderByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductOrderById>>,
  TError = ErrorType<void>,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getProductOrderById>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductOrderByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductOrderById>>> = ({ signal }) =>
    getProductOrderById(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductOrderById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductOrderByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProductOrderById>>>;
export type GetProductOrderByIdQueryError = ErrorType<void>;

export const useGetProductOrderById = <
  TData = Awaited<ReturnType<typeof getProductOrderById>>,
  TError = ErrorType<void>,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getProductOrderById>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductOrderByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createProductOrder = (createProductOrderRequestBody: CreateProductOrderRequestBody) => {
  return customInstance<CreateProductOrderResponseBody>({
    url: `/api/product/orders`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createProductOrderRequestBody,
  });
};

export const getCreateProductOrderMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProductOrder>>,
    TError,
    { data: CreateProductOrderRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createProductOrder>>,
  TError,
  { data: CreateProductOrderRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createProductOrder>>,
    { data: CreateProductOrderRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return createProductOrder(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateProductOrderMutationResult = NonNullable<Awaited<ReturnType<typeof createProductOrder>>>;
export type CreateProductOrderMutationBody = CreateProductOrderRequestBody;
export type CreateProductOrderMutationError = ErrorType<void>;

export const useCreateProductOrder = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProductOrder>>,
    TError,
    { data: CreateProductOrderRequestBody },
    TContext
  >;
}) => {
  const mutationOptions = getCreateProductOrderMutationOptions(options);

  return useMutation(mutationOptions);
};

export const isProductOrderPaid = (id: string, signal?: AbortSignal) => {
  return customInstance<boolean>({ url: `/api/product/orders/${id}/paid`, method: 'GET', signal });
};

export const getIsProductOrderPaidQueryKey = (id: string) => {
  return [`/api/product/orders/${id}/paid`] as const;
};

export const getIsProductOrderPaidQueryOptions = <
  TData = Awaited<ReturnType<typeof isProductOrderPaid>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof isProductOrderPaid>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIsProductOrderPaidQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof isProductOrderPaid>>> = ({ signal }) =>
    isProductOrderPaid(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof isProductOrderPaid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IsProductOrderPaidQueryResult = NonNullable<Awaited<ReturnType<typeof isProductOrderPaid>>>;
export type IsProductOrderPaidQueryError = ErrorType<unknown>;

export const useIsProductOrderPaid = <
  TData = Awaited<ReturnType<typeof isProductOrderPaid>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof isProductOrderPaid>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIsProductOrderPaidQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const webhook = () => {
  return customInstance<void>({ url: `/api/checkout/webhook`, method: 'POST' });
};

export const getWebhookMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof webhook>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof webhook>>, TError, void, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof webhook>>, void> = () => {
    return webhook();
  };

  return { mutationFn, ...mutationOptions };
};

export type WebhookMutationResult = NonNullable<Awaited<ReturnType<typeof webhook>>>;

export type WebhookMutationError = ErrorType<unknown>;

export const useWebhook = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof webhook>>, TError, void, TContext>;
}) => {
  const mutationOptions = getWebhookMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getAllProducts = (signal?: AbortSignal) => {
  return customInstance<ApiProduct[]>({ url: `/api/products`, method: 'GET', signal });
};

export const getGetAllProductsQueryKey = () => {
  return [`/api/products`] as const;
};

export const getGetAllProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllProducts>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllProducts>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllProductsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllProducts>>> = ({ signal }) => getAllProducts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllProducts>>>;
export type GetAllProductsQueryError = ErrorType<void>;

export const useGetAllProducts = <
  TData = Awaited<ReturnType<typeof getAllProducts>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllProducts>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllProductsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProductById = (id: string, signal?: AbortSignal) => {
  return customInstance<ApiProduct>({ url: `/api/products/${id}`, method: 'GET', signal });
};

export const getGetProductByIdQueryKey = (id: string) => {
  return [`/api/products/${id}`] as const;
};

export const getGetProductByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductById>>,
  TError = ErrorType<void>,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getProductById>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductById>>> = ({ signal }) => getProductById(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProductById>>>;
export type GetProductByIdQueryError = ErrorType<void>;

export const useGetProductById = <TData = Awaited<ReturnType<typeof getProductById>>, TError = ErrorType<void>>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getProductById>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAllPaymentMethods = (signal?: AbortSignal) => {
  return customInstance<ApiFormattedPaymentMethod[]>({ url: `/api/payment-methods`, method: 'GET', signal });
};

export const getGetAllPaymentMethodsQueryKey = () => {
  return [`/api/payment-methods`] as const;
};

export const getGetAllPaymentMethodsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllPaymentMethods>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllPaymentMethods>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllPaymentMethodsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllPaymentMethods>>> = ({ signal }) =>
    getAllPaymentMethods(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllPaymentMethods>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllPaymentMethods>>>;
export type GetAllPaymentMethodsQueryError = ErrorType<void>;

export const useGetAllPaymentMethods = <
  TData = Awaited<ReturnType<typeof getAllPaymentMethods>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllPaymentMethods>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllPaymentMethodsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPaymentMethodById = (id: string, signal?: AbortSignal) => {
  return customInstance<void>({ url: `/api/payment-methods/${id}`, method: 'GET', signal });
};

export const getGetPaymentMethodByIdQueryKey = (id: string) => {
  return [`/api/payment-methods/${id}`] as const;
};

export const getGetPaymentMethodByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentMethodById>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethodById>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentMethodByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentMethodById>>> = ({ signal }) =>
    getPaymentMethodById(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentMethodById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPaymentMethodByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentMethodById>>>;
export type GetPaymentMethodByIdQueryError = ErrorType<unknown>;

export const useGetPaymentMethodById = <
  TData = Awaited<ReturnType<typeof getPaymentMethodById>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethodById>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentMethodByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAllHosts = (signal?: AbortSignal) => {
  return customInstance<Host[]>({ url: `/api/admin/host/all`, method: 'GET', signal });
};

export const getGetAllHostsQueryKey = () => {
  return [`/api/admin/host/all`] as const;
};

export const getGetAllHostsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllHosts>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllHosts>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllHostsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllHosts>>> = ({ signal }) => getAllHosts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllHosts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllHostsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllHosts>>>;
export type GetAllHostsQueryError = ErrorType<HttpExceptionResponse>;

export const useGetAllHosts = <
  TData = Awaited<ReturnType<typeof getAllHosts>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllHosts>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllHostsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getHosts = (params: GetHostsParams, signal?: AbortSignal) => {
  return customInstance<GetHosts200>({ url: `/api/admin/host`, method: 'GET', params, signal });
};

export const getGetHostsQueryKey = (params: GetHostsParams) => {
  return [`/api/admin/host`, ...(params ? [params] : [])] as const;
};

export const getGetHostsQueryOptions = <
  TData = Awaited<ReturnType<typeof getHosts>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetHostsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getHosts>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHostsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHosts>>> = ({ signal }) => getHosts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getHosts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetHostsQueryResult = NonNullable<Awaited<ReturnType<typeof getHosts>>>;
export type GetHostsQueryError = ErrorType<HttpExceptionResponse>;

export const useGetHosts = <TData = Awaited<ReturnType<typeof getHosts>>, TError = ErrorType<HttpExceptionResponse>>(
  params: GetHostsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getHosts>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHostsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteHost = (hostId: string) => {
  return customInstance<void>({ url: `/api/admin/host/${hostId}`, method: 'DELETE' });
};

export const getDeleteHostMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteHost>>, TError, { hostId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteHost>>, TError, { hostId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteHost>>, { hostId: string }> = (props) => {
    const { hostId } = props ?? {};

    return deleteHost(hostId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteHostMutationResult = NonNullable<Awaited<ReturnType<typeof deleteHost>>>;

export type DeleteHostMutationError = ErrorType<unknown>;

export const useDeleteHost = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteHost>>, TError, { hostId: string }, TContext>;
}) => {
  const mutationOptions = getDeleteHostMutationOptions(options);

  return useMutation(mutationOptions);
};

export const invite = (invitations: Invitations) => {
  return customInstance<void>({
    url: `/api/invite/post`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: invitations,
  });
};

export const getInviteMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof invite>>, TError, { data: Invitations }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof invite>>, TError, { data: Invitations }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof invite>>, { data: Invitations }> = (props) => {
    const { data } = props ?? {};

    return invite(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InviteMutationResult = NonNullable<Awaited<ReturnType<typeof invite>>>;
export type InviteMutationBody = Invitations;
export type InviteMutationError = ErrorType<unknown>;

export const useInvite = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof invite>>, TError, { data: Invitations }, TContext>;
}) => {
  const mutationOptions = getInviteMutationOptions(options);

  return useMutation(mutationOptions);
};

export const referralStatus = (signal?: AbortSignal) => {
  return customInstance<ReferralStatus>({ url: `/api/invite/status`, method: 'GET', signal });
};

export const getReferralStatusQueryKey = () => {
  return [`/api/invite/status`] as const;
};

export const getReferralStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof referralStatus>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof referralStatus>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReferralStatusQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof referralStatus>>> = ({ signal }) => referralStatus(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof referralStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ReferralStatusQueryResult = NonNullable<Awaited<ReturnType<typeof referralStatus>>>;
export type ReferralStatusQueryError = ErrorType<HttpExceptionResponse>;

export const useReferralStatus = <
  TData = Awaited<ReturnType<typeof referralStatus>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof referralStatus>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReferralStatusQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateProfile = (updateUser: UpdateUser) => {
  return customInstance<FullUser>({
    url: `/api/profile`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateUser,
  });
};

export const getUpdateProfileMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateProfile>>, TError, { data: UpdateUser }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateProfile>>, TError, { data: UpdateUser }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProfile>>, { data: UpdateUser }> = (props) => {
    const { data } = props ?? {};

    return updateProfile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateProfileMutationResult = NonNullable<Awaited<ReturnType<typeof updateProfile>>>;
export type UpdateProfileMutationBody = UpdateUser;
export type UpdateProfileMutationError = ErrorType<HttpExceptionResponse>;

export const useUpdateProfile = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateProfile>>, TError, { data: UpdateUser }, TContext>;
}) => {
  const mutationOptions = getUpdateProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const uploadImage = (fileUpload: FileUpload) => {
  const formData = new FormData();
  formData.append('file', fileUpload.file);

  return customInstance<FullUser>({
    url: `/api/profile/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getUploadImageMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof uploadImage>>, TError, { data: FileUpload }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof uploadImage>>, TError, { data: FileUpload }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadImage>>, { data: FileUpload }> = (props) => {
    const { data } = props ?? {};

    return uploadImage(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadImageMutationResult = NonNullable<Awaited<ReturnType<typeof uploadImage>>>;
export type UploadImageMutationBody = FileUpload;
export type UploadImageMutationError = ErrorType<HttpExceptionResponse>;

export const useUploadImage = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof uploadImage>>, TError, { data: FileUpload }, TContext>;
}) => {
  const mutationOptions = getUploadImageMutationOptions(options);

  return useMutation(mutationOptions);
};

export const onboarding = () => {
  return customInstance<void>({ url: `/api/profile/finished-onboarding`, method: 'POST' });
};

export const getOnboardingMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof onboarding>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof onboarding>>, TError, void, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof onboarding>>, void> = () => {
    return onboarding();
  };

  return { mutationFn, ...mutationOptions };
};

export type OnboardingMutationResult = NonNullable<Awaited<ReturnType<typeof onboarding>>>;

export type OnboardingMutationError = ErrorType<HttpExceptionResponse>;

export const useOnboarding = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof onboarding>>, TError, void, TContext>;
}) => {
  const mutationOptions = getOnboardingMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getPastGamesAndChallenges = (params: GetPastGamesAndChallengesParams, signal?: AbortSignal) => {
  return customInstance<GameChallengePaginationDto>({ url: `/api/profile/game`, method: 'GET', params, signal });
};

export const getGetPastGamesAndChallengesQueryKey = (params: GetPastGamesAndChallengesParams) => {
  return [`/api/profile/game`, ...(params ? [params] : [])] as const;
};

export const getGetPastGamesAndChallengesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPastGamesAndChallenges>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetPastGamesAndChallengesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPastGamesAndChallenges>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPastGamesAndChallengesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPastGamesAndChallenges>>> = ({ signal }) =>
    getPastGamesAndChallenges(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPastGamesAndChallenges>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPastGamesAndChallengesQueryResult = NonNullable<Awaited<ReturnType<typeof getPastGamesAndChallenges>>>;
export type GetPastGamesAndChallengesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetPastGamesAndChallenges = <
  TData = Awaited<ReturnType<typeof getPastGamesAndChallenges>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetPastGamesAndChallengesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPastGamesAndChallenges>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPastGamesAndChallengesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCards = (signal?: AbortSignal) => {
  return customInstance<Card[]>({ url: `/api/payment/card`, method: 'GET', signal });
};

export const getGetCardsQueryKey = () => {
  return [`/api/payment/card`] as const;
};

export const getGetCardsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCards>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCards>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCards>>> = ({ signal }) => getCards(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCards>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCardsQueryResult = NonNullable<Awaited<ReturnType<typeof getCards>>>;
export type GetCardsQueryError = ErrorType<HttpExceptionResponse>;

export const useGetCards = <
  TData = Awaited<ReturnType<typeof getCards>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCards>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCardsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getIntent = (signal?: AbortSignal) => {
  return customInstance<CardSetup>({ url: `/api/payment/card/setup`, method: 'GET', signal });
};

export const getGetIntentQueryKey = () => {
  return [`/api/payment/card/setup`] as const;
};

export const getGetIntentQueryOptions = <
  TData = Awaited<ReturnType<typeof getIntent>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getIntent>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIntentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIntent>>> = ({ signal }) => getIntent(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIntent>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIntentQueryResult = NonNullable<Awaited<ReturnType<typeof getIntent>>>;
export type GetIntentQueryError = ErrorType<HttpExceptionResponse>;

export const useGetIntent = <
  TData = Awaited<ReturnType<typeof getIntent>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getIntent>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIntentQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createCheckout = (checkoutCreate: CheckoutCreate) => {
  return customInstance<Checkout>({
    url: `/api/checkout`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: checkoutCreate,
  });
};

export const getCreateCheckoutMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCheckout>>, TError, { data: CheckoutCreate }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof createCheckout>>, TError, { data: CheckoutCreate }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCheckout>>, { data: CheckoutCreate }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return createCheckout(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCheckoutMutationResult = NonNullable<Awaited<ReturnType<typeof createCheckout>>>;
export type CreateCheckoutMutationBody = CheckoutCreate;
export type CreateCheckoutMutationError = ErrorType<HttpExceptionResponse>;

export const useCreateCheckout = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCheckout>>, TError, { data: CheckoutCreate }, TContext>;
}) => {
  const mutationOptions = getCreateCheckoutMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCheckout = (checkoutId: string, signal?: AbortSignal) => {
  return customInstance<Checkout>({ url: `/api/checkout/${checkoutId}`, method: 'GET', signal });
};

export const getGetCheckoutQueryKey = (checkoutId: string) => {
  return [`/api/checkout/${checkoutId}`] as const;
};

export const getGetCheckoutQueryOptions = <
  TData = Awaited<ReturnType<typeof getCheckout>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  checkoutId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCheckout>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCheckoutQueryKey(checkoutId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCheckout>>> = ({ signal }) =>
    getCheckout(checkoutId, signal);

  return { queryKey, queryFn, enabled: !!checkoutId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCheckout>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCheckoutQueryResult = NonNullable<Awaited<ReturnType<typeof getCheckout>>>;
export type GetCheckoutQueryError = ErrorType<HttpExceptionResponse>;

export const useGetCheckout = <
  TData = Awaited<ReturnType<typeof getCheckout>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  checkoutId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCheckout>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCheckoutQueryOptions(checkoutId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const payCheckout = (checkoutId: string, params: PayCheckoutParams) => {
  return customInstance<CheckoutPayResponse>({ url: `/api/checkout/${checkoutId}`, method: 'POST', params });
};

export const getPayCheckoutMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof payCheckout>>,
    TError,
    { checkoutId: string; params: PayCheckoutParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof payCheckout>>,
  TError,
  { checkoutId: string; params: PayCheckoutParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof payCheckout>>,
    { checkoutId: string; params: PayCheckoutParams }
  > = (props) => {
    const { checkoutId, params } = props ?? {};

    return payCheckout(checkoutId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PayCheckoutMutationResult = NonNullable<Awaited<ReturnType<typeof payCheckout>>>;

export type PayCheckoutMutationError = ErrorType<HttpExceptionResponse>;

export const usePayCheckout = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof payCheckout>>,
    TError,
    { checkoutId: string; params: PayCheckoutParams },
    TContext
  >;
}) => {
  const mutationOptions = getPayCheckoutMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getSubscriptions = (signal?: AbortSignal) => {
  return customInstance<SubscriptionRequest>({ url: `/api/subscription`, method: 'GET', signal });
};

export const getGetSubscriptionsQueryKey = () => {
  return [`/api/subscription`] as const;
};

export const getGetSubscriptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptions>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptions>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptions>>> = ({ signal }) => getSubscriptions(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSubscriptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptions>>>;
export type GetSubscriptionsQueryError = ErrorType<HttpExceptionResponse>;

export const useGetSubscriptions = <
  TData = Awaited<ReturnType<typeof getSubscriptions>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptions>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubscriptionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserSubscription = (customerId: string, signal?: AbortSignal) => {
  return customInstance<Subscription>({ url: `/api/subscription/${customerId}`, method: 'GET', signal });
};

export const getGetUserSubscriptionQueryKey = (customerId: string) => {
  return [`/api/subscription/${customerId}`] as const;
};

export const getGetUserSubscriptionQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSubscription>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  customerId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSubscription>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSubscriptionQueryKey(customerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSubscription>>> = ({ signal }) =>
    getUserSubscription(customerId, signal);

  return { queryKey, queryFn, enabled: !!customerId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSubscription>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getUserSubscription>>>;
export type GetUserSubscriptionQueryError = ErrorType<HttpExceptionResponse>;

export const useGetUserSubscription = <
  TData = Awaited<ReturnType<typeof getUserSubscription>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  customerId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSubscription>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSubscriptionQueryOptions(customerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProduct = (productId: string, signal?: AbortSignal) => {
  return customInstance<SubscriptionProduct>({ url: `/api/subscription/product/${productId}`, method: 'GET', signal });
};

export const getGetProductQueryKey = (productId: string) => {
  return [`/api/subscription/product/${productId}`] as const;
};

export const getGetProductQueryOptions = <
  TData = Awaited<ReturnType<typeof getProduct>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  productId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductQueryKey(productId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProduct>>> = ({ signal }) => getProduct(productId, signal);

  return { queryKey, queryFn, enabled: !!productId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductQueryResult = NonNullable<Awaited<ReturnType<typeof getProduct>>>;
export type GetProductQueryError = ErrorType<HttpExceptionResponse>;

export const useGetProduct = <
  TData = Awaited<ReturnType<typeof getProduct>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  productId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductQueryOptions(productId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const cancelSubscription = () => {
  return customInstance<void>({ url: `/api/subscription/cancel`, method: 'POST' });
};

export const getCancelSubscriptionMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelSubscription>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof cancelSubscription>>, TError, void, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelSubscription>>, void> = () => {
    return cancelSubscription();
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof cancelSubscription>>>;

export type CancelSubscriptionMutationError = ErrorType<HttpExceptionResponse>;

export const useCancelSubscription = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelSubscription>>, TError, void, TContext>;
}) => {
  const mutationOptions = getCancelSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetGames = (params: AdminGetGamesParams, signal?: AbortSignal) => {
  return customInstance<AdminGetGames200>({ url: `/api/admin/game`, method: 'GET', params, signal });
};

export const getAdminGetGamesQueryKey = (params: AdminGetGamesParams) => {
  return [`/api/admin/game`, ...(params ? [params] : [])] as const;
};

export const getAdminGetGamesQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetGames>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: AdminGetGamesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetGames>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminGetGamesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminGetGames>>> = ({ signal }) =>
    adminGetGames(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetGames>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetGamesQueryResult = NonNullable<Awaited<ReturnType<typeof adminGetGames>>>;
export type AdminGetGamesQueryError = ErrorType<HttpExceptionResponse>;

export const useAdminGetGames = <
  TData = Awaited<ReturnType<typeof adminGetGames>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: AdminGetGamesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetGames>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminGetGamesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminGetGame = (gameId: string, signal?: AbortSignal) => {
  return customInstance<FullGame>({ url: `/api/admin/game/${gameId}`, method: 'GET', signal });
};

export const getAdminGetGameQueryKey = (gameId: string) => {
  return [`/api/admin/game/${gameId}`] as const;
};

export const getAdminGetGameQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetGame>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  gameId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetGame>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminGetGameQueryKey(gameId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminGetGame>>> = ({ signal }) => adminGetGame(gameId, signal);

  return { queryKey, queryFn, enabled: !!gameId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetGame>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetGameQueryResult = NonNullable<Awaited<ReturnType<typeof adminGetGame>>>;
export type AdminGetGameQueryError = ErrorType<HttpExceptionResponse>;

export const useAdminGetGame = <
  TData = Awaited<ReturnType<typeof adminGetGame>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  gameId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetGame>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminGetGameQueryOptions(gameId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const addQuestion = (gameId: string, createQuestion: CreateQuestion) => {
  return customInstance<FullGame>({
    url: `/api/admin/game/${gameId}/question`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createQuestion,
  });
};

export const getAddQuestionMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addQuestion>>,
    TError,
    { gameId: string; data: CreateQuestion },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addQuestion>>,
  TError,
  { gameId: string; data: CreateQuestion },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addQuestion>>,
    { gameId: string; data: CreateQuestion }
  > = (props) => {
    const { gameId, data } = props ?? {};

    return addQuestion(gameId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof addQuestion>>>;
export type AddQuestionMutationBody = CreateQuestion;
export type AddQuestionMutationError = ErrorType<HttpExceptionResponse>;

export const useAddQuestion = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addQuestion>>,
    TError,
    { gameId: string; data: CreateQuestion },
    TContext
  >;
}) => {
  const mutationOptions = getAddQuestionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const uploadQuestions = (gameId: string, fileUpload: FileUpload) => {
  const formData = new FormData();
  formData.append('file', fileUpload.file);

  return customInstance<void>({
    url: `/api/admin/game/${gameId}/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getUploadQuestionsMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadQuestions>>,
    TError,
    { gameId: string; data: FileUpload },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadQuestions>>,
  TError,
  { gameId: string; data: FileUpload },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadQuestions>>,
    { gameId: string; data: FileUpload }
  > = (props) => {
    const { gameId, data } = props ?? {};

    return uploadQuestions(gameId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadQuestionsMutationResult = NonNullable<Awaited<ReturnType<typeof uploadQuestions>>>;
export type UploadQuestionsMutationBody = FileUpload;
export type UploadQuestionsMutationError = ErrorType<unknown>;

export const useUploadQuestions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadQuestions>>,
    TError,
    { gameId: string; data: FileUpload },
    TContext
  >;
}) => {
  const mutationOptions = getUploadQuestionsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteQuestion = (gameId: string, questionId: string) => {
  return customInstance<FullGame>({ url: `/api/admin/game/${gameId}/question/${questionId}`, method: 'DELETE' });
};

export const getDeleteQuestionMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuestion>>,
    TError,
    { gameId: string; questionId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteQuestion>>,
  TError,
  { gameId: string; questionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteQuestion>>,
    { gameId: string; questionId: string }
  > = (props) => {
    const { gameId, questionId } = props ?? {};

    return deleteQuestion(gameId, questionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteQuestion>>>;

export type DeleteQuestionMutationError = ErrorType<HttpExceptionResponse>;

export const useDeleteQuestion = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuestion>>,
    TError,
    { gameId: string; questionId: string },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteQuestionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetStatus = (signal?: AbortSignal) => {
  return customInstance<GameStatus>({ url: `/api/admin/game/status`, method: 'GET', signal });
};

export const getAdminGetStatusQueryKey = () => {
  return [`/api/admin/game/status`] as const;
};

export const getAdminGetStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetStatus>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetStatus>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminGetStatusQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminGetStatus>>> = ({ signal }) => adminGetStatus(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetStatusQueryResult = NonNullable<Awaited<ReturnType<typeof adminGetStatus>>>;
export type AdminGetStatusQueryError = ErrorType<HttpExceptionResponse>;

export const useAdminGetStatus = <
  TData = Awaited<ReturnType<typeof adminGetStatus>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetStatus>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminGetStatusQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminNextQuestion = (gameId: string) => {
  return customInstance<Question>({ url: `/api/admin/game/${gameId}/next-question`, method: 'POST' });
};

export const getAdminNextQuestionMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminNextQuestion>>, TError, { gameId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof adminNextQuestion>>, TError, { gameId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminNextQuestion>>, { gameId: string }> = (props) => {
    const { gameId } = props ?? {};

    return adminNextQuestion(gameId);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminNextQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof adminNextQuestion>>>;

export type AdminNextQuestionMutationError = ErrorType<HttpExceptionResponse>;

export const useAdminNextQuestion = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminNextQuestion>>, TError, { gameId: string }, TContext>;
}) => {
  const mutationOptions = getAdminNextQuestionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminStart = (gameId: string) => {
  return customInstance<void>({ url: `/api/admin/game/${gameId}/start`, method: 'POST' });
};

export const getAdminStartMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminStart>>, TError, { gameId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof adminStart>>, TError, { gameId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminStart>>, { gameId: string }> = (props) => {
    const { gameId } = props ?? {};

    return adminStart(gameId);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminStartMutationResult = NonNullable<Awaited<ReturnType<typeof adminStart>>>;

export type AdminStartMutationError = ErrorType<HttpExceptionResponse>;

export const useAdminStart = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminStart>>, TError, { gameId: string }, TContext>;
}) => {
  const mutationOptions = getAdminStartMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminEnd = (gameId: string) => {
  return customInstance<void>({ url: `/api/admin/game/${gameId}/end`, method: 'POST' });
};

export const getAdminEndMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminEnd>>, TError, { gameId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof adminEnd>>, TError, { gameId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminEnd>>, { gameId: string }> = (props) => {
    const { gameId } = props ?? {};

    return adminEnd(gameId);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminEndMutationResult = NonNullable<Awaited<ReturnType<typeof adminEnd>>>;

export type AdminEndMutationError = ErrorType<HttpExceptionResponse>;

export const useAdminEnd = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminEnd>>, TError, { gameId: string }, TContext>;
}) => {
  const mutationOptions = getAdminEndMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gameUpload = (fileUpload: FileUpload) => {
  const formData = new FormData();
  formData.append('file', fileUpload.file);

  return customInstance<File>({
    url: `/api/admin/game/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getGameUploadMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof gameUpload>>, TError, { data: FileUpload }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof gameUpload>>, TError, { data: FileUpload }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof gameUpload>>, { data: FileUpload }> = (props) => {
    const { data } = props ?? {};

    return gameUpload(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GameUploadMutationResult = NonNullable<Awaited<ReturnType<typeof gameUpload>>>;
export type GameUploadMutationBody = FileUpload;
export type GameUploadMutationError = ErrorType<HttpExceptionResponse>;

export const useGameUpload = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof gameUpload>>, TError, { data: FileUpload }, TContext>;
}) => {
  const mutationOptions = getGameUploadMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateGame = (gameId: string, gameInfo: GameInfo) => {
  return customInstance<void>({
    url: `/api/admin/game/${gameId}/update`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: gameInfo,
  });
};

export const getUpdateGameMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGame>>,
    TError,
    { gameId: string; data: GameInfo },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGame>>,
  TError,
  { gameId: string; data: GameInfo },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGame>>, { gameId: string; data: GameInfo }> = (
    props,
  ) => {
    const { gameId, data } = props ?? {};

    return updateGame(gameId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGameMutationResult = NonNullable<Awaited<ReturnType<typeof updateGame>>>;
export type UpdateGameMutationBody = GameInfo;
export type UpdateGameMutationError = ErrorType<unknown>;

export const useUpdateGame = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGame>>,
    TError,
    { gameId: string; data: GameInfo },
    TContext
  >;
}) => {
  const mutationOptions = getUpdateGameMutationOptions(options);

  return useMutation(mutationOptions);
};

export const createGame = (gameInfo: GameInfo) => {
  return customInstance<Game>({
    url: `/api/admin/game/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: gameInfo,
  });
};

export const getCreateGameMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGame>>, TError, { data: GameInfo }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof createGame>>, TError, { data: GameInfo }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGame>>, { data: GameInfo }> = (props) => {
    const { data } = props ?? {};

    return createGame(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGameMutationResult = NonNullable<Awaited<ReturnType<typeof createGame>>>;
export type CreateGameMutationBody = GameInfo;
export type CreateGameMutationError = ErrorType<HttpExceptionResponse>;

export const useCreateGame = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGame>>, TError, { data: GameInfo }, TContext>;
}) => {
  const mutationOptions = getCreateGameMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteGame = (id: string) => {
  return customInstance<void>({ url: `/api/admin/game/${id}`, method: 'DELETE' });
};

export const getDeleteGameMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteGame>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteGame>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGame>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return deleteGame(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGameMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGame>>>;

export type DeleteGameMutationError = ErrorType<unknown>;

export const useDeleteGame = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteGame>>, TError, { id: string }, TContext>;
}) => {
  const mutationOptions = getDeleteGameMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getLoadedGame = (signal?: AbortSignal) => {
  return customInstance<GetLoadedGameResponse>({ url: `/api/admin/game/loaded`, method: 'GET', signal });
};

export const getGetLoadedGameQueryKey = () => {
  return [`/api/admin/game/loaded`] as const;
};

export const getGetLoadedGameQueryOptions = <
  TData = Awaited<ReturnType<typeof getLoadedGame>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLoadedGame>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoadedGameQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLoadedGame>>> = ({ signal }) => getLoadedGame(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLoadedGame>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLoadedGameQueryResult = NonNullable<Awaited<ReturnType<typeof getLoadedGame>>>;
export type GetLoadedGameQueryError = ErrorType<HttpExceptionResponse>;

export const useGetLoadedGame = <
  TData = Awaited<ReturnType<typeof getLoadedGame>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLoadedGame>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLoadedGameQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const generateQuestions = (generateQuestionsRequest: GenerateQuestionsRequest) => {
  return customInstance<QuestionCandidate[]>({
    url: `/api/admin/game/generate`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: generateQuestionsRequest,
  });
};

export const getGenerateQuestionsMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateQuestions>>,
    TError,
    { data: GenerateQuestionsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateQuestions>>,
  TError,
  { data: GenerateQuestionsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateQuestions>>,
    { data: GenerateQuestionsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return generateQuestions(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateQuestionsMutationResult = NonNullable<Awaited<ReturnType<typeof generateQuestions>>>;
export type GenerateQuestionsMutationBody = GenerateQuestionsRequest;
export type GenerateQuestionsMutationError = ErrorType<HttpExceptionResponse>;

export const useGenerateQuestions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateQuestions>>,
    TError,
    { data: GenerateQuestionsRequest },
    TContext
  >;
}) => {
  const mutationOptions = getGenerateQuestionsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getActiveGames = (signal?: AbortSignal) => {
  return customInstance<Game[]>({ url: `/api/admin/game/active`, method: 'GET', signal });
};

export const getGetActiveGamesQueryKey = () => {
  return [`/api/admin/game/active`] as const;
};

export const getGetActiveGamesQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveGames>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveGames>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveGamesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveGames>>> = ({ signal }) => getActiveGames(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveGames>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetActiveGamesQueryResult = NonNullable<Awaited<ReturnType<typeof getActiveGames>>>;
export type GetActiveGamesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetActiveGames = <
  TData = Awaited<ReturnType<typeof getActiveGames>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveGames>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActiveGamesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const dangerouslyResetEngine = () => {
  return customInstance<void>({ url: `/api/admin/game/reset`, method: 'DELETE' });
};

export const getDangerouslyResetEngineMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof dangerouslyResetEngine>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof dangerouslyResetEngine>>, TError, void, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof dangerouslyResetEngine>>, void> = () => {
    return dangerouslyResetEngine();
  };

  return { mutationFn, ...mutationOptions };
};

export type DangerouslyResetEngineMutationResult = NonNullable<Awaited<ReturnType<typeof dangerouslyResetEngine>>>;

export type DangerouslyResetEngineMutationError = ErrorType<unknown>;

export const useDangerouslyResetEngine = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof dangerouslyResetEngine>>, TError, void, TContext>;
}) => {
  const mutationOptions = getDangerouslyResetEngineMutationOptions(options);

  return useMutation(mutationOptions);
};

export const dangerouslyRestoreDefaultGame = () => {
  return customInstance<void>({ url: `/api/admin/game/reset/default`, method: 'DELETE' });
};

export const getDangerouslyRestoreDefaultGameMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof dangerouslyRestoreDefaultGame>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof dangerouslyRestoreDefaultGame>>, TError, void, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof dangerouslyRestoreDefaultGame>>, void> = () => {
    return dangerouslyRestoreDefaultGame();
  };

  return { mutationFn, ...mutationOptions };
};

export type DangerouslyRestoreDefaultGameMutationResult = NonNullable<
  Awaited<ReturnType<typeof dangerouslyRestoreDefaultGame>>
>;

export type DangerouslyRestoreDefaultGameMutationError = ErrorType<unknown>;

export const useDangerouslyRestoreDefaultGame = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof dangerouslyRestoreDefaultGame>>, TError, void, TContext>;
}) => {
  const mutationOptions = getDangerouslyRestoreDefaultGameMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getDebugInfo = (signal?: AbortSignal) => {
  return customInstance<void>({ url: `/api/admin/game/debug`, method: 'GET', signal });
};

export const getGetDebugInfoQueryKey = () => {
  return [`/api/admin/game/debug`] as const;
};

export const getGetDebugInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getDebugInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDebugInfo>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDebugInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDebugInfo>>> = ({ signal }) => getDebugInfo(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDebugInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDebugInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getDebugInfo>>>;
export type GetDebugInfoQueryError = ErrorType<unknown>;

export const useGetDebugInfo = <
  TData = Awaited<ReturnType<typeof getDebugInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDebugInfo>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDebugInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getOrders = (params: GetOrdersParams, signal?: AbortSignal) => {
  return customInstance<GetOrders200>({ url: `/api/admin/order`, method: 'GET', params, signal });
};

export const getGetOrdersQueryKey = (params: GetOrdersParams) => {
  return [`/api/admin/order`, ...(params ? [params] : [])] as const;
};

export const getGetOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetOrdersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrders>>> = ({ signal }) => getOrders(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getOrders>>>;
export type GetOrdersQueryError = ErrorType<HttpExceptionResponse>;

export const useGetOrders = <TData = Awaited<ReturnType<typeof getOrders>>, TError = ErrorType<HttpExceptionResponse>>(
  params: GetOrdersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrdersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getRefunds = (signal?: AbortSignal) => {
  return customInstance<void>({ url: `/api/admin/order/refunds`, method: 'GET', signal });
};

export const getGetRefundsQueryKey = () => {
  return [`/api/admin/order/refunds`] as const;
};

export const getGetRefundsQueryOptions = <
  TData = Awaited<ReturnType<typeof getRefunds>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRefunds>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRefundsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRefunds>>> = ({ signal }) => getRefunds(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRefunds>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRefundsQueryResult = NonNullable<Awaited<ReturnType<typeof getRefunds>>>;
export type GetRefundsQueryError = ErrorType<unknown>;

export const useGetRefunds = <TData = Awaited<ReturnType<typeof getRefunds>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRefunds>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRefundsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const refundOrder = (refundOrderBody: string[]) => {
  return customInstance<boolean>({
    url: `/api/admin/order/refund`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: refundOrderBody,
  });
};

export const getRefundOrderMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refundOrder>>, TError, { data: string[] }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof refundOrder>>, TError, { data: string[] }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof refundOrder>>, { data: string[] }> = (props) => {
    const { data } = props ?? {};

    return refundOrder(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefundOrderMutationResult = NonNullable<Awaited<ReturnType<typeof refundOrder>>>;
export type RefundOrderMutationBody = string[];
export type RefundOrderMutationError = ErrorType<HttpExceptionResponse>;

export const useRefundOrder = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refundOrder>>, TError, { data: string[] }, TContext>;
}) => {
  const mutationOptions = getRefundOrderMutationOptions(options);

  return useMutation(mutationOptions);
};

export const refundOrderBatch = (refundBatch: RefundBatch) => {
  return customInstance<boolean>({
    url: `/api/admin/order/refund-batch`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: refundBatch,
  });
};

export const getRefundOrderBatchMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refundOrderBatch>>, TError, { data: RefundBatch }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof refundOrderBatch>>, TError, { data: RefundBatch }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof refundOrderBatch>>, { data: RefundBatch }> = (props) => {
    const { data } = props ?? {};

    return refundOrderBatch(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefundOrderBatchMutationResult = NonNullable<Awaited<ReturnType<typeof refundOrderBatch>>>;
export type RefundOrderBatchMutationBody = RefundBatch;
export type RefundOrderBatchMutationError = ErrorType<HttpExceptionResponse>;

export const useRefundOrderBatch = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refundOrderBatch>>, TError, { data: RefundBatch }, TContext>;
}) => {
  const mutationOptions = getRefundOrderBatchMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getUsers = (params: GetUsersParams, signal?: AbortSignal) => {
  return customInstance<GetUsers200>({ url: `/api/admin/user`, method: 'GET', params, signal });
};

export const getGetUsersQueryKey = (params: GetUsersParams) => {
  return [`/api/admin/user`, ...(params ? [params] : [])] as const;
};

export const getGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetUsersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>;
export type GetUsersQueryError = ErrorType<HttpExceptionResponse>;

export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = ErrorType<HttpExceptionResponse>>(
  params: GetUsersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createUser = (createUpdateUser: CreateUpdateUser) => {
  return customInstance<FullUser>({
    url: `/api/admin/user`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUpdateUser,
  });
};

export const getCreateUserMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError, { data: CreateUpdateUser }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError, { data: CreateUpdateUser }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUser>>, { data: CreateUpdateUser }> = (props) => {
    const { data } = props ?? {};

    return createUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof createUser>>>;
export type CreateUserMutationBody = CreateUpdateUser;
export type CreateUserMutationError = ErrorType<HttpExceptionResponse>;

export const useCreateUser = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError, { data: CreateUpdateUser }, TContext>;
}) => {
  const mutationOptions = getCreateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getUserById = (userId: string, signal?: AbortSignal) => {
  return customInstance<FullUser>({ url: `/api/admin/user/${userId}`, method: 'GET', signal });
};

export const getGetUserByIdQueryKey = (userId: string) => {
  return [`/api/admin/user/${userId}`] as const;
};

export const getGetUserByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserById>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserById>>> = ({ signal }) => getUserById(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserById>>>;
export type GetUserByIdQueryError = ErrorType<HttpExceptionResponse>;

export const useGetUserById = <
  TData = Awaited<ReturnType<typeof getUserById>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserByIdQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateUserById = (userId: string, createUpdateUser: CreateUpdateUser) => {
  return customInstance<FullUser>({
    url: `/api/admin/user/${userId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createUpdateUser,
  });
};

export const getUpdateUserByIdMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserById>>,
    TError,
    { userId: string; data: CreateUpdateUser },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserById>>,
  TError,
  { userId: string; data: CreateUpdateUser },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserById>>,
    { userId: string; data: CreateUpdateUser }
  > = (props) => {
    const { userId, data } = props ?? {};

    return updateUserById(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserById>>>;
export type UpdateUserByIdMutationBody = CreateUpdateUser;
export type UpdateUserByIdMutationError = ErrorType<HttpExceptionResponse>;

export const useUpdateUserById = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserById>>,
    TError,
    { userId: string; data: CreateUpdateUser },
    TContext
  >;
}) => {
  const mutationOptions = getUpdateUserByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteUserById = (userId: string) => {
  return customInstance<void>({ url: `/api/admin/user/${userId}`, method: 'DELETE' });
};

export const getDeleteUserByIdMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUserById>>, TError, { userId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteUserById>>, TError, { userId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserById>>, { userId: string }> = (props) => {
    const { userId } = props ?? {};

    return deleteUserById(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserById>>>;

export type DeleteUserByIdMutationError = ErrorType<HttpExceptionResponse>;

export const useDeleteUserById = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUserById>>, TError, { userId: string }, TContext>;
}) => {
  const mutationOptions = getDeleteUserByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetGroups = (params: AdminGetGroupsParams, signal?: AbortSignal) => {
  return customInstance<AdminGetGroups200>({ url: `/api/admin/group`, method: 'GET', params, signal });
};

export const getAdminGetGroupsQueryKey = (params: AdminGetGroupsParams) => {
  return [`/api/admin/group`, ...(params ? [params] : [])] as const;
};

export const getAdminGetGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetGroups>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: AdminGetGroupsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetGroups>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminGetGroupsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminGetGroups>>> = ({ signal }) =>
    adminGetGroups(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof adminGetGroups>>>;
export type AdminGetGroupsQueryError = ErrorType<HttpExceptionResponse>;

export const useAdminGetGroups = <
  TData = Awaited<ReturnType<typeof adminGetGroups>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: AdminGetGroupsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetGroups>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminGetGroupsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminGetGroup = (groupId: string, signal?: AbortSignal) => {
  return customInstance<FullGroupDto>({ url: `/api/admin/group/${groupId}`, method: 'GET', signal });
};

export const getAdminGetGroupQueryKey = (groupId: string) => {
  return [`/api/admin/group/${groupId}`] as const;
};

export const getAdminGetGroupQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetGroup>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  groupId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetGroup>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminGetGroupQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminGetGroup>>> = ({ signal }) =>
    adminGetGroup(groupId, signal);

  return { queryKey, queryFn, enabled: !!groupId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetGroup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetGroupQueryResult = NonNullable<Awaited<ReturnType<typeof adminGetGroup>>>;
export type AdminGetGroupQueryError = ErrorType<HttpExceptionResponse>;

export const useAdminGetGroup = <
  TData = Awaited<ReturnType<typeof adminGetGroup>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  groupId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetGroup>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminGetGroupQueryOptions(groupId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminDeleteGroupById = (groupId: string) => {
  return customInstance<void>({ url: `/api/admin/group/${groupId}`, method: 'DELETE' });
};

export const getAdminDeleteGroupByIdMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteGroupById>>,
    TError,
    { groupId: string },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof adminDeleteGroupById>>, TError, { groupId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminDeleteGroupById>>, { groupId: string }> = (
    props,
  ) => {
    const { groupId } = props ?? {};

    return adminDeleteGroupById(groupId);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDeleteGroupByIdMutationResult = NonNullable<Awaited<ReturnType<typeof adminDeleteGroupById>>>;

export type AdminDeleteGroupByIdMutationError = ErrorType<HttpExceptionResponse>;

export const useAdminDeleteGroupById = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteGroupById>>,
    TError,
    { groupId: string },
    TContext
  >;
}) => {
  const mutationOptions = getAdminDeleteGroupByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUpdateGroup = (groupId: string, groupInfo: GroupInfo) => {
  return customInstance<void>({
    url: `/api/admin/group/${groupId}/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: groupInfo,
  });
};

export const getAdminUpdateGroupMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateGroup>>,
    TError,
    { groupId: string; data: GroupInfo },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUpdateGroup>>,
  TError,
  { groupId: string; data: GroupInfo },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUpdateGroup>>,
    { groupId: string; data: GroupInfo }
  > = (props) => {
    const { groupId, data } = props ?? {};

    return adminUpdateGroup(groupId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUpdateGroupMutationResult = NonNullable<Awaited<ReturnType<typeof adminUpdateGroup>>>;
export type AdminUpdateGroupMutationBody = GroupInfo;
export type AdminUpdateGroupMutationError = ErrorType<unknown>;

export const useAdminUpdateGroup = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateGroup>>,
    TError,
    { groupId: string; data: GroupInfo },
    TContext
  >;
}) => {
  const mutationOptions = getAdminUpdateGroupMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUpdateGroupMemberAdminStatus = (groupId: string, id: string, groupMemberInfo: GroupMemberInfo) => {
  return customInstance<void>({
    url: `/api/admin/group/${groupId}/member/${id}/admin/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: groupMemberInfo,
  });
};

export const getAdminUpdateGroupMemberAdminStatusMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateGroupMemberAdminStatus>>,
    TError,
    { groupId: string; id: string; data: GroupMemberInfo },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUpdateGroupMemberAdminStatus>>,
  TError,
  { groupId: string; id: string; data: GroupMemberInfo },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUpdateGroupMemberAdminStatus>>,
    { groupId: string; id: string; data: GroupMemberInfo }
  > = (props) => {
    const { groupId, id, data } = props ?? {};

    return adminUpdateGroupMemberAdminStatus(groupId, id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUpdateGroupMemberAdminStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUpdateGroupMemberAdminStatus>>
>;
export type AdminUpdateGroupMemberAdminStatusMutationBody = GroupMemberInfo;
export type AdminUpdateGroupMemberAdminStatusMutationError = ErrorType<unknown>;

export const useAdminUpdateGroupMemberAdminStatus = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateGroupMemberAdminStatus>>,
    TError,
    { groupId: string; id: string; data: GroupMemberInfo },
    TContext
  >;
}) => {
  const mutationOptions = getAdminUpdateGroupMemberAdminStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUpdateGroupMemberFounderStatus = (groupId: string, id: string, groupMemberInfo: GroupMemberInfo) => {
  return customInstance<void>({
    url: `/api/admin/group/${groupId}/member/${id}/founder/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: groupMemberInfo,
  });
};

export const getAdminUpdateGroupMemberFounderStatusMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateGroupMemberFounderStatus>>,
    TError,
    { groupId: string; id: string; data: GroupMemberInfo },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUpdateGroupMemberFounderStatus>>,
  TError,
  { groupId: string; id: string; data: GroupMemberInfo },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUpdateGroupMemberFounderStatus>>,
    { groupId: string; id: string; data: GroupMemberInfo }
  > = (props) => {
    const { groupId, id, data } = props ?? {};

    return adminUpdateGroupMemberFounderStatus(groupId, id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUpdateGroupMemberFounderStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUpdateGroupMemberFounderStatus>>
>;
export type AdminUpdateGroupMemberFounderStatusMutationBody = GroupMemberInfo;
export type AdminUpdateGroupMemberFounderStatusMutationError = ErrorType<unknown>;

export const useAdminUpdateGroupMemberFounderStatus = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateGroupMemberFounderStatus>>,
    TError,
    { groupId: string; id: string; data: GroupMemberInfo },
    TContext
  >;
}) => {
  const mutationOptions = getAdminUpdateGroupMemberFounderStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUploadGroupFile = (fileUpload: FileUpload) => {
  const formData = new FormData();
  formData.append('file', fileUpload.file);

  return customInstance<File>({
    url: `/api/admin/group/file/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getAdminUploadGroupFileMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUploadGroupFile>>,
    TError,
    { data: FileUpload },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof adminUploadGroupFile>>, TError, { data: FileUpload }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminUploadGroupFile>>, { data: FileUpload }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return adminUploadGroupFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUploadGroupFileMutationResult = NonNullable<Awaited<ReturnType<typeof adminUploadGroupFile>>>;
export type AdminUploadGroupFileMutationBody = FileUpload;
export type AdminUploadGroupFileMutationError = ErrorType<HttpExceptionResponse>;

export const useAdminUploadGroupFile = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUploadGroupFile>>,
    TError,
    { data: FileUpload },
    TContext
  >;
}) => {
  const mutationOptions = getAdminUploadGroupFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getEpisodes = (params: GetEpisodesParams, signal?: AbortSignal) => {
  return customInstance<GetEpisodes200>({ url: `/api/admin/episodes`, method: 'GET', params, signal });
};

export const getGetEpisodesQueryKey = (params: GetEpisodesParams) => {
  return [`/api/admin/episodes`, ...(params ? [params] : [])] as const;
};

export const getGetEpisodesQueryOptions = <
  TData = Awaited<ReturnType<typeof getEpisodes>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetEpisodesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getEpisodes>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEpisodesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEpisodes>>> = ({ signal }) => getEpisodes(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEpisodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEpisodesQueryResult = NonNullable<Awaited<ReturnType<typeof getEpisodes>>>;
export type GetEpisodesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetEpisodes = <
  TData = Awaited<ReturnType<typeof getEpisodes>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetEpisodesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getEpisodes>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEpisodesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteEpisode = (episodeId: string) => {
  return customInstance<Boolean>({ url: `/api/admin/episodes/${episodeId}`, method: 'DELETE' });
};

export const getDeleteEpisodeMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteEpisode>>, TError, { episodeId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteEpisode>>, TError, { episodeId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEpisode>>, { episodeId: string }> = (props) => {
    const { episodeId } = props ?? {};

    return deleteEpisode(episodeId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEpisodeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEpisode>>>;

export type DeleteEpisodeMutationError = ErrorType<HttpExceptionResponse>;

export const useDeleteEpisode = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteEpisode>>, TError, { episodeId: string }, TContext>;
}) => {
  const mutationOptions = getDeleteEpisodeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getAllEpisodes = (signal?: AbortSignal) => {
  return customInstance<Episode[]>({ url: `/api/episode`, method: 'GET', signal });
};

export const getGetAllEpisodesQueryKey = () => {
  return [`/api/episode`] as const;
};

export const getGetAllEpisodesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllEpisodes>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllEpisodes>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllEpisodesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllEpisodes>>> = ({ signal }) => getAllEpisodes(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllEpisodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllEpisodesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllEpisodes>>>;
export type GetAllEpisodesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetAllEpisodes = <
  TData = Awaited<ReturnType<typeof getAllEpisodes>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllEpisodes>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllEpisodesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createEpisodeFromGameId = (gameId: string) => {
  return customInstance<Episode>({ url: `/api/episode/${gameId}`, method: 'POST' });
};

export const getCreateEpisodeFromGameIdMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEpisodeFromGameId>>,
    TError,
    { gameId: string },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof createEpisodeFromGameId>>, TError, { gameId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEpisodeFromGameId>>, { gameId: string }> = (
    props,
  ) => {
    const { gameId } = props ?? {};

    return createEpisodeFromGameId(gameId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateEpisodeFromGameIdMutationResult = NonNullable<Awaited<ReturnType<typeof createEpisodeFromGameId>>>;

export type CreateEpisodeFromGameIdMutationError = ErrorType<HttpExceptionResponse>;

export const useCreateEpisodeFromGameId = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEpisodeFromGameId>>,
    TError,
    { gameId: string },
    TContext
  >;
}) => {
  const mutationOptions = getCreateEpisodeFromGameIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTransactionList = (params: GetTransactionListParams, signal?: AbortSignal) => {
  return customInstance<TransactionHistory>({ url: `/api/admin/transaction/list`, method: 'GET', params, signal });
};

export const getGetTransactionListQueryKey = (params: GetTransactionListParams) => {
  return [`/api/admin/transaction/list`, ...(params ? [params] : [])] as const;
};

export const getGetTransactionListQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactionList>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetTransactionListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactionList>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionList>>> = ({ signal }) =>
    getTransactionList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransactionList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTransactionListQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactionList>>>;
export type GetTransactionListQueryError = ErrorType<HttpExceptionResponse>;

export const useGetTransactionList = <
  TData = Awaited<ReturnType<typeof getTransactionList>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetTransactionListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactionList>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTransactionListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBalanceByUser = (userId: string, signal?: AbortSignal) => {
  return customInstance<UserWalletBalance>({ url: `/api/admin/transaction/${userId}/balance`, method: 'GET', signal });
};

export const getGetBalanceByUserQueryKey = (userId: string) => {
  return [`/api/admin/transaction/${userId}/balance`] as const;
};

export const getGetBalanceByUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getBalanceByUser>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getBalanceByUser>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBalanceByUserQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBalanceByUser>>> = ({ signal }) =>
    getBalanceByUser(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBalanceByUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBalanceByUserQueryResult = NonNullable<Awaited<ReturnType<typeof getBalanceByUser>>>;
export type GetBalanceByUserQueryError = ErrorType<HttpExceptionResponse>;

export const useGetBalanceByUser = <
  TData = Awaited<ReturnType<typeof getBalanceByUser>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getBalanceByUser>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBalanceByUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTransactionsByUser = (userId: string, params: GetTransactionsByUserParams, signal?: AbortSignal) => {
  return customInstance<TransactionHistory>({
    url: `/api/admin/transaction/${userId}/list`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetTransactionsByUserQueryKey = (userId: string, params: GetTransactionsByUserParams) => {
  return [`/api/admin/transaction/${userId}/list`, ...(params ? [params] : [])] as const;
};

export const getGetTransactionsByUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactionsByUser>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  userId: string,
  params: GetTransactionsByUserParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactionsByUser>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsByUserQueryKey(userId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionsByUser>>> = ({ signal }) =>
    getTransactionsByUser(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransactionsByUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTransactionsByUserQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactionsByUser>>>;
export type GetTransactionsByUserQueryError = ErrorType<HttpExceptionResponse>;

export const useGetTransactionsByUser = <
  TData = Awaited<ReturnType<typeof getTransactionsByUser>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  userId: string,
  params: GetTransactionsByUserParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactionsByUser>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTransactionsByUserQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminMove = (adminMove: AdminMove) => {
  return customInstance<Boolean>({
    url: `/api/admin/transaction/move`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: adminMove,
  });
};

export const getAdminMoveMutationOptions = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminMove>>, TError, { data: AdminMove }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof adminMove>>, TError, { data: AdminMove }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminMove>>, { data: AdminMove }> = (props) => {
    const { data } = props ?? {};

    return adminMove(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminMoveMutationResult = NonNullable<Awaited<ReturnType<typeof adminMove>>>;
export type AdminMoveMutationBody = AdminMove;
export type AdminMoveMutationError = ErrorType<HttpExceptionResponse>;

export const useAdminMove = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminMove>>, TError, { data: AdminMove }, TContext>;
}) => {
  const mutationOptions = getAdminMoveMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminWalletAction = (adminWalletAction: AdminWalletAction) => {
  return customInstance<Boolean>({
    url: `/api/admin/transaction/action`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: adminWalletAction,
  });
};

export const getAdminWalletActionMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminWalletAction>>,
    TError,
    { data: AdminWalletAction },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminWalletAction>>,
  TError,
  { data: AdminWalletAction },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminWalletAction>>, { data: AdminWalletAction }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return adminWalletAction(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminWalletActionMutationResult = NonNullable<Awaited<ReturnType<typeof adminWalletAction>>>;
export type AdminWalletActionMutationBody = AdminWalletAction;
export type AdminWalletActionMutationError = ErrorType<HttpExceptionResponse>;

export const useAdminWalletAction = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminWalletAction>>,
    TError,
    { data: AdminWalletAction },
    TContext
  >;
}) => {
  const mutationOptions = getAdminWalletActionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const revertTransaction = (transactionId: string) => {
  return customInstance<void>({ url: `/api/admin/transaction/transactions/${transactionId}/revert`, method: 'POST' });
};

export const getRevertTransactionMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revertTransaction>>,
    TError,
    { transactionId: string },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof revertTransaction>>, TError, { transactionId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof revertTransaction>>, { transactionId: string }> = (
    props,
  ) => {
    const { transactionId } = props ?? {};

    return revertTransaction(transactionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevertTransactionMutationResult = NonNullable<Awaited<ReturnType<typeof revertTransaction>>>;

export type RevertTransactionMutationError = ErrorType<unknown>;

export const useRevertTransaction = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revertTransaction>>,
    TError,
    { transactionId: string },
    TContext
  >;
}) => {
  const mutationOptions = getRevertTransactionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getUserLeaderboard = (params: GetUserLeaderboardParams, signal?: AbortSignal) => {
  return customInstance<LeaderBoardUserResult>({ url: `/api/leaderboard/user`, method: 'GET', params, signal });
};

export const getGetUserLeaderboardQueryKey = (params: GetUserLeaderboardParams) => {
  return [`/api/leaderboard/user`, ...(params ? [params] : [])] as const;
};

export const getGetUserLeaderboardQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserLeaderboard>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetUserLeaderboardParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUserLeaderboard>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserLeaderboardQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLeaderboard>>> = ({ signal }) =>
    getUserLeaderboard(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserLeaderboard>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserLeaderboardQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLeaderboard>>>;
export type GetUserLeaderboardQueryError = ErrorType<HttpExceptionResponse>;

export const useGetUserLeaderboard = <
  TData = Awaited<ReturnType<typeof getUserLeaderboard>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetUserLeaderboardParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUserLeaderboard>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserLeaderboardQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGroupsLeaderboard = (params: GetGroupsLeaderboardParams, signal?: AbortSignal) => {
  return customInstance<LeaderBoardGroupResult>({ url: `/api/leaderboard/group`, method: 'GET', params, signal });
};

export const getGetGroupsLeaderboardQueryKey = (params: GetGroupsLeaderboardParams) => {
  return [`/api/leaderboard/group`, ...(params ? [params] : [])] as const;
};

export const getGetGroupsLeaderboardQueryOptions = <
  TData = Awaited<ReturnType<typeof getGroupsLeaderboard>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetGroupsLeaderboardParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGroupsLeaderboard>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGroupsLeaderboardQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupsLeaderboard>>> = ({ signal }) =>
    getGroupsLeaderboard(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGroupsLeaderboard>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGroupsLeaderboardQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupsLeaderboard>>>;
export type GetGroupsLeaderboardQueryError = ErrorType<HttpExceptionResponse>;

export const useGetGroupsLeaderboard = <
  TData = Awaited<ReturnType<typeof getGroupsLeaderboard>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetGroupsLeaderboardParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getGroupsLeaderboard>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGroupsLeaderboardQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const ping = (signal?: AbortSignal) => {
  return customInstance<void>({ url: `/api/ping`, method: 'GET', signal });
};

export const getPingQueryKey = () => {
  return [`/api/ping`] as const;
};

export const getPingQueryOptions = <TData = Awaited<ReturnType<typeof ping>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPingQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ping>>> = ({ signal }) => ping(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type PingQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>;
export type PingQueryError = ErrorType<unknown>;

export const usePing = <TData = Awaited<ReturnType<typeof ping>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const personaWebhook = (personaWebhookDto: PersonaWebhookDto) => {
  return customInstance<void>({
    url: `/api/security/webhook/persona`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: personaWebhookDto,
  });
};

export const getPersonaWebhookMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof personaWebhook>>,
    TError,
    { data: PersonaWebhookDto },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof personaWebhook>>, TError, { data: PersonaWebhookDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof personaWebhook>>, { data: PersonaWebhookDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return personaWebhook(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PersonaWebhookMutationResult = NonNullable<Awaited<ReturnType<typeof personaWebhook>>>;
export type PersonaWebhookMutationBody = PersonaWebhookDto;
export type PersonaWebhookMutationError = ErrorType<unknown>;

export const usePersonaWebhook = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof personaWebhook>>,
    TError,
    { data: PersonaWebhookDto },
    TContext
  >;
}) => {
  const mutationOptions = getPersonaWebhookMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getModules = (signal?: AbortSignal) => {
  return customInstance<SecurityModuleDto[]>({ url: `/api/security/module`, method: 'GET', signal });
};

export const getGetModulesQueryKey = () => {
  return [`/api/security/module`] as const;
};

export const getGetModulesQueryOptions = <
  TData = Awaited<ReturnType<typeof getModules>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getModules>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModulesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getModules>>> = ({ signal }) => getModules(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getModules>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetModulesQueryResult = NonNullable<Awaited<ReturnType<typeof getModules>>>;
export type GetModulesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetModules = <
  TData = Awaited<ReturnType<typeof getModules>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getModules>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetModulesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const performChecksOnUserModule = (userModuleCheck: UserModuleCheck) => {
  return customInstance<UserModuleDto[]>({
    url: `/api/security/module/user`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userModuleCheck,
  });
};

export const getPerformChecksOnUserModuleMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof performChecksOnUserModule>>,
    TError,
    { data: UserModuleCheck },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof performChecksOnUserModule>>,
  TError,
  { data: UserModuleCheck },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof performChecksOnUserModule>>,
    { data: UserModuleCheck }
  > = (props) => {
    const { data } = props ?? {};

    return performChecksOnUserModule(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PerformChecksOnUserModuleMutationResult = NonNullable<
  Awaited<ReturnType<typeof performChecksOnUserModule>>
>;
export type PerformChecksOnUserModuleMutationBody = UserModuleCheck;
export type PerformChecksOnUserModuleMutationError = ErrorType<HttpExceptionResponse>;

export const usePerformChecksOnUserModule = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof performChecksOnUserModule>>,
    TError,
    { data: UserModuleCheck },
    TContext
  >;
}) => {
  const mutationOptions = getPerformChecksOnUserModuleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getPolicies = (signal?: AbortSignal) => {
  return customInstance<SecurityPolicyDto[]>({ url: `/api/security/policy`, method: 'GET', signal });
};

export const getGetPoliciesQueryKey = () => {
  return [`/api/security/policy`] as const;
};

export const getGetPoliciesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPolicies>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPolicies>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPoliciesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPolicies>>> = ({ signal }) => getPolicies(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPolicies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPoliciesQueryResult = NonNullable<Awaited<ReturnType<typeof getPolicies>>>;
export type GetPoliciesQueryError = ErrorType<HttpExceptionResponse>;

export const useGetPolicies = <
  TData = Awaited<ReturnType<typeof getPolicies>>,
  TError = ErrorType<HttpExceptionResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPolicies>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPoliciesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createPolicy = (createSecurityPolicyDto: CreateSecurityPolicyDto) => {
  return customInstance<SecurityPolicyDto>({
    url: `/api/security/policy`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createSecurityPolicyDto,
  });
};

export const getCreatePolicyMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPolicy>>,
    TError,
    { data: CreateSecurityPolicyDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPolicy>>,
  TError,
  { data: CreateSecurityPolicyDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPolicy>>, { data: CreateSecurityPolicyDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return createPolicy(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePolicyMutationResult = NonNullable<Awaited<ReturnType<typeof createPolicy>>>;
export type CreatePolicyMutationBody = CreateSecurityPolicyDto;
export type CreatePolicyMutationError = ErrorType<HttpExceptionResponse>;

export const useCreatePolicy = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPolicy>>,
    TError,
    { data: CreateSecurityPolicyDto },
    TContext
  >;
}) => {
  const mutationOptions = getCreatePolicyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const activatePolicy = (id: string) => {
  return customInstance<SecurityPolicyDto>({ url: `/api/security/policy/${id}/activate`, method: 'POST' });
};

export const getActivatePolicyMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof activatePolicy>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof activatePolicy>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof activatePolicy>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return activatePolicy(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActivatePolicyMutationResult = NonNullable<Awaited<ReturnType<typeof activatePolicy>>>;

export type ActivatePolicyMutationError = ErrorType<HttpExceptionResponse>;

export const useActivatePolicy = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof activatePolicy>>, TError, { id: string }, TContext>;
}) => {
  const mutationOptions = getActivatePolicyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getShopProducts = (params: GetShopProductsParams, signal?: AbortSignal) => {
  return customInstance<ShopProductPagination>({ url: `/api/shop/product`, method: 'GET', params, signal });
};

export const getGetShopProductsQueryKey = (params: GetShopProductsParams) => {
  return [`/api/shop/product`, ...(params ? [params] : [])] as const;
};

export const getGetShopProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getShopProducts>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetShopProductsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getShopProducts>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetShopProductsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getShopProducts>>> = ({ signal }) =>
    getShopProducts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getShopProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetShopProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getShopProducts>>>;
export type GetShopProductsQueryError = ErrorType<HttpExceptionResponse>;

export const useGetShopProducts = <
  TData = Awaited<ReturnType<typeof getShopProducts>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  params: GetShopProductsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getShopProducts>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetShopProductsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getShopProduct = (id: number, signal?: AbortSignal) => {
  return customInstance<ShopProductDto>({ url: `/api/shop/product/${id}`, method: 'GET', signal });
};

export const getGetShopProductQueryKey = (id: number) => {
  return [`/api/shop/product/${id}`] as const;
};

export const getGetShopProductQueryOptions = <
  TData = Awaited<ReturnType<typeof getShopProduct>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  id: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getShopProduct>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetShopProductQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getShopProduct>>> = ({ signal }) => getShopProduct(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getShopProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetShopProductQueryResult = NonNullable<Awaited<ReturnType<typeof getShopProduct>>>;
export type GetShopProductQueryError = ErrorType<HttpExceptionResponse>;

export const useGetShopProduct = <
  TData = Awaited<ReturnType<typeof getShopProduct>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  id: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getShopProduct>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetShopProductQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const shopCheckout = (checkoutItemDto: CheckoutItemDto[]) => {
  return customInstance<ShopCheckoutDto>({
    url: `/api/shop/checkout`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: checkoutItemDto,
  });
};

export const getShopCheckoutMutationOptions = <
  TError = ErrorType<HttpExceptionResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopCheckout>>,
    TError,
    { data: CheckoutItemDto[] },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof shopCheckout>>, TError, { data: CheckoutItemDto[] }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof shopCheckout>>, { data: CheckoutItemDto[] }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return shopCheckout(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ShopCheckoutMutationResult = NonNullable<Awaited<ReturnType<typeof shopCheckout>>>;
export type ShopCheckoutMutationBody = CheckoutItemDto[];
export type ShopCheckoutMutationError = ErrorType<HttpExceptionResponse>;

export const useShopCheckout = <TError = ErrorType<HttpExceptionResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopCheckout>>,
    TError,
    { data: CheckoutItemDto[] },
    TContext
  >;
}) => {
  const mutationOptions = getShopCheckoutMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getShopOrder = (orderId: number, signal?: AbortSignal) => {
  return customInstance<ShopOrderDto>({ url: `/api/shop/order/${orderId}`, method: 'GET', signal });
};

export const getGetShopOrderQueryKey = (orderId: number) => {
  return [`/api/shop/order/${orderId}`] as const;
};

export const getGetShopOrderQueryOptions = <
  TData = Awaited<ReturnType<typeof getShopOrder>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  orderId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getShopOrder>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetShopOrderQueryKey(orderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getShopOrder>>> = ({ signal }) =>
    getShopOrder(orderId, signal);

  return { queryKey, queryFn, enabled: !!orderId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getShopOrder>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetShopOrderQueryResult = NonNullable<Awaited<ReturnType<typeof getShopOrder>>>;
export type GetShopOrderQueryError = ErrorType<HttpExceptionResponse>;

export const useGetShopOrder = <
  TData = Awaited<ReturnType<typeof getShopOrder>>,
  TError = ErrorType<HttpExceptionResponse>,
>(
  orderId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getShopOrder>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetShopOrderQueryOptions(orderId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
