import React from 'react';

export default function ShieldIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
      <title>privacy</title>
      <g fill="currentColor">
        <path d="M16,11a2,2,0,0,0-2,2v2h4V13A2,2,0,0,0,16,11Z" fill="currentColor"></path>
        <path
          d="M28.243,4.03l-12-3a.976.976,0,0,0-.486,0l-12,3A1,1,0,0,0,3,5V19a13,13,0,0,0,26,0V5A1,1,0,0,0,28.243,4.03ZM22,23a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h1V13a4,4,0,0,1,8,0v2h1a1,1,0,0,1,1,1Z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
}
