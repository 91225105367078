import { getNotifications, useGetNotificationsCount, useMakeNotificationsViewed } from "@/api/endpoints/fanalyst";
import { ScrollContainer } from "@/components/ScrollContainer";
import { ConditionalNotification } from "@/components/notification/NotificationList";
import { ActivityIndicatorFullScreen } from "@/components/ui/ActivityIndicatorFullScreen";
import MenuHeader from "@/components/ui/header/MenuHeader";
import { useMdBreakpoint } from "@/hooks/tailwind";
import routes from "@/router/routes";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import AuthenticatedLayout from "../components/layout/AuthenticatedLayout";
import { HeaderNavigation } from "../components/ui/header/HeaderNavigation";

export default function Notifications() {
  const { ref, inView } = useInView();
  const { refetch } = useGetNotificationsCount({
    query: {
      queryKey: "getNotificationsCount",
    },
  });

  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchNotification,
  } = useInfiniteQuery(
    ["pagination-notifications"],
    async ({ pageParam = 0 }) => getNotifications({ perPage: 15, page: pageParam }),
    {
      getPreviousPageParam: firstPage =>
        (firstPage._pagination?.currentPage !== undefined && firstPage._pagination?.currentPage - 1) ?? undefined,
      getNextPageParam: lastPage =>
        (lastPage._pagination?.currentPage !== undefined && lastPage._pagination?.currentPage + 1) ?? undefined,
    },
  );

  useEffect(() => {
    if (
      inView &&
      data &&
      (data.pages[data.pages.length - 1]._pagination?.currentPage ?? 0) <
        (data.pages[data.pages.length - 1]._pagination?.pages ?? 0)
    ) {
      fetchNextPage();
    }
  }, [inView]);

  const { mutateAsync: markNotificationsViewed } = useMakeNotificationsViewed();
  const md = useMdBreakpoint();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    refetch();

    const countdown = setTimeout(() => {
      markNotificationsViewed().then(() => refetch());
    }, 1000);

    return () => clearTimeout(countdown);
  }, [data, refetch, markNotificationsViewed]);

  return (
    <AuthenticatedLayout
      headerComponent={md ? <MenuHeader routes={routes} /> : <HeaderNavigation title="Notifications" />}
    >
      <ScrollContainer>
        {isLoading ? (
          <ActivityIndicatorFullScreen />
        ) : data?.pages.length === 1 && data?.pages[0].items && data?.pages[0].items.length === 0 ? (
          <div className="flex flex-col items-center justify-center p-4">
            <p className="text-white/60">No notifications</p>
          </div>
        ) : (
          <div className="flex flex-col divide-y divide-white/10">
            {data?.pages.map(
              page =>
                page.items &&
                page.items.map(notification => (
                  <ConditionalNotification
                    key={notification.id}
                    notification={notification}
                    selected={(searchParams.get("notificationId") ?? null) === notification.id}
                    refetch={refetchNotification}
                  />
                )),
            )}
            <span ref={ref} className="flex w-full px-2 py-4 text-center opacity-50">
              {isFetchingNextPage && "Loading more..."}
            </span>
          </div>
        )}
      </ScrollContainer>
    </AuthenticatedLayout>
  );
}
