import { AxiosError } from 'axios';
import isArray from 'lodash-es/isArray';

export function getFirstErrorMessageFromAxios(e: any, defaultMessage: string) {
  if (e instanceof AxiosError && e.isAxiosError && e.response && e.response.status >= 400 && e.response.status < 500) {
    const responseData = e.response.data;

    if (responseData.message && isArray(responseData.message) && responseData.message.length > 0) {
      return responseData.message[0];
    }

    if (responseData.message && responseData.message.length > 0) {
      return responseData.message;
    }
  }

  return defaultMessage;
}
