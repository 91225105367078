import React from 'react';
import { useAccount, useConnect } from 'wagmi';

export function Connectors({ onConnectorClick }: { onConnectorClick?: (connector: any) => void }) {
  const { connectors } = useConnect();

  return (
    <div className="grid grid-flow-col grid-rows-2 gap-4 md:grid-cols-1 md:grid-rows-4">
      {connectors
        .filter((connector) => connector.ready)
        .map((connector) => (
          <button
            className="border border-white p-4 font-bold"
            disabled={!connector.ready}
            key={connector.id}
            onClick={() => onConnectorClick && onConnectorClick(connector)}
          >
            {connector.id !== 'injected' && (
              <div className="mb-2">
                <img className="mx-auto h-10" src={`/logo/${connector.id}.png`} alt="" />
              </div>
            )}
            {connector.name}
          </button>
        ))}
    </div>
  );
}

export function Connect({ children }: { children: React.ReactNode }) {
  const { isConnected } = useAccount();
  const { connect, error, isLoading, pendingConnector } = useConnect();

  if (isConnected) {
    return <>{children}</>;
  }

  return (
    <div className="mx-6 text-center">
      <h1>CONNECT WALLET</h1>
      <p className="mb-4">Connect with one of the providers below</p>
      {error && <p className="mb-2 text-red-500">{error.message}</p>}
      {isLoading && pendingConnector && <p className="mb-2">Connecting to {pendingConnector.name}...</p>}
      <div className="grid grid-flow-col grid-rows-2 gap-2">
        <Connectors onConnectorClick={(connector) => connect({ connector })} />
      </div>
    </div>
  );
}
