import { classNames } from '@/lib/util/classNames';
import { FC } from 'react';
import { ActivityIndicator } from '../ui/ActivityIndicator';

type Props = {
  loading?: boolean;
  size?: string;
  imageUrl: string | undefined;
  defaultText?: string;
  className?: string;
};

export const Avatar: FC<Props> = ({ loading, imageUrl, defaultText = '', className, size = 'h-44 w-44' }) => {
  if (loading) {
    return (
      <div
        className={classNames(
          'flex items-center justify-center rounded-full border-2 border-dashed border-gray-400',
          size,
          className,
        )}
      >
        <ActivityIndicator />
      </div>
    );
  }

  if (imageUrl) {
    return (
      <img
        alt="profile"
        src={imageUrl}
        className={classNames('rounded-full border-2 border-white object-cover', size, className)}
      />
    );
  }

  return (
    <div
      className={classNames(
        'flex items-center justify-center rounded-full border-2 border-dashed border-gray-400',
        size,
        className,
      )}
    >
      <p className="font-bold">{defaultText}</p>
    </div>
  );
};
