import { ImageVideoBackground } from '@/components/ui/ImageVideoBackground';
import { useBrowserProvider } from '@/lib/providers/browser.provider';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

export default function LoginLayout() {
  const { isSafari } = useBrowserProvider();
  const data = useSelector((state: RootState) => state.background?.imageVideoBackgroundProps);

  return (
    <div className="h-full">
      {data && (
        <div className="absolute inset-0 z-0 h-[100vh] w-[100vw]">
          <div className={`absolute h-full w-full bg-gradient-to-t from-black via-black via-30% to-transparent`} />
          <ImageVideoBackground {...data} muted={isSafari} />
        </div>
      )}
      <div className="fixed z-10 h-full w-full">
        <Outlet />
      </div>
    </div>
  );
}
