import { Notification } from '@/api/model';
import { BaseNotification } from '@/components/notification/BaseNotification';

export default function NotificationFriendRequestAccepted({
  notification,
  selected,
}: {
  notification: Notification;
  selected: boolean;
}) {
  return (
    <BaseNotification notification={notification} selected={selected}>
      <p className="text-sm font-bold capitalize md:text-base">{notification.payload.username}</p>
      <p className="text-sm text-white/80 md:text-base">You are now friends</p>
    </BaseNotification>
  );
}
