import React, { forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { ChangeHandler, RefCallBack } from 'react-hook-form/dist/types/form';

export const Checkbox = forwardRef<
  HTMLInputElement,
  {
    onChange?: ChangeHandler;
    onBlur?: ChangeHandler;
    name?: string;
    label?: string;
    ref: RefCallBack;
    errors?: FieldErrors<{ [key: string]: string }>;
  } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>(({ name, label, onChange, onBlur, ...props }, ref) => (
  <label className="relative flex cursor-pointer items-center">
    <input
      aria-describedby="comments-description"
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      type="checkbox"
      {...props}
      ref={ref}
      className="mr-2 h-6 w-6 rounded border border-white bg-black text-indigo-600 accent-black"
    />
    {label}
  </label>
));
