import { RootState } from '@/store/store';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStream } from '../../../lib/providers/stream.provider';
import { BaseTimer, DefaultTimer, SynchronisedTimer } from '../../../lib/timers/synchronisedTimer';
import { findClosestIndex } from './CountdownUtil';

const paths10 = [
  'M43.8815 16.3472C43.5807 15.884 43.7122 15.2641 44.1785 14.9682C57.0678 6.79012 71.744 1.85092 86.956 0.572123C87.506 0.525923 87.985 0.940123 88.026 1.49092L88.581 9.04422C88.621 9.59502 88.207 10.0737 87.657 10.1207C74.054 11.2805 60.9294 15.6974 49.393 22.9979C48.9263 23.2932 48.3072 23.1621 48.0064 22.699L43.8815 16.3472Z',
  'M8.53359 61.738C7.42449 61.312 6.86819 60.0665 7.31909 58.9674C12.8166 45.5644 21.3181 33.6017 32.1677 24.0021C33.0574 23.2149 34.4164 23.3306 35.1832 24.238L38.5858 28.2644C39.3526 29.1718 39.2366 30.527 38.3489 31.3165C28.7287 39.8723 21.1755 50.5006 16.2594 62.399C15.8058 63.497 14.5641 64.053 13.455 63.627L8.53359 61.738Z',
  'M5.54289 118.824C4.39539 119.131 3.21339 118.451 2.93209 117.296C-0.498413 103.222 -0.652113 88.5471 2.48289 74.4031C2.73999 73.2431 3.90749 72.5381 5.06119 72.8221L10.1806 74.0791C11.3343 74.3621 12.037 75.5271 11.7829 76.6881C9.02899 89.2641 9.16549 102.302 12.1822 114.818C12.4605 115.973 11.7824 117.152 10.6349 117.459L5.54289 118.824Z',
  'M35.4301 165.739C34.6665 166.649 33.3078 166.77 32.4154 165.985C21.5324 156.424 12.9892 144.491 7.4449 131.107C6.9902 130.01 7.5421 128.762 8.6497 128.333L13.5645 126.426C14.6721 125.997 15.9157 126.548 16.3732 127.644C21.3308 139.526 28.921 150.127 38.571 158.65C39.4615 159.436 39.5822 160.791 38.8186 161.701L35.4301 165.739Z',
  'M90.045 187.252C89.983 188.438 88.971 189.352 87.786 189.263C73.34 188.176 59.3358 183.788 46.8534 176.436C45.8298 175.833 45.5199 174.504 46.1459 173.495L48.9238 169.014C49.5498 168.005 50.8745 167.696 51.8997 168.297C63.01 174.802 75.452 178.701 88.287 179.7C89.472 179.792 90.383 180.801 90.321 181.988L90.045 187.252Z',
  'M145.261 172.457C145.908 173.453 145.626 174.787 144.615 175.412C132.29 183.024 118.38 187.705 103.96 189.094C102.778 189.208 101.746 188.315 101.659 187.13L101.273 181.873C101.186 180.688 102.077 179.66 103.259 179.543C116.071 178.276 128.429 174.117 139.4 167.38C140.412 166.759 141.743 167.039 142.39 168.036L145.261 172.457Z',
  'M181.235 128.031C182.344 128.457 182.9 129.703 182.449 130.802C176.952 144.205 168.45 156.167 157.601 165.767C156.711 166.554 155.352 166.439 154.585 165.531L151.183 161.505C150.416 160.597 150.532 159.242 151.42 158.453C161.04 149.897 168.593 139.269 173.509 127.37C173.963 126.272 175.204 125.717 176.314 126.142L181.235 128.031Z',
  'M184.632 72.5081C185.785 72.2211 186.955 72.9221 187.216 74.0811C190.4 88.2131 190.298 102.889 186.917 116.975C186.639 118.131 185.46 118.815 184.311 118.512L179.214 117.165C178.066 116.862 177.384 115.685 177.658 114.529C180.631 102.003 180.722 88.9641 177.924 76.3981C177.666 75.2381 178.365 74.0711 179.517 73.7841L184.632 72.5081Z',
  'M154.34 24.0301C155.104 23.1201 156.463 22.9996 157.355 23.7837C168.238 33.3453 176.781 45.2783 182.326 58.6621C182.78 59.7596 182.228 61.0071 181.121 61.4361L176.206 63.3431C175.098 63.7721 173.855 63.2211 173.397 62.1251C168.44 50.2437 160.849 39.6418 151.199 31.1196C150.309 30.3332 150.188 28.9784 150.952 28.0684L154.34 24.0301Z',
  'M101.336 2.61591C101.419 1.43081 102.448 0.534808 103.63 0.644608C118.055 1.98321 131.981 6.61571 144.333 14.1845C145.346 14.8052 145.633 16.1387 144.989 17.1373L142.133 21.5684C141.49 22.567 140.16 22.8522 139.145 22.2341C128.151 15.5358 115.779 11.4201 102.962 10.1976C101.78 10.0848 100.886 9.05981 100.969 7.87471L101.336 2.61591Z',
];

export function Countdown10({ projectedEndUTC }: { projectedEndUTC: number }) {
  const { colors } = useSelector((state: RootState) => state.config);
  const { stream } = useStream();
  const total = paths10.length;
  const [current, setCurrent] = useState<number>(total);
  const [closestIndex, setClosestIndex] = useState<number>(0);

  useEffect(() => {
    let currentTimer: BaseTimer;
    if (stream.timeSync) {
      currentTimer = new SynchronisedTimer(stream.timeSync);
    } else {
      console.warn('Using default timer instead of synchronised timer');
      currentTimer = new DefaultTimer();
    }

    const timer = currentTimer.generateStepsUntil(projectedEndUTC);

    const update = (progress: number) => {
      setCurrent(progress);
      const index = findClosestIndex(progress, total, paths10.length);
      setClosestIndex(index);
    };

    timer.on('start', (data) => {
      update(data.progress);
    });
    timer.on('cycle', (data) => {
      update(data.progress);
    });
    timer.on('stop', (data) => {
      update(data.progress);
    });
    timer.start();

    return () => {
      console.info('timer effect stop');
      timer.stop();
    };
  }, [projectedEndUTC, setCurrent, stream.timeSync, total]);

  return (
    <div className="relative flex flex-col p-3">
      <motion.h1
        id={`countdown-${current}`}
        key={`countdown-${current}`}
        initial={{
          scale: 0.5,
          translateX: '-50%',
          translateY: '-50%',
        }}
        animate={{
          scale: 1,
        }}
        transition={{ type: 'spring', restSpeed: 0.4, restDelta: 0.5 }}
        className="absolute left-1/2 top-1/2 origin-bottom font-lemonmilk text-5xl"
      >
        {current}
      </motion.h1>
      <svg
        className="mx-auto"
        width="100px"
        height="100px"
        viewBox="0 0 190 190"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {paths10.map((path, index) => (
          <path
            key={index}
            d={path}
            fill={index >= closestIndex ? '#FFF' : colors.ringColor}
            opacity={index >= closestIndex ? '0.25' : '1'}
          />
        ))}
      </svg>
    </div>
  );
}
