import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Card } from '../../../api/model';
import { classNames } from '../../../lib/util/classNames';

export default function PaymentCardSelect({
  cards,
  selectedCard,
  setSelectedCard,
}: {
  cards: Card[];
  selectedCard: Card;
  setSelectedCard: (card: Card) => void;
}) {
  return (
    <RadioGroup value={selectedCard} onChange={setSelectedCard}>
      <div className="mt-2 grid grid-cols-1 gap-y-2">
        {cards.map((card) => (
          <RadioGroup.Option
            key={card.id}
            value={card}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-primary' : '',
                'relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none',
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block font-bold">
                      {card.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center font-mono text-sm">
                      XXXX XXXX XXXX {card.last4}
                    </RadioGroup.Description>
                    <p className="mt-3 text-xs font-bold">EXPIRES</p>
                    <RadioGroup.Description as="span" className="mt-1 font-mono text-xs">
                      {card.expMonth} / {card.expYear}
                    </RadioGroup.Description>
                  </span>
                </span>
                {checked ? <CheckCircleIcon className="h-5 w-5 text-primary" aria-hidden="true" /> : null}
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-primary' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg',
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
