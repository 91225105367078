import DolbyPublishUserMedia from './DolbyPublishUserMedia';
import React, { useEffect, useState } from 'react';
import { DeviceInputSelector } from './DeviceInputSelector';

export type FacingMode = 'user' | 'environment' | 'unknown' | undefined;

export default function DolbyPublisherDeviceInputSelector({
  kind,
  publisher,
  onUpdateMediaStream,
}: {
  kind: 'video' | 'audio';
  publisher: DolbyPublishUserMedia;
  onUpdateMediaStream?: (stream: MediaStream) => void;
}) {
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>();

  useEffect(() => {
    if (selectedDeviceId === undefined) {
      console.log('selectedDeviceId is undefined');
      return;
    }

    publisher
      .updateMediaStream(kind, selectedDeviceId)
      .then((stream) => {
        if (onUpdateMediaStream) {
          console.log('updating media stream');
          onUpdateMediaStream(stream);
        }
        // check if the device is still selected
        if (selectedDeviceId === publisher.mediaManager.getDeviceId(kind)) {
          console.log('updating selectedDeviceId');
          setSelectedDeviceId(selectedDeviceId);
        } else {
          console.log('selectedDeviceId changed');
        }
      })
      .catch((e) => console.error('publisher.updateMediaStream', e));
  }, [selectedDeviceId, publisher]);

  return (
    <DeviceInputSelector
      kind={`${kind}input`}
      publisher={publisher}
      onChange={(value) => setSelectedDeviceId(value)}
      selectedDeviceId={selectedDeviceId}
    />
  );
}
