import { resetPassword } from '@/api/endpoints/fanalyst';
import { ActivityIndicatorFullScreen } from '@/components/ui/ActivityIndicatorFullScreen';
import { BackHeader } from '@/components/ui/header/BackHeader';
import { Dispatch } from '@/store/store';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BaseLayout from '../../../../components/layout/BaseLayout';
import ContentContainer from '../../../../components/ui/ContentContainer';
import Input from '../../../../components/ui/Input';
import SubTitle from '../../../../components/ui/SubTitle';
import Title from '../../../../components/ui/Title';

interface ResetWithTokenForm {
  password: string;
}

function translateError(error: string) {
  switch (error) {
    case 'INVALID_TOKEN':
      return 'Token is invalid';
    case 'TOKEN_EXPIRED':
      return 'Token is expired, please request a new one';
    default:
      return 'Unknown error occurred';
  }
}
export function ResetWithToken() {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from') || '/';
  const { alert } = useDispatch<Dispatch>();
  const { register, handleSubmit, formState } = useForm<ResetWithTokenForm>({
    defaultValues: {
      password: '',
    },
  });
  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler<ResetWithTokenForm> = async (data) => {
    try {
      const session = await resetPassword({
        ...data,
        token: searchParams.get('token') ?? '',
      });
      dispatch.auth.setSession(session);
      navigate({
        pathname: from,
      });
    } catch (err: any) {
      alert.set(translateError(err?.response?.data?.message ?? err?.message ?? 'UNKNOWN_ERROR'));
    }
  };

  return (
    <BaseLayout
      headerComponent={<BackHeader backUrl={`/login/password?from=${encodeURIComponent(from)}`} homeUrl="/" />}
    >
      <ContentContainer className="flex h-full w-full flex-col items-center justify-center gap-8">
        <div>
          <Title>New password</Title>
          <SubTitle>Enter your new password</SubTitle>
        </div>
        {isSubmitting && <ActivityIndicatorFullScreen />}
        <form onSubmit={handleSubmit(onSubmit)} className="relative flex flex-col gap-4 px-2">
          <Input
            data-cy="password"
            required
            type="password"
            minLength={3}
            placeholder="Password"
            {...register('password', {
              required: 'Please enter new password.',
            })}
          />
          <button type="submit" className="btn btn-primary uppercase" data-cy="reset-button">
            Set new password
          </button>
        </form>
      </ContentContainer>
    </BaseLayout>
  );
}
