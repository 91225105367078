import { classNames } from '@/lib/util/classNames';
import { useRef } from 'react';
import { NavLink } from 'react-router-dom';

export interface TabBarItemInterface {
  title: string; // Title for the tab bar
  link: string; // This is the route where will be naviagted to
  routerLink?: string; // This where the router will match the path
}

export function TabBarItem({
  title,
  link,
  tooltipPlacement,
  showRouteIndicator = false,
  opacityWhenNotActive = false,
}: {
  title: string;
  link: string;
  tooltipPlacement?: 'top' | 'bottom';
  showRouteIndicator?: boolean;
  opacityWhenNotActive?: boolean;
}) {
  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <NavLink
      ref={ref}
      to={link}
      className={({ isActive }) =>
        classNames(
          'relative pb-2 pt-2 text-center text-[0.8rem] font-bold text-white transition-all',
          isActive
            ? 'sm:border-opacity-100 sm:hover:border-opacity-100'
            : opacityWhenNotActive
            ? 'opacity-30 hover:opacity-100'
            : '',
          tooltipPlacement === 'top' ? 'pb-2' : 'pt-2',
          showRouteIndicator
            ? 'border-b-4 border-b-primary border-opacity-0 p-2 hover:border-opacity-50 md:border-b-0'
            : '',
        )
      }
      end={true}
      data-cy={link}
    >
      {({ isActive }) => (
        <>
          {showRouteIndicator && isActive && (
            <div
              style={{ boxShadow: '0px 4px 22px var(--color-navigation)' }}
              className="absolute left-0 right-0 top-full h-[5px] rounded bg-navigation"
            />
          )}
          {title}
        </>
      )}
    </NavLink>
  );
}
