import { Logger } from '@/lib/dolby/logger';
import { Dispatch } from '@/store/store';
import { BroadcastEvent, Director, MediaStreamSource, View, ViewerCount } from '@millicast/sdk';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import reducer from './viewer/reducer';
import { RemoteTrackSources, Viewer, ViewerActionType, ViewerProps, ViewerState } from './viewer/types';
import { addRemoteTrack, unprojectFromStream } from './viewer/utils';

const useViewer = ({ handleError, streamAccountId, streamName, subscriberToken, mode }: ViewerProps): Viewer => {
  const logger = new Logger('useViewer');
  const viewerRef = useRef<View>();

  const globalStateDispatch = useDispatch<Dispatch>();
  const [remoteTrackSources, dispatch] = useReducer(reducer, new Map() as RemoteTrackSources);

  const remoteTrackSourcesRef = useRef<RemoteTrackSources>();
  remoteTrackSourcesRef.current = remoteTrackSources;

  const [currentState, setCurrentState] = useState<ViewerState>({ connecting: false, connected: false, error: false });

  // Use this to keep track of the quantity of concurrent active event handlers
  const activeEventCounterRef = useRef(0);
  const [hostMediaStream, setHostMediaStream] = useState<MediaStream>();
  const [doxMediaStream, setDoxMediaStream] = useState<MediaStream>();
  const [viewerCount, setViewerCount] = useState<number>(0);

  const handleInternalError = (error: unknown) => {
    if (error instanceof Error) {
      handleError?.(error.message);
    } else {
      handleError?.(`${error}`);
    }
  };

  const connect = async () => {
    const { current: viewer } = viewerRef;

    if (!viewer) {
      return;
    }

    try {
      setCurrentState({ connected: false, connecting: true, error: false });
      logger.log('connecting to viewer');
      await viewer.connect({ events: ['active', 'inactive', 'layers', 'viewercount'] });
      logger.log('connected to viewer');
      setCurrentState({ connected: true, connecting: false, error: false });
    } catch {
      try {
        logger.log('reconnecting to viewer');
        await viewer.reconnect();
        logger.log('reconnected to viewer');
        setCurrentState({ connected: true, connecting: false, error: false });
      } catch (error) {
        setCurrentState({ connected: false, connecting: false, error: true });
        handleInternalError(error);
        logger.error('error reconnecting to viewer', error);
      }
    }
  };

  const handleBroadcastEvent = async (event: BroadcastEvent) => {
    logger.log('handleBroadcastEvent', event);
    const { current: viewer } = viewerRef;

    if (!viewer) {
      logger.error('viewer is not defined, cannot handle broadcast event');
      return;
    }

    const { tracks } = event.data as MediaStreamSource;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sourceId = event.data?.sourceId || new Date().getTime().toString();

    logger.log('sourceId', sourceId);
    logger.log('tracks', tracks);

    switch (event.name) {
      case 'active':
        logger.log('active', event);

        try {
          activeEventCounterRef.current = activeEventCounterRef.current + 1;
          logger.log('activeEventCounterRef.current', activeEventCounterRef.current);

          if (sourceId.includes('dox') && mode === 'dox') {
            // If the dox stream is active, and we are in dox mode, then we should not project it
            // Since the user is already seeing the dox stream as they are broadcasting it
            logger.log('dox stream is active, and we are in dox mode, not projecting');
            return;
          }

          if (sourceId.includes('host') && mode === 'host') {
            // If the host stream is active, and we are in host mode, then we should not project it
            // Since the user is already seeing the host stream as they are broadcasting it
            logger.log('host stream is active, and we are in host mode, not projecting');
            return;
          }

          logger.time('addRemoteTrack');
          logger.log('adding remote track');
          const newRemoteTrackSource = await addRemoteTrack(viewer, sourceId, tracks);
          logger.log('added remote track', newRemoteTrackSource);
          logger.timeEnd('addRemoteTrack');

          dispatch({ remoteTrackSource: newRemoteTrackSource, sourceId, type: ViewerActionType.ADD_SOURCE });

          try {
            // Project to main stream if there are currently no remote tracks and it is the first active event
            if (sourceId.includes('host')) {
              logger.log('projecting to main stream');
              await viewer.project(sourceId, newRemoteTrackSource.projectMapping);
              setHostMediaStream(newRemoteTrackSource.mediaStream);
              globalStateDispatch.game.SET_HOST_STREAMING(true);
            } else if (sourceId.includes('dox')) {
              logger.log('projecting to secondary stream');
              await viewer.project(sourceId, newRemoteTrackSource.projectMapping);
              setDoxMediaStream(newRemoteTrackSource.mediaStream);
            } else {
              logger.error('Unknown sourceId', sourceId);
            }
          } catch (error) {
            logger.error('error projecting', {
              sourceId,
              newRemoteTrackSource,
              error,
            });
            handleInternalError(error);
          }
        } catch (error) {
          logger.error('error adding remote track', error);
          handleInternalError(error);
        } finally {
          activeEventCounterRef.current = activeEventCounterRef.current - 1;
          logger.log('activeEventCounterRef.current', activeEventCounterRef.current);
        }

        break;

      case 'inactive': {
        const remoteTrackSource = remoteTrackSourcesRef.current?.get(sourceId);

        if (remoteTrackSource) {
          try {
            dispatch({ sourceId, type: ViewerActionType.REMOVE_SOURCE });
            await unprojectFromStream(viewer, remoteTrackSource);

            if (sourceId.includes('host')) {
              setHostMediaStream(undefined);
              globalStateDispatch.game.SET_HOST_STREAMING(false);
            }
            if (sourceId.includes('dox')) {
              setDoxMediaStream(undefined);
            }
          } catch (error) {
            handleInternalError(error);
          }
        }

        break;
      }

      case 'viewercount':
        setViewerCount((event.data as ViewerCount).viewercount);
        break;

      case 'layers': {
        logger.log('layers', event.data);
        break;
      }
    }
    return;
  };

  const startViewer = async () => {
    if (!viewerRef.current) {
      try {
        const newViewer = new View(streamName, tokenGenerator);

        logger.log('startViewer', newViewer);

        newViewer.on('broadcastEvent', handleBroadcastEvent);
        // newViewer.on("connectionStateChange", handleConnectionStateChange);
        // newViewer.on("track", handleTrack);

        viewerRef.current = newViewer;

        await connect();
        logger.warn('Viewer connected');
      } catch (error) {
        handleInternalError(error);
      }
    } else {
      // Should not happen since we clean up on unmount
      logger.error('Viewer already started');
    }
  };

  const stopViewer = () => {
    const { current: viewer } = viewerRef;

    logger.log('stopViewer', viewer);
    if (viewer) {
      viewer.removeAllListeners('broadcastEvent');
      viewer.webRTCPeer?.removeAllListeners('stats');
      viewer.webRTCPeer?.stopStats();
      viewer.stop();
      viewerRef.current = undefined;
    }
  };

  const tokenGenerator = () => Director.getSubscriber({ streamAccountId, streamName, subscriberToken });

  // clean-up on unmount
  useEffect(() => {
    // cleanup
    return () => {
      stopViewer();
    };
  }, [viewerRef]);

  return {
    hostMediaStream,
    doxMediaStream,
    currentState,
    startViewer,
    stopViewer,

    // not used by app
    viewerCount,
    remoteTrackSources,
  };
};

export default useViewer;
