import { generateImageUrl } from '@/store/models/auth';
import { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { VideoSource, VideoSourceOptions, VideoState } from '../game/video/VideoSource';

export interface ImageVideoBackgroundProps {
  portal?: boolean;
  fillScreen?: boolean;
  desktopImageId: string;
  mobileImageId: string;
  desktopVideoUrl: string;
  mobileVideoUrl: string;
  muted?: boolean;
}

export function ImageVideoBackground({
  desktopImageId,
  mobileImageId,
  desktopVideoUrl,
  mobileVideoUrl,
  portal = true,
  fillScreen,
  muted = false,
}: ImageVideoBackgroundProps) {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const onVideoUpdate = (videoState: VideoState) => {
    setVideoLoaded(videoState.isLoaded);
  };

  const canShowImage = useMemo(() => {
    return !videoLoaded;
  }, [videoLoaded]);

  return (
    <div
      className={twMerge(
        'pointer-events-none absolute -z-10 flex w-full select-none items-center justify-center overflow-hidden',
        fillScreen ? 'h-screen' : 'h-full',
      )}
    >
      <VideoSource
        portal={portal}
        source={{
          file: mobileVideoUrl,
          fullFile: desktopVideoUrl,
          type: VideoSourceOptions.FILE,
          loop: true,
        }}
        onUpdate={onVideoUpdate}
        alwaysMuted={muted}
        inGame={false}
      />
      {canShowImage && (
        <img
          className="hidden w-full object-cover h-screen md:flex"
          alt=""
          src={generateImageUrl(desktopImageId, 'public')}
        />
      )}
      {canShowImage && (
        <img
          className={twMerge('flex w-full object-cover h-screen md:hidden')}
          alt=""
          src={generateImageUrl(mobileImageId, 'public')}
        />
      )}
    </div>
  );
}
