export class Logger {
  constructor(private readonly name: string) {}

  private timemap: Record<string, number> = {};

  private toHead(type: 'log' | 'warn' | 'error') {
    if (type === 'error') {
      return `[${this.name}(ERR)]`;
    }
    return `[${this.name}(${type.toUpperCase()})]`;
  }

  private baseLog(type: 'log' | 'warn' | 'error', ...args: unknown[]) {
    const head = this.toHead(type);
    console[type](head, ...args);
    if (type === 'error' || type === 'warn') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.APM) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.APM.captureError(new Error([head, ...args.map((arg) => JSON.stringify(arg))].join(' ')));
        console[type](head, 'Pushed error to APM');
      }
    }
  }

  public time(identifier: string) {
    this.timemap[identifier] = Date.now();
  }

  public timeEnd(identifier: string) {
    const start = this.timemap[identifier];
    if (start) {
      const end = Date.now();
      this.baseLog('log', `${identifier} took ${end - start}ms`);
    }
  }

  public warn(...args: unknown[]) {
    this.baseLog('warn', ...args);
  }

  public log(...args: unknown[]) {
    this.baseLog('log', ...args);
  }

  public error(...args: unknown[]) {
    this.baseLog('error', ...args);
  }

  public timeout(message: string, ms: number) {
    try {
      let timeoutReached = false;
      const timeoutStarted = Date.now();
      const timeoutId = setTimeout(() => {
        this.error('Timeout reached', message);
        timeoutReached = true;
      }, ms);

      return () => {
        if (timeoutReached) {
          this.error(`Timeout reached, but eventually cleared after ${Date.now() - timeoutStarted}ms`, message);
        }
        clearTimeout(timeoutId);
      };
    } catch (error) {
      this.error('Error creating timeout', error);
      // Return a noop function
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
  }
}
