import { adminGetGame } from '@/api/endpoints/fanalyst';
import { FullGame, FullGroupDto, UserListItem } from '@/api/model';
import { createModel } from '@rematch/core';
import { RootModel } from '.';

export interface AdminStoreType {
  enabled: boolean;
  game: FullGame | undefined;
  gameVisible: boolean;
  user: UserListItem | undefined;
  userVisible: boolean;
  group: FullGroupDto | undefined;
  groupVisible: boolean;
  editMode: boolean;
  loading: boolean;
  policyVisible: boolean;
  duplicateMode: boolean;
}

export const admin = createModel<RootModel>()({
  state: {
    enabled: false,
  } as AdminStoreType,
  reducers: {
    SET_ADMIN_STATE: (state, enabled: boolean): AdminStoreType => ({
      ...state,
      enabled,
    }),
    EDIT_GAME: (state, game: FullGame): AdminStoreType => ({
      ...state,
      gameVisible: true,
      editMode: true,
      loading: false,
      game,
    }),
    DUPLICATE_GAME: (state, game: FullGame): AdminStoreType => ({
      ...state,
      gameVisible: true,
      duplicateMode: true,
      loading: false,
      game,
    }),
    EDIT_USER: (state, user: UserListItem): AdminStoreType => ({
      ...state,
      userVisible: true,
      editMode: true,
      loading: false,
      user,
    }),
    EDIT_GROUP: (state, group: FullGroupDto): AdminStoreType => ({
      ...state,
      groupVisible: true,
      editMode: true,
      loading: false,
      group,
    }),
    DUPLICATE_LOADER: (state): AdminStoreType => ({
      ...state,
      gameVisible: true,
      duplicateMode: true,
      loading: true,
    }),
    EDIT_LOADER: (state): AdminStoreType => ({
      ...state,
      gameVisible: true,
      editMode: true,
      loading: true,
    }),
    CREATE_GAME: (state): AdminStoreType => ({
      ...state,
      gameVisible: true,
      editMode: false,
      game: undefined,
    }),
    CREATE_USER: (state): AdminStoreType => ({
      ...state,
      userVisible: true,
      editMode: false,
      user: undefined,
    }),
    CREATE_GROUP: (state): AdminStoreType => ({
      ...state,
      groupVisible: true,
      editMode: false,
      group: undefined,
    }),
    CREATE_POLICY: (state): AdminStoreType => ({
      ...state,
      policyVisible: true,
      editMode: false,
    }),
    CLOSE: (state): AdminStoreType => ({
      ...state,
      gameVisible: false,
      userVisible: false,
      groupVisible: false,
      policyVisible: false,
      editMode: false,
      game: undefined,
      user: undefined,
    }),
  },
  effects: (dispatch) => ({
    editGame: async (gameId: string) => {
      dispatch.admin.EDIT_LOADER();
      const game = await adminGetGame(gameId);
      dispatch.admin.EDIT_GAME(game);
    },
    duplicateGame: async (gameId: string) => {
      dispatch.admin.DUPLICATE_LOADER();
      const game = await adminGetGame(gameId);
      dispatch.admin.DUPLICATE_GAME(game);
    },
  }),
});
