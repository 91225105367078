import React, { FC, PropsWithChildren } from 'react';
import { classNames } from '../lib/util/classNames';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const ScrollContainer: FC<PropsWithChildren<Props>> = ({ children, style, className = '', onClick }) => {
  return (
    <div
      style={style}
      onClick={onClick}
      className={classNames(
        'relative z-10 flex flex-[1_1_auto] touch-pan-x touch-pan-y flex-col overflow-y-auto',
        'md:mx-auto md:w-full md:max-w-3xl lg:max-w-4xl',
        className,
      )}
    >
      {children}
    </div>
  );
};
