import { Modal } from '@/components/ui/Modal';
import { useSmBreakpoint } from '@/hooks/tailwind';
import { classNames } from '@/lib/util/classNames';
import { AnimatePresence, PanInfo, motion, useAnimation } from 'framer-motion';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';

type Props = {
  show: boolean;
  onClose: () => void;
  onOpen: () => void;
  className?: string;
};

function usePrevious(value: any) {
  const previousValueRef = useRef();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
}

const DraggableBottomCard: FC<PropsWithChildren<Props>> = ({ children, show, onClose, onOpen, className }) => {
  const previousIsOpen = usePrevious(show);
  const controls = useAnimation();

  const onDragEnd = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const shouldClose = info.velocity.y > 20 || (info.velocity.y >= 0 && info.point.y > 45);
    if (shouldClose) {
      controls.start('hidden');
      onClose();
    } else {
      controls.start('visible');
      onOpen();
    }
  };

  useEffect(() => {
    if (previousIsOpen && !show) {
      controls.start('hidden');
    } else if (!previousIsOpen && show) {
      controls.start('visible');
    }
  }, [controls, show, previousIsOpen]);

  return (
    <>
      <AnimatePresence>
        {show && (
          <motion.div
            onClick={() => onClose()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.4 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="absolute z-40 h-[100vh] w-[100vw] bg-black opacity-40"
          />
        )}
      </AnimatePresence>
      <motion.div
        drag="y"
        onDragEnd={onDragEnd}
        initial="hidden"
        animate={controls}
        transition={{
          type: 'spring',
          damping: 40,
          stiffness: 400,
        }}
        variants={{
          visible: { y: 0 },
          hidden: { y: '100%' },
        }}
        dragConstraints={{ top: 0 }}
        dragElastic={0.2}
        className={classNames(
          'absolute bottom-[-60px] z-50 h-fit w-full rounded-t-3xl bg-[#141414] pb-20 shadow-lg',
          className,
        )}
      >
        {children}
      </motion.div>
    </>
  );
};

export const BottomSheet: FC<PropsWithChildren<Props>> = ({ children, show, onClose, onOpen }) => {
  const isDesktop = useSmBreakpoint();

  return isDesktop ? (
    <Modal show={show} onClose={onClose}>
      <div className="-mx-6 -mt-6">{children}</div>
    </Modal>
  ) : (
    <DraggableBottomCard onClose={onClose} onOpen={onOpen} show={show}>
      {children}
    </DraggableBottomCard>
  );
};
