import { FullLogo } from '@/components/ui/logo/FullLogo';
import SmallLogo from '@/components/ui/logo/SmallLogo';

export function ResponsiveLogo() {
  return (
    <>
      <FullLogo className="hidden max-w-[200px] md:block" />
      <SmallLogo className="md:hidden" />
    </>
  );
}
