import { useSearchParams } from 'react-router-dom';
import { ResetWithEmail } from './reset/ResetWithEmail';
import { ResetWithToken } from './reset/ResetWithToken';

export default function Reset() {
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('token');

  if (resetToken) {
    return <ResetWithToken />;
  }

  return <ResetWithEmail />;
}
