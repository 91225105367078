import { TabBarItemInterface } from '@/components/ui/tab-bar/TabBarItem';

const baseRoutes = [
  {
    title: 'SHOP',
    link: '/shop',
  },
  {
    title: 'MENU',
    link: '/menu',
  },
];

const DEFAULT_ROUTES: TabBarItemInterface[] = [
  {
    title: 'GAMES',
    link: '/',
  },
  ...baseRoutes,
];

export default DEFAULT_ROUTES;
