import { classNames } from '@/lib/util/classNames';
import { RootState } from '@/store/store';
import React from 'react';
import { useSelector } from 'react-redux';

export default function SmallLogo({ ...props }: React.SVGProps<SVGSVGElement>) {
  const { logo } = useSelector((state: RootState) => state.config);

  if (logo.icon)
    return (
      <div className={classNames('max-h-[82px] max-w-[82px] px-4 py-2', props.className)}>
        <img className="object-cover" src={logo.icon} alt="Logo" />
      </div>
    );

  return (
    <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9756 0.00737067L41.556 0L40.158 5.28344C39.6975 7.02455 39.0681 9.4038 37.4695 11.2139C35.8272 13.0735 33.674 13.8653 31.3635 14.1867C31.0693 15.9677 29.8926 17.5053 28.7089 18.9681C27.1543 20.8892 25.5605 22.0723 23.2634 22.3013L23.0577 22.3218H20.3238C19.6698 25.2495 18.5242 27.8805 16.7235 30.0142C14.6341 32.49 11.8812 34.0343 8.68587 34.7028L2.48441 36L3.76323 29.7947C4.3482 26.9563 4.68376 24.5032 4.96884 22.3218H0L0.988657 17.3535C1.38656 15.3539 2.07285 12.9501 3.92807 11.1158C5.14658 9.91097 6.59445 9.20911 8.13929 8.82133C8.76917 6.56297 9.78237 4.4506 11.4057 2.82672C13.1786 1.05317 15.4197 0.126238 17.8797 0.0118289L17.9756 0.00737067ZM12.0847 10.1546C12.251 9.52508 12.4434 8.94509 12.6619 8.41465C13.0623 7.44251 13.5502 6.63682 14.1257 5.9976C15.1591 4.84977 16.4748 4.2387 18.0729 4.16437L36.1555 4.15872C35.6704 5.99205 35.2533 7.35287 34.4767 8.31628C33.8667 9.07294 33.035 9.58447 31.7743 9.88726C31.2643 10.0097 30.6841 10.0981 30.0201 10.1546H12.0847ZM22.8511 18.1648C24.1315 18.0371 25.1433 17.5584 25.8865 16.7287C26.4362 16.1151 26.839 15.3094 27.0949 14.3117C27.2299 13.7852 27.324 13.2052 27.3772 12.5717H11.2436C9.19145 12.6661 7.84368 13.1314 6.91713 14.0077C6.84637 14.0747 6.77807 14.144 6.7121 14.2157C5.87255 15.1286 5.41041 16.4327 5.06575 18.1648H22.8511ZM9.16102 22.3218C9.15932 22.3349 9.15762 22.348 9.15591 22.3611C9.01146 23.4712 8.85374 24.6833 8.6549 26.0014C8.44078 27.4208 8.17898 28.9633 7.8347 30.6338C9.65842 30.2523 11.2065 29.5125 12.4789 28.4145C13.6282 27.4226 14.5527 26.1384 15.2522 24.5619C15.5586 23.8713 15.8219 23.1246 16.042 22.3218C16.2083 21.7149 16.3501 21.0759 16.4671 20.4048H9.41706C9.3323 21.0064 9.24908 21.6451 9.16102 22.3218Z"
        fill="currentColor"
      />
    </svg>
  );
}
