import useUserAgent from '@/hooks/useUserAgent';
import * as React from 'react';
import { createContext, useContext } from 'react';

type BrowserContextType = {
  isSafari: boolean;
};

const BrowserContext = createContext<BrowserContextType | undefined>(undefined);

function BrowserProvider({ children }: { children: React.ReactNode }) {
  const { isSafari } = useUserAgent();

  return (
    <BrowserContext.Provider
      value={{
        isSafari,
      }}
    >
      {children}
    </BrowserContext.Provider>
  );
}

function useBrowserProvider() {
  const context = useContext<BrowserContextType | undefined>(BrowserContext);
  if (context === undefined) {
    throw new Error('useBrowserProvider must be used within a BrowserProvider.');
  }
  return context;
}

export { BrowserProvider, useBrowserProvider };
