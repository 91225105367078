import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ActivityIndicatorFullScreen } from '../ActivityIndicatorFullScreen';
import Input from '../Input';
import InputGroup from '../InputGroup';

const SetupForm = ({ checkoutId, cancelAction }: { checkoutId: string; cancelAction?: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm<{
    name: string;
  }>({
    shouldUseNativeValidation: true,
  });

  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const onSubmit: SubmitHandler<{
    name: string;
  }> = async (data) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/checkout/${checkoutId}?from=/subscription&to=/checkout/subscription-added`,
        payment_method_data: {
          billing_details: {
            name: data.name,
          },
        },
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setLoading(false);
      setErrorMessage(error.message ?? '');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-1 flex-col text-left md:my-4">
      {loading && <ActivityIndicatorFullScreen />}
      <InputGroup
        title="Full Name"
        inputComponent={
          <Input
            {...register('name', {
              required: 'Name is required.',
            })}
          />
        }
      />
      <PaymentElement />
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
      <button className="btn btn-primary mt-auto md:mt-2" type="submit" disabled={!stripe}>
        Add card
      </button>
      {cancelAction && (
        <button className="btn btn-secondary mt-auto safe-margin-bottom md:mt-2" onClick={cancelAction}>
          cancel
        </button>
      )}
    </form>
  );
};

export default SetupForm;
