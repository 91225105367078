import { handleGroupInvite } from "@/api/endpoints/fanalyst";
import { Notification, NotificationType } from "@/api/model";
import Check from "@/assets/icons/ui/check.svg?react";
import Cross from "@/assets/icons/ui/cross.svg?react";
import { BaseNotification } from "@/components/notification/BaseNotification";
import { getFirstErrorMessageFromAxios } from "@/lib/general/api-helper";
import { useCallback } from "react";

export default function NotificationGroupInvite({
  notification,
  selected,
  type,
  refetch,
}: {
  notification: Notification;
  selected: boolean;
  type: NotificationType;
  refetch: () => void;
}) {
  const handler = useCallback(async (type: NotificationType) => {
    try {
      await handleGroupInvite(notification.payload.groupId, {
        type,
        referenceId: notification.referenceId ?? "",
      });
      refetch();
    } catch (e) {
      console.error(getFirstErrorMessageFromAxios(e, "Unknown error occurred"));
    }
  }, []);

  return (
    <BaseNotification
      notification={notification}
      selected={selected}
      actions={
        <div className="flex items-center">
          {type === "GROUP_INVITE_INCOMING" && (
            <>
              <button
                className="bg-secondary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full md:h-10 md:w-10"
                onClick={() => {
                  handler(NotificationType.GROUP_INVITE_ACCEPTED);
                }}
              >
                <Check />
              </button>
              <button
                className="ml-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-2 border-red-700 md:ml-4 md:h-10 md:w-10"
                onClick={() => {
                  handler(NotificationType.GROUP_INVITE_DECLINED);
                }}
              >
                <Cross />
              </button>
            </>
          )}
        </div>
      }
    >
      <p className="text-sm font-bold capitalize md:text-base">Group invitation</p>
      {type === NotificationType.GROUP_INVITE_INCOMING && (
        <p className="text-sm text-white/80 md:text-base">
          You've been invited to the group {notification.payload.groupName}
        </p>
      )}
      {type === NotificationType.GROUP_INVITE_ACCEPTED && (
        <>
          {notification.payload.text !== null ? (
            notification.payload.text
          ) : (
            <p className="text-sm text-white/80 md:text-base">
              You've accepted the invitation to the group {notification.payload.groupName}
            </p>
          )}
        </>
      )}
      {type === NotificationType.GROUP_INVITE_DECLINED && (
        <>
          {notification.payload.text !== null ? (
            notification.payload.text
          ) : (
            <p className="text-sm text-white/80 md:text-base">
              You've declined the invitation to the group {notification.payload.groupName}
            </p>
          )}
        </>
      )}
    </BaseNotification>
  );
}
