import { createModel } from '@rematch/core';
import { RootModel } from './index';

export enum OnboardingField {
  Email = 'email',
  Username = 'username',
  Phone = 'phoneNumber',
  ZipCode = 'zipCode',
  Age = 'age',
}

const DEFAULT_ONBOARDING_FIELDS = [OnboardingField.Username, OnboardingField.Email];

type ColorConfig = {
  primaryColor: string;
  secondaryColor: string;
  ringColor: string;
  navigationColor: string;
  buttonColor: string;
  errorColor: string;
  successColor: string;
};

type LogoConfig = {
  icon?: string;
  full?: string;
};

type AppConfig = {
  title: string;
  description: string;
};

type SettingsConfig = {
  challengesEnabled: boolean;
  groupLeaderboardEnabled: boolean;
  showCountdownName: boolean;
  onboardingType: 'STANDARD' | 'PAYMENT' | 'FREE';
  onboardingFields: OnboardingField[];
};

export type ConfigStore = {
  colors: ColorConfig;
  logo: LogoConfig;
  app: AppConfig;
  loading: boolean;
  settings: SettingsConfig;
};

export const DEFAULT_CONFIG: ConfigStore = {
  loading: true,
  colors: {
    primaryColor: '#FF4343',
    secondaryColor: '#479A3F',
    ringColor: '#FF4343',
    navigationColor: '#FF4343',
    buttonColor: '#FF4343',
    errorColor: '#FF4343',
    successColor: '#479A3F',
  },
  logo: {
    icon: undefined,
    full: undefined,
  },
  app: {
    title: 'Fanalyst',
    description: 'The Fanalyst app!',
  },
  settings: {
    challengesEnabled: true,
    groupLeaderboardEnabled: true,
    showCountdownName: true,
    onboardingType: 'FREE',
    onboardingFields: [...DEFAULT_ONBOARDING_FIELDS, OnboardingField.Phone],
  },
};

export const config = createModel<RootModel>()({
  state: DEFAULT_CONFIG,
  reducers: {
    setConfig: (state, config: ConfigStore) => config,
  },
  effects: () => ({}),
});
