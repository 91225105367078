import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Navigate, useLocation } from 'react-router-dom';

export default function Authenticated({ children }: { children: React.ReactNode }) {
  const token = useSelector((state: RootState) => state.auth?.session?.token);
  const location = useLocation();

  if (!token) {
    return <Navigate to="/welcome" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
