import { ErrorMessage } from '@hookform/error-message';
import React, { forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { ChangeHandler } from 'react-hook-form/dist/types/form';
import InputErrorMessage from './InputErrorMessage';

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  name: string;
  errors?: FieldErrors<{ [key: string]: string }>;
};

const Input = forwardRef<HTMLInputElement, Props>(({ onChange, onBlur, name, ...props }, ref) => (
  <>
    <input
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      {...props}
      className={`w-full rounded border bg-transparent p-2 ${
        props.errors && props.errors[name] ? 'border-red-500' : 'border-white'
      } ${props.className}`.trim()}
      ref={ref}
    />
    {props.errors && (
      <ErrorMessage
        errors={props.errors}
        name={name}
        render={({ message }) => <InputErrorMessage message={message} />}
      />
    )}
  </>
));

export default Input;
