import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import { FC } from 'react';

export const AddToHomeScreenPopup: FC = () => {
  return (
    <div className="absolute bottom-0 left-0 right-0 z-10">
      <AddToHomeScreen
        appId={'fanalyst-add-to-homescreen'}
        lifespan={300}
        displayPace={0}
        maxDisplayCount={3}
        skipFirstVisit={false}
        startDelay={0}
        customPromptPlatformDependencies={{
          iphone: {
            images: [
              {
                src: '/img/guidance/iphone.jpeg',
                alt: 'Guide to install application on home screen.',
              },
            ],
          },
          ipad: {
            images: [
              {
                src: '/img/guidance/ipad.png',
                alt: 'Guide to install application on home screen.',
              },
            ],
          },
          samsung: {
            images: [
              {
                src: '/img/guidance/firefox.png',
                alt: 'Guide to install application on home screen.',
              },
            ],
          },
          opera: {
            images: [
              {
                src: '/img/guidance/opera.png',
                alt: 'Guide to install application on home screen.',
              },
            ],
          },
        }}
        customPromptContent={{
          title: 'Fanalyst works best on your home screen.',
          src: '/img/defaultProfile.png',
          cancelMsg: '',
          installMsg: 'Install',
          guidanceCancelMsg: '',
        }}
        customPromptElements={{
          container: 'athContainer',
          containerAddOns: '',
          banner: 'athBanner',
          logoCell: 'athLogoCell',
          logoCellAddOns: 'athContentCell',
          logo: 'athLogo',
          titleCell: 'athTitleCell',
          titleCellAddOns: 'athContentCell',
          title: 'athTitle',
          cancelButtonCell: 'athCancelButtonCell',
          cancelButtonCellAddOns: 'athButtonCell',
          cancelButton: 'athCancelButton',
          installButtonCell: 'athInstallButtonCell',
          installButtonCellAddOns: 'athButtonCell',
          installButton: 'athInstallButton',
          installButtonAddOns: 'button',
          guidance: 'athGuidance',
          guidanceImageCell: 'athGuidanceImageCell',
          guidanceImageCellAddOns: '',
          guidanceCancelButton: 'athGuidanceCancelButton',
        }}
      />
    </div>
  );
};
