import { ConfigInjector } from '@/lib/config/config-injector';
import { BeforeGameChallenges } from '@/pages/game/BeforeGameChallenges';
import { OldCheckoutCompleted } from '@/pages/payment/CheckoutCompleted';
import { FundsAdded } from '@/pages/payment/FundsAdded';
import { SubscriptionAdded } from '@/pages/payment/SubscriptionAdded';
import Welcome from '@/pages/Welcome';
import LoginRouter from '@/router/login.router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ActivityIndicatorFullScreen } from './components/ui/ActivityIndicatorFullScreen';
import { CreditCardForm } from './components/ui/CreditCardForm';
import AuthInjector from './lib/auth/auth-injector';
import GameDox from './pages/game/GameDox';
import GameRouter from './pages/game/GameRouter';
import GameScreen from './pages/game/GameScreen';
import { HowToPlay } from './pages/HowToPlay';
import NotFound from './pages/NotFound';
import Notifications from './pages/Notifications';
import Onboarding from './pages/onboarding/Onboarding';
import OldCheckout from './pages/payment/Checkout';
import Referral from './pages/Referral';
import Subscription from './pages/subscription/Subscription';
import SubscriptionCrypto from './pages/subscription/SubscriptionCrypto';
import SubscriptionPPP from './pages/subscription/SubscriptionPPP';

const Admin = lazy(() => import('./router/admin.router'));
const ProfileRouter = lazy(() => import('./router/profile.router'));
const MainRouter = lazy(() => import('./router/main.router'));

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? '');

export default function AppRouter() {
  return (
    <BrowserRouter>
      <ConfigInjector>
        <Suspense fallback={<ActivityIndicatorFullScreen />}>
          <Routes>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/how-to-play" element={<HowToPlay />} />
            <Route element={<LoginRouter />} path="/login/*" />
            <Route element={<AuthInjector requireOnboarding={true} />}>
              <Route
                path="profile/*"
                element={
                  <Suspense fallback={<ActivityIndicatorFullScreen />}>
                    <ProfileRouter />
                  </Suspense>
                }
              />
              <Route path="/notification" element={<Notifications />} />
              <Route path="/*" element={<MainRouter />} />
              <Route element={<GameRouter />} path="/game">
                <Route path=":gameId" element={<GameScreen />} />
                <Route path=":gameId/fantime" element={<GameDox />} />
              </Route>
              <Route path="/game/:gameId/challenges" element={<BeforeGameChallenges />} />
              <Route path="/admin/*" element={<Admin />} />
            </Route>
            <Route element={<AuthInjector requireOnboarding={false} />}>
              <Route
                path="/checkout/:checkoutId"
                element={
                  <Elements stripe={stripePromise}>
                    <OldCheckout />
                  </Elements>
                }
              />
              <Route path="/checkout/completed" element={<OldCheckoutCompleted />} />
              <Route path="/checkout/funds-added" element={<FundsAdded />} />
              <Route path="/checkout/subscription-added" element={<SubscriptionAdded />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/subscription/crypto" element={<SubscriptionCrypto />} />
              <Route path="/pay-per-play" element={<SubscriptionPPP />} />
              <Route path="/subscription/crypto/:productId" element={<SubscriptionCrypto />} />

              <Route path="/card" element={<CreditCardForm />} />
            </Route>
            <Route path="/referral/:referralId" element={<Referral />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ConfigInjector>
    </BrowserRouter>
  );
}
