import { Challenge, GameStatus, GameToken, Question } from '@/api/model';
import { createModel } from '@rematch/core';
import { Socket } from 'socket.io-client';
import { RootModel } from '.';

enum PlayerType {
  // Currently not in any game.
  NONE,
  SPECTATOR,
  PLAYER,
}

export interface GameStateType {
  question: Question | null;
  questionTimeReceivedUTC: number | null;
  winnerPlayerId: string | null;
  gameToken: GameToken | null;
  preRoll: boolean;
  playerState: 'WINNER' | 'ELIMINATED' | null;
  playerStateVideoActive: boolean;
  spectatorMode: boolean;
  connected: boolean;
  directStart: boolean;
  status: GameStatus | null;
  lifeLines: number;
  disconnect: Socket.DisconnectReason | null;
  serverDisconnect: string | null;
  correctAnswerIds: string[];
  previousCorrectAnswerIds: string[];
  selectedAnswerId: string | null;
  hidden: boolean;
  answerCounts: { [key: string]: number } | null;
  challenges: Challenge[];
  showServerInfo: boolean; // debug info for server
  serverInfo: any; // debug info for server
  isDoxingActive: boolean;
  hostIsStreaming: boolean;
  playerType: PlayerType;
  reconnected: boolean;
  serverStatuses: Map<string, Array<GameStatus>>;
  joinRejectReason: string | null;
  joinSuccess: boolean | null;
}

export const initialState: GameStateType = {
  preRoll: false,
  question: null,
  questionTimeReceivedUTC: null,
  winnerPlayerId: null,
  gameToken: null,
  playerState: null,
  spectatorMode: false,
  playerStateVideoActive: false,
  status: null,
  directStart: false,
  connected: false,
  disconnect: null,
  serverDisconnect: null,
  correctAnswerIds: [],
  previousCorrectAnswerIds: [],
  selectedAnswerId: null,
  hidden: false,
  lifeLines: 0,
  answerCounts: null,
  challenges: [],
  showServerInfo: false,
  serverInfo: null,
  isDoxingActive: false,
  hostIsStreaming: false,
  playerType: PlayerType.NONE,
  reconnected: false,
  serverStatuses: new Map<string, Array<GameStatus>>(),
  joinRejectReason: null,
  joinSuccess: null,
};

export const game = createModel<RootModel>()({
  state: initialState,
  reducers: {
    SET_PRE_ROLL: (state, preRoll: boolean): GameStateType => ({
      ...state,
      preRoll,
    }),
    SET_GAME_TOKEN: (state, gameToken: GameToken | null) => ({
      ...initialState, // reset state
      gameToken,
      directStart: state.directStart,
    }),
    RESET: ({ gameToken, directStart }): GameStateType => ({
      ...initialState,
      gameToken,
      directStart,
    }),
    SET_HOST_STREAMING: (state, hostIsStreaming: boolean): GameStateType => ({
      ...state,
      hostIsStreaming,
    }),
    SET_DOXING_ACTIVE: (state, isDoxingActive: boolean): GameStateType => ({
      ...state,
      isDoxingActive,
    }),
    SET_DISCONNECT: ({ directStart, gameToken }, disconnect: Socket.DisconnectReason | null): GameStateType => ({
      ...initialState, // reset state
      disconnect,
      serverDisconnect: null,
      connected: false,
      directStart,
      gameToken,
    }),
    SET_SERVER_DISCONNECT: ({ directStart, gameToken }, serverDisconnect: string | null): GameStateType => ({
      ...initialState, // reset state
      disconnect: null,
      serverDisconnect,
      connected: false,
      directStart,
      gameToken,
    }),
    SET_QUESTION: (state, question: Question | null): GameStateType => ({
      ...state,
      question,
      questionTimeReceivedUTC: question ? Date.now() : null,
      selectedAnswerId: null,
      answerCounts: null,
      correctAnswerIds: [],
      previousCorrectAnswerIds: state.correctAnswerIds,
    }),
    SET_LIFELINES: (state, lifeLines: number): GameStateType => ({
      ...state,
      lifeLines,
    }),
    SET_HIDDEN: (state, hidden: boolean): GameStateType => ({
      ...state,
      hidden,
    }),
    SET_CORRECT_ANSWER_IDS: (state, correctAnswerIds: string[]): GameStateType => ({
      ...state,
      correctAnswerIds,
      hidden: false,
    }),
    SET_ANSWER_COUNTS: (state, answerCounts: { [key: string]: number }): GameStateType => ({
      ...state,
      answerCounts,
    }),
    SET_CONNECTED: (state): GameStateType => ({
      ...state,
      winnerPlayerId: null,
      playerState: null,
      disconnect: null,
      serverDisconnect: null,
      connected: true,
    }),
    SET_DIRECT_START: (state, directStart: boolean): GameStateType => ({
      ...state,
      directStart,
    }),
    SET_PLAYER_WINNER_ID: (state, winnerPlayerId: string): GameStateType => ({
      ...state,
      winnerPlayerId,
    }),
    RESET_DISCONNECT: (state: GameStateType): GameStateType => ({
      ...state,
      disconnect: null,
      serverDisconnect: null,
    }),
    SET_PLAYER_STATE: (state, playerState: 'WINNER' | 'ELIMINATED' | null): GameStateType => ({
      ...state,
      playerState,
      playerStateVideoActive: playerState !== null,
    }),
    SET_SPECTATOR_MODE: (state, spectatorMode: boolean): GameStateType => ({
      ...state,
      spectatorMode,
    }),
    SET_PLAYER_STATE_VIDEO_ACTIVE: (state, playerStateVideoActive: boolean): GameStateType => ({
      ...state,
      playerStateVideoActive,
    }),
    SET_GAME_STATUS: (state, status: GameStatus): GameStateType => ({
      ...state,
      status,
    }),
    SET_SELECTED_ANSWER_ID: (state, selectedAnswerId: string): GameStateType => ({
      ...state,
      selectedAnswerId,
    }),
    SET_CHALLENGES: (state, challenges: any[]): GameStateType => ({
      ...state,
      challenges,
    }),
    SET_SERVER_INFO: (state, serverInfo: any): GameStateType => ({
      ...state,
      serverInfo,
    }),
    TOGGLE_SHOW_SERVER_INFO: (state): GameStateType => ({
      ...state,
      showServerInfo: !state.showServerInfo,
    }),
    SET_PLAYER_TYPE: (state, playerType: PlayerType) => ({
      ...state,
      playerType,
    }),
    SET_RECONNECTED: (state, hasReconnected: boolean) => ({
      ...state,
      reconnected: hasReconnected,
    }),
    ADD_SERVER_STATUS: (state: GameStateType, serverId: string, gameStatus: GameStatus) => {
      const statuses = state.serverStatuses.get(serverId);

      if (statuses) {
        statuses.push(gameStatus);
      } else {
        state.serverStatuses.set(serverId, [gameStatus]);
      }

      return state;
    },
    CLEAR_ALL_SERVER_STATUSES: (state: GameStateType) => ({
      ...state,
      serverStatuses: new Map<string, Array<GameStatus>>(),
    }),
    SET_JOIN_SUCCESS: (state: GameStateType, joinSuccess: boolean | null) => ({
      ...state,
      joinSuccess,
    }),
    SET_JOIN_REJECT_REASON: (state: GameStateType, joinRejectReason: string | null) => ({
      ...state,
      joinRejectReason,
    }),
  },
});
