const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  currencyDisplay: 'narrowSymbol',
});

export function formatPrice(price: number) {
  return formatter.format(price / 100);
}

export function formatChallengePrice(price: number) {
  return price === 0 ? 'FREE' : formatPrice(price);
}

export function formatShopPrice(price: string | number) {
  const value = parseFloat(price.toString());
  return formatter.format(value);
}
