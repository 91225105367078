import React from 'react';

export function PlayerCountIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.07219 5.49297C3.07219 3.6298 4.4422 2.23305 5.99796 2.23305C7.55372 2.23305 8.92372 3.6298 8.92372 5.49297C8.92372 7.35614 7.55372 8.7529 5.99796 8.7529C4.4422 8.7529 3.07219 7.35614 3.07219 5.49297ZM5.99796 0.733047C3.49358 0.733047 1.57219 2.9269 1.57219 5.49297C1.57219 8.05904 3.49358 10.2529 5.99796 10.2529C8.50233 10.2529 10.4237 8.05904 10.4237 5.49297C10.4237 2.9269 8.50233 0.733047 5.99796 0.733047ZM5.99776 10.7578C3.9563 10.7578 1.99219 11.5466 0.473512 12.9646C0.170752 13.2472 0.154477 13.7218 0.437159 14.0246C0.719842 14.3274 1.19444 14.3436 1.4972 14.061C2.74592 12.895 4.34691 12.2578 5.99776 12.2578C7.64861 12.2578 9.2496 12.895 10.4983 14.061C10.8011 14.3436 11.2757 14.3274 11.5584 14.0246C11.841 13.7218 11.8248 13.2472 11.522 12.9646C10.0033 11.5466 8.03922 10.7578 5.99776 10.7578Z"
        fill="currentColor"
      />
    </svg>
  );
}
