import DolbyPublishUserMedia from '@/lib/dolby/DolbyPublishUserMedia';
import { useEffect } from 'react';
import useMediaDevices from '../../hooks/useMediaDevices';

export function DeviceInputSelector({
  kind,
  onChange,
  selectedDeviceId: initialSelectedDeviceId,
  publisher,
}: {
  kind: 'videoinput' | 'audioinput';
  onChange: (deviceId: string) => void;
  selectedDeviceId?: string;
  publisher: DolbyPublishUserMedia;
}) {
  const { devices, selectedDeviceId, setSelectedDeviceId, error } = useMediaDevices(kind, publisher);

  if (error) {
    // TODO: handle error
    return <div>{error}</div>;
  }

  useEffect(() => {
    if (initialSelectedDeviceId) {
      setSelectedDeviceId(initialSelectedDeviceId);
    }
  }, [initialSelectedDeviceId]);

  useEffect(() => {
    if (selectedDeviceId) {
      onChange(selectedDeviceId);
    }
  }, [selectedDeviceId, onChange]);

  return (
    <select
      className="rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      value={selectedDeviceId}
      onChange={(e) => {
        const deviceId = e.target.value;
        setSelectedDeviceId(deviceId);
        onChange(deviceId);
      }}
    >
      {devices.map((device) => (
        <option key={device.deviceId} value={device.deviceId}>
          {device.label}
        </option>
      ))}
    </select>
  );
}
