import VersusIcon from "@/assets/icons/challenges/versus.svg?react";
import ShieldIcon from "@/components/ui/icon/ShieldIcon";
import { ResponsiveLogo } from "@/components/ui/logo/ResponsiveLogo";
import { classNames } from "@/lib/util/classNames";
import { RootState } from "@/store/store";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TabBarItem, TabBarItemInterface } from "./TabBarItem";

export function LogoTopBarSide({
  className = "",
  routes,
  children,
}: {
  className?: string;
  routes: any[];
  children?: ReactNode;
}) {
  return (
    <div className={classNames("relative flex grid-cols-2 gap-1 lg:gap-4", className)}>
      {routes.map((route, index) => (
        <TabBarItem key={index} title={route.title} link={route.link} showRouteIndicator={true} />
      ))}
      {children}
    </div>
  );
}

export function LogoTabBar({
  className = "",
  routes,
}: {
  className?: string;
  routes: Pick<TabBarItemInterface, "link" | "title" | "routerLink">[];
  tooltipPlacement?: "top" | "bottom";
}) {
  const navigate = useNavigate();
  const [leftSideRoutes, setLeftSideRoutes] = useState<Pick<TabBarItemInterface, "link" | "title" | "routerLink">[]>(
    [],
  );
  const [rightSideRoutes, setRightSideRoutes] = useState<Pick<TabBarItemInterface, "link" | "title" | "routerLink">[]>(
    [],
  );
  const admin = useSelector((state: RootState) => state.auth?.user?.admin);
  const config = useSelector((state: RootState) => state.config);

  useEffect(() => {
    const clonedRoutes = [...routes];
    const center = Math.floor(clonedRoutes.length / 2);
    setLeftSideRoutes(clonedRoutes.splice(0, center));
    setRightSideRoutes(clonedRoutes);
  }, [routes]);

  return (
    <div className={classNames("z-10 flex items-center justify-center gap-x-4 lg:gap-x-8", className)}>
      <div className="hidden items-center justify-end md:flex md:w-40 lg:w-60">
        {admin && (
          <div
            onClick={() => {
              navigate("/admin");
            }}
            className="z-10 mr-4 cursor-pointer items-center justify-center rounded bg-blue-600 px-4 py-1 text-black md:flex"
          >
            <ShieldIcon height={24} />
          </div>
        )}
        <LogoTopBarSide routes={leftSideRoutes} />
      </div>
      <Link to="/" className="flex flex-1 items-center justify-center">
        <ResponsiveLogo />
      </Link>
      <LogoTopBarSide routes={rightSideRoutes} className="hidden items-center md:flex md:w-40 lg:w-60">
        {config?.settings.challengesEnabled && (
          <div
            onClick={() => {
              navigate("/challenge");
            }}
            className="z-10 cursor-pointer items-center justify-center rounded bg-secondary px-4 py-1 text-black md:flex"
          >
            <VersusIcon fill="white" height={24} />
          </div>
        )}
      </LogoTopBarSide>
    </div>
  );
}
