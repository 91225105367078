import BaseHeader from '@/components/ui/header/BaseHeader';
import { FullLogo } from '@/components/ui/logo/FullLogo';
import { Link } from 'react-router-dom';
import BackIcon from '../icon/BackIcon';

export function BackHeader({ homeUrl, backUrl }: { homeUrl?: string; backUrl: string }) {
  return (
    <BaseHeader className="z-20 w-full justify-between">
      <div className="ml-4 flex w-24 items-start">
        <Link className="mt-3 inline-block" to={backUrl}>
          <BackIcon />
        </Link>
      </div>
      <Link to={homeUrl || ''} className="flex items-center">
        <FullLogo className="mt-12 max-w-[180px] sm:max-w-[250px]" />
      </Link>
      <div className="mr-4 w-24"></div>
    </BaseHeader>
  );
}
