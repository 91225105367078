import { LayerInfo, ViewProjectSourceMapping } from '@millicast/sdk';

export enum ViewerActionType {
  ADD_SOURCE = 'ADD_SOURCE',
  REMOVE_SOURCE = 'REMOVE_SOURCE',
}

export interface RemoteTrackSource {
  audioMediaId?: string;
  mediaStream: MediaStream;
  projectMapping: ViewProjectSourceMapping[];
  quality?: StreamQuality;
  sourceId: string;
  videoMediaId?: string;
}

export type RemoteTrackSources = Map<string, RemoteTrackSource>;

export type SimulcastQuality = {
  simulcastLayer?: LayerInfo; // Auto has an idx of null
  streamQuality: StreamQuality;
};

export type StreamQuality = 'Auto' | 'High' | 'Medium' | 'Low';

type StreamAudioOutboundsStats = {
  bitrate: number;
  id: string;
  mid: string;
  mimeType: string;
  timestamp: number;
  totalBytesSent: number;
};

type StreamAudioInboundsStats = {
  bitrate: number;
  id: string;
  jitter: number;
  mid: string;
  mimeType: string;
  timestamp: number;
  packetsLostDeltaPerSecond: number;
  packetsLostRatioPerSecond: number;
  totalBytesReceived: number;
  totalPacketsReceived: number;
  totalPacketsLost: number;
};

type StreamAudioStats = {
  inbounds?: StreamAudioInboundsStats[];
  outbounds?: StreamAudioOutboundsStats[];
};

type StreamVideoOutboundsStats = {
  bitrate: number;
  frameHeight: number;
  frameWidth: number;
  framesPerSecond: number;
  id: string;
  mid: string;
  mimeType: string;
  qualityLimitationReason: string;
  timestamp: number;
  totalBytesSent: number;
};

type StreamVideoInboundsStats = {
  bitrate: number;
  frameHeight: number;
  frameWidth: number;
  framesPerSecond?: number;
  jitter: number;
  id: string;
  mid: string;
  mimeType: string;
  packetsLostDeltaPerSecond: number;
  packetsLostRatioPerSecond: number;
  totalBytesReceived: number;
  totalPacketsLost: number;
  totalPacketsReceived: number;
  timestamp: number;
};

type StreamVideoStats = {
  inbounds?: StreamVideoInboundsStats[];
  outbounds?: StreamVideoOutboundsStats[];
};
export type StreamStats = {
  audio: StreamAudioStats;
  availableOutgoingBitrate?: number;
  candidateType?: string;
  currentRoundTripTime?: number;
  raw?: {
    size: number;
  };
  totalRoundTripTime?: number;
  video: StreamVideoStats;
};

export interface ViewerState {
  connecting: boolean;
  connected: boolean;
  error: boolean;
}

export interface Viewer {
  hostMediaStream?: MediaStream;
  doxMediaStream?: MediaStream;
  remoteTrackSources: RemoteTrackSources;
  startViewer: () => void;
  stopViewer: () => void;
  viewerCount: number;
  currentState: ViewerState;
}

export type ViewerAction =
  | {
      remoteTrackSource: RemoteTrackSource;
      sourceId: string;
      type: ViewerActionType.ADD_SOURCE;
    }
  | { sourceId: string; type: ViewerActionType.REMOVE_SOURCE };

export interface ViewerProps {
  handleError?: (error: string) => void;
  streamAccountId: string;
  streamName: string;
  subscriberToken?: string;
  mode: 'viewer' | 'dox' | 'host';
}
