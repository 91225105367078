export default function NotificationIcon() {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75003 20C10.1297 20 11.2489 18.8809 11.2489 17.5H6.2512C6.2512 18.8809 7.37034 20 8.75003 20ZM17.1637 14.152C16.409 13.341 14.9969 12.1211 14.9969 8.125C14.9969 5.08984 12.8688 2.66016 9.99925 2.06406V1.25C9.99925 0.559766 9.43987 0 8.75003 0C8.06018 0 7.50081 0.559766 7.50081 1.25V2.06406C4.63128 2.66016 2.50315 5.08984 2.50315 8.125C2.50315 12.1211 1.09104 13.341 0.336355 14.152C0.10198 14.4039 -0.00192612 14.7051 2.70074e-05 15C0.00432388 15.6406 0.507058 16.25 1.25393 16.25H16.2461C16.993 16.25 17.4961 15.6406 17.5 15C17.502 14.7051 17.3981 14.4035 17.1637 14.152Z"
        fill="currentColor"
      />
    </svg>
  );
}
